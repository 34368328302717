import roundActionFrequencies from "./roundActionFrequencies";

const getActionFrequencies = (ranges, hand, mixOption) => {
	let actionFrequencies = {};
	let totalFrequency = 0;
	for (let action in ranges) {
		if (ranges[action].hasOwnProperty(hand)) {
			const frequency = parseFloat(ranges[action][hand]);
			actionFrequencies[action] = frequency;
			totalFrequency += frequency;
		} else actionFrequencies[action] = 0;
	}
	actionFrequencies["0"] = totalFrequency < 1 ? 1 - totalFrequency : 0; // Sometimes rounding error caused Fold to be -0.2%, so if it's negative set it to 0
	if (mixOption === "10p") {
		actionFrequencies = roundActionFrequencies(actionFrequencies);
	}
	return actionFrequencies;
};

export default getActionFrequencies;
