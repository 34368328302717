// React stuff
import React from "react";

export default function PLOInfoBar({ priceLabel }) {
	return (
		<div className={`plo-header-div`}>
			<h5 className="plo-header small">{`${priceLabel}`}</h5>
		</div>
	);
}
