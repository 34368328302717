import { round } from "lodash";
import { allHandsArray } from "../../../constants";

export default function getCombos(range) {
	const totalCombos = allHandsArray.reduce((total, hand) => {
		let combos;
		if (!range[hand]) return total;
		if (hand.endsWith("s")) combos = range[hand] * 4;
		else if (hand.endsWith("o")) combos = range[hand] * 12;
		else combos = range[hand] * 6;
		return total + combos;
	}, 0);
	return round(totalCombos, 1);
}
