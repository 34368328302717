import base from "../../../base";

export default async function getScenarioRepData(scenarioReps, tree) {
	const scenarioRepData = {};

	const promiseArray = [];

	Object.keys(scenarioReps).forEach((rep) => {
		promiseArray.push(rep);
		promiseArray.push(base.fetch(`reps/${tree}/${rep}`, { context: this }));
	});

	const resolvedPromises = await Promise.all(promiseArray);

	for (let i = 0; i < resolvedPromises.length; i = i + 2) scenarioRepData[resolvedPromises[i]] = resolvedPromises[i + 1];

	// 	for (const rep of Object.keys(scenarioReps)) {
	// 		const data = await base.fetch(`reps/${tree}/${rep}`, { context: this });
	// 		scenarioRepData[rep] = data;
	// 	}

	return scenarioRepData;
}
