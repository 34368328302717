import React from "react";
import { capitalize } from "lodash";

export default function DealDetails({ dealInfo, accountingUsers, defaultDeal = false, makeDealDefault, retireDeal }) {
	const type = capitalize(dealInfo.dealType);
	const effectiveDate = dealInfo.effectiveDate;
	const subjectID = dealInfo.subject;
	const subjectName = accountingUsers[dealInfo.subject];
	const breakdown = dealInfo.shareholderBreakdown;

	const shareInfo = (name, breakdown) => (
		<div className="deal-detail-row">
			<span>{name}</span>
			<span>
				Action: <span>{breakdown.action}</span>%
			</span>
			<span>
				Backing: <span>{breakdown.backing}</span>%
			</span>
			<span>
				Equity: <span>{breakdown.bankroll}</span>%
			</span>
		</div>
	);

	const shareholders = Object.keys(breakdown).reduce((acc, cur) => {
		if (cur === subjectID) return acc;
		return [...acc, cur];
	}, []);

	return (
		<div className="deal-detail-cnt">
			<h3>{`${type} Deal as of ${effectiveDate}`}</h3>
			{shareInfo(subjectName, breakdown[subjectID])}
			{shareholders.map((id) => shareInfo(accountingUsers[id], breakdown[id]))}
			<div className="deal-detail-button-cnt">
				<button disabled={defaultDeal} onClick={() => retireDeal()}>
					Retire Deal
				</button>
				{defaultDeal ? <span>Default Deal</span> : <button onClick={() => makeDealDefault()}>Make Deal Default</button>}
			</div>
		</div>
	);
}

// Deal Type as of Effective Date
// Subject: Action
// Shareholders: Action
// Default status - OR - Make default deal button
// If more than one deal, make deal inactive button
