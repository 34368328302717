// React stuff
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../UserContext";
// Range Functions
import fetchRange from "../../Shared/functions/fetchRange";
import RangeTitleBar from "./RangeTitleBar";
import RangeInfoBar from "./RangeInfoBar";
import RangeCell from "./RangeCell";
import getPreviousActionFromSpot from "../../Shared/functions/getPreviousAction";
import roundFreq from "../functions/roundFreq";
// Constants and Helpers
import { cloneDeep, round } from "lodash";
import { allHandsMatrix } from "../../../constants";
import getCombos from "../functions/getCombos";

export default function RangeChart({ treeID, action, setSpotFrequencies, coordinates = null, highlight = null, treeInfo = null }) {
	const context = useContext(UserContext);
	const largeDisplay = context.settings.drdLargeDisplays;
	const [range, setRange] = useState(null);
	const [ev, setEv] = useState(null);
	const [normRange, setNormRange] = useState(null);
	const [frequency, setFrequency] = useState(0);

	// fetch range data on component loading
	useEffect(() => {
		getRange();
	}, []);
	async function getRange() {
		let rangeData = await fetchRange(treeID, action, treeInfo?.evEnabled);
		let range = {};
		let ev = {};
		if (treeInfo?.evEnabled) {
			Object.keys(rangeData).forEach((hand) => {
				if (hand === "ev_enabled") return;
				range[hand] = rangeData[hand].f;
				ev[hand] = rangeData[hand].ev;
			});
			setEv(ev);
		} else range = rangeData;
		let normRange = cloneDeep(range);
		let totalCombos = 1326;
		const previousAction = getPreviousActionFromSpot(action);
		if (previousAction) {
			const prevData = await fetchRange(treeID, previousAction);
			for (const hand in range) {
				range[hand] = range[hand] / prevData[hand];
			}
			totalCombos = getCombos(prevData);
		}
		setNormRange(normRange);
		setRange(range);
		setFrequency(getCombos(normRange) / totalCombos);
		if (setSpotFrequencies) setSpotFrequencies(getCombos(normRange) / totalCombos);
	}

	if (range) {
		let style;
		if (coordinates) style = { position: "fixed", left: coordinates.left - 50 + "px", top: coordinates.top - 25 + "px" };
		else style = { position: "relative", display: "inline-block" };
		let infoBar = <></>;
		if (treeInfo) infoBar = <RangeInfoBar action={action} normRange={normRange} treeInfo={treeInfo} />;
		return (
			<div className={`range-chart ${largeDisplay ? "range-chart-large" : ""}`} style={style}>
				<RangeTitleBar action={action} normRange={normRange} frequency={frequency} />
				{allHandsMatrix.map((row, index) => (
					<div className="range-row" key={index}>
						{row.map((hand) => {
							const freq = roundFreq(range[hand]);
							const classes = `range-cell ${highlight === hand ? "rc-focus" : ""}`;
							return <RangeCell key={hand} classes={classes} hand={hand} freq={freq} ev={ev ? ev[hand] : null} large={largeDisplay} />;
						})}
					</div>
				))}
				{infoBar}
			</div>
		);
	} else return <div></div>;
}
