import React, { useState, useContext } from "react";
import { UserContext } from "../../UserContext";
import PlayerSpotRangeChart from "./PlayerSpotRangeChart";
import MistakeList from "./MistakeList";
import Paper from "@material-ui/core/Paper";
import RangeChart from "../../DRD/components/RangeChart";
import { removePositionText } from "../../../helpers";

export default function DataDetailContainer({ reps, scenarioRepData, availableRanges }) {
	const context = useContext(UserContext);
	const [displayIndex, setDisplayIndex] = useState(0);
	const handleChange = (e) => setDisplayIndex(parseInt(e.target.value));

	const spot = scenarioRepData[Object.keys(reps)[0]].spot;
	const availableActions = availableRanges.reduce((acc, cur) => {
		if (cur.startsWith(spot) && spot.split(",").length === cur.split(",").length) acc.push(cur);
		return acc;
	}, []);

	const lastActionSimpleName = (action) => {
		const code = removePositionText(action.split(",").pop());
		return code === "1" ? "Call" : code === "3" ? "All-in" : "Raise";
	};

	const displayRangeCharts = availableActions.map((action) => (
		<RangeChart key={action} treeID={context.currentTrees[0].id} action={action} />
	));
	displayRangeCharts.unshift(<PlayerSpotRangeChart reps={reps} repData={scenarioRepData} />);

	const displayRadioButtons = availableActions.map((action, index) => (
		<label>
			<input key={action} type="radio" name="display" value={index + 1} checked={displayIndex === index + 1} onChange={handleChange} />
			{` ${lastActionSimpleName(action)}`}
		</label>
	));
	displayRadioButtons.unshift(
		<label>
			<b>Your Reps:</b> <input key="reps" type="radio" name="display" value={0} checked={displayIndex === 0} onChange={handleChange} />
			<b> View Ranges:</b>
			{"  "}
		</label>
	);

	return (
		<Paper className="data-detail-container" style={{ backgroundColor: "lightgrey" }}>
			<div className="data-detail-rng-cnt">
				<div className="data-detail-header">Your Training Rep Accuracy</div>
				{displayRangeCharts[displayIndex]}
				<div className="data-detail-h-container">{displayRadioButtons}</div>
			</div>
			<div className="data-detail-header">Suboptimal Reps</div>
			<MistakeList reps={reps} repData={scenarioRepData} />
		</Paper>
	);
}

// to implement once new tree system is in place - it'll improve ability to get availableRanges

// change to a radio button system generated from the available ranges - async grab the available ranges from the tree when going into a scenario
// breakdown, then map actions to buttons underneath the 'your rep accuracy chart'

// OR could be a range display (if that doesn't end up being too wide)
