import { toast } from 'react-toastify';
import _ from 'lodash';
import { useEffect } from 'react';
import successIcon from '../../css/images/success.svg';
import failedIcon from '../../css/images/error.svg';
// import infoIcon from '../assets/images/toast-info.svg';
// import warnIcon from '../assets/images/toast-warning.svg';
import 'react-toastify/dist/ReactToastify.css';
import useAlertReducer from '../Login/reducer/AlertReducer';

const icons = {
  success: successIcon,
  error: failedIcon,
  // warn: warnIcon,
  // info: infoIcon,
};

const colors = {
  success: '#65BD50',
  error: '#FF5F60',
  warn: '#EF934D',
  info: '#0263D1',
};

// const titles = {
//   success: 'Success!',
//   error: 'Yikes. Something went wrong.',
// };

const notify = (message, type, toastPosition, clear) => {
  if (!_.includes(['success', 'error', 'warn', 'info'], type)) return;

  toast(
    <>
      <div className="toast-body d-flex">
        <div className={type === 'error' ? 'icon-wrp error-toast' : 'icon-wrp'}>
          <img className="" src={icons[type]} alt="" />
        </div>
        <div className="toast-content-sec">
          <h5 className="title">{type}</h5>
          <p className="sm-txt">{message}</p>
        </div>
      </div>
    </>,
    {
      position: toastPosition,
      autoClose: 1000, // Automatically close after 3 seconds

      pauseOnFocusLoss: true,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: false,
      toastId: type + '-' + Date.now(),
      className: type,
      progressStyle: { background: colors[type] },
      onClose: () => {
        clear();
      },
    }
  );
};

export const Toaster = () => {
  const value = useAlertReducer((state) => state.value);
  const clear = useAlertReducer((state) => state.clear);

  useEffect(() => {
    if (value?.message) {
      notify(value?.message, value.type, 'top-right', clear);
      setTimeout(() => {
        clear();
      }, 2000);
    }
  }, [value]);

  return null;
};
