import { randomElement, lastElement } from "../../../helpers";
import { suits, trainingHandsArray } from "../../../constants";
import isHandRelevant from "./isHandRelevant";
import weightedRandomDeal from "./weightedRandomDeal";

export default function getNLHEHoleCards(ranges, possibleHands = trainingHandsArray, tightHandSelect = false, previousFrequencies = null) {
	let validHands = [];

	// Checks all possible hands for relevancy;
	// First, all mixed hands are auto-includes
	// Then, for each 100% or 0% frequency hand in each range, a 2-hand radius is checked.
	// If at least one hand in the radius is a different value, then the hand is included
	Object.keys(ranges).forEach((action) => {
		possibleHands.forEach((hand) => {
			if (validHands.includes(hand)) return;
			if (ranges[action][hand] && ranges[action][hand] !== "1.0") {
				validHands.push(hand);
				return;
			}
			if (isHandRelevant(hand, ranges[action], tightHandSelect)) validHands.push(hand);
		});
	});

	// Check to make sure there are some valid hands (for corner case where it's 100% fold or call)
	if (validHands.length === 0) validHands = possibleHands;

	// If proportional deal setting is on and it applies to the current situation,
	// Use a weighted random roll to select the hand. Otherwise, uniform random select
	let hand;
	if (previousFrequencies) hand = weightedRandomDeal(validHands, previousFrequencies);
	else hand = randomElement(validHands);

	const handArr = hand.split("");
	const suit1 = randomElement(suits);
	let suit2;
	if (lastElement(handArr) === "s") {
		suit2 = suit1;
	} else {
		suit2 = randomElement(suits.filter((suit) => suit != suit1));
	}
	return [hand, handArr[0] + suit1, handArr[1] + suit2];
}

// possibleHands and previousFrequencies -- > combine them ?
