import React from "react";
import { UserContext } from "../../UserContext";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import fetchPublicTrees from "../functions/fetchPublicTrees";
import Loading from "../../Loading";
import fetchAllTreesMetadata from "../functions/fetchAllTreesMetadata";
import base from "../../../base";
import addTreeToSelf from "../functions/addTreeToSelf";
import removeTreeFromSelf from "../functions/removeTreeFromSelf";
import userIsBasic from "../../Shared/functions/userIsBasic";
import TreeNameAndRakeFAQ from "./TreeNameAndRakeFAQ";

// TODO: in fetchAllTreesMetadata, remove stake/site pieces (not needed after tree naming update)

class TreeManager extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			allRowData: { user: [{ id: 1 }], public: [{ id: 1 }] },
			displayRows: { user: [{ id: 1 }], public: [{ id: 1 }] },
			edits: 0,
			view: "public",
			filterOptions: { treeName: [], stackDepth: [], openSize: [], rake: [], ante: [], players: [], gameType: [], straddle: [] },
			filterValues: { treeName: "", stackDepth: "", openSize: "", rake: "", ante: "", players: "", gameType: "", straddle: "" },
		};
	}

	handleRowClick = (params) => console.log(params.id);

	updateFilterOptions = () => {
		if (this.state.view === "faq") return;
		const allOptions = {
			treeName: [],
			stackDepth: [],
			openSize: [],
			rake: [],
			ante: [],
			players: [],
			gameType: [],
			straddle: [],
		};
		const filterOptions = {
			treeName: [],
			stackDepth: [],
			openSize: [],
			rake: [],
			ante: [],
			players: [],
			gameType: [],
			straddle: [],
		};
		this.state.allRowData[this.state.view].forEach((row) => {
			allOptions.treeName.push(row.treeName);
			allOptions.stackDepth.push(row.stackDepth);
			allOptions.openSize.push(row.openSize);
			allOptions.rake.push(row.rake);
			allOptions.ante.push(row.ante);
			allOptions.players.push(row.players);
			allOptions.gameType.push(row.gameType);
			allOptions.straddle.push(row.straddle);
		});

		this.state.displayRows[this.state.view].forEach((row) => {
			filterOptions.treeName.push(row.treeName);
			filterOptions.stackDepth.push(row.stackDepth);
			filterOptions.openSize.push(row.openSize);
			filterOptions.rake.push(row.rake);
			filterOptions.ante.push(row.ante);
			filterOptions.players.push(row.players);
			filterOptions.gameType.push(row.gameType);
			filterOptions.straddle.push(row.straddle);
		});
		Object.keys(filterOptions).forEach((filterOption) => {
			if (this.state.filterValues[filterOption] === "") filterOptions[filterOption] = [...new Set(filterOptions[filterOption])].sort();
			else filterOptions[filterOption] = [...new Set(allOptions[filterOption])].sort();
		});
		this.setState({ filterOptions });
	};

	handleFilterValueChange = (e) => {
		const filterValues = { ...this.state.filterValues };
		filterValues[e.target.name] = e.target.value;
		this.setState({ filterValues }, () => {
			this.updateDisplayRows();
		});
	};

	updateDisplayRows = () => {
		if (this.state.view === "faq") return;
		const displayRows = { ...this.state.displayRows };
		displayRows[this.state.view] = this.state.allRowData[this.state.view].filter((row) => {
			let match = true;
			Object.keys(this.state.filterValues).forEach((filterOption) => {
				if (this.state.filterValues[filterOption] !== "") {
					if (filterOption === "treeName") {
						if (!row[filterOption].toLowerCase().includes(this.state.filterValues[filterOption].toLowerCase())) {
							match = false;
						}
					} else if (row[filterOption] != this.state.filterValues[filterOption]) {
						match = false;
					}
				}
			});
			return match;
		});
		this.setState({ displayRows }, () => this.updateFilterOptions());
	};

	clearFilters = () => {
		this.setState(
			{
				filterValues: { treeName: "", stackDepth: "", openSize: "", rake: "", ante: "", players: "", gameType: "", straddle: "" },
			},
			() => this.updateDisplayRows()
		);
	};

	userTreesColumns = [
		{ field: "treeName", headerName: "Tree Name", flex: 4, sort: "asc" },
		{ field: "alias", headerName: "Alias (Editable)", flex: 10, editable: true },

		{ field: "stackDepth", headerName: "Depth", flex: 4 },
		{ field: "openSize", headerName: "Open Size", flex: 5 },
		{ field: "rake", headerName: "Rake Structure", flex: 6 },

		{ field: "ante", headerName: "Ante", flex: 3, sortable: false, disableColumnMenu: true },
		{ field: "players", headerName: "Players", flex: 4, disableColumnMenu: true },

		{ field: "gameType", headerName: "Game", flex: 4 },
		{ field: "straddle", headerName: "Straddle", flex: 4, disableColumnMenu: true },
		{ field: "privateTree", headerName: "Private?", flex: 3, sortable: false, disableColumnMenu: true },
		{
			field: "removeTree",
			headerName: "Remove Tree",
			width: 125,
			disableClickEventBubbling: true,
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				return <button onClick={() => this.handleRemoveTreeButtonClick(params.id, params.row.privateTree)}>Remove</button>;
			},
			sortable: false,
			disableColumnMenu: true,
		},
	];

	publicTreesColumns = [
		{ field: "treeName", headerName: "Tree Name", flex: 12, sort: "dsc" },

		{ field: "stackDepth", headerName: "Depth", flex: 4 },
		{ field: "openSize", headerName: "Open Size", flex: 5 },
		{ field: "rake", headerName: "Rake Structure", flex: 6 },

		{ field: "ante", headerName: "Ante", flex: 3, disableColumnMenu: true },
		{ field: "players", headerName: "Players", flex: 4, disableColumnMenu: true },

		{ field: "gameType", headerName: "Game", flex: 4, disableColumnMenu: true },
		{ field: "straddle", headerName: "Straddle", flex: 4, disableColumnMenu: true },

		{
			field: "addTree",
			headerName: "Add Tree",
			width: 125,
			disableClickEventBubbling: true,
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				return <button onClick={() => this.handleAddTreeButtonClick(params.id, params.row.treeName)}>Add</button>;
			},
			sortable: false,
			disableColumnMenu: true,
		},
	];

	async componentDidMount() {
		const basic = await userIsBasic(this.context.uid);
		this.setState({ basic });
		const userTrees = this.context.treePermissions;
		const publicTrees = await fetchPublicTrees();
		const allRowData = await fetchAllTreesMetadata(userTrees, publicTrees);
		this.setState({ userTrees, publicTrees, allRowData, displayRows: allRowData, loading: false }, () => this.updateFilterOptions());
	}

	editTreeAlias = (e) => {
		const id = e.id;
		const alias = e.props.value;
		base.update(`treePermissions/${this.context.uid}`, { data: { [id]: alias } });
	};

	handleRemoveTreeButtonClick = async (treeID, privateTree) => {
		if (privateTree === "Yes") {
			if (
				!confirm(
					"Remove access to this private tree from your account? Your training data WILL NOT be lost, but you will not be able to access it or re-add the tree yourself."
				)
			)
				return;
		}
		// removes all tree
		this.context.handleTreesChange([]);
		await removeTreeFromSelf(this.context.uid, treeID);
		location.reload();
	};

	handleAddTreeButtonClick = async (treeID, treeName) => {
		await addTreeToSelf(this.context.uid, treeID, treeName);
		location.reload();
	};

	setView = (view) =>
		this.setState({ view }, () => {
			this.updateFilterOptions();
			this.updateDisplayRows();
		});

	render() {
		let display;
		if (this.state.view === "user") {
			display = (
				<div style={{ background: "grey", border: "2px solid black", height: "60vh", width: "92.5%" }}>
					<DataGrid
						rows={this.state.displayRows.user}
						columns={this.userTreesColumns}
						autoPageSize
						density="compact"
						disableColumnSelector
						disableSelectionOnClick
						isRowSelectable={false}
						onEditCellPropsChange={this.editTreeAlias}
						onRowClick={this.handleRowClick}
					/>
				</div>
			);
		} else if (this.state.view === "public") {
			display = (
				<div style={{ background: "grey", border: "2px solid black", height: "60vh", width: "90%" }}>
					<DataGrid
						rows={this.state.displayRows.public}
						columns={this.publicTreesColumns}
						autoPageSize
						density="compact"
						disableColumnSelector
						disableSelectionOnClick
						isRowSelectable={false}
						onRowClick={this.handleRowClick}
					/>
				</div>
			);
		} else {
			display = <TreeNameAndRakeFAQ />;
		}
		if (this.state.basic === true) return <div>(403) - Access to this page is denied</div>;
		if (this.state.loading) return <Loading />;
		else
			return (
				<div className="tree-man-view-ctn">
					<h1 className="page-heading">Tree Manager</h1>
					<div className="tree-man-view-ctn">
						<span className={`tree-man-view-btn ${this.state.view === "public" ? "active" : ""}`} onClick={() => this.setView("public")}>
							Add Trees
						</span>
						<span className={`tree-man-view-btn ${this.state.view === "user" ? "active" : ""}`} onClick={() => this.setView("user")}>
							View/Remove Trees
						</span>
						<span className={`tree-man-view-btn ${this.state.view === "faq" ? "active" : ""}`} onClick={() => this.setView("faq")}>
							Tree Naming & Rake Structure Info/FAQ
						</span>
					</div>
					<div className={`tree-man-filter-ctn ${this.state.view === "faq" ? "display-none" : ""}`}>
						<div>
							Search:{" "}
							<input
								name="treeName"
								placeholder="Tree Name"
								value={this.state.filterValues.treeName}
								onChange={(e) => this.handleFilterValueChange(e)}
							/>
						</div>
						<div>
							Depth:
							<select name="stackDepth" value={this.state.filterValues.stackDepth} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.stackDepth.map((option) => {
									return (
										<option value={option} key={`${option}`}>
											{option}
										</option>
									);
								})}
							</select>
						</div>
						<div>
							Open Size:
							<select name="openSize" value={this.state.filterValues.openSize} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.openSize.map((option) => {
									return <option>{option}</option>;
								})}
							</select>
						</div>
						<div>
							Rake Structure:
							<select name="rake" value={this.state.filterValues.rake} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.rake.map((option) => {
									return <option>{option}</option>;
								})}
							</select>
						</div>
						<div>
							Ante:
							<select name="ante" value={this.state.filterValues.ante} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.ante.map((option) => {
									return <option>{option}</option>;
								})}
							</select>
						</div>
						<div>
							Players:
							<select name="players" value={this.state.filterValues.players} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.players.map((option) => {
									return <option>{option}</option>;
								})}
							</select>
						</div>
						<div>
							Game:
							<select name="gameType" value={this.state.filterValues.gameType} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.gameType.map((option) => {
									return <option>{option}</option>;
								})}
							</select>
						</div>
						<div>
							Straddle:
							<select name="straddle" value={this.state.filterValues.straddle} onChange={(e) => this.handleFilterValueChange(e)}>
								<option value="">Any</option>
								{this.state.filterOptions.straddle.map((option) => {
									return <option>{option}</option>;
								})}
							</select>
						</div>
						<button className={`tree-man-clear-btn`} onClick={() => this.clearFilters()}>
							Clear Filters
						</button>
					</div>
					{display}
				</div>
			);
	}
}

export default TreeManager;
