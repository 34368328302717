import base from "../../base";
import userIsAdmin from "../Shared/functions/userIsAdmin";

export default async function getTreeNamesAndIDs(uid, alphaSort) {
	if (!(await userIsAdmin(uid))) return;

	const allTrees = await base.fetch("trees", { context: this });

	console.log(allTrees);

	if (!alphaSort) {
		for (const tree in allTrees) {
			console.log(`${tree} - ${allTrees[tree].metadata.treeName}`);
		}
	} else {
		const sortable = [];
		for (const id in allTrees) {
			// console.log(id); // <-- for troubleshooting
			sortable.push([id, allTrees[id]?.metadata?.treeName]);
		}
		sortable.sort((a, b) => a[1].localeCompare(b[1]));
		sortable.forEach((option) => console.log(`${option[0]} - ${option[1]}`));
	}
}
