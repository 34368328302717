import React from "react";

const suitColors = {
	s: "#313236",
	d: "#2e6fea",
	h: "#b02822",
	c: "#4da208",
};

export default function MiniCard({ cardInfo, cardNumber }) {
	let rank = cardInfo.split("")[0].toUpperCase();
	let suit = cardInfo.split("")[1];
	let suitColor = suitColors[suit];
	return (
		<div className="mini-card" style={{ backgroundColor: suitColor, transform: `translate(${cardNumber * -10}px, 0px)` }}>
			{rank}
		</div>
	);
}
