import Gateway from '../../../config/Gateway';

const getUserProfile = () => Gateway.get('/user-profile');

const cancel = (payload) => Gateway.post('/unsubscribe/plan', payload);

export default {
  getUserProfile,
  cancel,
};
