const sortActions = (actions) => {
	// Puts 'all-in' last for the purposes of displaying action buttons
	const sortedActions = actions.sort((a, b) => {
		if (a === "3") return 1;
		else if (b === "3") return -1;
		else return 0;
	});
	return sortedActions;
};

export default sortActions;
