import { useEffect, useRef, useState } from 'react';
import ResetPasswordReducer from './reducer/ResetReducer';
import hidePasswordIcon from '../../css/images/icon-hide-password.svg';
import showPasswordIcon from '../../css/images/icon-show-password.svg';
// import history from '../../history';

export default function ResetPassword({ match, history }) {
  // const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const formRef = useRef(null);
  const [user, setUser] = useState({
    password: '',
    newpassword: '',
  });

  const [err, setErr] = useState({});
  const [visible, setVisible] = useState({
    pass: false,
    newpass: false,
  });

  const { resetPwd, isResetLoading, successMessage } = ResetPasswordReducer(
    (state) => state
  );

  useEffect(() => {
    if (successMessage === 'Password reset successfully') {
      history.push('/login');
    }
  }, [successMessage]);

  // Valid Password
  const passRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,15}$/;
  // const passRegex = /.{8,}/;
  const validatePass = (val) => passRegex.test(val);

  const validate = (name, value) => {
    let error = '';
    switch (name) {
      case 'password':
        if (!value) {
          setErr((prevState) => ({
            ...prevState,
            [name]: ' New Password is required',
          }));
          error = true;
        } else if (!validatePass(value)) {
          setErr((prevState) => ({
            ...prevState,
            [name]: 'Enter valid Password',
          }));
          error = true;
        } else {
          setErr((prevState) => ({ ...prevState, [name]: '' }));
          error = false;
        }

        break;
      case 'newpassword':
        if (!value) {
          setErr((prevState) => ({
            ...prevState,
            [name]: 'Confirm Password is required',
          }));
          error = true;
        } else if (!validatePass(value)) {
          setErr((prevState) => ({
            ...prevState,
            [name]: 'Enter valid Password',
          }));
          error = true;
        } else if (user?.password !== user?.newpassword) {
          setErr((prevState) => ({
            ...prevState,
            [name]: 'Passwords should match',
          }));
          error = true;
        } else {
          setErr((prevState) => ({ ...prevState, [name]: '' }));
          error = false;
        }

        break;

      default:
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (err?.[name]) {
      setErr({
        ...err,
        [name]: validate(name, value.trim()),
      });
    }
    setUser({
      ...user,
      [name]: value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const test = Object.keys(user).map((item) => validate(item, user?.[item]));
    if (test.every((item) => !item)) {
      const obj = {
        ...user,
        confirmPassword: user?.newpassword,
        token: match?.params?.token,
      };
      delete obj.newpassword;
      resetPwd(obj);
    }
  };

  return (
    <div className="section section-login">
      <div className="container custom-container">
        <div className="row">
          <div className="col login-form-wrp">
            <h3 className="login-tile">Reset Your Password</h3>
            <form action="#" className="login-form login">
              <div className="form-wrp">
                <div className="form-steps step-0">
                  <div className="input-wrp">
                    <label htmlFor="emailID" className="label">
                      New Password *
                    </label>
                    <div className="password-field-wrp">
                      <span
                        className="show-hide"
                        onClick={() =>
                          setVisible((prev) => ({
                            ...prev,
                            pass: !visible.pass,
                          }))
                        }
                        style={{ cursor: 'pointer' }}>
                        <img
                          src={
                            !visible?.pass ? hidePasswordIcon : showPasswordIcon
                          }
                          alt=""
                          className="show"
                        />
                      </span>
                      <input
                        id="new-password"
                        type={visible?.pass ? 'text' : 'password'}
                        className="form-control"
                        placeholder="New Password"
                        name="password"
                        onChange={handleChange}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>

                    {err?.password && (
                      <span className="text-danger">{err.password}</span>
                    )}
                  </div>
                  <div className="input-wrp">
                    <label htmlFor="password" className="label">
                      Confirm New Password *
                    </label>
                    {/* <div className="password-field-wrp">
                      <button className="show-hide hidden">
                        <span
                          className="show-hide"
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <img
                            src={
                              !isPasswordVisible
                                ? hidePasswordIcon
                                : showPasswordIcon
                            }
                            alt=""
                            className="show"
                          />
                        </span>
                      </button>
                      <input
                        id="password-field"
                        type={visible?.newpass ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Confirm Password"
                        name="newpassword"
                        onChange={handleChange}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                        }}
                      />
                      {err?.newpassword && (
                        <span className="text-danger">{err.newpassword}</span>
                      )}
                    </div> */}
                    <div className="password-field-wrp">
                      <span
                        className="show-hide"
                        onClick={() =>
                          setVisible((prev) => ({
                            ...prev,
                            newpass: !visible.newpass,
                          }))
                        }
                        style={{ cursor: 'pointer' }}>
                        <img
                          src={
                            !visible?.newpass
                              ? hidePasswordIcon
                              : showPasswordIcon
                          }
                          alt=""
                          className="show"
                        />
                      </span>
                      <input
                        id="password-field"
                        type={visible?.newpass ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Confirm Password"
                        name="newpassword"
                        onChange={handleChange}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                    {err?.newpassword && (
                      <span className="text-danger">{err.newpassword}</span>
                    )}
                  </div>
                  <div className="input-wrp reset-pw-conf">
                    <button
                      className="btn btn-primary w-100"
                      onClick={handleSubmit}>
                      {isResetLoading ? 'Loading' : 'Confirm'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
