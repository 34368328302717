import React from 'react';
import cardIcon from '../../css/images/card-img.png';
import shade from '../../css/images/shade.png';
class Dashboard extends React.Component {
  render() {
    return (
      <div className="inner-sec">
        <div className="top-img">
          <img src={cardIcon} alt="" />
        </div>
        <div className="welcome-text">
          <h1 key="header" className="page-heading homepage-header">
            Welcome To Preflop Mastery
          </h1>
        </div>
        <div className="content">
          <p>
            The Only Platform That Helps Players Of All Levels Sharpen Their
            skills And <br /> Achieve Greater Success At The Tables.{' '}
          </p>
        </div>
        <div className="shade">
          <img src={shade} alt="" />
        </div>
      </div>
    );
  }
}

export default Dashboard;
