import { PLOTrainingHandsArray } from "../../../constants";

export default function sortEVArray(rangeData, sortBy = { criteria: "ev", actionIndex: 0 }, hands = [...PLOTrainingHandsArray]) {
	if (!rangeData) return;
	const rangeToSort = rangeData[sortBy.actionIndex];
	const sortCriteria = sortBy.criteria;

	return hands.sort((a, b) => {
		if (sortCriteria === "ev") {
			return rangeToSort[b]?.ev - rangeToSort[a]?.ev;
		} else if (sortCriteria === "f") {
			if (rangeToSort[a].f && rangeToSort[b].f) {
				if (rangeToSort[a].f !== rangeToSort[b].f) return rangeToSort[b].f - rangeToSort[a].f;
				else return rangeToSort[b].ev - rangeToSort[a].ev;
			} else if (rangeToSort[a].f && !rangeToSort[b].f) return -1;
			else if (!rangeToSort[a].f && rangeToSort[b].f) return 1;
			else return rangeToSort[b].ev - rangeToSort[a].ev;
		}
	});
}
