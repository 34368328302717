import { allTrainingScenarios } from "../../../constants";

export default function filterAndSortScenarios(scenarios, selectedScenarios = Object.keys(allTrainingScenarios)) {
	// bit of a frankenstein function that sorts either an array or an object of scenarios by the order
	// that I manually put them in in the "allTrainingScenarios" constant
	let sortedScenarios;
	if (Array.isArray(scenarios)) {
		sortedScenarios = [];
		selectedScenarios.forEach((scenario) => {
			if (scenarios.includes(scenario)) sortedScenarios.push(scenario);
		});
	} else {
		sortedScenarios = {};
		selectedScenarios.forEach((scenario) => {
			if (scenarios[scenario]) sortedScenarios[scenario] = scenarios[scenario];
		});
	}
	return sortedScenarios;
}
