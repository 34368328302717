import React from "react";
import base from "../../base";
import { UserContext } from "../UserContext";
import MultiTreeSelector from "../Shared/components/MultiTreeSelector";
import getSeatNamesFromNumberOfSeats from "../Shared/functions/getSeatNamesFromNumberOfSeats";
import generatePIOExportFile from "./generatePIOExportFile";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getPositionsFromActionArray, getPositionFromAction, lastElement, removePositionText } from "../../helpers";
import fetchRange from "../Shared/functions/fetchRange";
import getSizingInfo from "../Shared/functions/getSizingInfo";
import fetchTreeInfo from "../Shared/functions/fetchTreeInfo";
import getActionDescriptionWithCall from "./getActionDescriptionWithCall";

export default class MassPioExport extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			allHUSpots: [],
			treeInfo: {},
			counter: 0,
		};
	}
	componentDidMount = () => this.loadTrees();

	getOOPandIP = (positions) => {
		const seats = getSeatNamesFromNumberOfSeats(this.context.currentTrees[0].playerCount);
		seats.unshift(seats.pop());
		seats.unshift(seats.pop());
		if (seats.indexOf(positions[0]) < seats.indexOf(positions[1])) return [positions[0], positions[1]];
		else return [positions[1], positions[0]];
	};

	getAllHUSpots = (ranges) => {
		let spots = ranges.reduce((acc, spot) => {
			const actions = spot.split(",");
			if (actions.length === 1) return acc;
			const lastAction = removePositionText(lastElement(actions));
			if (lastAction !== "1") return acc;
			const secondLastAction = removePositionText(actions[actions.length - 2]);
			if (["1", "3"].includes(secondLastAction)) return acc;
			let positions = getPositionsFromActionArray(actions);
			let potType, hu;
			// if there are only two positions, it's a standard (non mw) spot
			if (positions.length === 2) {
				hu = true;
				potType = actions.length === 2 ? "SRP" : `${actions.length}BP`;
			} else {
				hu = false;
				positions = actions.slice(-2).map((action) => getPositionFromAction(action));
				const isASqueeze = !actions.slice(0, -1).every((action) => removePositionText(action) !== "1");
				potType = isASqueeze ? (actions.length === 4 ? "SQZ" : "Other") : `Cold ${actions.length}Bet`;
			}
			const [oop, ip] = this.getOOPandIP(positions);
			acc.push({ oop, ip, potType, actions, hu });
			return acc;
		}, []);
		return spots;
	};

	getHUFilename = (spot) => {
		let fileName = spot.potType + " - ";
		const p2 = getPositionFromAction(lastElement(spot.actions));
		const p1 = getPositionFromAction(spot.actions[spot.actions.length - 2]);
		if (spot.actions.length === 2) fileName = fileName + p1.toUpperCase() + " vs " + p2.toUpperCase();
		else fileName = fileName + p1.toUpperCase() + ` ${spot.actions.length}b vs ` + p2.toUpperCase();
		return fileName;
	};
	getMWFilename = (spot) => {
		let fileName = getActionDescriptionWithCall(spot.actions);
		return fileName;
	};
	convertRangeToStringFormat = (range) => {
		let formattedRange = "";
		for (let hand in range) {
			if (range[hand] === "1.0") formattedRange = `${formattedRange},${hand}`;
			else formattedRange = `${formattedRange},${hand}:${range[hand]}`;
		}
		// remove leading comma & return
		return formattedRange.substring(1);
	};
	getPotSize = (callAction, caller) => {
		const sizingInfo = getSizingInfo(callAction, this.state.treeInfo);
		let potSize = Object.keys(sizingInfo.actionSummary).reduce((acc, cur) => acc + sizingInfo.actionSummary[cur].amountCommitted, 0);
		let effStacks = this.state.treeInfo.stackDepth - sizingInfo.actionSummary[caller].amountCommitted;
		return [potSize * 5, effStacks * 5];
	};

	exportPIORanges = async () => {
		const treeID = this.context.currentTrees[0].id;
		const treeName = this.context.currentTrees[0].treeName;
		const zip = new JSZip();
		for (let i = 0; i < this.state.allHUSpots.length; i++) {
			this.setState({ counter: this.state.counter + 1 });
			const spot = this.state.allHUSpots[i];
			const caller = getPositionFromAction(lastElement(spot.actions));
			const IPisRaiser = caller === spot.oop;
			const callAction = spot.actions.join(",");
			const raiseAction = spot.actions.slice(0, -1).join(",");
			const callRange = await fetchRange(treeID, callAction);
			const raiseRange = await fetchRange(treeID, raiseAction);
			let iprange, ooprange;
			if (IPisRaiser) {
				ooprange = this.convertRangeToStringFormat(callRange);
				iprange = this.convertRangeToStringFormat(raiseRange);
			} else {
				ooprange = this.convertRangeToStringFormat(raiseRange);
				iprange = this.convertRangeToStringFormat(callRange);
			}
			const ranges = [ooprange, iprange];
			const fileName = spot.hu ? this.getHUFilename(spot) : this.getMWFilename(spot);
			let pot, effStacks;
			[pot, effStacks] = this.getPotSize(callAction, caller);
			const fileContent = generatePIOExportFile(spot, ranges, IPisRaiser, pot, effStacks);
			zip.file(`${treeName}/${spot.potType}/${fileName}.txt`, fileContent);
		}
		zip.generateAsync({ type: "blob" }).then((content) => {
			saveAs(content, `pio-export.zip`);
		});
	};

	loadTrees = async () => {
		// not sure why, but if there are no trees selected currentTrees sometimes becomes an object
		if (!Array.isArray(this.context.currentTrees) || this.context.currentTrees.length === 0) return;
		const treeID = this.context.currentTrees[0].id;
		const ranges = await base.fetch(`trees/${treeID}/availableRanges`, { context: this });
		const treeInfo = await fetchTreeInfo(treeID);
		const allHUSpots = this.getAllHUSpots(ranges);
		this.setState({ allHUSpots, treeInfo, counter: 0 });
	};

	render() {
		return (
			<div className="pio-mass-export-container">
				<h2>PIO All HU Spots Export</h2>
				<br />
				<h4>Select a tree</h4>
				<MultiTreeSelector loadTrees={this.loadTrees} selectionLimit={1} />
				<button className="tool-button" onClick={this.exportPIORanges}>
					Export
				</button>
				<span className={`${this.state.counter ? "" : "display-none"}`}>
					Progress: {`${this.state.counter}`} / {`${this.state.allHUSpots.length} spots`}
				</span>
			</div>
		);
	}
}
