import React from "react";
import formatDollarAmount from "../functions/formatDollarAmount";

export default function SettlementSummary({ siteName, settlementAmount, currency, screenshots, notes }) {
	const profitColour = settlementAmount == 0 ? "profit-yellow" : settlementAmount > 0 ? "profit-green" : "profit-red";

	return (
		<div className="site-summary-cnt">
			<div className="site-summary-header">
				<h3>{siteName}</h3>
			</div>
			<div className={`site-summary-row settlement-amount-input`}>
				<span>Amount: </span>
				<span className={`${profitColour} bold`}>
					{formatDollarAmount(settlementAmount)} {currency}
				</span>
			</div>

			<div className={`${screenshots?.transactions ? `screenshot-list-cnt` : "display-none"}`}>
				{screenshots?.transactions?.map((url, i) => (
					<div className="link-cnt">
						<span className="screenshot-link" onClick={() => window.open(url)} title={url}>
							Screenshot #{i + 1}
						</span>
					</div>
				))}
			</div>

			<div className={`${notes ? "screenshot-list-cnt" : "display-none"}`}>
				{notes?.map((note, i) => (
					<div key={i} className="link-cnt">
						<span className="note-label">Note:</span>
						<span className="note-content">{note}</span>
					</div>
				))}
			</div>
		</div>
	);
}
