import Gateway from '../../../config/Gateway';

const listPlans = () => Gateway.get('/plans');
const subscriptionSetup = (params, payload) =>
  Gateway.post(`/subscription/setup-intent/${params}`, payload);
const subscription = (payload) => Gateway.post('/subscription', payload);
const signup = (payload) => Gateway.post('/auth/signup', payload);
const checkEmail = (payload) => Gateway.post('/auth/check-email', payload);
const checkCoupon = (payload) => Gateway.post('/subscription/coupon', payload);
const checkActivationCode = (payload) =>
  Gateway.post('/activation-code/verify', payload);

export default {
  listPlans,
  subscriptionSetup,
  subscription,
  signup,
  checkEmail,
  checkCoupon,
  checkActivationCode,
};
