import React from "react";
import Loading from "../../Loading";
import { UserContext } from "../../UserContext";
import AccordionTable from "./AccordionTable/AccordionTable";
import getScenarioRepData from "../functions/getScenarioRepData";
import sortRepDataByPosition from "../functions/sortRepDataByPosition";
import sortRepDataByTimestamp from "../functions/sortRepDataByTimestamp";
import { allTrainingScenarios } from "../../../constants";
import DataDetailContainer from "./DataDetailContainer";
import Checkbox from "../../Shared/components/Checkbox";
import getPositionFromSpot from "../../Shared/functions/getPositionFromSpot";
import { getMaxValueKey } from "../../../helpers";
import base from "../../../base";

class ScenarioBreakdown extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			// Data the ScenarioBreakdown controller passes down to populate the table rows
			scenarioRepData: {},
			repIDsByPosition: {},
			// To display the data detail container when a table row is clicked
			selectedSpot: null,
			selectedIDs: [],
			// Recent hand filter state
			repFilter: "total",
			totalReps: 0,
			repsToShow: 0,
		};
	}

	async componentDidMount() {
		const treeID = this.context.currentTrees[0].id
		const unsortedScenarioRepData = await getScenarioRepData(this.props.repScores, treeID);
		const availableRanges = await base.fetch(`trees/${treeID}/availableRanges`, { context: this });
		const scenarioRepData = sortRepDataByTimestamp(unsortedScenarioRepData);
		const totalReps = Object.keys(scenarioRepData).length;
		const repIDsByPosition = sortRepDataByPosition(scenarioRepData);
		this.setState({ scenarioRepData, repIDsByPosition, totalReps, repsToShow: totalReps, availableRanges, loading: false });
	}

	handleSpotSelect = (pos, facing, IDs = null) => {
		let selectedSpot = null;
		if (this.state.selectedSpot !== `${pos}-${facing}` && pos !== "") selectedSpot = `${pos}-${facing}`;
		this.setState({ selectedSpot, selectedIDs: IDs });
	};

	handleHandCountInput = (e) => {
		this.handleSpotSelect("", "");
		let repsToShow = e.target.value;
		let newScenarioData = {};
		let totalReps = Object.keys(this.state.scenarioRepData).length;
		let repIDs = Object.keys(this.state.scenarioRepData).reverse();
		if (this.state.repFilter === "total") {
			let counter = 0;
			for (let id of repIDs) {
				if (counter >= repsToShow) break;
				newScenarioData[id] = this.state.scenarioRepData[id];
				counter += 1;
			}
		} else {
			let counter = {};
			for (let id of repIDs) {
				// the param is what the filter is set to - the counter object has a key for each param (either spot or position)
				const param =
					this.state.repFilter === "pos" ? getPositionFromSpot(this.state.scenarioRepData[id].spot) : this.state.scenarioRepData[id].spot;
				counter[param] = counter[param] ? counter[param] + 1 : 1;
				if (counter[param] > repsToShow) continue;
				else newScenarioData[id] = this.state.scenarioRepData[id];
			}
			// grab the total reps for the label
			totalReps = counter[getMaxValueKey(counter)];
		}
		if (repsToShow > totalReps) repsToShow = totalReps;
		const repIDsByPosition = sortRepDataByPosition(newScenarioData);
		this.setState({ repsToShow, repIDsByPosition, totalReps });
	};
	handleFilterCheckbox = (e) => {
		this.setState({ repFilter: e.target.name }, () => {
			const val = { target: { value: Object.keys(this.state.scenarioRepData).length } };
			this.handleHandCountInput(val);
		});
	};

	render() {
		let loading, tableDisplay, detailDisplay;
		const treeName = this.context.treePermissions[this.context.currentTrees[0].id];
		if (this.state.loading) loading = <Loading />;
		else
			tableDisplay = (
				<AccordionTable
					repScores={this.props.repScores}
					scenarioRepData={this.state.scenarioRepData}
					repIDsByPosition={this.state.repIDsByPosition}
					handleSpotSelect={this.handleSpotSelect}
					selectedSpot={this.state.selectedSpot}
				/>
			);
		if (this.state.selectedSpot) {
			const spotRepScores = {};
			this.state.selectedIDs.forEach((rep) => (spotRepScores[rep] = this.props.repScores[rep]));
			detailDisplay = <DataDetailContainer reps={spotRepScores} scenarioRepData={this.state.scenarioRepData} availableRanges={this.state.availableRanges}/>;
		}
		let repInputMessage;
		if (this.state.repFilter === "total") repInputMessage = `Showing most recent ${this.state.repsToShow} reps`;
		else if (this.state.repFilter === "pos") repInputMessage = `Showing up to ${this.state.repsToShow} reps per position`;
		else repInputMessage = `Showing up to ${this.state.repsToShow} reps per spot`;
		const isRFI = Array.isArray(this.state.repIDsByPosition[Object.keys(this.state.repIDsByPosition)[0]]);
		const step = this.state.totalReps > 1000 ? 50 : this.state.totalReps > 500 ? 25 : this.state.totalReps > 100 ? 10 : 1;
		const repInputOptions = (
			<div className={Object.keys(this.state.scenarioRepData).length < 100 ? "display-none" : "rep-range-container"}>
				<label className="rep-range-label" htmlFor="repRange">
					{repInputMessage}
				</label>
				<input
					id="repRange"
					type="range"
					step={step}
					min={100}
					max={this.state.totalReps + step - (this.state.totalReps % step)}
					value={this.state.repsToShow}
					onChange={this.handleHandCountInput}
				/>
				<div className="rep-checkbox-container">
					<div className="rep-checkbox">
						<label>
							All Hands
							<Checkbox name="total" checked={this.state.repFilter === "total"} onChange={this.handleFilterCheckbox} />
						</label>
					</div>
					<div className="rep-checkbox">
						<label>
							Per Position
							<Checkbox name="pos" checked={this.state.repFilter === "pos"} onChange={this.handleFilterCheckbox} />
						</label>
					</div>
					<div className={isRFI ? "display-none" : "rep-checkbox"}>
						<label>
							Per Spot
							<Checkbox name="spot" checked={this.state.repFilter === "spot"} onChange={this.handleFilterCheckbox} />
						</label>
					</div>
				</div>
			</div>
		);
		return (
			<div>
				{loading}
				<div className="data-scenario-container">
					{`${treeName} - ${allTrainingScenarios[this.props.scenario]}`}
					{tableDisplay}
					{detailDisplay}
					<div className={this.state.loading ? "display-none" : "breakdownBottomMenu"}>
						<button className="data-back-btn" onClick={this.props.backToDataOverview}>
							Back
						</button>
						{repInputOptions}
					</div>
				</div>
			</div>
		);
	}
}

export default ScenarioBreakdown;

// https://material-ui.com/components/tables/#collapsible-table

// passed in Data from AccordionTable to get the repIDs, this.props.repScores to find the scores/mistakes, scenarioRepData to find the details.
