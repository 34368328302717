// React Stuff
import React from "react";
// Data Specific Functions & Components
import getScenarioTotalScore from "../functions/getScenarioTotalScore";
import getNumberOfReps from "../functions/getNumberOfReps";
// Material UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// Helpers & Constants
import { allTrainingScenarios } from "../../../constants";
import { getAccuracy, getColorFromPercentage } from "../../../helpers";

export default function DataOverview({ userRepHistory, setScenario }) {
	const useStyles = makeStyles({
		tableContainer: {
			border: "none",
			maxWidth: 900,
			marginTop: "20px",
			backgroundColor: "maroon",
		},
		accuracy: {
			fontWeight: "bold",
			fontSize: "0.9rem",
			textShadow: "1px 1px #222",
		},
		total: {
			borderTop: "3px solid black",
		},
	});
	const classes = useStyles();
	const StyledTableRow = withStyles(() => ({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: "rgba(0,0,0,0.55)",
			},
			"&:nth-of-type(even)": {
				backgroundColor: "rgba(0,0,0,0.45)",
			},
			"&:hover": {
				backgroundColor: "rgba(0,0,0,0.65)",
			},
		},
	}))(TableRow);
	const StyledTableCell = withStyles(() => ({
		head: {
			padding: "10px",
			backgroundColor: "black",
			color: "white",
		},
		body: {
			color: "white",
			border: "none",
			"& >button": {
				padding: -1,
			},
			"& >button:hover": {
				filter: "brightness(80%)",
			},
		},
	}))(TableCell);

	// Getting summaries of repHistory broken down by scenario
	const getScenarioSummaryData = (scenario) => {
		const scenarioName = allTrainingScenarios[scenario];
		const score = getScenarioTotalScore(userRepHistory[scenario]);
		const reps = getNumberOfReps(userRepHistory[scenario]);
		return { scenario, scenarioName, score, reps };
	};
	const sortedScenarios = Object.keys(userRepHistory).sort((a, b) => {
		return Object.keys(allTrainingScenarios).indexOf(a) - Object.keys(allTrainingScenarios).indexOf(b);
	});
	const scenarioSummaries = sortedScenarios.map((scenario) => {
		return getScenarioSummaryData(scenario);
	});
	// Getting total summary of user's rep data
	const totalReps = Object.keys(userRepHistory).reduce((acc, val) => {
		return { ...acc, ...userRepHistory[val] };
	}, {});
	const totalSummary = { scenarioName: "TOTAL", score: getScenarioTotalScore(totalReps), reps: Object.keys(totalReps).length };

	return (
		<TableContainer className={classes.tableContainer} component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<StyledTableCell>Scenario</StyledTableCell>
						<StyledTableCell align="right">Reps</StyledTableCell>
						<StyledTableCell align="right">Total Score</StyledTableCell>
						<StyledTableCell align="right">Accuracy %</StyledTableCell>
						<StyledTableCell align="center">Breakdown</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{scenarioSummaries.map((summary) => (
						<StyledTableRow key={summary.scenario}>
							<StyledTableCell component="th" scope="row">
								{summary.scenarioName}
							</StyledTableCell>
							<StyledTableCell align="right">{summary.reps}</StyledTableCell>
							<StyledTableCell align="right">{summary.score}</StyledTableCell>
							<StyledTableCell className={classes.accuracy} style={{ color: getColorFromPercentage(getAccuracy(summary.score) / 100) }} align="right">
								{getAccuracy(summary.score)}
							</StyledTableCell>
							<StyledTableCell align="center">
								<button onClick={() => setScenario(summary.scenario)}>
									<ArrowForwardIosIcon style={{ fontSize: "0.9em", margin: "2px 2px 1px 3px" }} />
								</button>
							</StyledTableCell>
						</StyledTableRow>
					))}
					<StyledTableRow className={classes.total} key={totalSummary.scenario}>
						<StyledTableCell component="th" scope="row">
							<b>{totalSummary.scenarioName}</b>
						</StyledTableCell>
						<StyledTableCell align="right">
							<b>{totalSummary.reps}</b>
						</StyledTableCell>
						<StyledTableCell align="right">
							<b>{totalSummary.score}</b>
						</StyledTableCell>
						<StyledTableCell className={classes.accuracy} style={{ color: getColorFromPercentage(getAccuracy(totalSummary.score) / 100) }} align="right">
							<b>{getAccuracy(totalSummary.score)}</b>
						</StyledTableCell>
						<StyledTableCell align="right"></StyledTableCell>
					</StyledTableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}
