import '../../css/scss/footer.scss';
export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-info">
        <div className="container custom-container">
          <p className="footer-copy">©PREFLOP MASTERY. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}
