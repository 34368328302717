// React stuff
import React, { useState, useEffect } from "react";
// Components
import PLOTitleBar from "./PLOTitleBar";
import PLORangeTile from "./PLORangeTile";
import PLOFoldTile from "./PLOFoldTile";
import PLOInfoBar from "./PLOInfoBar";
// Range Functions
import fetchRange from "../../Shared/functions/fetchRange";
import getPreviousActionFromSpot from "../../Shared/functions/getPreviousAction";
// Constants and Helpers
import getPLOCombos from "../functions/getPLOCombos";
import getEVSortArray from "../functions/getEVSortArray";
import getSizingInfo from "../../Shared/functions/getSizingInfo";
import { cloneDeep, initial, round } from "lodash";
import { lastElement, removePositionText } from "../../../helpers";
import getBreakevenValue from "../functions/getBreakevenValue";
import PLOSearchField from "./PLOSearchField";
import { ranks } from "../../../constants";
import filterHands from "../functions/filterHands";
import PLOScrollBar from "./PLOScrollBar";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import PLOFilterButtons from "./PLOFilterButtons";

let TOTAL_COMBOS = 270725; // let because this value changes to calculate compound range frequencies
const NUMBER_OF_HANDS = 13;

export default function PLORangeStack({ treeID, actions, setSpotFrequencies, treeInfo = null, initialQuery = "", coordinates = null }) {
	const [ranges, setRanges] = useState(null);
	const [evSortArray, setEvSortArray] = useState([]);
	const [breakevenIndex, setBreakevenIndex] = useState(null);
	const [displayIndex, setDisplayIndex] = useState(null);
	const [frequencies, setFrequencies] = useState(0);
	const [sortBy, setSortBy] = useState({ criteria: "f", actionIndex: 0 });
	const [searchQuery, setSearchQuery] = useState("");
	const [filters, setFilters] = useState(["", ""]); // pair, suitedness

	// fetch range data & other first-load stuff
	useEffect(() => {
		getRange();
	}, []);

	useEffect(() => {
		if (!ranges) return; // prevents running on first render
		const hands = filterHands(searchQuery, filters);
		const evSortArray = getEVSortArray(ranges, sortBy, hands);
		const breakevenValue = getBreakevenValue(actions[0], treeInfo);
		// do not refactor! times tried: 2
		const breakevenIndex = evSortArray.findIndex((hand) => !(ranges[sortBy.actionIndex][hand][sortBy.criteria] > breakevenValue));
		setEvSortArray(evSortArray);
		setBreakevenIndex(breakevenIndex);
		setDisplayIndex(0);
	}, [searchQuery, sortBy, filters]);

	async function getRange() {
		const rangePromises = [];
		actions.forEach((action) => rangePromises.push(fetchRange(treeID, action, true)));
		let ranges = await Promise.all(rangePromises);
		let evSortArray = getEVSortArray(ranges, sortBy);
		const breakevenValue = getBreakevenValue(actions[0], treeInfo);
		// do not refactor
		const breakevenIndex = evSortArray.findIndex((hand) => !(ranges[sortBy.actionIndex][hand][sortBy.criteria] > breakevenValue));
		const previousAction = getPreviousActionFromSpot(actions[0]);
		if (previousAction) {
			const prevData = await fetchRange(treeID, previousAction, true);
			TOTAL_COMBOS = getPLOCombos(prevData);
			for (const range in ranges) {
				for (const hand in range) {
					if (hand !== "ev_enabled" && prevData[hand]?.f) range[hand].f = range[hand]?.f / prevData[hand]?.f;
				}
			}
		}
		const frequencies = ranges.map((range) => getPLOCombos(range) / TOTAL_COMBOS);
		setFrequencies(frequencies);
		if (setSpotFrequencies) ranges.forEach((range) => setSpotFrequencies(getPLOCombos(range) / TOTAL_COMBOS));
		setEvSortArray(evSortArray);
		setBreakevenIndex(breakevenIndex);
		setDisplayIndex(0);
		setRanges(ranges);
		if (initialQuery) setSearchQuery(initialQuery);
	}

	const getDisplayTiles = (index) => {
		const displayTiles = [];
		let containsFoldIndicator = false;
		for (let i = index; i < index + NUMBER_OF_HANDS * 2; i++) {
			const hand = evSortArray[i];
			if (!hand) continue;
			const handData = [];
			if (i === breakevenIndex && evSortArray.length > NUMBER_OF_HANDS * 2) {
				displayTiles.push(<PLOFoldTile />);
				containsFoldIndicator = true;
			}
			ranges.forEach((range) => handData.push(range[hand]));
			displayTiles.push(
				<PLORangeTile key={`${hand}-${index}`} index={index} hand={hand} handData={handData} setSearchQuery={setSearchQuery} />
			);
		}
		if (containsFoldIndicator) displayTiles.pop();
		return displayTiles;
	};

	const getTitleBar = () => {
		const titleBar = [];
		titleBar.push(<PLOSearchField key={"search"} searchQuery={searchQuery} handleSearch={handleSearch} handleXClick={handleXClick} />);
		actions.forEach((action, i) => {
			titleBar.push(
				<PLOTitleBar
					key={`${action}${i}`}
					index={i}
					action={action}
					frequency={frequencies[i]}
					sortBy={cloneDeep(sortBy)}
					handleSortChange={handleSortChange}
				/>
			);
		});
		return titleBar;
	};
	const getInfoBar = () => {
		const infoBar = [];
		actions.forEach((action, i) => {
			const sizingInfo = getSizingInfo(action, treeInfo);
			if (!i) infoBar.push(<h5 className="hand-col-foot">{`Pot: ${sizingInfo.potSize / 2}bb`}</h5>);
			// Pot & raise sizing
			const actionCode = removePositionText(lastElement(action.split(",")));
			const priceLabel = `${actionCode === "1" ? "Opp. Size: " : "Raise to: "}${round(sizingInfo.priceToCall / 2, 2)}bb`;
			infoBar.push(<PLOInfoBar priceLabel={priceLabel} />);
		});
		return infoBar;
	};

	const handleWheel = (e) => {
		if (evSortArray.length < NUMBER_OF_HANDS * 2) return;
		const scrollFactor = Math.min(Math.floor(evSortArray.length / 75), NUMBER_OF_HANDS);
		// scroll up
		if (e.deltaY <= 0) displayIndex > scrollFactor ? setDisplayIndex(Math.max(displayIndex - scrollFactor, 0)) : setDisplayIndex(0);
		// scroll down
		else
			displayIndex + scrollFactor < evSortArray.length - NUMBER_OF_HANDS * 2
				? setDisplayIndex(displayIndex + scrollFactor)
				: setDisplayIndex(evSortArray.length - NUMBER_OF_HANDS * 2);
	};
	const handleSortChange = (criteria, actionIndex) => {
		if (
			criteria === sortBy.criteria &&
			actionIndex === sortBy.actionIndex &&
			displayIndex !== breakevenIndex - NUMBER_OF_HANDS &&
			!searchQuery
		)
			setDisplayIndex(breakevenIndex - NUMBER_OF_HANDS);
		else setSortBy({ criteria, actionIndex });
	};
	const handleSearch = (newValue) => {
		if (newValue.length > 4) return;
		if (newValue.split("").some((letter) => !ranks.includes(letter.toUpperCase()))) return;
		setSearchQuery(newValue.toUpperCase());
	};
	const handleXClick = () => setSearchQuery("");
	const handleFilterSet = (category, property) => {
		if (category === "pair") {
			if (filters[0] !== property) setFilters([property, filters[1]]);
			else setFilters(["", filters[1]]);
		} else {
			if (filters[1] !== property) setFilters([filters[0], property]);
			else setFilters([filters[0], ""]);
		}
	};

	if (ranges) {
		let displayTiles = getDisplayTiles(displayIndex);
		let titleBar = getTitleBar();
		let infoBar = getInfoBar();
		let style = initialQuery
			? { position: "fixed", left: coordinates.left - 50 + "px", top: coordinates.top - 25 + "px", backgroundColor: "greysmoke" }
			: {};
		return (
			<div className={`plo-stack-container ${initialQuery ? "rd-container" : ""}`} style={style}>
				<div className={initialQuery ? "display-none" : ""}>
					<PLOFilterButtons filters={filters} handleFilterSet={handleFilterSet} />
				</div>
				<div className="plo-range-stack" onWheel={(e) => handleWheel(e)}>
					<div className="plo-header-container">{titleBar}</div>
					<div className={`plo-tile-container ${initialQuery ? "training-range" : ""}`}>{displayTiles}</div>
					<div className="plo-header-container info-bar">{infoBar}</div>
				</div>
				<PLOScrollBar numberOfHands={NUMBER_OF_HANDS} displayIndex={displayIndex} evSortArray={evSortArray} />
			</div>
		);
	} else return <div />;
}

// this might take a while but I think the range display loading for a few seconds isn't a big deal
// 1) get all the ranges and their EVs
// 2) SORT them by EV
// 3) Find where the fold EV mark would be
// 4) Determine which are the ~5 (probably more) hand's ranges immediately on top and bottom of the Fold/breakeven line
// 5) Implement 'scrolling' up and down of these... this part is probably hard, or is it?

// *6*) Implement a simple search bar, maybe at first just bluntly explaining the format of the hands
/// ----> (or actually showing it somewhat in the tiles)

// TODO: idea: have a filter that sorts top/bottom/middle X% of hands
