import React from "react";
import { getColorFromPercentage } from "../../../helpers";

export default function PlayerSpotRangeCell({ freq, hand }) {
	const color = freq === "-" ? "#ededed" : getColorFromPercentage(freq);
	return (
		<div className="range-cell" style={{ backgroundColor: color }}>
			{`${hand} \n`}
			<span className="freq">{freq}</span>
		</div>
	);
}
