// React stuff
import React from "react";
// Range Functions
import getCombos from "../functions/getCombos";
import getSizingInfo from "../../Shared/functions/getSizingInfo";
// Material UI
import Grid from "@material-ui/core/Grid";
// Constants and Helpers
import { round } from "lodash";
import { lastElement, removePositionText } from "../../../helpers";

export default function RangeTitleBar({ action, normRange, treeInfo }) {
	// Combos label
	const combos = getCombos(normRange);
	const totalCombos = 1326;
	const frequency = round((combos / totalCombos) * 100, 1);

	// Pot & raise sizing
	const sizingInfo = getSizingInfo(action, treeInfo);
	const actionCode = removePositionText(lastElement(action.split(",")));

	const comboLabel = `${combos} (${frequency}%)`;

	return (
		<Grid className={`range-info-bar`} container direction="row" justifyContent="space-around" alignItems="center">
			<span>{`Combos: ${comboLabel}`}</span>
			<span>{`Pot: ${sizingInfo.potSize / 2}bb`}</span>
			<span>{`${actionCode === "1" ? "Villain Size: " : "Raise to: "}${round(sizingInfo.priceToCall / 2, 2)}bb`}</span>
		</Grid>
	);
}
