import React from "react";
import { round } from "lodash";
import onMobile from "../../Mobile/onMobile";

export default class PlayerSpot extends React.Component {
	// the actionSummary?. question mark is included because there is a slight desync when going from
	// Xmax to >Xmax in multi-tree training. This fixes it and otherwise seems to work smoothly
	render() {
		let status, dealerChip, displayPrevFreq;
		let coordinates = this.props.coordinates;
		let actionSummary = this.props.playerActionSummary;
		// Stack and raise sizing, standardized to BBs
		let stack = round((this.props.stackDepth - (actionSummary?.amountCommitted || 0)) / 2, 1);
		let bbsCommitted = round(actionSummary?.amountCommitted / 2, 2) || "";
		// Highlight/opacity visuals based on whether or not still in the hand or active
		if (actionSummary?.folded === true) status = "folded";
		else if (actionSummary?.active === true) status = "active";
		else status = "player-circle";
		// Dealer chip positioning
		if (this.props.button) dealerChip = { cx: coordinates.dealerX, cy: coordinates.dealerY };
		else dealerChip = { visibility: "hidden" };
		// Mobile size adjustments
		const isMobile = onMobile();
		const stackFont = isMobile ? "1.4em" : "1.1em";
		const bbsCommittedFont = isMobile ? "2.1em" : "1.8em";
		const dealerChipRadius = isMobile ? "16" : "12";
		// Full ring size adjustments
		const radius = this.props.fullRing ? "60" : "65";
		const width = this.props.fullRing ? "120" : "140";
		const transX = this.props.fullRing ? "-60" : "-70";
		// Previous frequency label - check that it's the 0th spot ie only show for the hero position
		if (
			!this.props.prevFreq ||
			this.props.seatNumber !== 0 ||
			this.props.prevFreqDisplaySetting === "never" ||
			(this.props.prevFreqDisplaySetting === "<100" && this.props.prevFreq === "1.0")
		)
			displayPrevFreq = { visibility: "hidden" };
		const prevFreqX = this.props.fullRing ? "24" : "32.5";

		return (
			<svg>
				<g className={status} transform={`translate${coordinates.spot}`}>
					<circle
						className="svg-seat"
						r={radius}
						fill="white"
						stroke="#222"
						strokeWidth="2"
						onClick={(e) => {
							this.props.toggleRangeDisplay(e, this.props.tree, actionSummary, this.props.position, this.props.spot, this.props.hand);
						}}
						onMouseEnter={(e) => this.props.onMouseEnter(e, this.props.tree, actionSummary)}
						onMouseLeave={this.props.onMouseLeave}
					/>
					<rect className="player-hud" width={width} height="50" x={transX} y="15" rx="5" fill="white" stroke="#222" strokeWidth="3" />
					<text className="table-view-pos" textAnchor="middle">
						{this.props.position.toUpperCase()}
					</text>
					<text x="0" y="46" fill="#222" fontSize={stackFont} textAnchor="middle">
						{stack}
					</text>
					<text
						x={coordinates.chipX}
						y={coordinates.chipY}
						fill="white"
						stroke="#222"
						strokeWidth="0.4px"
						fontSize={bbsCommittedFont}
						textAnchor="middle">
						{bbsCommitted}
					</text>
					<text fill="#858585" {...displayPrevFreq} x={prevFreqX} y="57.5" fontSize="0.8em">
						{`${this.props.prevFreq === "1.0" ? "100" : (parseFloat(this.props.prevFreq) * 100).toFixed(1)}%`}
					</text>
				</g>
				<g transform={`translate${coordinates.spot}`}>
					<circle id="dealer-button" {...dealerChip} r={dealerChipRadius} fill="lightblue" stroke="#222" strokeWidth="2" />
				</g>
			</svg>
		);
	}
}
