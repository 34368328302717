import { round } from "lodash";
import React from "react";
import getRaiseSize from "../../Shared/functions/getRaiseSize";
import isCheckAnAvailableAction from "../functions/isCheckAnAvailableAction";
import sortActions from "../functions/sortActions";
import { Tooltip } from "@material-ui/core";
import HotkeyTooltipText from "./HotkeyTooltipText";

//TODO: maybe just pass in all the table props? like why have I spread them here
export default function TrainingTableButtons({
	// from old training interface
	ranges,
	rngRoll,
	rollRng, // function
	spot,
	scenario,
	actionSummary, // to determine price to call, replacing playerSpotArray
	handleResumeTraining,
	checkAnswer,
	mixOption,
	// from old tableview
	message,
	messageStatus,
	hidePreviousButton,
	viewPreviousHand,
	reviewIndex,
	viewNextHand,
	handleShowRanges,
	handleMarkRep,
	// contained within tableInfo... 3 is enough to just pass them all I think
	treeInfo,
}) {
	const getActionButtons = () => {
		// Check if it's a scenario where fold is needed or if call needs to be transformed into check
		let checkSpot = isCheckAnAvailableAction(spot, scenario, treeInfo.seats);
		let actionButtons;
		let sizeToCall = Object.keys(actionSummary).reduce((acc, current) => {
			return Math.max(acc, actionSummary[current].amountCommitted / 2 || 0); // Divided by two because in bbs;
		}, 0);

		if (reviewIndex !== null) {
			actionButtons = [
				<button key="res" className="action-button full-width" onClick={handleResumeTraining}>
					Resume Training
				</button>,
			];
			return actionButtons;
		}
		actionButtons = [
			<Tooltip key="fold" title={<HotkeyTooltipText text="F, 1" />} placement="top" enterDelay={750}>
				<button className="action-button" onClick={() => checkAnswer("0")}>
					<u>F</u>old
				</button>
			</Tooltip>,
		];
		if (checkSpot) actionButtons.pop();
		// Sorted so that the buttons come in call -> raise -> all-in order, when applicable
		const actions = sortActions(Object.keys(ranges));
		actions.forEach((action) => {
			switch (action) {
				case "1":
					actionButtons.push(
						<Tooltip key={action} title={<HotkeyTooltipText text="C, 2" />} placement="top" enterDelay={750}>
							<button id="1" className="action-button" key={action} onClick={() => checkAnswer(action)}>
								{checkSpot ? (
									<>
										<u>C</u>heck
									</>
								) : (
									<>
										<u>C</u>all
									</>
								)}
							</button>
						</Tooltip>
					);
					break;
				case "3":
					actionButtons.push(
						<Tooltip key={action} title={<HotkeyTooltipText text="R, 3" />} placement="top" enterDelay={750}>
							<button className="action-button" key={action} onClick={() => checkAnswer(action)}>
								All-in
							</button>
						</Tooltip>
					);
					break;
				default:
					const size = getRaiseSize(action, treeInfo.chipSize).endsWith("bb")
						? `to ${round(sizeToCall + parseFloat(getRaiseSize(action, treeInfo.chipSize).replace("bb", "")), 1)}bb`
						: getRaiseSize(action, treeInfo.chipSize);
					actionButtons.push(
						<Tooltip key={action} title={<HotkeyTooltipText text="R, 3" />} placement="top" enterDelay={750}>
							<button className="action-button" key={action} onClick={() => checkAnswer(action)}>
								<u>R</u>
								aise {`${size}`}
							</button>
						</Tooltip>
					);
			}
		});
		return actionButtons;
	};

	const getRNGButton = () => {
		if (reviewIndex !== null && rngRoll) {
			return (
				<button id="rng" className="action-button mix" key="mix">
					{`RNG: ${round(rngRoll * 100, 1)}`}
				</button>
			);
		}
		if (mixOption !== "high-freq") {
			return (
				<Tooltip key="mix" title={<HotkeyTooltipText text="Space, 4" />} placement="top" enterDelay={750}>
					<button id="rng" className="action-button mix" key="mix" onClick={rollRng}>
						{rngRoll ? `RNG: ${round(rngRoll * 100, 1)}` : "RNG Roll"}
					</button>
				</Tooltip>
			);
		}
	};

	const actionButtons = getActionButtons();
	const rngButton = getRNGButton();

	return (
		// formerly the overall div was table-view-container
		<div className="rt-all-buttons-container">
			<div className="rt-action-button-container">
				<div>{actionButtons}</div>
				{rngButton}
			</div>
			<div className={`rt-answer-nav-container ${messageStatus}`}>
				<div className="rt-nav-container">
					<button className={`rt-nav-btn ${hidePreviousButton ? "hidden" : ""} bold`} onClick={viewPreviousHand}>
						{"<"}
					</button>
					<button className={`${reviewIndex === null ? "display-none" : "rt-nav-btn"} bold`} onClick={viewNextHand}>
						{">"}
					</button>
				</div>
				<p className="rep-message">{message}</p>
				<div className="column">
					<button className={`rt-other-btn ${reviewIndex === null ? "display-none" : ""}`} onClick={handleShowRanges}>
						Show Ranges
					</button>
					<button className={`rt-other-btn ${hidePreviousButton && reviewIndex !== 0 ? "hidden" : ""}`} onClick={handleMarkRep}>
						{reviewIndex === null ? "Mark Last Hand" : "Mark This Hand"}
					</button>
				</div>
			</div>
		</div>
	);
}
