import React from "react";
import { UserContext } from "../../UserContext";
import getActionDescription from "../functions/getActionDescription";
import onMobile from "../../Mobile/onMobile";
import onTouchScreen from "../../Mobile/onTouchScreen";
import PLORangeStack from "./PLORangeStack";
import { round } from "lodash";

let lastTap;

export default class PLORangeDisplay extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			spotFrequencies: [],
			onMobile: onMobile(),
			onTouchScreen: onTouchScreen(),
		};
	}

	setSpotFrequencies = (freq) => this.setState({ spotFrequencies: [...this.state.spotFrequencies, freq] });

	getFoldFrequency = (spotFrequencies) => round(spotFrequencies.reduce((fold, freq) => (fold -= freq), 1) * 100, 1);

	render() {
		let { position, actionString } = this.props.info;
		const straddlePos = this.props.info.treeInfo.straddle ? getSeatNamesFromNumberOfSeats(this.props.info.treeInfo.seats)[0] : null;
		const posLabel = straddlePos === position ? "Straddle" : position.toUpperCase();
		const title = posLabel + (actionString !== "" ? " Facing " + getActionDescription(actionString, straddlePos) : " Open");
		return (
			<div className="range-display" onContextMenu={this.state.onTouchScreen ? (e) => e.preventDefault() : this.props.handleRightClick}>
				<div className="plo-tree-label">{this.context.treePermissions[this.props.info.treeID]}</div>
				<div className="range-display-title">{title}</div>
				<div className="range-display-subtitle">{`Fold - ${this.getFoldFrequency(this.state.spotFrequencies)}%`}</div>
				<PLORangeStack
					treeID={this.props.info.treeID}
					actions={this.props.info.ranges}
					setSpotFrequencies={this.setSpotFrequencies}
					treeInfo={this.props.info.treeInfo}
				/>
				<button className="rd-exit-button" onClick={this.props.handleRightClick}>
					x
				</button>
			</div>
		);
	}
}
