import React from "react";
import { ranks } from "../../../constants";

export default function PLODisplayHand({ hand, setSearchQuery }) {
	const suitColours = ["red", "black", "blue", "green"];
	const cardArray = [];
	let openBracket = false;
	let currentColorIndex = 0;

	hand.split("").forEach((letter) => {
		if (letter === "(") openBracket = true;
		else if (letter === ")") {
			openBracket = false;
			currentColorIndex += 1;
		} else {
			cardArray.push({ rank: letter, colour: suitColours[currentColorIndex] });
			if (!openBracket) currentColorIndex += 1;
		}
	});

	cardArray.sort((a, b) => {
		if (a.colour !== b.colour) {
			return suitColours.indexOf(a.colour) - suitColours.indexOf(b.colour);
		} else return ranks.indexOf(a.rank) - ranks.indexOf(b.rank);
	});

	const rankString = cardArray.map((card) => card.rank).join("");

	return (
		<div className="plo-hand-container">
			{cardArray.map((card, i) => {
				return (
					<div key={`${card}${i}`} className={`plo-card ${card.colour}`} onClick={() => setSearchQuery(rankString)}>
						{card.rank}
					</div>
				);
			})}
		</div>
	);
}
