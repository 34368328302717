import React from "react";
import MiniCard from "../components/MiniCard";

// Hand in the form of "K5o"/"XXs"/AA

export default function getMiniHandVisuals(hand) {
	const handArr = [];
	// NLHE
	if (hand.length < 4) {
		if (hand.endsWith("s")) {
			handArr[0] = hand.split("")[0] + "h";
			handArr[1] = hand.split("")[1] + "h";
		} else {
			handArr[0] = hand.split("")[0] + "h";
			handArr[1] = hand.split("")[1] + "s";
		}
	}
	// PLO
	else {
		const suitArray = ["h", "s", "d", "c"];
		let currentSuit = suitArray[0];
		let changeSuit = true;
		// randomly select suits for cards based on suitedness brackets
		// // cases: rainbow, 1 suited, 2 suited
		for (let i = 0; i < hand.length; i++) {
			if (hand.charAt(i) === "(") changeSuit = false;
			else if (hand.charAt(i) === ")") changeSuit = true;
			else handArr.push(`${hand[i]}${currentSuit}`);
			if (changeSuit) currentSuit = suitArray[suitArray.indexOf(currentSuit) + 1];
		}
	}
	// PLO
	let handVis = handArr.map((card, i) => <MiniCard key={card} cardInfo={card} cardNumber={i} />);
	return handVis;
}
