import { getAccuracy } from "../../../helpers";

export default function getAggressionFactor(data, repScores, scenarioRepData) {
	let untracked = true;
	let aggressionFactor = 0;
	const mistakeRepIDs = [];

	// Most recent 50 reps
	for (let i = 0; i < 50; i++) {
		if (data[i]) {
			if (getAccuracy(repScores[data[i]]) < 100) mistakeRepIDs.push(data[i]);
		} else break;
	}

	mistakeRepIDs.forEach((rep) => {
		if (scenarioRepData[rep].correctAction) {
			untracked = false;
			const selected = parseInt(scenarioRepData[rep].actionSelected);
			const correct = parseInt(scenarioRepData[rep].correctAction);
			if (selected === "3" || (selected > correct)) aggressionFactor += 1;
			else if (selected < correct) aggressionFactor -= 1;
		}
	});

	if (untracked === true) return "-";
	else return aggressionFactor;
}
