import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import './css/style.css';
import { Router, Switch } from 'react-router-dom';
import history from './history';

render(
  <Router history={history}>
    <Switch>
      <App />
    </Switch>
  </Router>,
  document.querySelector('#main')
);
