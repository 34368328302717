// React-related imports
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../UserContext";
import base from "../../../base";
// Range Trainer components/functions
import TrainingTable from "./TrainingTable";
import weightedRandomScenario from "../functions/weightedRandomScenario";
import getHandAndRangeInfo from "../functions/getHandAndRangeInfo.ts";
import MiniRangeChart from "../../DRD/components/MiniRangeChart";
import RangeChart from "../../DRD/components/RangeChart";
import MarkedHandSnackBar from "./MarkedHandSnackBar";
import PLORangeStack from "../../DRD/components/PLORangeStack";
// Helpers & other
import { getAccuracy, getDate, isObjEmpty, lastElement, randomElement } from "../../../helpers";
import { allTrainingScenarios } from "../../../constants";
import { cloneDeep, round } from "lodash";
import { nanoid } from "nanoid";
import randomizeHandSuits from "../functions/randomizeHandSuits";

export default function TrainingController(props) {
	const context = useContext(UserContext);
	const [tableProps, setTableProps] = useState({});
	const [mistakeRepList, setMistakeRepList] = useState([]);
	const [rep, setRep] = useState(1);
	const [scoreState, setScoreState] = useState({ score: "0/0", currentStreak: 0, bestStreak: 0 });
	// hand reviewing & ranges
	const [sessionReps, setSessionReps] = useState({});
	const [pausedTables, setPausedTables] = useState({});
	const [rangeDisplays, setRangeDisplays] = useState([]);
	const [hoverRangeDisplays, setHoverRangeDisplays] = useState([]);
	const [clickRangeDisplays, setClickRangeDisplays] = useState({});
	// hand mark
	const [markSuccess, setMarkSuccess] = useState(false);
	//TODO: when multitabling, add an active table that corresponds to which table will receive hotkeys

	// deal to each table on mount
	useEffect(() => {
		for (let i = 0; i < props.numberOfTables; i++) deal(i);
	}, []);

	const deal = async (tableIndex) => {
		// repID
		const repID = `REP-${nanoid(10)}`;
		let treeID, treeInfo, scenario, spot, hand, ranges, prevFreq, evEnabled, evs;
		// whether or not it's a mistake rep re-train - logic for removing them from the mistake queue to go in getMistakeInfo (extract MistakeInfo? pop MistakeInfo?)
		const repIsMistakeTraining = mistakeRepList.length > 1 && Math.random() < 0.15;
		let index;
		if (repIsMistakeTraining) {
			index = Math.floor(Math.random() * (mistakeRepList.length - 1));
			const mistakeRep = sessionReps[mistakeRepList[index]];
			({ treeID, scenario, spot } = mistakeRep);
			hand = randomizeHandSuits(mistakeRep.hand);
			treeInfo = props.treesInfo[treeID];
			evEnabled = treeInfo.hasOwnProperty("evEnabled");
			[hand, ranges, prevFreq, evs] = await getHandAndRangeInfo(
				treeID,
				props.gameType,
				spot,
				props.allTrainingSpots[treeID][spot],
				context.settings.rtProportionalDeal,
				context.settings.rtTightHandSelect,
				hand,
				evEnabled
			);
		} else {
			// randomly select training tree, then the scenario+spot, then get the hand/ranges/prevFreq
			treeID = randomElement(Object.keys(props.treesInfo));
			treeInfo = props.treesInfo[treeID];
			evEnabled = treeInfo.hasOwnProperty("evEnabled");
			if (context.settings.rtProportionalScenarios) scenario = weightedRandomScenario(props.allSelectedScenarioSpots[treeID]);
			else scenario = randomElement(Object.keys(props.allSelectedScenarioSpots[treeID]));
			spot = randomElement(props.allSelectedScenarioSpots[treeID][scenario]);
			[hand, ranges, prevFreq, evs] = await getHandAndRangeInfo(
				treeID,
				props.gameType,
				spot,
				props.allTrainingSpots[treeID][spot],
				context.settings.rtProportionalDeal,
				context.settings.rtTightHandSelect,
				undefined,
				evEnabled
			);
		}
		// then set the state of
		const newTableProps = { tableIndex, rep, repID, treeID, treeInfo, scenario, spot, hand, ranges, prevFreq, evs };
		setTableProps({ ...tableProps, [tableIndex]: newTableProps });
		setRep(rep + 1);
		if (repIsMistakeTraining) setMistakeRepList([...mistakeRepList.slice(0, index), ...mistakeRepList.slice(index + 1)]);
	};

	const getTrainingTableComponents = (tableProps) => {
		const trainingTables = [];
		Object.keys(tableProps).forEach((table, index) => {
			trainingTables.push(
				<TrainingTable
					key={`table${index}`}
					props={tableProps[table]}
					finishRep={finishRep}
					reviewHand={reviewHand}
					resumeTraining={resumeTraining}
					markRep={markRep}
					showRanges={showRanges}
					handlePlayerSpotMouseEnter={handlePlayerSpotMouseEnter}
					handlePlayerSpotMouseLeave={handlePlayerSpotMouseLeave}
					toggleRangeDisplay={toggleRangeDisplay}
				/>
			);
		});
		return trainingTables;
	};

	const finishRep = (tableProps, action, correctAction, score, rngRoll, message, messageStatus) => {
		trackRep(tableProps, action, correctAction, score, rngRoll);
		updateSessionReps(tableProps, rngRoll, message, messageStatus, action, score); // is action needed here? I had it in before in the old ver
		updateScoreAndStreaks(score);
		deal(tableProps.tableIndex);
		if (getAccuracy(score) < 100) setMistakeRepList([...mistakeRepList, tableProps.rep]);
	};

	const updateSessionReps = (tableProps, rngRoll, message, messageStatus, action, score) => {
		const repDetails = {
			...tableProps,
			rngRoll,
			message,
			messageStatus,
			actionSelected: action,
			score,
			message,
		};
		setSessionReps({ ...sessionReps, [tableProps.rep]: repDetails });
	};

	const trackRep = (tableProps, action, correctAction, score, rngRoll) => {
		const repData = {
			user: context.uid,
			timestamp: getDate(),
			mixOption: context.settings.rtOptions.mix ? context.settings.rtOptions.mix : "10p",
			spot: tableProps.spot,
			hand: tableProps.hand[0],
			actionSelected: action,
			correctAction: correctAction,
			rngRoll: rngRoll,
			score: score,
		};
		base.post(`reps/${tableProps.treeID}/${tableProps.repID}`, { data: repData });
		base.post(`users/${context.uid}/repHistory/${tableProps.treeID}/${tableProps.scenario}/${tableProps.repID}`, { data: score });
		base.post(`trees/${tableProps.treeID}/repHistory/${context.uid}/${tableProps.scenario}/${tableProps.repID}`, { data: score });
	};

	const updateScoreAndStreaks = (score) => {
		const prevScore = scoreState.score.split("/");
		const repScore = score.split("/");
		const newScore = `${round(parseFloat(prevScore[0]) + parseFloat(repScore[0]), 1)}/${
			parseFloat(prevScore[1]) + parseFloat(repScore[1])
		}`;

		let currentStreak = scoreState.currentStreak;
		let bestStreak = scoreState.bestStreak;
		if (getAccuracy(score) >= 75) {
			if (currentStreak === bestStreak) bestStreak += 1;
			currentStreak += 1;
		} else currentStreak = 0;
		setScoreState({ score: newScore, currentStreak, bestStreak });
	};

	const reviewHand = (tableIndex, reviewRep, message, messageStatus) => {
		setRangeDisplays([]);
		// if there isn't already a hand paused, set paused hand as the current table props
		const pausedHand = pausedTables[tableIndex] ? pausedTables[tableIndex] : { ...tableProps[tableIndex], message, messageStatus };
		const handToReview = sessionReps[reviewRep];
		setTableProps({ ...tableProps, [tableIndex]: handToReview });
		setPausedTables({ ...pausedTables, [tableIndex]: pausedHand });
	};

	const resumeTraining = (tableIndex) => {
		setRangeDisplays([]);
		setClickRangeDisplays([]);
		setTableProps({ ...tableProps, [tableIndex]: pausedTables[tableIndex] });
		setPausedTables({ ...pausedTables, [tableIndex]: null });
	};

	const showRanges = (tableIndex, treeID, treeInfo, spot, hand) => {
		// TODO: expand this to multi-table functionality
		const boundingRect = document.getElementById(`table-${tableIndex}`).getBoundingClientRect();
		let left = boundingRect.right;
		let top = boundingRect.top + 25;
		const actions = props.allTrainingSpots[treeID][spot];
		let reviewRangeDisplays = [];
		if (treeInfo.gameType !== "PLO") {
			for (const action of actions) {
				reviewRangeDisplays.push(
					<RangeChart
						key={action}
						treeID={treeID}
						treeInfo={treeInfo}
						action={spot + action}
						coordinates={{ left, top }}
						highlight={hand[0]}
					/>
				);
				top += 385;
			}
		} else {
			const fullActionArray = actions.map((action) => spot + action).reverse();
			reviewRangeDisplays.push(
				<PLORangeStack
					key={"review-range"}
					treeID={treeID}
					treeInfo={treeInfo}
					actions={fullActionArray}
					initialQuery={hand[0].replaceAll("(", "").replaceAll(")", "")}
					coordinates={{ left, top }}
				/>
			);
		}
		setRangeDisplays(reviewRangeDisplays);
	};

	// hover rds
	const handlePlayerSpotMouseEnter = (e, tree, actionSummary) => {
		if (props.gameType === "PLO") return;
		let actionToDisplay;
		const boundingRect = e.target.getBoundingClientRect();
		const coordinates = { left: boundingRect.right, top: boundingRect.top };
		if (actionSummary.active && actionSummary.previousAction) actionToDisplay = actionSummary.previousAction;
		else if (!actionSummary.folded && !actionSummary.active && actionSummary.currentAction) actionToDisplay = actionSummary.currentAction;
		else return;
		setHoverRangeDisplays([
			<MiniRangeChart tree={tree} key={"hvr-rng"} action={actionToDisplay} coordinates={coordinates} isMini={true} />,
		]);
	};
	const handlePlayerSpotMouseLeave = () => setHoverRangeDisplays([]);

	// click rds
	const toggleRangeDisplay = (e, tree, actionSummary, position = null, spot, hand = null) => {
		if (props.gameType === "PLO") return;
		if (!position) {
			setClickRangeDisplays({});
			setHoverRangeDisplays([]);
			return;
		}
		let actionToDisplay;
		let clickedRangeDisplays = cloneDeep(clickRangeDisplays);
		const boundingRect = e.target.getBoundingClientRect();
		const coordinates = { left: boundingRect.right, top: boundingRect.top };
		if (actionSummary.active && actionSummary.previousAction) actionToDisplay = actionSummary.previousAction;
		else if (!actionSummary.folded && !actionSummary.active && actionSummary.currentAction) actionToDisplay = actionSummary.currentAction;
		else return;
		if (clickedRangeDisplays[position]) delete clickedRangeDisplays[position];
		else {
			const highlight = position === lastElement(spot.split(",")) ? hand[0] : null;
			clickedRangeDisplays[position] = (
				<MiniRangeChart tree={tree} action={actionToDisplay} coordinates={coordinates} isMini={true} highlight={highlight} />
			);
		}
		setClickRangeDisplays(clickedRangeDisplays);
		setHoverRangeDisplays([]);
	};

	const markRep = (tableIndex, reviewRep) => {
		const repID = sessionReps[reviewRep].repID;
		const timestamp = Date.now();
		base.post(`users/${context.uid}/markedReps/${tableProps[tableIndex].treeID}/${repID}`, { data: timestamp });
		base.post(`markedReps/${context.uid}/${tableProps[tableIndex].treeID}/${repID}`, { data: timestamp });
		setMarkSuccess(true); // to display the alert/snackbar
	};

	let display, feedbackPanel;
	if (!isObjEmpty(tableProps)) {
		display = getTrainingTableComponents(tableProps);
		feedbackPanel = (
			<div id="feedback-panel">
				<div>{`Rep ${rep - 1} | Score: ${scoreState.score} ${rep !== 2 ? "- " + getAccuracy(scoreState.score) + "%" : ""}`}</div>
				<div>{`Current Scenario: ${allTrainingScenarios[tableProps[0].scenario]}`}</div>
				<div
					className={
						!scoreState.bestStreak ? "display-none" : ""
					}>{`Best Streak: ${scoreState.bestStreak} | Current Streak: ${scoreState.currentStreak}`}</div>
			</div>
		);
	}

	return (
		<div>
			{feedbackPanel}
			{display}
			{rangeDisplays}
			{hoverRangeDisplays}
			{Object.values(clickRangeDisplays)}
			<MarkedHandSnackBar markSuccess={markSuccess} setMarkSuccess={setMarkSuccess} />
		</div>
	);
}

// mistake training

// beta branch
