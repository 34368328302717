import { cloneDeep } from "lodash";

export default function getAmountCommittedFromActionSummary(actionSummary) {
	const amountCommitted = Object.keys(actionSummary).reduce((acc, cur) => {
		const pos = actionSummary[cur];
		if (pos.hasOwnProperty("amountCommitted")) return acc + pos.amountCommitted;
		else return acc;
	}, 0);
	return amountCommitted;
}
