import { cloneDeep, round } from "lodash";
import { getPositionFromAction } from "../../../helpers";
import getPriceToCall from "../../RangeTrainer/functions/getPriceToCall";
import getAmountCommittedFromActionSummary from "./getAmountCommittedFromActionSummary";
import getChipRaiseAmount from "./getChipRaiseAmount";
import getRaisePercent from "./getRaisePercent";
import getSeatNamesFromNumberOfSeats from "./getSeatNamesFromNumberOfSeats";
import getStarterActionSummary from "./getStarterActionSummary";

export default function getSizingInfo(actions, treeInfo = null) {
	if (!treeInfo) return;
	const { ante, seats, straddle, stackDepth, chipSize } = treeInfo;
	const actionArray = actions.split(",");
	const actionSummary = getStarterActionSummary(treeInfo);
	const initialPot = ante * seats + getAmountCommittedFromActionSummary(actionSummary);
	let potSize = initialPot;
	let potIncrease = 0;
	let priceToCall = getPriceToCall(actionSummary);
	let raiseAmount = 0;
	let playersInHand = getSeatNamesFromNumberOfSeats(treeInfo.seats);
	if (straddle) playersInHand.push(playersInHand.shift());
	let prevPos = null;
	actionArray.forEach((action) => {
		potSize += potIncrease;
		const pos = getPositionFromAction(action);
		const actionCode = action.replace(pos, "");
		// check for folds, remove them from playersInHand & update action summary status to folded
		const folds = [];
		for (let j = 0; j < playersInHand.length; j++) {
			if (!(pos === playersInHand[j])) {
				actionSummary[playersInHand[j]].folded = true;
				folds.push(playersInHand[j]);
			} else break;
		}
		folds.forEach((pos) => playersInHand.splice(playersInHand.indexOf(pos), 1));
		playersInHand.push(playersInHand.shift());
		// Call
		if (actionCode === "1") {
			potIncrease = priceToCall - (actionSummary[pos].amountCommitted || 0);
			actionSummary[pos].amountCommitted = priceToCall;
		} else {
			// All-in
			if (actionCode === "3") raiseAmount = stackDepth - priceToCall;
			// Min raise
			else if (actionCode === "5") raiseAmount = priceToCall;
			// Half-pot raise
			else if (actionCode === "4") raiseAmount = round((potSize + priceToCall - (actionSummary[pos].amountCommitted || 0)) * 0.5, 1);
			// Pot-size raise
			else if (actionCode === "2") raiseAmount = round(potSize + priceToCall - (actionSummary[pos].amountCommitted || 0), 1);
			// % based raise
			else if (actionCode.startsWith("40") && actionCode.length !== 2) {
				const raisePercent = getRaisePercent(actionCode);
				raiseAmount = round((potSize + priceToCall - (actionSummary[pos].amountCommitted || 0)) * raisePercent, 1);
			}
			// Chip amount raise
			else {
				raiseAmount = getChipRaiseAmount(actionCode);
				raiseAmount = raiseAmount * chipSize; // Normalizes raiseAmount to SB
			}
			priceToCall = priceToCall + raiseAmount;
			potIncrease = priceToCall - (actionSummary[pos].amountCommitted || 0);
			actionSummary[pos].amountCommitted = priceToCall;
		}
	});

	// returning the whole action summary here so it can be used when needed
	// right now that means for the pio mass export tool,
	return { potSize, priceToCall, actionSummary };
}
