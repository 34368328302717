/* eslint-disable react/prop-types */
// import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import closeIcon from '../../css/images/close.svg';
import '../../css/scss/common-modal.scss';
import '../../css/scss/login-modals.scss';

export default function CustomModal({
  defaultHeader,
  className,
  dialgName,
  show,
  closeModal,
  body,
  footer,
  header,
  bodyClassname,
  createModal,
  disableCenter,
  contentClassName,
  isLoading,
  noCloseButton,
}) {
  // const location = useLocation();
  // const titleName = getSidenavTitle(location?.pathname);

  return (
    <Modal
      className={className || ''}
      dialogClassName={dialgName || ''}
      contentClassName={contentClassName || ''}
      show={show}
      onHide={closeModal}
      backdrop="static"
      centered={!disableCenter}>
      {(header || defaultHeader || isLoading) && (
        <Modal.Header
          style={
            (isLoading && !defaultHeader && !header && { padding: '0' }) || {}
          }>
          {isLoading && <div className="animate-progress top-position" />}
          {header ||
            (defaultHeader && (
              <h4 className="modal-title">{defaultHeader}</h4>
            )) ||
            ''}
          {!noCloseButton && (
            <button
              type="button"
              className="btn-close"
              onClick={() => closeModal()}>
              <img src={closeIcon} alt="" />
            </button>
          )}
        </Modal.Header>
      )}
      {createModal ? (
        <Modal.Body className={bodyClassname || ''}>{body}</Modal.Body>
      ) : (
        body
      )}
      {footer || null}
    </Modal>
  );
}
