import React from "react";

export default function SettlementInput({
	siteName,
	siteInfo,
	handleSiteUpdate,
	deleteSite,
	openScreenshotOverlay,
	handleScreenshotRemove,
	openNoteOverlay,
	handleNoteRemove,
}) {
	// only allows numbers, periods, and commas in an input
	const scrub = (input) => input.replace(/[^\d,.-]/g, "");

	return (
		<div className="site-input-cnt">
			<div className="site-input-header">
				<h3>{siteName}</h3>{" "}
				<span className="site-delete" onClick={() => deleteSite(siteName)} title="Delete Site">
					x{" "}
				</span>{" "}
			</div>
			<div className="site-input-row settlement-amount-input">
				<label htmlFor="transactions" title="Input deposits as a negative number">
					Settlement Amount:{" "}
				</label>
				<span>
					$
					<input
						id="transactions"
						type="text"
						inputMode="numeric"
						value={siteInfo.netTransactions}
						onChange={(e) => handleSiteUpdate(scrub(e.target.value), siteName, "netTransactions")}
					/>{" "}
					{`${siteInfo.currency}`}
				</span>
				<button onClick={() => openScreenshotOverlay(siteName, "transactions")}>Add Screenshot</button>
			</div>

			<div className={`${siteInfo?.screenshots?.transactions?.length ? "screenshot-list-cnt" : "display-none"}`}>
				{siteInfo?.screenshots?.transactions?.map((url, i) => (
					<div className="link-cnt">
						<span className="screenshot-link" onClick={() => window.open(url)} title={url}>
							Transactions Screenshot #{i + 1}
						</span>
						<span
							className="screenshot-delete"
							onClick={() => handleScreenshotRemove(i, siteName, "transactions")}
							title="Remove Screenshot">
							x
						</span>
					</div>
				))}
			</div>
			<div className={`${siteInfo?.notes ? "screenshot-list-cnt" : "display-none"}`}>
				{siteInfo?.notes?.map((note, i) => (
					<div className="note-cnt">
						<div className="note-label">Note: </div>
						<div className="note-content">{note}</div>
						<div className="note-delete" onClick={() => handleNoteRemove(i, siteName)} title="Delete Note">
							x
						</div>
					</div>
				))}
			</div>
			<div className="note-add-cnt">
				<button className="note-add-button" onClick={() => openNoteOverlay(siteName)}>
					Add Note
				</button>
			</div>
		</div>
	);
}
