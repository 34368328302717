import React from "react";
import formatDollarAmount from "../functions/formatDollarAmount";
import getPreviousMonth from "../functions/getPreviousMonth";
import getProfitAndNetTransactionsByDeal from "../functions/getProfitAndNetTransactionsByDeal";
import fx from "../functions/money";

export default function StudentResult({
	user,
	studentName,
	monthYear,
	studentAccountingHistory,
	dealsInfo,
	shareholderDealList,
	currency,
}) {
	const previousMonth = getPreviousMonth(studentAccountingHistory, monthYear);
	let previousBankrolls = {};
	if (previousMonth) {
		const previousMonthSites = studentAccountingHistory[previousMonth].sites;
		Object.keys(previousMonthSites).forEach((site) => (previousBankrolls[site] = previousMonthSites[site].endBankroll));
	}


	if (!Object.keys(studentAccountingHistory).length) return <div></div>;
	if (!studentAccountingHistory[monthYear])
		return (
			<div className="student-result">
				<h2 className="student-result-header">{studentName}</h2>
				<span className="student-msg">No results posted for this time period</span>
			</div>
		);
	if (!studentAccountingHistory[monthYear].finalized)
		return (
			<div className="student-result">
				<h2 className="student-result-header">{studentName}</h2>
				<span className="student-msg">Results not finalized</span>
			</div>
		);

	// TODO: HAVE SOME SORT OF MESSAGE IF THERE ARE NO STUDENT RESULTS TO SHOW FOR THE MONTH

	// TODO: GET RID OF THE UNNEEDED ROWS, IE. WHEN THERE IS NO BANKROLL EQUITY TO BE HAD

	fx.rates = studentAccountingHistory[monthYear].rates;
	const { totalProfit, profitByDeal, netTransactionsByDeal } = getProfitAndNetTransactionsByDeal(
		studentAccountingHistory[monthYear].sites,
		previousBankrolls,
		currency,
		fx.rates
	);

	let resultShare = 0.0;
	let equityChange = 0.0;
	let amountOwed = 0.0;

	Object.keys(dealsInfo).forEach((deal) => {
		const actionShare =
			(parseFloat(dealsInfo[deal].shareholderBreakdown[user].action) + parseFloat(dealsInfo[deal].shareholderBreakdown[user].backing)) /
			100;
		const equityPercent = dealsInfo[deal].shareholderBreakdown[user].bankroll / 100;
		const dealProfit = profitByDeal.hasOwnProperty(deal) ? profitByDeal[deal] : 0;
		const dealTransactions = netTransactionsByDeal.hasOwnProperty(deal) ? netTransactionsByDeal[deal] : 0;
		const dealResultShare = dealProfit * actionShare;
		const dealEquityChange = (dealProfit - dealTransactions) * equityPercent;
		resultShare += dealResultShare;
		equityChange += dealEquityChange;
		amountOwed += dealResultShare - dealEquityChange;
	});

	return (
		<div className="student-result">
			<h2 className="student-result-header">{studentName}</h2>
			<div className="student-result-row">
				Student Total Result: {formatDollarAmount(totalProfit)} {currency}
			</div>
			<div className="student-result-row">
				Your Result Share: {formatDollarAmount(resultShare)} {currency}
			</div>
			<div className="student-result-row">
				Your Change in Bankroll Equity: {formatDollarAmount(equityChange)} {currency}
			</div>
			<div className="student-result-row">
				{amountOwed > 0 ? "Amount Owed" : "Amount Owing"}:{" "}
				<span className={amountOwed > 0 ? "profit-green bold" : amountOwed < 0 ? "profit-red bold" : "bold"}>
					{formatDollarAmount(amountOwed)}
				</span>{" "}
				{currency}
			</div>
		</div>
	);
}
