import Gateway from '../../../config/Gateway';

const googleLoginValidate = (idToken, tokenType) =>
  Gateway.post('/auth/sign-in/google', {
    idToken,
    tokenType,
  });

const login = (data) => Gateway.post('/auth/login', data);

const forgotPassword = (data) => Gateway.post('/auth/forgot-password', data);

export default {
  googleLoginValidate,
  login,
  forgotPassword,
};
