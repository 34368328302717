import fetchRange from "../../Shared/functions/fetchRange";
import getPreviousAction from "../../Shared/functions/getPreviousAction";
import getNLHEHoleCards from "./getNLHEHoleCards";
import getPLOHoleCards from "./getPLOHoleCards";

export default async function getHandAndRangeInfo(
	treeID: string,
	gameType: string,
	spot: string,
	actions: Array<string>,
	proportionalDeal: boolean = false,
	tightHandSelect: boolean = false,
	hand: Array<string> | null = null,
	evEnabled: boolean = false
) {
	let prevData;
	let prevFreq;
	let ranges: Record<any, any> = {};
	let evs: Record<any, any> = {};
	const previousAction = getPreviousAction(spot);

	for (const action of actions) {
		const data = await fetchRange(treeID, spot + action, evEnabled);
		let rangeData: Record<any, any> = {};
		let evData: Record<any, any> = {};
		if (evEnabled) {
			Object.keys(data).forEach((hand) => {
				if (data[hand].hasOwnProperty("f")) rangeData[hand] = data[hand].f;
				evData[hand] = data[hand].ev;
			});
		} else rangeData = data;
		ranges[action] = rangeData;
		evs[action] = evData;
	}

	if (previousAction) {
		prevData = await fetchRange(treeID, previousAction);
		for (const range in ranges) {
			for (const hand in ranges[range]) {
				ranges[range][hand] = ranges[range][hand] / prevData[hand];
			}
		}
	}
	// only pass in prevData for the purposes of weighted random roll when the setting is turned on
	const dealFrequencies = proportionalDeal ? prevData : undefined;
	const prevDataKeys = prevData ? Object.keys(prevData) : undefined;

	if (!hand) {
		if (!hand && gameType === "NLHE") {
			hand = getNLHEHoleCards(ranges, prevDataKeys, tightHandSelect, dealFrequencies);
		} else {
			hand = getPLOHoleCards(prevDataKeys, dealFrequencies);
		}
	}

	if (prevData) prevFreq = prevData[hand[0]];
	return [hand, ranges, prevFreq, evs];
}
