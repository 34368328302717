export const ranks = ["A", "K", "Q", "J", "T", "9", "8", "7", "6", "5", "4", "3", "2"];
export const suits = ["s", "c", "d", "h"];
// prettier-ignore
export const allHandsArray = [
	"AA", "AKs", "AQs", "AJs", "ATs", "A9s", "A8s", "A7s", "A6s", "A5s", "A4s", "A3s", "A2s",
	"AKo", "KK", "KQs", "KJs", "KTs", "K9s", "K8s", "K7s", "K6s", "K5s", "K4s", "K3s", "K2s",
	"AQo", "KQo", "QQ", "QJs", "QTs", "Q9s", "Q8s", "Q7s", "Q6s", "Q5s", "Q4s", "Q3s", "Q2s",
	"AJo", "KJo", "QJo", "JJ", "JTs", "J9s", "J8s", "J7s", "J6s", "J5s", "J4s", "J3s", "J2s",
	"ATo", "KTo", "QTo", "JTo", "TT", "T9s", "T8s", "T7s", "T6s", "T5s", "T4s", "T3s", "T2s",
	"A9o", "K9o", "Q9o", "J9o", "T9o", "99", "98s", "97s", "96s", "95s", "94s", "93s", "92s",
	"A8o", "K8o", "Q8o", "J8o", "T8o", "98o", "88", "87s", "86s", "85s", "84s", "83s", "82s",
	"A7o", "K7o", "Q7o", "J7o", "T7o", "97o", "87o", "77", "76s", "75s", "74s", "73s", "72s",
	"A6o", "K6o", "Q6o", "J6o", "T6o", "96o", "86o", "76o", "66", "65s", "64s", "63s", "62s",
	"A5o", "K5o", "Q5o", "J5o", "T5o", "95o", "85o", "75o", "65o", "55", "54s", "53s", "52s",
	"A4o", "K4o", "Q4o", "J4o", "T4o", "94o", "84o", "74o", "64o", "54o", "44", "43s", "42s",
	"A3o", "K3o", "Q3o", "J3o", "T3o", "93o", "83o", "73o", "63o", "53o", "43o", "33", "32s",
	"A2o", "K2o", "Q2o", "J2o", "T2o", "92o", "82o", "72o", "62o", "52o", "42o", "32o", "22",
];
export const allHandsMatrix = [
	["AA", "AKs", "AQs", "AJs", "ATs", "A9s", "A8s", "A7s", "A6s", "A5s", "A4s", "A3s", "A2s"],
	["AKo", "KK", "KQs", "KJs", "KTs", "K9s", "K8s", "K7s", "K6s", "K5s", "K4s", "K3s", "K2s"],
	["AQo", "KQo", "QQ", "QJs", "QTs", "Q9s", "Q8s", "Q7s", "Q6s", "Q5s", "Q4s", "Q3s", "Q2s"],
	["AJo", "KJo", "QJo", "JJ", "JTs", "J9s", "J8s", "J7s", "J6s", "J5s", "J4s", "J3s", "J2s"],
	["ATo", "KTo", "QTo", "JTo", "TT", "T9s", "T8s", "T7s", "T6s", "T5s", "T4s", "T3s", "T2s"],
	["A9o", "K9o", "Q9o", "J9o", "T9o", "99", "98s", "97s", "96s", "95s", "94s", "93s", "92s"],
	["A8o", "K8o", "Q8o", "J8o", "T8o", "98o", "88", "87s", "86s", "85s", "84s", "83s", "82s"],
	["A7o", "K7o", "Q7o", "J7o", "T7o", "97o", "87o", "77", "76s", "75s", "74s", "73s", "72s"],
	["A6o", "K6o", "Q6o", "J6o", "T6o", "96o", "86o", "76o", "66", "65s", "64s", "63s", "62s"],
	["A5o", "K5o", "Q5o", "J5o", "T5o", "95o", "85o", "75o", "65o", "55", "54s", "53s", "52s"],
	["A4o", "K4o", "Q4o", "J4o", "T4o", "94o", "84o", "74o", "64o", "54o", "44", "43s", "42s"],
	["A3o", "K3o", "Q3o", "J3o", "T3o", "93o", "83o", "73o", "63o", "53o", "43o", "33", "32s"],
	["A2o", "K2o", "Q2o", "J2o", "T2o", "92o", "82o", "72o", "62o", "52o", "42o", "32o", "22"],
];
// prettier-ignore
export const trainingHandsArray = [
	"AA", "AKs", "AQs", "AJs", "ATs", "A9s", "A8s", "A7s", "A6s", "A5s", "A4s", "A3s", "A2s",
	"AKo", "KK", "KQs", "KJs", "KTs", "K9s", "K8s", "K7s", "K6s", "K5s", "K4s", "K3s", "K2s",
	"AQo", "KQo", "QQ", "QJs", "QTs", "Q9s", "Q8s", "Q7s", "Q6s", "Q5s", "Q4s", "Q3s", "Q2s",
	"AJo", "KJo", "QJo", "JJ", "JTs", "J9s", "J8s", "J7s", "J6s", "J5s", "J4s", "J3s", "J2s",
	"ATo", "KTo", "QTo", "JTo", "TT", "T9s", "T8s", "T7s", "T6s", "T5s", "T4s", "T3s", "T2s",
	"A9o", "K9o", "Q9o", "J9o", "T9o", "99", "98s", "97s", "96s", "95s", "94s", "93s", "92s",
	"A8o", "K8o", "Q8o", "J8o", "T8o", "98o", "88", "87s", "86s", "85s", "84s", "83s", "82s",
	"A7o", "K7o", "Q7o", "J7o", "T7o", "97o", "87o", "77", "76s", "75s", "74s", "73s", "72s",
	"A6o", "K6o", "Q6o", "J6o", "T6o", "96o", "86o", "76o", "66", "65s", "64s", "63s", "62s",
	"A5o", "K5o", "Q5o", "J5o", "T5o", "95o", "85o", "75o", "65o", "55", "54s", "53s", "52s",
	"A4o", "K4o", "Q4o", "J4o", "T4o",               "74o", "64o", "54o", "44", "43s", "42s",
	"A3o", "K3o", "Q3o", "J3o",                                    "53o", "43o", "33", "32s",
	"A2o", "K2o", "Q2o", "J2o",                                                         "22",
];
// prettier-ignore
export const trainingHandsMatrix = [
	["AA", "AKs", "AQs", "AJs", "ATs", "A9s", "A8s", "A7s", "A6s", "A5s", "A4s", "A3s", "A2s"],
	["AKo", "KK", "KQs", "KJs", "KTs", "K9s", "K8s", "K7s", "K6s", "K5s", "K4s", "K3s", "K2s"],
	["AQo", "KQo", "QQ", "QJs", "QTs", "Q9s", "Q8s", "Q7s", "Q6s", "Q5s", "Q4s", "Q3s", "Q2s"],
	["AJo", "KJo", "QJo", "JJ", "JTs", "J9s", "J8s", "J7s", "J6s", "J5s", "J4s", "J3s", "J2s"],
	["ATo", "KTo", "QTo", "JTo", "TT", "T9s", "T8s", "T7s", "T6s", "T5s", "T4s", "T3s", "T2s"],
	["A9o", "K9o", "Q9o", "J9o", "T9o", "99", "98s", "97s", "96s", "95s", "94s", "93s", "92s"],
	["A8o", "K8o", "Q8o", "J8o", "T8o", "98o", "88", "87s", "86s", "85s", "84s", "83s", "82s"],
	["A7o", "K7o", "Q7o", "J7o", "T7o", "97o", "87o", "77", "76s", "75s", "74s", "73s", "72s"],
	["A6o", "K6o", "Q6o", "J6o", "T6o", "96o", "86o", "76o", "66", "65s", "64s", "63s", "62s"],
	["A5o", "K5o", "Q5o", "J5o", "T5o", "95o", "85o", "75o", "65o", "55", "54s", "53s", "52s"],
	["A4o", "K4o", "Q4o", "J4o", "T4o", ""   , ""   , "74o", "64o", "54o", "44", "43s", "42s"],
	["A3o", "K3o", "Q3o", "J3o", ""   , ""   , ""   , ""   , ""   , "53o", "43o", "33", "32s"],
	["A2o", "K2o", "Q2o", "J2o", ""   , ""   , ""   , ""   , ""   , ""   , ""   , ""   , "22"],
];

export const allTrainingScenarios = {
	rfi: "Raise First In",
	frfi: "Facing RFI",
	f3bet: "Facing 3bet",
	f3betc: "Facing 3bet (Cold)",
	f4bet: "Facing 4bet",
	f4betmw: "Facing 4bet (Multiway)",
	f5bet: "Facing 5bet",
	f6bet: "Facing 6bet",
	f7bet: "Facing 7bet",
	fjmw: "Facing Jam (Multiway)",
	sqz: "Squeeze Opportunity",
	fsqz: "Facing Squeeze",
	flmp: "Facing Limp",
	fral: "Facing Raise as Limper",
	flrr: "Facing Limp Re-raise",
	lrrr: "Facing 4bet as Limper",
	lmpj: "Limp Jams",
	olmp: "Other Limp",
	other: "Other Multiway",
};

export const actionNames = {
	0: "Fold",
	1: "Call",
	3: "All-in",
};

export const betNames = ["Open", "3bet", "4bet", "5bet", "All-in"];

export const transformCoordinatesFullRing = {
	spot0: { spot: "(255,445)", chipX: "30", chipY: "-80", dealerX: "-50", dealerY: "-70" },
	spot1: { spot: "(60,355)", chipX: "80", chipY: "-40", dealerX: "90", dealerY: "40" },
	spot2: { spot: "(90,155)", chipX: "95", chipY: "75", dealerX: "90", dealerY: "40" },
	spot3: { spot: "(330,85)", chipX: "0", chipY: "100", dealerX: "90", dealerY: "40" },
	spot4: { spot: "(645,85)", chipX: "0", chipY: "100", dealerX: "-90", dealerY: "50" },
	spot5: { spot: "(885,155)", chipX: "-95", chipY: "75", dealerX: "-90", dealerY: "20" },
	spot6: { spot: "(915,355)", chipX: "-80", chipY: "-40", dealerX: "-90", dealerY: "0" },
	spot7: { spot: "(725,445)", chipX: "-30", chipY: "-80", dealerX: "60", dealerY: "-60" },
	spot8: { spot: "(490,475)", chipX: "0", chipY: "-90", dealerX: "-90", dealerY: "-50" },
};

export const transformCoordinates = {
	spot0: { spot: "(490,475)", chipX: "0", chipY: "-90", dealerX: "-90", dealerY: "-50" },
	spot1: { spot: "(160,435)", chipX: "40", chipY: "-90", dealerX: "90", dealerY: "-50" },
	spot2: { spot: "(160,115)", chipX: "40", chipY: "100", dealerX: "90", dealerY: "40" },
	spot3: { spot: "(490,75)", chipX: "0", chipY: "100", dealerX: "-90", dealerY: "40" },
	spot4: { spot: "(820,115)", chipX: "-40", chipY: "100", dealerX: "-90", dealerY: "40" },
	spot5: { spot: "(820,435)", chipX: "-40", chipY: "-90", dealerX: "-90", dealerY: "-50" },
};

export const PLOTrainingHandsArray = [
	"AAAA",
	"(2A)AA",
	"AAA2",
	"(3A)AA",
	"AAA3",
	"(4A)AA",
	"AAA4",
	"(5A)AA",
	"AAA5",
	"(6A)AA",
	"AAA6",
	"(7A)AA",
	"AAA7",
	"(8A)AA",
	"AAA8",
	"(9A)AA",
	"AAA9",
	"(TA)AA",
	"AAAT",
	"(JA)AA",
	"AAAJ",
	"(QA)AA",
	"AAAQ",
	"(KA)AA",
	"AAAK",
	"(2A)(2A)",
	"(2A)A2",
	"(23A)A",
	"(2A)(3A)",
	"(2A)A3",
	"(24A)A",
	"(2A)(4A)",
	"(2A)A4",
	"(25A)A",
	"(2A)(5A)",
	"(2A)A5",
	"(26A)A",
	"(2A)(6A)",
	"(2A)A6",
	"(27A)A",
	"(2A)(7A)",
	"(2A)A7",
	"(28A)A",
	"(2A)(8A)",
	"(2A)A8",
	"(29A)A",
	"(2A)(9A)",
	"(2A)A9",
	"(2TA)A",
	"(2A)(TA)",
	"(2A)AT",
	"(2JA)A",
	"(2A)(JA)",
	"(2A)AJ",
	"(2QA)A",
	"(2A)(QA)",
	"(2A)AQ",
	"(2KA)A",
	"(2A)(KA)",
	"(2A)AK",
	"AA22",
	"(3A)A2",
	"AA(23)",
	"AA23",
	"(4A)A2",
	"AA(24)",
	"AA24",
	"(5A)A2",
	"AA(25)",
	"AA25",
	"(6A)A2",
	"AA(26)",
	"AA26",
	"(7A)A2",
	"AA(27)",
	"AA27",
	"(8A)A2",
	"AA(28)",
	"AA28",
	"(9A)A2",
	"AA(29)",
	"AA29",
	"(TA)A2",
	"AA(2T)",
	"AA2T",
	"(JA)A2",
	"AA(2J)",
	"AA2J",
	"(QA)A2",
	"AA(2Q)",
	"AA2Q",
	"(KA)A2",
	"AA(2K)",
	"AA2K",
	"(3A)(3A)",
	"(3A)A3",
	"(34A)A",
	"(3A)(4A)",
	"(3A)A4",
	"(35A)A",
	"(3A)(5A)",
	"(3A)A5",
	"(36A)A",
	"(3A)(6A)",
	"(3A)A6",
	"(37A)A",
	"(3A)(7A)",
	"(3A)A7",
	"(38A)A",
	"(3A)(8A)",
	"(3A)A8",
	"(39A)A",
	"(3A)(9A)",
	"(3A)A9",
	"(3TA)A",
	"(3A)(TA)",
	"(3A)AT",
	"(3JA)A",
	"(3A)(JA)",
	"(3A)AJ",
	"(3QA)A",
	"(3A)(QA)",
	"(3A)AQ",
	"(3KA)A",
	"(3A)(KA)",
	"(3A)AK",
	"AA33",
	"(4A)A3",
	"AA(34)",
	"AA34",
	"(5A)A3",
	"AA(35)",
	"AA35",
	"(6A)A3",
	"AA(36)",
	"AA36",
	"(7A)A3",
	"AA(37)",
	"AA37",
	"(8A)A3",
	"AA(38)",
	"AA38",
	"(9A)A3",
	"AA(39)",
	"AA39",
	"(TA)A3",
	"AA(3T)",
	"AA3T",
	"(JA)A3",
	"AA(3J)",
	"AA3J",
	"(QA)A3",
	"AA(3Q)",
	"AA3Q",
	"(KA)A3",
	"AA(3K)",
	"AA3K",
	"(4A)(4A)",
	"(4A)A4",
	"(45A)A",
	"(4A)(5A)",
	"(4A)A5",
	"(46A)A",
	"(4A)(6A)",
	"(4A)A6",
	"(47A)A",
	"(4A)(7A)",
	"(4A)A7",
	"(48A)A",
	"(4A)(8A)",
	"(4A)A8",
	"(49A)A",
	"(4A)(9A)",
	"(4A)A9",
	"(4TA)A",
	"(4A)(TA)",
	"(4A)AT",
	"(4JA)A",
	"(4A)(JA)",
	"(4A)AJ",
	"(4QA)A",
	"(4A)(QA)",
	"(4A)AQ",
	"(4KA)A",
	"(4A)(KA)",
	"(4A)AK",
	"AA44",
	"(5A)A4",
	"AA(45)",
	"AA45",
	"(6A)A4",
	"AA(46)",
	"AA46",
	"(7A)A4",
	"AA(47)",
	"AA47",
	"(8A)A4",
	"AA(48)",
	"AA48",
	"(9A)A4",
	"AA(49)",
	"AA49",
	"(TA)A4",
	"AA(4T)",
	"AA4T",
	"(JA)A4",
	"AA(4J)",
	"AA4J",
	"(QA)A4",
	"AA(4Q)",
	"AA4Q",
	"(KA)A4",
	"AA(4K)",
	"AA4K",
	"(5A)(5A)",
	"(5A)A5",
	"(56A)A",
	"(5A)(6A)",
	"(5A)A6",
	"(57A)A",
	"(5A)(7A)",
	"(5A)A7",
	"(58A)A",
	"(5A)(8A)",
	"(5A)A8",
	"(59A)A",
	"(5A)(9A)",
	"(5A)A9",
	"(5TA)A",
	"(5A)(TA)",
	"(5A)AT",
	"(5JA)A",
	"(5A)(JA)",
	"(5A)AJ",
	"(5QA)A",
	"(5A)(QA)",
	"(5A)AQ",
	"(5KA)A",
	"(5A)(KA)",
	"(5A)AK",
	"AA55",
	"(6A)A5",
	"AA(56)",
	"AA56",
	"(7A)A5",
	"AA(57)",
	"AA57",
	"(8A)A5",
	"AA(58)",
	"AA58",
	"(9A)A5",
	"AA(59)",
	"AA59",
	"(TA)A5",
	"AA(5T)",
	"AA5T",
	"(JA)A5",
	"AA(5J)",
	"AA5J",
	"(QA)A5",
	"AA(5Q)",
	"AA5Q",
	"(KA)A5",
	"AA(5K)",
	"AA5K",
	"(6A)(6A)",
	"(6A)A6",
	"(67A)A",
	"(6A)(7A)",
	"(6A)A7",
	"(68A)A",
	"(6A)(8A)",
	"(6A)A8",
	"(69A)A",
	"(6A)(9A)",
	"(6A)A9",
	"(6TA)A",
	"(6A)(TA)",
	"(6A)AT",
	"(6JA)A",
	"(6A)(JA)",
	"(6A)AJ",
	"(6QA)A",
	"(6A)(QA)",
	"(6A)AQ",
	"(6KA)A",
	"(6A)(KA)",
	"(6A)AK",
	"AA66",
	"(7A)A6",
	"AA(67)",
	"AA67",
	"(8A)A6",
	"AA(68)",
	"AA68",
	"(9A)A6",
	"AA(69)",
	"AA69",
	"(TA)A6",
	"AA(6T)",
	"AA6T",
	"(JA)A6",
	"AA(6J)",
	"AA6J",
	"(QA)A6",
	"AA(6Q)",
	"AA6Q",
	"(KA)A6",
	"AA(6K)",
	"AA6K",
	"(7A)(7A)",
	"(7A)A7",
	"(78A)A",
	"(7A)(8A)",
	"(7A)A8",
	"(79A)A",
	"(7A)(9A)",
	"(7A)A9",
	"(7TA)A",
	"(7A)(TA)",
	"(7A)AT",
	"(7JA)A",
	"(7A)(JA)",
	"(7A)AJ",
	"(7QA)A",
	"(7A)(QA)",
	"(7A)AQ",
	"(7KA)A",
	"(7A)(KA)",
	"(7A)AK",
	"AA77",
	"(8A)A7",
	"AA(78)",
	"AA78",
	"(9A)A7",
	"AA(79)",
	"AA79",
	"(TA)A7",
	"AA(7T)",
	"AA7T",
	"(JA)A7",
	"AA(7J)",
	"AA7J",
	"(QA)A7",
	"AA(7Q)",
	"AA7Q",
	"(KA)A7",
	"AA(7K)",
	"AA7K",
	"(8A)(8A)",
	"(8A)A8",
	"(89A)A",
	"(8A)(9A)",
	"(8A)A9",
	"(8TA)A",
	"(8A)(TA)",
	"(8A)AT",
	"(8JA)A",
	"(8A)(JA)",
	"(8A)AJ",
	"(8QA)A",
	"(8A)(QA)",
	"(8A)AQ",
	"(8KA)A",
	"(8A)(KA)",
	"(8A)AK",
	"AA88",
	"(9A)A8",
	"AA(89)",
	"AA89",
	"(TA)A8",
	"AA(8T)",
	"AA8T",
	"(JA)A8",
	"AA(8J)",
	"AA8J",
	"(QA)A8",
	"AA(8Q)",
	"AA8Q",
	"(KA)A8",
	"AA(8K)",
	"AA8K",
	"(9A)(9A)",
	"(9A)A9",
	"(9TA)A",
	"(9A)(TA)",
	"(9A)AT",
	"(9JA)A",
	"(9A)(JA)",
	"(9A)AJ",
	"(9QA)A",
	"(9A)(QA)",
	"(9A)AQ",
	"(9KA)A",
	"(9A)(KA)",
	"(9A)AK",
	"AA99",
	"(TA)A9",
	"AA(9T)",
	"AA9T",
	"(JA)A9",
	"AA(9J)",
	"AA9J",
	"(QA)A9",
	"AA(9Q)",
	"AA9Q",
	"(KA)A9",
	"AA(9K)",
	"AA9K",
	"(TA)(TA)",
	"(TA)AT",
	"(TJA)A",
	"(TA)(JA)",
	"(TA)AJ",
	"(TQA)A",
	"(TA)(QA)",
	"(TA)AQ",
	"(TKA)A",
	"(TA)(KA)",
	"(TA)AK",
	"AATT",
	"(JA)AT",
	"AA(TJ)",
	"AATJ",
	"(QA)AT",
	"AA(TQ)",
	"AATQ",
	"(KA)AT",
	"AA(TK)",
	"AATK",
	"(JA)(JA)",
	"(JA)AJ",
	"(JQA)A",
	"(JA)(QA)",
	"(JA)AQ",
	"(JKA)A",
	"(JA)(KA)",
	"(JA)AK",
	"AAJJ",
	"(QA)AJ",
	"AA(JQ)",
	"AAJQ",
	"(KA)AJ",
	"AA(JK)",
	"AAJK",
	"(QA)(QA)",
	"(QA)AQ",
	"(QKA)A",
	"(QA)(KA)",
	"(QA)AK",
	"AAQQ",
	"(KA)AQ",
	"AA(QK)",
	"AAQK",
	"(KA)(KA)",
	"(KA)AK",
	"AAKK",
	"(2A)22",
	"(23A)2",
	"(2A)(23)",
	"(2A)23",
	"(24A)2",
	"(2A)(24)",
	"(2A)24",
	"(25A)2",
	"(2A)(25)",
	"(2A)25",
	"(26A)2",
	"(2A)(26)",
	"(2A)26",
	"(27A)2",
	"(2A)(27)",
	"(2A)27",
	"(28A)2",
	"(2A)(28)",
	"(2A)28",
	"(29A)2",
	"(2A)(29)",
	"(2A)29",
	"(2TA)2",
	"(2A)(2T)",
	"(2A)2T",
	"(2JA)2",
	"(2A)(2J)",
	"(2A)2J",
	"(2QA)2",
	"(2A)(2Q)",
	"(2A)2Q",
	"(2KA)2",
	"(2A)(2K)",
	"(2A)2K",
	"(23A)3",
	"(234A)",
	"(23A)4",
	"(235A)",
	"(23A)5",
	"(236A)",
	"(23A)6",
	"(237A)",
	"(23A)7",
	"(238A)",
	"(23A)8",
	"(239A)",
	"(23A)9",
	"(23TA)",
	"(23A)T",
	"(23JA)",
	"(23A)J",
	"(23QA)",
	"(23A)Q",
	"(23KA)",
	"(23A)K",
	"(2A)33",
	"(24A)3",
	"(2A)(34)",
	"(2A)34",
	"(25A)3",
	"(2A)(35)",
	"(2A)35",
	"(26A)3",
	"(2A)(36)",
	"(2A)36",
	"(27A)3",
	"(2A)(37)",
	"(2A)37",
	"(28A)3",
	"(2A)(38)",
	"(2A)38",
	"(29A)3",
	"(2A)(39)",
	"(2A)39",
	"(2TA)3",
	"(2A)(3T)",
	"(2A)3T",
	"(2JA)3",
	"(2A)(3J)",
	"(2A)3J",
	"(2QA)3",
	"(2A)(3Q)",
	"(2A)3Q",
	"(2KA)3",
	"(2A)(3K)",
	"(2A)3K",
	"(24A)4",
	"(245A)",
	"(24A)5",
	"(246A)",
	"(24A)6",
	"(247A)",
	"(24A)7",
	"(248A)",
	"(24A)8",
	"(249A)",
	"(24A)9",
	"(24TA)",
	"(24A)T",
	"(24JA)",
	"(24A)J",
	"(24QA)",
	"(24A)Q",
	"(24KA)",
	"(24A)K",
	"(2A)44",
	"(25A)4",
	"(2A)(45)",
	"(2A)45",
	"(26A)4",
	"(2A)(46)",
	"(2A)46",
	"(27A)4",
	"(2A)(47)",
	"(2A)47",
	"(28A)4",
	"(2A)(48)",
	"(2A)48",
	"(29A)4",
	"(2A)(49)",
	"(2A)49",
	"(2TA)4",
	"(2A)(4T)",
	"(2A)4T",
	"(2JA)4",
	"(2A)(4J)",
	"(2A)4J",
	"(2QA)4",
	"(2A)(4Q)",
	"(2A)4Q",
	"(2KA)4",
	"(2A)(4K)",
	"(2A)4K",
	"(25A)5",
	"(256A)",
	"(25A)6",
	"(257A)",
	"(25A)7",
	"(258A)",
	"(25A)8",
	"(259A)",
	"(25A)9",
	"(25TA)",
	"(25A)T",
	"(25JA)",
	"(25A)J",
	"(25QA)",
	"(25A)Q",
	"(25KA)",
	"(25A)K",
	"(2A)55",
	"(26A)5",
	"(2A)(56)",
	"(2A)56",
	"(27A)5",
	"(2A)(57)",
	"(2A)57",
	"(28A)5",
	"(2A)(58)",
	"(2A)58",
	"(29A)5",
	"(2A)(59)",
	"(2A)59",
	"(2TA)5",
	"(2A)(5T)",
	"(2A)5T",
	"(2JA)5",
	"(2A)(5J)",
	"(2A)5J",
	"(2QA)5",
	"(2A)(5Q)",
	"(2A)5Q",
	"(2KA)5",
	"(2A)(5K)",
	"(2A)5K",
	"(26A)6",
	"(267A)",
	"(26A)7",
	"(268A)",
	"(26A)8",
	"(269A)",
	"(26A)9",
	"(26TA)",
	"(26A)T",
	"(26JA)",
	"(26A)J",
	"(26QA)",
	"(26A)Q",
	"(26KA)",
	"(26A)K",
	"(2A)66",
	"(27A)6",
	"(2A)(67)",
	"(2A)67",
	"(28A)6",
	"(2A)(68)",
	"(2A)68",
	"(29A)6",
	"(2A)(69)",
	"(2A)69",
	"(2TA)6",
	"(2A)(6T)",
	"(2A)6T",
	"(2JA)6",
	"(2A)(6J)",
	"(2A)6J",
	"(2QA)6",
	"(2A)(6Q)",
	"(2A)6Q",
	"(2KA)6",
	"(2A)(6K)",
	"(2A)6K",
	"(27A)7",
	"(278A)",
	"(27A)8",
	"(279A)",
	"(27A)9",
	"(27TA)",
	"(27A)T",
	"(27JA)",
	"(27A)J",
	"(27QA)",
	"(27A)Q",
	"(27KA)",
	"(27A)K",
	"(2A)77",
	"(28A)7",
	"(2A)(78)",
	"(2A)78",
	"(29A)7",
	"(2A)(79)",
	"(2A)79",
	"(2TA)7",
	"(2A)(7T)",
	"(2A)7T",
	"(2JA)7",
	"(2A)(7J)",
	"(2A)7J",
	"(2QA)7",
	"(2A)(7Q)",
	"(2A)7Q",
	"(2KA)7",
	"(2A)(7K)",
	"(2A)7K",
	"(28A)8",
	"(289A)",
	"(28A)9",
	"(28TA)",
	"(28A)T",
	"(28JA)",
	"(28A)J",
	"(28QA)",
	"(28A)Q",
	"(28KA)",
	"(28A)K",
	"(2A)88",
	"(29A)8",
	"(2A)(89)",
	"(2A)89",
	"(2TA)8",
	"(2A)(8T)",
	"(2A)8T",
	"(2JA)8",
	"(2A)(8J)",
	"(2A)8J",
	"(2QA)8",
	"(2A)(8Q)",
	"(2A)8Q",
	"(2KA)8",
	"(2A)(8K)",
	"(2A)8K",
	"(29A)9",
	"(29TA)",
	"(29A)T",
	"(29JA)",
	"(29A)J",
	"(29QA)",
	"(29A)Q",
	"(29KA)",
	"(29A)K",
	"(2A)99",
	"(2TA)9",
	"(2A)(9T)",
	"(2A)9T",
	"(2JA)9",
	"(2A)(9J)",
	"(2A)9J",
	"(2QA)9",
	"(2A)(9Q)",
	"(2A)9Q",
	"(2KA)9",
	"(2A)(9K)",
	"(2A)9K",
	"(2TA)T",
	"(2TJA)",
	"(2TA)J",
	"(2TQA)",
	"(2TA)Q",
	"(2TKA)",
	"(2TA)K",
	"(2A)TT",
	"(2JA)T",
	"(2A)(TJ)",
	"(2A)TJ",
	"(2QA)T",
	"(2A)(TQ)",
	"(2A)TQ",
	"(2KA)T",
	"(2A)(TK)",
	"(2A)TK",
	"(2JA)J",
	"(2JQA)",
	"(2JA)Q",
	"(2JKA)",
	"(2JA)K",
	"(2A)JJ",
	"(2QA)J",
	"(2A)(JQ)",
	"(2A)JQ",
	"(2KA)J",
	"(2A)(JK)",
	"(2A)JK",
	"(2QA)Q",
	"(2QKA)",
	"(2QA)K",
	"(2A)QQ",
	"(2KA)Q",
	"(2A)(QK)",
	"(2A)QK",
	"(2KA)K",
	"(2A)KK",
	"A222",
	"(3A)22",
	"A(23)2",
	"A223",
	"(4A)22",
	"A(24)2",
	"A224",
	"(5A)22",
	"A(25)2",
	"A225",
	"(6A)22",
	"A(26)2",
	"A226",
	"(7A)22",
	"A(27)2",
	"A227",
	"(8A)22",
	"A(28)2",
	"A228",
	"(9A)22",
	"A(29)2",
	"A229",
	"(TA)22",
	"A(2T)2",
	"A22T",
	"(JA)22",
	"A(2J)2",
	"A22J",
	"(QA)22",
	"A(2Q)2",
	"A22Q",
	"(KA)22",
	"A(2K)2",
	"A22K",
	"(3A)(23)",
	"(3A)23",
	"(34A)2",
	"(3A)(24)",
	"(3A)24",
	"(35A)2",
	"(3A)(25)",
	"(3A)25",
	"(36A)2",
	"(3A)(26)",
	"(3A)26",
	"(37A)2",
	"(3A)(27)",
	"(3A)27",
	"(38A)2",
	"(3A)(28)",
	"(3A)28",
	"(39A)2",
	"(3A)(29)",
	"(3A)29",
	"(3TA)2",
	"(3A)(2T)",
	"(3A)2T",
	"(3JA)2",
	"(3A)(2J)",
	"(3A)2J",
	"(3QA)2",
	"(3A)(2Q)",
	"(3A)2Q",
	"(3KA)2",
	"(3A)(2K)",
	"(3A)2K",
	"A(23)3",
	"(4A)(23)",
	"A(234)",
	"A(23)4",
	"(5A)(23)",
	"A(235)",
	"A(23)5",
	"(6A)(23)",
	"A(236)",
	"A(23)6",
	"(7A)(23)",
	"A(237)",
	"A(23)7",
	"(8A)(23)",
	"A(238)",
	"A(23)8",
	"(9A)(23)",
	"A(239)",
	"A(23)9",
	"(TA)(23)",
	"A(23T)",
	"A(23)T",
	"(JA)(23)",
	"A(23J)",
	"A(23)J",
	"(QA)(23)",
	"A(23Q)",
	"A(23)Q",
	"(KA)(23)",
	"A(23K)",
	"A(23)K",
	"A233",
	"(4A)23",
	"A(24)3",
	"A2(34)",
	"A234",
	"(5A)23",
	"A(25)3",
	"A2(35)",
	"A235",
	"(6A)23",
	"A(26)3",
	"A2(36)",
	"A236",
	"(7A)23",
	"A(27)3",
	"A2(37)",
	"A237",
	"(8A)23",
	"A(28)3",
	"A2(38)",
	"A238",
	"(9A)23",
	"A(29)3",
	"A2(39)",
	"A239",
	"(TA)23",
	"A(2T)3",
	"A2(3T)",
	"A23T",
	"(JA)23",
	"A(2J)3",
	"A2(3J)",
	"A23J",
	"(QA)23",
	"A(2Q)3",
	"A2(3Q)",
	"A23Q",
	"(KA)23",
	"A(2K)3",
	"A2(3K)",
	"A23K",
	"(4A)(24)",
	"(4A)24",
	"(45A)2",
	"(4A)(25)",
	"(4A)25",
	"(46A)2",
	"(4A)(26)",
	"(4A)26",
	"(47A)2",
	"(4A)(27)",
	"(4A)27",
	"(48A)2",
	"(4A)(28)",
	"(4A)28",
	"(49A)2",
	"(4A)(29)",
	"(4A)29",
	"(4TA)2",
	"(4A)(2T)",
	"(4A)2T",
	"(4JA)2",
	"(4A)(2J)",
	"(4A)2J",
	"(4QA)2",
	"(4A)(2Q)",
	"(4A)2Q",
	"(4KA)2",
	"(4A)(2K)",
	"(4A)2K",
	"A(24)4",
	"(5A)(24)",
	"A(245)",
	"A(24)5",
	"(6A)(24)",
	"A(246)",
	"A(24)6",
	"(7A)(24)",
	"A(247)",
	"A(24)7",
	"(8A)(24)",
	"A(248)",
	"A(24)8",
	"(9A)(24)",
	"A(249)",
	"A(24)9",
	"(TA)(24)",
	"A(24T)",
	"A(24)T",
	"(JA)(24)",
	"A(24J)",
	"A(24)J",
	"(QA)(24)",
	"A(24Q)",
	"A(24)Q",
	"(KA)(24)",
	"A(24K)",
	"A(24)K",
	"A244",
	"(5A)24",
	"A(25)4",
	"A2(45)",
	"A245",
	"(6A)24",
	"A(26)4",
	"A2(46)",
	"A246",
	"(7A)24",
	"A(27)4",
	"A2(47)",
	"A247",
	"(8A)24",
	"A(28)4",
	"A2(48)",
	"A248",
	"(9A)24",
	"A(29)4",
	"A2(49)",
	"A249",
	"(TA)24",
	"A(2T)4",
	"A2(4T)",
	"A24T",
	"(JA)24",
	"A(2J)4",
	"A2(4J)",
	"A24J",
	"(QA)24",
	"A(2Q)4",
	"A2(4Q)",
	"A24Q",
	"(KA)24",
	"A(2K)4",
	"A2(4K)",
	"A24K",
	"(5A)(25)",
	"(5A)25",
	"(56A)2",
	"(5A)(26)",
	"(5A)26",
	"(57A)2",
	"(5A)(27)",
	"(5A)27",
	"(58A)2",
	"(5A)(28)",
	"(5A)28",
	"(59A)2",
	"(5A)(29)",
	"(5A)29",
	"(5TA)2",
	"(5A)(2T)",
	"(5A)2T",
	"(5JA)2",
	"(5A)(2J)",
	"(5A)2J",
	"(5QA)2",
	"(5A)(2Q)",
	"(5A)2Q",
	"(5KA)2",
	"(5A)(2K)",
	"(5A)2K",
	"A(25)5",
	"(6A)(25)",
	"A(256)",
	"A(25)6",
	"(7A)(25)",
	"A(257)",
	"A(25)7",
	"(8A)(25)",
	"A(258)",
	"A(25)8",
	"(9A)(25)",
	"A(259)",
	"A(25)9",
	"(TA)(25)",
	"A(25T)",
	"A(25)T",
	"(JA)(25)",
	"A(25J)",
	"A(25)J",
	"(QA)(25)",
	"A(25Q)",
	"A(25)Q",
	"(KA)(25)",
	"A(25K)",
	"A(25)K",
	"A255",
	"(6A)25",
	"A(26)5",
	"A2(56)",
	"A256",
	"(7A)25",
	"A(27)5",
	"A2(57)",
	"A257",
	"(8A)25",
	"A(28)5",
	"A2(58)",
	"A258",
	"(9A)25",
	"A(29)5",
	"A2(59)",
	"A259",
	"(TA)25",
	"A(2T)5",
	"A2(5T)",
	"A25T",
	"(JA)25",
	"A(2J)5",
	"A2(5J)",
	"A25J",
	"(QA)25",
	"A(2Q)5",
	"A2(5Q)",
	"A25Q",
	"(KA)25",
	"A(2K)5",
	"A2(5K)",
	"A25K",
	"(6A)(26)",
	"(6A)26",
	"(67A)2",
	"(6A)(27)",
	"(6A)27",
	"(68A)2",
	"(6A)(28)",
	"(6A)28",
	"(69A)2",
	"(6A)(29)",
	"(6A)29",
	"(6TA)2",
	"(6A)(2T)",
	"(6A)2T",
	"(6JA)2",
	"(6A)(2J)",
	"(6A)2J",
	"(6QA)2",
	"(6A)(2Q)",
	"(6A)2Q",
	"(6KA)2",
	"(6A)(2K)",
	"(6A)2K",
	"A(26)6",
	"(7A)(26)",
	"A(267)",
	"A(26)7",
	"(8A)(26)",
	"A(268)",
	"A(26)8",
	"(9A)(26)",
	"A(269)",
	"A(26)9",
	"(TA)(26)",
	"A(26T)",
	"A(26)T",
	"(JA)(26)",
	"A(26J)",
	"A(26)J",
	"(QA)(26)",
	"A(26Q)",
	"A(26)Q",
	"(KA)(26)",
	"A(26K)",
	"A(26)K",
	"A266",
	"(7A)26",
	"A(27)6",
	"A2(67)",
	"A267",
	"(8A)26",
	"A(28)6",
	"A2(68)",
	"A268",
	"(9A)26",
	"A(29)6",
	"A2(69)",
	"A269",
	"(TA)26",
	"A(2T)6",
	"A2(6T)",
	"A26T",
	"(JA)26",
	"A(2J)6",
	"A2(6J)",
	"A26J",
	"(QA)26",
	"A(2Q)6",
	"A2(6Q)",
	"A26Q",
	"(KA)26",
	"A(2K)6",
	"A2(6K)",
	"A26K",
	"(7A)(27)",
	"(7A)27",
	"(78A)2",
	"(7A)(28)",
	"(7A)28",
	"(79A)2",
	"(7A)(29)",
	"(7A)29",
	"(7TA)2",
	"(7A)(2T)",
	"(7A)2T",
	"(7JA)2",
	"(7A)(2J)",
	"(7A)2J",
	"(7QA)2",
	"(7A)(2Q)",
	"(7A)2Q",
	"(7KA)2",
	"(7A)(2K)",
	"(7A)2K",
	"A(27)7",
	"(8A)(27)",
	"A(278)",
	"A(27)8",
	"(9A)(27)",
	"A(279)",
	"A(27)9",
	"(TA)(27)",
	"A(27T)",
	"A(27)T",
	"(JA)(27)",
	"A(27J)",
	"A(27)J",
	"(QA)(27)",
	"A(27Q)",
	"A(27)Q",
	"(KA)(27)",
	"A(27K)",
	"A(27)K",
	"A277",
	"(8A)27",
	"A(28)7",
	"A2(78)",
	"A278",
	"(9A)27",
	"A(29)7",
	"A2(79)",
	"A279",
	"(TA)27",
	"A(2T)7",
	"A2(7T)",
	"A27T",
	"(JA)27",
	"A(2J)7",
	"A2(7J)",
	"A27J",
	"(QA)27",
	"A(2Q)7",
	"A2(7Q)",
	"A27Q",
	"(KA)27",
	"A(2K)7",
	"A2(7K)",
	"A27K",
	"(8A)(28)",
	"(8A)28",
	"(89A)2",
	"(8A)(29)",
	"(8A)29",
	"(8TA)2",
	"(8A)(2T)",
	"(8A)2T",
	"(8JA)2",
	"(8A)(2J)",
	"(8A)2J",
	"(8QA)2",
	"(8A)(2Q)",
	"(8A)2Q",
	"(8KA)2",
	"(8A)(2K)",
	"(8A)2K",
	"A(28)8",
	"(9A)(28)",
	"A(289)",
	"A(28)9",
	"(TA)(28)",
	"A(28T)",
	"A(28)T",
	"(JA)(28)",
	"A(28J)",
	"A(28)J",
	"(QA)(28)",
	"A(28Q)",
	"A(28)Q",
	"(KA)(28)",
	"A(28K)",
	"A(28)K",
	"A288",
	"(9A)28",
	"A(29)8",
	"A2(89)",
	"A289",
	"(TA)28",
	"A(2T)8",
	"A2(8T)",
	"A28T",
	"(JA)28",
	"A(2J)8",
	"A2(8J)",
	"A28J",
	"(QA)28",
	"A(2Q)8",
	"A2(8Q)",
	"A28Q",
	"(KA)28",
	"A(2K)8",
	"A2(8K)",
	"A28K",
	"(9A)(29)",
	"(9A)29",
	"(9TA)2",
	"(9A)(2T)",
	"(9A)2T",
	"(9JA)2",
	"(9A)(2J)",
	"(9A)2J",
	"(9QA)2",
	"(9A)(2Q)",
	"(9A)2Q",
	"(9KA)2",
	"(9A)(2K)",
	"(9A)2K",
	"A(29)9",
	"(TA)(29)",
	"A(29T)",
	"A(29)T",
	"(JA)(29)",
	"A(29J)",
	"A(29)J",
	"(QA)(29)",
	"A(29Q)",
	"A(29)Q",
	"(KA)(29)",
	"A(29K)",
	"A(29)K",
	"A299",
	"(TA)29",
	"A(2T)9",
	"A2(9T)",
	"A29T",
	"(JA)29",
	"A(2J)9",
	"A2(9J)",
	"A29J",
	"(QA)29",
	"A(2Q)9",
	"A2(9Q)",
	"A29Q",
	"(KA)29",
	"A(2K)9",
	"A2(9K)",
	"A29K",
	"(TA)(2T)",
	"(TA)2T",
	"(TJA)2",
	"(TA)(2J)",
	"(TA)2J",
	"(TQA)2",
	"(TA)(2Q)",
	"(TA)2Q",
	"(TKA)2",
	"(TA)(2K)",
	"(TA)2K",
	"A(2T)T",
	"(JA)(2T)",
	"A(2TJ)",
	"A(2T)J",
	"(QA)(2T)",
	"A(2TQ)",
	"A(2T)Q",
	"(KA)(2T)",
	"A(2TK)",
	"A(2T)K",
	"A2TT",
	"(JA)2T",
	"A(2J)T",
	"A2(TJ)",
	"A2TJ",
	"(QA)2T",
	"A(2Q)T",
	"A2(TQ)",
	"A2TQ",
	"(KA)2T",
	"A(2K)T",
	"A2(TK)",
	"A2TK",
	"(JA)(2J)",
	"(JA)2J",
	"(JQA)2",
	"(JA)(2Q)",
	"(JA)2Q",
	"(JKA)2",
	"(JA)(2K)",
	"(JA)2K",
	"A(2J)J",
	"(QA)(2J)",
	"A(2JQ)",
	"A(2J)Q",
	"(KA)(2J)",
	"A(2JK)",
	"A(2J)K",
	"A2JJ",
	"(QA)2J",
	"A(2Q)J",
	"A2(JQ)",
	"A2JQ",
	"(KA)2J",
	"A(2K)J",
	"A2(JK)",
	"A2JK",
	"(QA)(2Q)",
	"(QA)2Q",
	"(QKA)2",
	"(QA)(2K)",
	"(QA)2K",
	"A(2Q)Q",
	"(KA)(2Q)",
	"A(2QK)",
	"A(2Q)K",
	"A2QQ",
	"(KA)2Q",
	"A(2K)Q",
	"A2(QK)",
	"A2QK",
	"(KA)(2K)",
	"(KA)2K",
	"A(2K)K",
	"A2KK",
	"(3A)33",
	"(34A)3",
	"(3A)(34)",
	"(3A)34",
	"(35A)3",
	"(3A)(35)",
	"(3A)35",
	"(36A)3",
	"(3A)(36)",
	"(3A)36",
	"(37A)3",
	"(3A)(37)",
	"(3A)37",
	"(38A)3",
	"(3A)(38)",
	"(3A)38",
	"(39A)3",
	"(3A)(39)",
	"(3A)39",
	"(3TA)3",
	"(3A)(3T)",
	"(3A)3T",
	"(3JA)3",
	"(3A)(3J)",
	"(3A)3J",
	"(3QA)3",
	"(3A)(3Q)",
	"(3A)3Q",
	"(3KA)3",
	"(3A)(3K)",
	"(3A)3K",
	"(34A)4",
	"(345A)",
	"(34A)5",
	"(346A)",
	"(34A)6",
	"(347A)",
	"(34A)7",
	"(348A)",
	"(34A)8",
	"(349A)",
	"(34A)9",
	"(34TA)",
	"(34A)T",
	"(34JA)",
	"(34A)J",
	"(34QA)",
	"(34A)Q",
	"(34KA)",
	"(34A)K",
	"(3A)44",
	"(35A)4",
	"(3A)(45)",
	"(3A)45",
	"(36A)4",
	"(3A)(46)",
	"(3A)46",
	"(37A)4",
	"(3A)(47)",
	"(3A)47",
	"(38A)4",
	"(3A)(48)",
	"(3A)48",
	"(39A)4",
	"(3A)(49)",
	"(3A)49",
	"(3TA)4",
	"(3A)(4T)",
	"(3A)4T",
	"(3JA)4",
	"(3A)(4J)",
	"(3A)4J",
	"(3QA)4",
	"(3A)(4Q)",
	"(3A)4Q",
	"(3KA)4",
	"(3A)(4K)",
	"(3A)4K",
	"(35A)5",
	"(356A)",
	"(35A)6",
	"(357A)",
	"(35A)7",
	"(358A)",
	"(35A)8",
	"(359A)",
	"(35A)9",
	"(35TA)",
	"(35A)T",
	"(35JA)",
	"(35A)J",
	"(35QA)",
	"(35A)Q",
	"(35KA)",
	"(35A)K",
	"(3A)55",
	"(36A)5",
	"(3A)(56)",
	"(3A)56",
	"(37A)5",
	"(3A)(57)",
	"(3A)57",
	"(38A)5",
	"(3A)(58)",
	"(3A)58",
	"(39A)5",
	"(3A)(59)",
	"(3A)59",
	"(3TA)5",
	"(3A)(5T)",
	"(3A)5T",
	"(3JA)5",
	"(3A)(5J)",
	"(3A)5J",
	"(3QA)5",
	"(3A)(5Q)",
	"(3A)5Q",
	"(3KA)5",
	"(3A)(5K)",
	"(3A)5K",
	"(36A)6",
	"(367A)",
	"(36A)7",
	"(368A)",
	"(36A)8",
	"(369A)",
	"(36A)9",
	"(36TA)",
	"(36A)T",
	"(36JA)",
	"(36A)J",
	"(36QA)",
	"(36A)Q",
	"(36KA)",
	"(36A)K",
	"(3A)66",
	"(37A)6",
	"(3A)(67)",
	"(3A)67",
	"(38A)6",
	"(3A)(68)",
	"(3A)68",
	"(39A)6",
	"(3A)(69)",
	"(3A)69",
	"(3TA)6",
	"(3A)(6T)",
	"(3A)6T",
	"(3JA)6",
	"(3A)(6J)",
	"(3A)6J",
	"(3QA)6",
	"(3A)(6Q)",
	"(3A)6Q",
	"(3KA)6",
	"(3A)(6K)",
	"(3A)6K",
	"(37A)7",
	"(378A)",
	"(37A)8",
	"(379A)",
	"(37A)9",
	"(37TA)",
	"(37A)T",
	"(37JA)",
	"(37A)J",
	"(37QA)",
	"(37A)Q",
	"(37KA)",
	"(37A)K",
	"(3A)77",
	"(38A)7",
	"(3A)(78)",
	"(3A)78",
	"(39A)7",
	"(3A)(79)",
	"(3A)79",
	"(3TA)7",
	"(3A)(7T)",
	"(3A)7T",
	"(3JA)7",
	"(3A)(7J)",
	"(3A)7J",
	"(3QA)7",
	"(3A)(7Q)",
	"(3A)7Q",
	"(3KA)7",
	"(3A)(7K)",
	"(3A)7K",
	"(38A)8",
	"(389A)",
	"(38A)9",
	"(38TA)",
	"(38A)T",
	"(38JA)",
	"(38A)J",
	"(38QA)",
	"(38A)Q",
	"(38KA)",
	"(38A)K",
	"(3A)88",
	"(39A)8",
	"(3A)(89)",
	"(3A)89",
	"(3TA)8",
	"(3A)(8T)",
	"(3A)8T",
	"(3JA)8",
	"(3A)(8J)",
	"(3A)8J",
	"(3QA)8",
	"(3A)(8Q)",
	"(3A)8Q",
	"(3KA)8",
	"(3A)(8K)",
	"(3A)8K",
	"(39A)9",
	"(39TA)",
	"(39A)T",
	"(39JA)",
	"(39A)J",
	"(39QA)",
	"(39A)Q",
	"(39KA)",
	"(39A)K",
	"(3A)99",
	"(3TA)9",
	"(3A)(9T)",
	"(3A)9T",
	"(3JA)9",
	"(3A)(9J)",
	"(3A)9J",
	"(3QA)9",
	"(3A)(9Q)",
	"(3A)9Q",
	"(3KA)9",
	"(3A)(9K)",
	"(3A)9K",
	"(3TA)T",
	"(3TJA)",
	"(3TA)J",
	"(3TQA)",
	"(3TA)Q",
	"(3TKA)",
	"(3TA)K",
	"(3A)TT",
	"(3JA)T",
	"(3A)(TJ)",
	"(3A)TJ",
	"(3QA)T",
	"(3A)(TQ)",
	"(3A)TQ",
	"(3KA)T",
	"(3A)(TK)",
	"(3A)TK",
	"(3JA)J",
	"(3JQA)",
	"(3JA)Q",
	"(3JKA)",
	"(3JA)K",
	"(3A)JJ",
	"(3QA)J",
	"(3A)(JQ)",
	"(3A)JQ",
	"(3KA)J",
	"(3A)(JK)",
	"(3A)JK",
	"(3QA)Q",
	"(3QKA)",
	"(3QA)K",
	"(3A)QQ",
	"(3KA)Q",
	"(3A)(QK)",
	"(3A)QK",
	"(3KA)K",
	"(3A)KK",
	"A333",
	"(4A)33",
	"A(34)3",
	"A334",
	"(5A)33",
	"A(35)3",
	"A335",
	"(6A)33",
	"A(36)3",
	"A336",
	"(7A)33",
	"A(37)3",
	"A337",
	"(8A)33",
	"A(38)3",
	"A338",
	"(9A)33",
	"A(39)3",
	"A339",
	"(TA)33",
	"A(3T)3",
	"A33T",
	"(JA)33",
	"A(3J)3",
	"A33J",
	"(QA)33",
	"A(3Q)3",
	"A33Q",
	"(KA)33",
	"A(3K)3",
	"A33K",
	"(4A)(34)",
	"(4A)34",
	"(45A)3",
	"(4A)(35)",
	"(4A)35",
	"(46A)3",
	"(4A)(36)",
	"(4A)36",
	"(47A)3",
	"(4A)(37)",
	"(4A)37",
	"(48A)3",
	"(4A)(38)",
	"(4A)38",
	"(49A)3",
	"(4A)(39)",
	"(4A)39",
	"(4TA)3",
	"(4A)(3T)",
	"(4A)3T",
	"(4JA)3",
	"(4A)(3J)",
	"(4A)3J",
	"(4QA)3",
	"(4A)(3Q)",
	"(4A)3Q",
	"(4KA)3",
	"(4A)(3K)",
	"(4A)3K",
	"A(34)4",
	"(5A)(34)",
	"A(345)",
	"A(34)5",
	"(6A)(34)",
	"A(346)",
	"A(34)6",
	"(7A)(34)",
	"A(347)",
	"A(34)7",
	"(8A)(34)",
	"A(348)",
	"A(34)8",
	"(9A)(34)",
	"A(349)",
	"A(34)9",
	"(TA)(34)",
	"A(34T)",
	"A(34)T",
	"(JA)(34)",
	"A(34J)",
	"A(34)J",
	"(QA)(34)",
	"A(34Q)",
	"A(34)Q",
	"(KA)(34)",
	"A(34K)",
	"A(34)K",
	"A344",
	"(5A)34",
	"A(35)4",
	"A3(45)",
	"A345",
	"(6A)34",
	"A(36)4",
	"A3(46)",
	"A346",
	"(7A)34",
	"A(37)4",
	"A3(47)",
	"A347",
	"(8A)34",
	"A(38)4",
	"A3(48)",
	"A348",
	"(9A)34",
	"A(39)4",
	"A3(49)",
	"A349",
	"(TA)34",
	"A(3T)4",
	"A3(4T)",
	"A34T",
	"(JA)34",
	"A(3J)4",
	"A3(4J)",
	"A34J",
	"(QA)34",
	"A(3Q)4",
	"A3(4Q)",
	"A34Q",
	"(KA)34",
	"A(3K)4",
	"A3(4K)",
	"A34K",
	"(5A)(35)",
	"(5A)35",
	"(56A)3",
	"(5A)(36)",
	"(5A)36",
	"(57A)3",
	"(5A)(37)",
	"(5A)37",
	"(58A)3",
	"(5A)(38)",
	"(5A)38",
	"(59A)3",
	"(5A)(39)",
	"(5A)39",
	"(5TA)3",
	"(5A)(3T)",
	"(5A)3T",
	"(5JA)3",
	"(5A)(3J)",
	"(5A)3J",
	"(5QA)3",
	"(5A)(3Q)",
	"(5A)3Q",
	"(5KA)3",
	"(5A)(3K)",
	"(5A)3K",
	"A(35)5",
	"(6A)(35)",
	"A(356)",
	"A(35)6",
	"(7A)(35)",
	"A(357)",
	"A(35)7",
	"(8A)(35)",
	"A(358)",
	"A(35)8",
	"(9A)(35)",
	"A(359)",
	"A(35)9",
	"(TA)(35)",
	"A(35T)",
	"A(35)T",
	"(JA)(35)",
	"A(35J)",
	"A(35)J",
	"(QA)(35)",
	"A(35Q)",
	"A(35)Q",
	"(KA)(35)",
	"A(35K)",
	"A(35)K",
	"A355",
	"(6A)35",
	"A(36)5",
	"A3(56)",
	"A356",
	"(7A)35",
	"A(37)5",
	"A3(57)",
	"A357",
	"(8A)35",
	"A(38)5",
	"A3(58)",
	"A358",
	"(9A)35",
	"A(39)5",
	"A3(59)",
	"A359",
	"(TA)35",
	"A(3T)5",
	"A3(5T)",
	"A35T",
	"(JA)35",
	"A(3J)5",
	"A3(5J)",
	"A35J",
	"(QA)35",
	"A(3Q)5",
	"A3(5Q)",
	"A35Q",
	"(KA)35",
	"A(3K)5",
	"A3(5K)",
	"A35K",
	"(6A)(36)",
	"(6A)36",
	"(67A)3",
	"(6A)(37)",
	"(6A)37",
	"(68A)3",
	"(6A)(38)",
	"(6A)38",
	"(69A)3",
	"(6A)(39)",
	"(6A)39",
	"(6TA)3",
	"(6A)(3T)",
	"(6A)3T",
	"(6JA)3",
	"(6A)(3J)",
	"(6A)3J",
	"(6QA)3",
	"(6A)(3Q)",
	"(6A)3Q",
	"(6KA)3",
	"(6A)(3K)",
	"(6A)3K",
	"A(36)6",
	"(7A)(36)",
	"A(367)",
	"A(36)7",
	"(8A)(36)",
	"A(368)",
	"A(36)8",
	"(9A)(36)",
	"A(369)",
	"A(36)9",
	"(TA)(36)",
	"A(36T)",
	"A(36)T",
	"(JA)(36)",
	"A(36J)",
	"A(36)J",
	"(QA)(36)",
	"A(36Q)",
	"A(36)Q",
	"(KA)(36)",
	"A(36K)",
	"A(36)K",
	"A366",
	"(7A)36",
	"A(37)6",
	"A3(67)",
	"A367",
	"(8A)36",
	"A(38)6",
	"A3(68)",
	"A368",
	"(9A)36",
	"A(39)6",
	"A3(69)",
	"A369",
	"(TA)36",
	"A(3T)6",
	"A3(6T)",
	"A36T",
	"(JA)36",
	"A(3J)6",
	"A3(6J)",
	"A36J",
	"(QA)36",
	"A(3Q)6",
	"A3(6Q)",
	"A36Q",
	"(KA)36",
	"A(3K)6",
	"A3(6K)",
	"A36K",
	"(7A)(37)",
	"(7A)37",
	"(78A)3",
	"(7A)(38)",
	"(7A)38",
	"(79A)3",
	"(7A)(39)",
	"(7A)39",
	"(7TA)3",
	"(7A)(3T)",
	"(7A)3T",
	"(7JA)3",
	"(7A)(3J)",
	"(7A)3J",
	"(7QA)3",
	"(7A)(3Q)",
	"(7A)3Q",
	"(7KA)3",
	"(7A)(3K)",
	"(7A)3K",
	"A(37)7",
	"(8A)(37)",
	"A(378)",
	"A(37)8",
	"(9A)(37)",
	"A(379)",
	"A(37)9",
	"(TA)(37)",
	"A(37T)",
	"A(37)T",
	"(JA)(37)",
	"A(37J)",
	"A(37)J",
	"(QA)(37)",
	"A(37Q)",
	"A(37)Q",
	"(KA)(37)",
	"A(37K)",
	"A(37)K",
	"A377",
	"(8A)37",
	"A(38)7",
	"A3(78)",
	"A378",
	"(9A)37",
	"A(39)7",
	"A3(79)",
	"A379",
	"(TA)37",
	"A(3T)7",
	"A3(7T)",
	"A37T",
	"(JA)37",
	"A(3J)7",
	"A3(7J)",
	"A37J",
	"(QA)37",
	"A(3Q)7",
	"A3(7Q)",
	"A37Q",
	"(KA)37",
	"A(3K)7",
	"A3(7K)",
	"A37K",
	"(8A)(38)",
	"(8A)38",
	"(89A)3",
	"(8A)(39)",
	"(8A)39",
	"(8TA)3",
	"(8A)(3T)",
	"(8A)3T",
	"(8JA)3",
	"(8A)(3J)",
	"(8A)3J",
	"(8QA)3",
	"(8A)(3Q)",
	"(8A)3Q",
	"(8KA)3",
	"(8A)(3K)",
	"(8A)3K",
	"A(38)8",
	"(9A)(38)",
	"A(389)",
	"A(38)9",
	"(TA)(38)",
	"A(38T)",
	"A(38)T",
	"(JA)(38)",
	"A(38J)",
	"A(38)J",
	"(QA)(38)",
	"A(38Q)",
	"A(38)Q",
	"(KA)(38)",
	"A(38K)",
	"A(38)K",
	"A388",
	"(9A)38",
	"A(39)8",
	"A3(89)",
	"A389",
	"(TA)38",
	"A(3T)8",
	"A3(8T)",
	"A38T",
	"(JA)38",
	"A(3J)8",
	"A3(8J)",
	"A38J",
	"(QA)38",
	"A(3Q)8",
	"A3(8Q)",
	"A38Q",
	"(KA)38",
	"A(3K)8",
	"A3(8K)",
	"A38K",
	"(9A)(39)",
	"(9A)39",
	"(9TA)3",
	"(9A)(3T)",
	"(9A)3T",
	"(9JA)3",
	"(9A)(3J)",
	"(9A)3J",
	"(9QA)3",
	"(9A)(3Q)",
	"(9A)3Q",
	"(9KA)3",
	"(9A)(3K)",
	"(9A)3K",
	"A(39)9",
	"(TA)(39)",
	"A(39T)",
	"A(39)T",
	"(JA)(39)",
	"A(39J)",
	"A(39)J",
	"(QA)(39)",
	"A(39Q)",
	"A(39)Q",
	"(KA)(39)",
	"A(39K)",
	"A(39)K",
	"A399",
	"(TA)39",
	"A(3T)9",
	"A3(9T)",
	"A39T",
	"(JA)39",
	"A(3J)9",
	"A3(9J)",
	"A39J",
	"(QA)39",
	"A(3Q)9",
	"A3(9Q)",
	"A39Q",
	"(KA)39",
	"A(3K)9",
	"A3(9K)",
	"A39K",
	"(TA)(3T)",
	"(TA)3T",
	"(TJA)3",
	"(TA)(3J)",
	"(TA)3J",
	"(TQA)3",
	"(TA)(3Q)",
	"(TA)3Q",
	"(TKA)3",
	"(TA)(3K)",
	"(TA)3K",
	"A(3T)T",
	"(JA)(3T)",
	"A(3TJ)",
	"A(3T)J",
	"(QA)(3T)",
	"A(3TQ)",
	"A(3T)Q",
	"(KA)(3T)",
	"A(3TK)",
	"A(3T)K",
	"A3TT",
	"(JA)3T",
	"A(3J)T",
	"A3(TJ)",
	"A3TJ",
	"(QA)3T",
	"A(3Q)T",
	"A3(TQ)",
	"A3TQ",
	"(KA)3T",
	"A(3K)T",
	"A3(TK)",
	"A3TK",
	"(JA)(3J)",
	"(JA)3J",
	"(JQA)3",
	"(JA)(3Q)",
	"(JA)3Q",
	"(JKA)3",
	"(JA)(3K)",
	"(JA)3K",
	"A(3J)J",
	"(QA)(3J)",
	"A(3JQ)",
	"A(3J)Q",
	"(KA)(3J)",
	"A(3JK)",
	"A(3J)K",
	"A3JJ",
	"(QA)3J",
	"A(3Q)J",
	"A3(JQ)",
	"A3JQ",
	"(KA)3J",
	"A(3K)J",
	"A3(JK)",
	"A3JK",
	"(QA)(3Q)",
	"(QA)3Q",
	"(QKA)3",
	"(QA)(3K)",
	"(QA)3K",
	"A(3Q)Q",
	"(KA)(3Q)",
	"A(3QK)",
	"A(3Q)K",
	"A3QQ",
	"(KA)3Q",
	"A(3K)Q",
	"A3(QK)",
	"A3QK",
	"(KA)(3K)",
	"(KA)3K",
	"A(3K)K",
	"A3KK",
	"(4A)44",
	"(45A)4",
	"(4A)(45)",
	"(4A)45",
	"(46A)4",
	"(4A)(46)",
	"(4A)46",
	"(47A)4",
	"(4A)(47)",
	"(4A)47",
	"(48A)4",
	"(4A)(48)",
	"(4A)48",
	"(49A)4",
	"(4A)(49)",
	"(4A)49",
	"(4TA)4",
	"(4A)(4T)",
	"(4A)4T",
	"(4JA)4",
	"(4A)(4J)",
	"(4A)4J",
	"(4QA)4",
	"(4A)(4Q)",
	"(4A)4Q",
	"(4KA)4",
	"(4A)(4K)",
	"(4A)4K",
	"(45A)5",
	"(456A)",
	"(45A)6",
	"(457A)",
	"(45A)7",
	"(458A)",
	"(45A)8",
	"(459A)",
	"(45A)9",
	"(45TA)",
	"(45A)T",
	"(45JA)",
	"(45A)J",
	"(45QA)",
	"(45A)Q",
	"(45KA)",
	"(45A)K",
	"(4A)55",
	"(46A)5",
	"(4A)(56)",
	"(4A)56",
	"(47A)5",
	"(4A)(57)",
	"(4A)57",
	"(48A)5",
	"(4A)(58)",
	"(4A)58",
	"(49A)5",
	"(4A)(59)",
	"(4A)59",
	"(4TA)5",
	"(4A)(5T)",
	"(4A)5T",
	"(4JA)5",
	"(4A)(5J)",
	"(4A)5J",
	"(4QA)5",
	"(4A)(5Q)",
	"(4A)5Q",
	"(4KA)5",
	"(4A)(5K)",
	"(4A)5K",
	"(46A)6",
	"(467A)",
	"(46A)7",
	"(468A)",
	"(46A)8",
	"(469A)",
	"(46A)9",
	"(46TA)",
	"(46A)T",
	"(46JA)",
	"(46A)J",
	"(46QA)",
	"(46A)Q",
	"(46KA)",
	"(46A)K",
	"(4A)66",
	"(47A)6",
	"(4A)(67)",
	"(4A)67",
	"(48A)6",
	"(4A)(68)",
	"(4A)68",
	"(49A)6",
	"(4A)(69)",
	"(4A)69",
	"(4TA)6",
	"(4A)(6T)",
	"(4A)6T",
	"(4JA)6",
	"(4A)(6J)",
	"(4A)6J",
	"(4QA)6",
	"(4A)(6Q)",
	"(4A)6Q",
	"(4KA)6",
	"(4A)(6K)",
	"(4A)6K",
	"(47A)7",
	"(478A)",
	"(47A)8",
	"(479A)",
	"(47A)9",
	"(47TA)",
	"(47A)T",
	"(47JA)",
	"(47A)J",
	"(47QA)",
	"(47A)Q",
	"(47KA)",
	"(47A)K",
	"(4A)77",
	"(48A)7",
	"(4A)(78)",
	"(4A)78",
	"(49A)7",
	"(4A)(79)",
	"(4A)79",
	"(4TA)7",
	"(4A)(7T)",
	"(4A)7T",
	"(4JA)7",
	"(4A)(7J)",
	"(4A)7J",
	"(4QA)7",
	"(4A)(7Q)",
	"(4A)7Q",
	"(4KA)7",
	"(4A)(7K)",
	"(4A)7K",
	"(48A)8",
	"(489A)",
	"(48A)9",
	"(48TA)",
	"(48A)T",
	"(48JA)",
	"(48A)J",
	"(48QA)",
	"(48A)Q",
	"(48KA)",
	"(48A)K",
	"(4A)88",
	"(49A)8",
	"(4A)(89)",
	"(4A)89",
	"(4TA)8",
	"(4A)(8T)",
	"(4A)8T",
	"(4JA)8",
	"(4A)(8J)",
	"(4A)8J",
	"(4QA)8",
	"(4A)(8Q)",
	"(4A)8Q",
	"(4KA)8",
	"(4A)(8K)",
	"(4A)8K",
	"(49A)9",
	"(49TA)",
	"(49A)T",
	"(49JA)",
	"(49A)J",
	"(49QA)",
	"(49A)Q",
	"(49KA)",
	"(49A)K",
	"(4A)99",
	"(4TA)9",
	"(4A)(9T)",
	"(4A)9T",
	"(4JA)9",
	"(4A)(9J)",
	"(4A)9J",
	"(4QA)9",
	"(4A)(9Q)",
	"(4A)9Q",
	"(4KA)9",
	"(4A)(9K)",
	"(4A)9K",
	"(4TA)T",
	"(4TJA)",
	"(4TA)J",
	"(4TQA)",
	"(4TA)Q",
	"(4TKA)",
	"(4TA)K",
	"(4A)TT",
	"(4JA)T",
	"(4A)(TJ)",
	"(4A)TJ",
	"(4QA)T",
	"(4A)(TQ)",
	"(4A)TQ",
	"(4KA)T",
	"(4A)(TK)",
	"(4A)TK",
	"(4JA)J",
	"(4JQA)",
	"(4JA)Q",
	"(4JKA)",
	"(4JA)K",
	"(4A)JJ",
	"(4QA)J",
	"(4A)(JQ)",
	"(4A)JQ",
	"(4KA)J",
	"(4A)(JK)",
	"(4A)JK",
	"(4QA)Q",
	"(4QKA)",
	"(4QA)K",
	"(4A)QQ",
	"(4KA)Q",
	"(4A)(QK)",
	"(4A)QK",
	"(4KA)K",
	"(4A)KK",
	"A444",
	"(5A)44",
	"A(45)4",
	"A445",
	"(6A)44",
	"A(46)4",
	"A446",
	"(7A)44",
	"A(47)4",
	"A447",
	"(8A)44",
	"A(48)4",
	"A448",
	"(9A)44",
	"A(49)4",
	"A449",
	"(TA)44",
	"A(4T)4",
	"A44T",
	"(JA)44",
	"A(4J)4",
	"A44J",
	"(QA)44",
	"A(4Q)4",
	"A44Q",
	"(KA)44",
	"A(4K)4",
	"A44K",
	"(5A)(45)",
	"(5A)45",
	"(56A)4",
	"(5A)(46)",
	"(5A)46",
	"(57A)4",
	"(5A)(47)",
	"(5A)47",
	"(58A)4",
	"(5A)(48)",
	"(5A)48",
	"(59A)4",
	"(5A)(49)",
	"(5A)49",
	"(5TA)4",
	"(5A)(4T)",
	"(5A)4T",
	"(5JA)4",
	"(5A)(4J)",
	"(5A)4J",
	"(5QA)4",
	"(5A)(4Q)",
	"(5A)4Q",
	"(5KA)4",
	"(5A)(4K)",
	"(5A)4K",
	"A(45)5",
	"(6A)(45)",
	"A(456)",
	"A(45)6",
	"(7A)(45)",
	"A(457)",
	"A(45)7",
	"(8A)(45)",
	"A(458)",
	"A(45)8",
	"(9A)(45)",
	"A(459)",
	"A(45)9",
	"(TA)(45)",
	"A(45T)",
	"A(45)T",
	"(JA)(45)",
	"A(45J)",
	"A(45)J",
	"(QA)(45)",
	"A(45Q)",
	"A(45)Q",
	"(KA)(45)",
	"A(45K)",
	"A(45)K",
	"A455",
	"(6A)45",
	"A(46)5",
	"A4(56)",
	"A456",
	"(7A)45",
	"A(47)5",
	"A4(57)",
	"A457",
	"(8A)45",
	"A(48)5",
	"A4(58)",
	"A458",
	"(9A)45",
	"A(49)5",
	"A4(59)",
	"A459",
	"(TA)45",
	"A(4T)5",
	"A4(5T)",
	"A45T",
	"(JA)45",
	"A(4J)5",
	"A4(5J)",
	"A45J",
	"(QA)45",
	"A(4Q)5",
	"A4(5Q)",
	"A45Q",
	"(KA)45",
	"A(4K)5",
	"A4(5K)",
	"A45K",
	"(6A)(46)",
	"(6A)46",
	"(67A)4",
	"(6A)(47)",
	"(6A)47",
	"(68A)4",
	"(6A)(48)",
	"(6A)48",
	"(69A)4",
	"(6A)(49)",
	"(6A)49",
	"(6TA)4",
	"(6A)(4T)",
	"(6A)4T",
	"(6JA)4",
	"(6A)(4J)",
	"(6A)4J",
	"(6QA)4",
	"(6A)(4Q)",
	"(6A)4Q",
	"(6KA)4",
	"(6A)(4K)",
	"(6A)4K",
	"A(46)6",
	"(7A)(46)",
	"A(467)",
	"A(46)7",
	"(8A)(46)",
	"A(468)",
	"A(46)8",
	"(9A)(46)",
	"A(469)",
	"A(46)9",
	"(TA)(46)",
	"A(46T)",
	"A(46)T",
	"(JA)(46)",
	"A(46J)",
	"A(46)J",
	"(QA)(46)",
	"A(46Q)",
	"A(46)Q",
	"(KA)(46)",
	"A(46K)",
	"A(46)K",
	"A466",
	"(7A)46",
	"A(47)6",
	"A4(67)",
	"A467",
	"(8A)46",
	"A(48)6",
	"A4(68)",
	"A468",
	"(9A)46",
	"A(49)6",
	"A4(69)",
	"A469",
	"(TA)46",
	"A(4T)6",
	"A4(6T)",
	"A46T",
	"(JA)46",
	"A(4J)6",
	"A4(6J)",
	"A46J",
	"(QA)46",
	"A(4Q)6",
	"A4(6Q)",
	"A46Q",
	"(KA)46",
	"A(4K)6",
	"A4(6K)",
	"A46K",
	"(7A)(47)",
	"(7A)47",
	"(78A)4",
	"(7A)(48)",
	"(7A)48",
	"(79A)4",
	"(7A)(49)",
	"(7A)49",
	"(7TA)4",
	"(7A)(4T)",
	"(7A)4T",
	"(7JA)4",
	"(7A)(4J)",
	"(7A)4J",
	"(7QA)4",
	"(7A)(4Q)",
	"(7A)4Q",
	"(7KA)4",
	"(7A)(4K)",
	"(7A)4K",
	"A(47)7",
	"(8A)(47)",
	"A(478)",
	"A(47)8",
	"(9A)(47)",
	"A(479)",
	"A(47)9",
	"(TA)(47)",
	"A(47T)",
	"A(47)T",
	"(JA)(47)",
	"A(47J)",
	"A(47)J",
	"(QA)(47)",
	"A(47Q)",
	"A(47)Q",
	"(KA)(47)",
	"A(47K)",
	"A(47)K",
	"A477",
	"(8A)47",
	"A(48)7",
	"A4(78)",
	"A478",
	"(9A)47",
	"A(49)7",
	"A4(79)",
	"A479",
	"(TA)47",
	"A(4T)7",
	"A4(7T)",
	"A47T",
	"(JA)47",
	"A(4J)7",
	"A4(7J)",
	"A47J",
	"(QA)47",
	"A(4Q)7",
	"A4(7Q)",
	"A47Q",
	"(KA)47",
	"A(4K)7",
	"A4(7K)",
	"A47K",
	"(8A)(48)",
	"(8A)48",
	"(89A)4",
	"(8A)(49)",
	"(8A)49",
	"(8TA)4",
	"(8A)(4T)",
	"(8A)4T",
	"(8JA)4",
	"(8A)(4J)",
	"(8A)4J",
	"(8QA)4",
	"(8A)(4Q)",
	"(8A)4Q",
	"(8KA)4",
	"(8A)(4K)",
	"(8A)4K",
	"A(48)8",
	"(9A)(48)",
	"A(489)",
	"A(48)9",
	"(TA)(48)",
	"A(48T)",
	"A(48)T",
	"(JA)(48)",
	"A(48J)",
	"A(48)J",
	"(QA)(48)",
	"A(48Q)",
	"A(48)Q",
	"(KA)(48)",
	"A(48K)",
	"A(48)K",
	"A488",
	"(9A)48",
	"A(49)8",
	"A4(89)",
	"A489",
	"(TA)48",
	"A(4T)8",
	"A4(8T)",
	"A48T",
	"(JA)48",
	"A(4J)8",
	"A4(8J)",
	"A48J",
	"(QA)48",
	"A(4Q)8",
	"A4(8Q)",
	"A48Q",
	"(KA)48",
	"A(4K)8",
	"A4(8K)",
	"A48K",
	"(9A)(49)",
	"(9A)49",
	"(9TA)4",
	"(9A)(4T)",
	"(9A)4T",
	"(9JA)4",
	"(9A)(4J)",
	"(9A)4J",
	"(9QA)4",
	"(9A)(4Q)",
	"(9A)4Q",
	"(9KA)4",
	"(9A)(4K)",
	"(9A)4K",
	"A(49)9",
	"(TA)(49)",
	"A(49T)",
	"A(49)T",
	"(JA)(49)",
	"A(49J)",
	"A(49)J",
	"(QA)(49)",
	"A(49Q)",
	"A(49)Q",
	"(KA)(49)",
	"A(49K)",
	"A(49)K",
	"A499",
	"(TA)49",
	"A(4T)9",
	"A4(9T)",
	"A49T",
	"(JA)49",
	"A(4J)9",
	"A4(9J)",
	"A49J",
	"(QA)49",
	"A(4Q)9",
	"A4(9Q)",
	"A49Q",
	"(KA)49",
	"A(4K)9",
	"A4(9K)",
	"A49K",
	"(TA)(4T)",
	"(TA)4T",
	"(TJA)4",
	"(TA)(4J)",
	"(TA)4J",
	"(TQA)4",
	"(TA)(4Q)",
	"(TA)4Q",
	"(TKA)4",
	"(TA)(4K)",
	"(TA)4K",
	"A(4T)T",
	"(JA)(4T)",
	"A(4TJ)",
	"A(4T)J",
	"(QA)(4T)",
	"A(4TQ)",
	"A(4T)Q",
	"(KA)(4T)",
	"A(4TK)",
	"A(4T)K",
	"A4TT",
	"(JA)4T",
	"A(4J)T",
	"A4(TJ)",
	"A4TJ",
	"(QA)4T",
	"A(4Q)T",
	"A4(TQ)",
	"A4TQ",
	"(KA)4T",
	"A(4K)T",
	"A4(TK)",
	"A4TK",
	"(JA)(4J)",
	"(JA)4J",
	"(JQA)4",
	"(JA)(4Q)",
	"(JA)4Q",
	"(JKA)4",
	"(JA)(4K)",
	"(JA)4K",
	"A(4J)J",
	"(QA)(4J)",
	"A(4JQ)",
	"A(4J)Q",
	"(KA)(4J)",
	"A(4JK)",
	"A(4J)K",
	"A4JJ",
	"(QA)4J",
	"A(4Q)J",
	"A4(JQ)",
	"A4JQ",
	"(KA)4J",
	"A(4K)J",
	"A4(JK)",
	"A4JK",
	"(QA)(4Q)",
	"(QA)4Q",
	"(QKA)4",
	"(QA)(4K)",
	"(QA)4K",
	"A(4Q)Q",
	"(KA)(4Q)",
	"A(4QK)",
	"A(4Q)K",
	"A4QQ",
	"(KA)4Q",
	"A(4K)Q",
	"A4(QK)",
	"A4QK",
	"(KA)(4K)",
	"(KA)4K",
	"A(4K)K",
	"A4KK",
	"(5A)55",
	"(56A)5",
	"(5A)(56)",
	"(5A)56",
	"(57A)5",
	"(5A)(57)",
	"(5A)57",
	"(58A)5",
	"(5A)(58)",
	"(5A)58",
	"(59A)5",
	"(5A)(59)",
	"(5A)59",
	"(5TA)5",
	"(5A)(5T)",
	"(5A)5T",
	"(5JA)5",
	"(5A)(5J)",
	"(5A)5J",
	"(5QA)5",
	"(5A)(5Q)",
	"(5A)5Q",
	"(5KA)5",
	"(5A)(5K)",
	"(5A)5K",
	"(56A)6",
	"(567A)",
	"(56A)7",
	"(568A)",
	"(56A)8",
	"(569A)",
	"(56A)9",
	"(56TA)",
	"(56A)T",
	"(56JA)",
	"(56A)J",
	"(56QA)",
	"(56A)Q",
	"(56KA)",
	"(56A)K",
	"(5A)66",
	"(57A)6",
	"(5A)(67)",
	"(5A)67",
	"(58A)6",
	"(5A)(68)",
	"(5A)68",
	"(59A)6",
	"(5A)(69)",
	"(5A)69",
	"(5TA)6",
	"(5A)(6T)",
	"(5A)6T",
	"(5JA)6",
	"(5A)(6J)",
	"(5A)6J",
	"(5QA)6",
	"(5A)(6Q)",
	"(5A)6Q",
	"(5KA)6",
	"(5A)(6K)",
	"(5A)6K",
	"(57A)7",
	"(578A)",
	"(57A)8",
	"(579A)",
	"(57A)9",
	"(57TA)",
	"(57A)T",
	"(57JA)",
	"(57A)J",
	"(57QA)",
	"(57A)Q",
	"(57KA)",
	"(57A)K",
	"(5A)77",
	"(58A)7",
	"(5A)(78)",
	"(5A)78",
	"(59A)7",
	"(5A)(79)",
	"(5A)79",
	"(5TA)7",
	"(5A)(7T)",
	"(5A)7T",
	"(5JA)7",
	"(5A)(7J)",
	"(5A)7J",
	"(5QA)7",
	"(5A)(7Q)",
	"(5A)7Q",
	"(5KA)7",
	"(5A)(7K)",
	"(5A)7K",
	"(58A)8",
	"(589A)",
	"(58A)9",
	"(58TA)",
	"(58A)T",
	"(58JA)",
	"(58A)J",
	"(58QA)",
	"(58A)Q",
	"(58KA)",
	"(58A)K",
	"(5A)88",
	"(59A)8",
	"(5A)(89)",
	"(5A)89",
	"(5TA)8",
	"(5A)(8T)",
	"(5A)8T",
	"(5JA)8",
	"(5A)(8J)",
	"(5A)8J",
	"(5QA)8",
	"(5A)(8Q)",
	"(5A)8Q",
	"(5KA)8",
	"(5A)(8K)",
	"(5A)8K",
	"(59A)9",
	"(59TA)",
	"(59A)T",
	"(59JA)",
	"(59A)J",
	"(59QA)",
	"(59A)Q",
	"(59KA)",
	"(59A)K",
	"(5A)99",
	"(5TA)9",
	"(5A)(9T)",
	"(5A)9T",
	"(5JA)9",
	"(5A)(9J)",
	"(5A)9J",
	"(5QA)9",
	"(5A)(9Q)",
	"(5A)9Q",
	"(5KA)9",
	"(5A)(9K)",
	"(5A)9K",
	"(5TA)T",
	"(5TJA)",
	"(5TA)J",
	"(5TQA)",
	"(5TA)Q",
	"(5TKA)",
	"(5TA)K",
	"(5A)TT",
	"(5JA)T",
	"(5A)(TJ)",
	"(5A)TJ",
	"(5QA)T",
	"(5A)(TQ)",
	"(5A)TQ",
	"(5KA)T",
	"(5A)(TK)",
	"(5A)TK",
	"(5JA)J",
	"(5JQA)",
	"(5JA)Q",
	"(5JKA)",
	"(5JA)K",
	"(5A)JJ",
	"(5QA)J",
	"(5A)(JQ)",
	"(5A)JQ",
	"(5KA)J",
	"(5A)(JK)",
	"(5A)JK",
	"(5QA)Q",
	"(5QKA)",
	"(5QA)K",
	"(5A)QQ",
	"(5KA)Q",
	"(5A)(QK)",
	"(5A)QK",
	"(5KA)K",
	"(5A)KK",
	"A555",
	"(6A)55",
	"A(56)5",
	"A556",
	"(7A)55",
	"A(57)5",
	"A557",
	"(8A)55",
	"A(58)5",
	"A558",
	"(9A)55",
	"A(59)5",
	"A559",
	"(TA)55",
	"A(5T)5",
	"A55T",
	"(JA)55",
	"A(5J)5",
	"A55J",
	"(QA)55",
	"A(5Q)5",
	"A55Q",
	"(KA)55",
	"A(5K)5",
	"A55K",
	"(6A)(56)",
	"(6A)56",
	"(67A)5",
	"(6A)(57)",
	"(6A)57",
	"(68A)5",
	"(6A)(58)",
	"(6A)58",
	"(69A)5",
	"(6A)(59)",
	"(6A)59",
	"(6TA)5",
	"(6A)(5T)",
	"(6A)5T",
	"(6JA)5",
	"(6A)(5J)",
	"(6A)5J",
	"(6QA)5",
	"(6A)(5Q)",
	"(6A)5Q",
	"(6KA)5",
	"(6A)(5K)",
	"(6A)5K",
	"A(56)6",
	"(7A)(56)",
	"A(567)",
	"A(56)7",
	"(8A)(56)",
	"A(568)",
	"A(56)8",
	"(9A)(56)",
	"A(569)",
	"A(56)9",
	"(TA)(56)",
	"A(56T)",
	"A(56)T",
	"(JA)(56)",
	"A(56J)",
	"A(56)J",
	"(QA)(56)",
	"A(56Q)",
	"A(56)Q",
	"(KA)(56)",
	"A(56K)",
	"A(56)K",
	"A566",
	"(7A)56",
	"A(57)6",
	"A5(67)",
	"A567",
	"(8A)56",
	"A(58)6",
	"A5(68)",
	"A568",
	"(9A)56",
	"A(59)6",
	"A5(69)",
	"A569",
	"(TA)56",
	"A(5T)6",
	"A5(6T)",
	"A56T",
	"(JA)56",
	"A(5J)6",
	"A5(6J)",
	"A56J",
	"(QA)56",
	"A(5Q)6",
	"A5(6Q)",
	"A56Q",
	"(KA)56",
	"A(5K)6",
	"A5(6K)",
	"A56K",
	"(7A)(57)",
	"(7A)57",
	"(78A)5",
	"(7A)(58)",
	"(7A)58",
	"(79A)5",
	"(7A)(59)",
	"(7A)59",
	"(7TA)5",
	"(7A)(5T)",
	"(7A)5T",
	"(7JA)5",
	"(7A)(5J)",
	"(7A)5J",
	"(7QA)5",
	"(7A)(5Q)",
	"(7A)5Q",
	"(7KA)5",
	"(7A)(5K)",
	"(7A)5K",
	"A(57)7",
	"(8A)(57)",
	"A(578)",
	"A(57)8",
	"(9A)(57)",
	"A(579)",
	"A(57)9",
	"(TA)(57)",
	"A(57T)",
	"A(57)T",
	"(JA)(57)",
	"A(57J)",
	"A(57)J",
	"(QA)(57)",
	"A(57Q)",
	"A(57)Q",
	"(KA)(57)",
	"A(57K)",
	"A(57)K",
	"A577",
	"(8A)57",
	"A(58)7",
	"A5(78)",
	"A578",
	"(9A)57",
	"A(59)7",
	"A5(79)",
	"A579",
	"(TA)57",
	"A(5T)7",
	"A5(7T)",
	"A57T",
	"(JA)57",
	"A(5J)7",
	"A5(7J)",
	"A57J",
	"(QA)57",
	"A(5Q)7",
	"A5(7Q)",
	"A57Q",
	"(KA)57",
	"A(5K)7",
	"A5(7K)",
	"A57K",
	"(8A)(58)",
	"(8A)58",
	"(89A)5",
	"(8A)(59)",
	"(8A)59",
	"(8TA)5",
	"(8A)(5T)",
	"(8A)5T",
	"(8JA)5",
	"(8A)(5J)",
	"(8A)5J",
	"(8QA)5",
	"(8A)(5Q)",
	"(8A)5Q",
	"(8KA)5",
	"(8A)(5K)",
	"(8A)5K",
	"A(58)8",
	"(9A)(58)",
	"A(589)",
	"A(58)9",
	"(TA)(58)",
	"A(58T)",
	"A(58)T",
	"(JA)(58)",
	"A(58J)",
	"A(58)J",
	"(QA)(58)",
	"A(58Q)",
	"A(58)Q",
	"(KA)(58)",
	"A(58K)",
	"A(58)K",
	"A588",
	"(9A)58",
	"A(59)8",
	"A5(89)",
	"A589",
	"(TA)58",
	"A(5T)8",
	"A5(8T)",
	"A58T",
	"(JA)58",
	"A(5J)8",
	"A5(8J)",
	"A58J",
	"(QA)58",
	"A(5Q)8",
	"A5(8Q)",
	"A58Q",
	"(KA)58",
	"A(5K)8",
	"A5(8K)",
	"A58K",
	"(9A)(59)",
	"(9A)59",
	"(9TA)5",
	"(9A)(5T)",
	"(9A)5T",
	"(9JA)5",
	"(9A)(5J)",
	"(9A)5J",
	"(9QA)5",
	"(9A)(5Q)",
	"(9A)5Q",
	"(9KA)5",
	"(9A)(5K)",
	"(9A)5K",
	"A(59)9",
	"(TA)(59)",
	"A(59T)",
	"A(59)T",
	"(JA)(59)",
	"A(59J)",
	"A(59)J",
	"(QA)(59)",
	"A(59Q)",
	"A(59)Q",
	"(KA)(59)",
	"A(59K)",
	"A(59)K",
	"A599",
	"(TA)59",
	"A(5T)9",
	"A5(9T)",
	"A59T",
	"(JA)59",
	"A(5J)9",
	"A5(9J)",
	"A59J",
	"(QA)59",
	"A(5Q)9",
	"A5(9Q)",
	"A59Q",
	"(KA)59",
	"A(5K)9",
	"A5(9K)",
	"A59K",
	"(TA)(5T)",
	"(TA)5T",
	"(TJA)5",
	"(TA)(5J)",
	"(TA)5J",
	"(TQA)5",
	"(TA)(5Q)",
	"(TA)5Q",
	"(TKA)5",
	"(TA)(5K)",
	"(TA)5K",
	"A(5T)T",
	"(JA)(5T)",
	"A(5TJ)",
	"A(5T)J",
	"(QA)(5T)",
	"A(5TQ)",
	"A(5T)Q",
	"(KA)(5T)",
	"A(5TK)",
	"A(5T)K",
	"A5TT",
	"(JA)5T",
	"A(5J)T",
	"A5(TJ)",
	"A5TJ",
	"(QA)5T",
	"A(5Q)T",
	"A5(TQ)",
	"A5TQ",
	"(KA)5T",
	"A(5K)T",
	"A5(TK)",
	"A5TK",
	"(JA)(5J)",
	"(JA)5J",
	"(JQA)5",
	"(JA)(5Q)",
	"(JA)5Q",
	"(JKA)5",
	"(JA)(5K)",
	"(JA)5K",
	"A(5J)J",
	"(QA)(5J)",
	"A(5JQ)",
	"A(5J)Q",
	"(KA)(5J)",
	"A(5JK)",
	"A(5J)K",
	"A5JJ",
	"(QA)5J",
	"A(5Q)J",
	"A5(JQ)",
	"A5JQ",
	"(KA)5J",
	"A(5K)J",
	"A5(JK)",
	"A5JK",
	"(QA)(5Q)",
	"(QA)5Q",
	"(QKA)5",
	"(QA)(5K)",
	"(QA)5K",
	"A(5Q)Q",
	"(KA)(5Q)",
	"A(5QK)",
	"A(5Q)K",
	"A5QQ",
	"(KA)5Q",
	"A(5K)Q",
	"A5(QK)",
	"A5QK",
	"(KA)(5K)",
	"(KA)5K",
	"A(5K)K",
	"A5KK",
	"(6A)66",
	"(67A)6",
	"(6A)(67)",
	"(6A)67",
	"(68A)6",
	"(6A)(68)",
	"(6A)68",
	"(69A)6",
	"(6A)(69)",
	"(6A)69",
	"(6TA)6",
	"(6A)(6T)",
	"(6A)6T",
	"(6JA)6",
	"(6A)(6J)",
	"(6A)6J",
	"(6QA)6",
	"(6A)(6Q)",
	"(6A)6Q",
	"(6KA)6",
	"(6A)(6K)",
	"(6A)6K",
	"(67A)7",
	"(678A)",
	"(67A)8",
	"(679A)",
	"(67A)9",
	"(67TA)",
	"(67A)T",
	"(67JA)",
	"(67A)J",
	"(67QA)",
	"(67A)Q",
	"(67KA)",
	"(67A)K",
	"(6A)77",
	"(68A)7",
	"(6A)(78)",
	"(6A)78",
	"(69A)7",
	"(6A)(79)",
	"(6A)79",
	"(6TA)7",
	"(6A)(7T)",
	"(6A)7T",
	"(6JA)7",
	"(6A)(7J)",
	"(6A)7J",
	"(6QA)7",
	"(6A)(7Q)",
	"(6A)7Q",
	"(6KA)7",
	"(6A)(7K)",
	"(6A)7K",
	"(68A)8",
	"(689A)",
	"(68A)9",
	"(68TA)",
	"(68A)T",
	"(68JA)",
	"(68A)J",
	"(68QA)",
	"(68A)Q",
	"(68KA)",
	"(68A)K",
	"(6A)88",
	"(69A)8",
	"(6A)(89)",
	"(6A)89",
	"(6TA)8",
	"(6A)(8T)",
	"(6A)8T",
	"(6JA)8",
	"(6A)(8J)",
	"(6A)8J",
	"(6QA)8",
	"(6A)(8Q)",
	"(6A)8Q",
	"(6KA)8",
	"(6A)(8K)",
	"(6A)8K",
	"(69A)9",
	"(69TA)",
	"(69A)T",
	"(69JA)",
	"(69A)J",
	"(69QA)",
	"(69A)Q",
	"(69KA)",
	"(69A)K",
	"(6A)99",
	"(6TA)9",
	"(6A)(9T)",
	"(6A)9T",
	"(6JA)9",
	"(6A)(9J)",
	"(6A)9J",
	"(6QA)9",
	"(6A)(9Q)",
	"(6A)9Q",
	"(6KA)9",
	"(6A)(9K)",
	"(6A)9K",
	"(6TA)T",
	"(6TJA)",
	"(6TA)J",
	"(6TQA)",
	"(6TA)Q",
	"(6TKA)",
	"(6TA)K",
	"(6A)TT",
	"(6JA)T",
	"(6A)(TJ)",
	"(6A)TJ",
	"(6QA)T",
	"(6A)(TQ)",
	"(6A)TQ",
	"(6KA)T",
	"(6A)(TK)",
	"(6A)TK",
	"(6JA)J",
	"(6JQA)",
	"(6JA)Q",
	"(6JKA)",
	"(6JA)K",
	"(6A)JJ",
	"(6QA)J",
	"(6A)(JQ)",
	"(6A)JQ",
	"(6KA)J",
	"(6A)(JK)",
	"(6A)JK",
	"(6QA)Q",
	"(6QKA)",
	"(6QA)K",
	"(6A)QQ",
	"(6KA)Q",
	"(6A)(QK)",
	"(6A)QK",
	"(6KA)K",
	"(6A)KK",
	"A666",
	"(7A)66",
	"A(67)6",
	"A667",
	"(8A)66",
	"A(68)6",
	"A668",
	"(9A)66",
	"A(69)6",
	"A669",
	"(TA)66",
	"A(6T)6",
	"A66T",
	"(JA)66",
	"A(6J)6",
	"A66J",
	"(QA)66",
	"A(6Q)6",
	"A66Q",
	"(KA)66",
	"A(6K)6",
	"A66K",
	"(7A)(67)",
	"(7A)67",
	"(78A)6",
	"(7A)(68)",
	"(7A)68",
	"(79A)6",
	"(7A)(69)",
	"(7A)69",
	"(7TA)6",
	"(7A)(6T)",
	"(7A)6T",
	"(7JA)6",
	"(7A)(6J)",
	"(7A)6J",
	"(7QA)6",
	"(7A)(6Q)",
	"(7A)6Q",
	"(7KA)6",
	"(7A)(6K)",
	"(7A)6K",
	"A(67)7",
	"(8A)(67)",
	"A(678)",
	"A(67)8",
	"(9A)(67)",
	"A(679)",
	"A(67)9",
	"(TA)(67)",
	"A(67T)",
	"A(67)T",
	"(JA)(67)",
	"A(67J)",
	"A(67)J",
	"(QA)(67)",
	"A(67Q)",
	"A(67)Q",
	"(KA)(67)",
	"A(67K)",
	"A(67)K",
	"A677",
	"(8A)67",
	"A(68)7",
	"A6(78)",
	"A678",
	"(9A)67",
	"A(69)7",
	"A6(79)",
	"A679",
	"(TA)67",
	"A(6T)7",
	"A6(7T)",
	"A67T",
	"(JA)67",
	"A(6J)7",
	"A6(7J)",
	"A67J",
	"(QA)67",
	"A(6Q)7",
	"A6(7Q)",
	"A67Q",
	"(KA)67",
	"A(6K)7",
	"A6(7K)",
	"A67K",
	"(8A)(68)",
	"(8A)68",
	"(89A)6",
	"(8A)(69)",
	"(8A)69",
	"(8TA)6",
	"(8A)(6T)",
	"(8A)6T",
	"(8JA)6",
	"(8A)(6J)",
	"(8A)6J",
	"(8QA)6",
	"(8A)(6Q)",
	"(8A)6Q",
	"(8KA)6",
	"(8A)(6K)",
	"(8A)6K",
	"A(68)8",
	"(9A)(68)",
	"A(689)",
	"A(68)9",
	"(TA)(68)",
	"A(68T)",
	"A(68)T",
	"(JA)(68)",
	"A(68J)",
	"A(68)J",
	"(QA)(68)",
	"A(68Q)",
	"A(68)Q",
	"(KA)(68)",
	"A(68K)",
	"A(68)K",
	"A688",
	"(9A)68",
	"A(69)8",
	"A6(89)",
	"A689",
	"(TA)68",
	"A(6T)8",
	"A6(8T)",
	"A68T",
	"(JA)68",
	"A(6J)8",
	"A6(8J)",
	"A68J",
	"(QA)68",
	"A(6Q)8",
	"A6(8Q)",
	"A68Q",
	"(KA)68",
	"A(6K)8",
	"A6(8K)",
	"A68K",
	"(9A)(69)",
	"(9A)69",
	"(9TA)6",
	"(9A)(6T)",
	"(9A)6T",
	"(9JA)6",
	"(9A)(6J)",
	"(9A)6J",
	"(9QA)6",
	"(9A)(6Q)",
	"(9A)6Q",
	"(9KA)6",
	"(9A)(6K)",
	"(9A)6K",
	"A(69)9",
	"(TA)(69)",
	"A(69T)",
	"A(69)T",
	"(JA)(69)",
	"A(69J)",
	"A(69)J",
	"(QA)(69)",
	"A(69Q)",
	"A(69)Q",
	"(KA)(69)",
	"A(69K)",
	"A(69)K",
	"A699",
	"(TA)69",
	"A(6T)9",
	"A6(9T)",
	"A69T",
	"(JA)69",
	"A(6J)9",
	"A6(9J)",
	"A69J",
	"(QA)69",
	"A(6Q)9",
	"A6(9Q)",
	"A69Q",
	"(KA)69",
	"A(6K)9",
	"A6(9K)",
	"A69K",
	"(TA)(6T)",
	"(TA)6T",
	"(TJA)6",
	"(TA)(6J)",
	"(TA)6J",
	"(TQA)6",
	"(TA)(6Q)",
	"(TA)6Q",
	"(TKA)6",
	"(TA)(6K)",
	"(TA)6K",
	"A(6T)T",
	"(JA)(6T)",
	"A(6TJ)",
	"A(6T)J",
	"(QA)(6T)",
	"A(6TQ)",
	"A(6T)Q",
	"(KA)(6T)",
	"A(6TK)",
	"A(6T)K",
	"A6TT",
	"(JA)6T",
	"A(6J)T",
	"A6(TJ)",
	"A6TJ",
	"(QA)6T",
	"A(6Q)T",
	"A6(TQ)",
	"A6TQ",
	"(KA)6T",
	"A(6K)T",
	"A6(TK)",
	"A6TK",
	"(JA)(6J)",
	"(JA)6J",
	"(JQA)6",
	"(JA)(6Q)",
	"(JA)6Q",
	"(JKA)6",
	"(JA)(6K)",
	"(JA)6K",
	"A(6J)J",
	"(QA)(6J)",
	"A(6JQ)",
	"A(6J)Q",
	"(KA)(6J)",
	"A(6JK)",
	"A(6J)K",
	"A6JJ",
	"(QA)6J",
	"A(6Q)J",
	"A6(JQ)",
	"A6JQ",
	"(KA)6J",
	"A(6K)J",
	"A6(JK)",
	"A6JK",
	"(QA)(6Q)",
	"(QA)6Q",
	"(QKA)6",
	"(QA)(6K)",
	"(QA)6K",
	"A(6Q)Q",
	"(KA)(6Q)",
	"A(6QK)",
	"A(6Q)K",
	"A6QQ",
	"(KA)6Q",
	"A(6K)Q",
	"A6(QK)",
	"A6QK",
	"(KA)(6K)",
	"(KA)6K",
	"A(6K)K",
	"A6KK",
	"(7A)77",
	"(78A)7",
	"(7A)(78)",
	"(7A)78",
	"(79A)7",
	"(7A)(79)",
	"(7A)79",
	"(7TA)7",
	"(7A)(7T)",
	"(7A)7T",
	"(7JA)7",
	"(7A)(7J)",
	"(7A)7J",
	"(7QA)7",
	"(7A)(7Q)",
	"(7A)7Q",
	"(7KA)7",
	"(7A)(7K)",
	"(7A)7K",
	"(78A)8",
	"(789A)",
	"(78A)9",
	"(78TA)",
	"(78A)T",
	"(78JA)",
	"(78A)J",
	"(78QA)",
	"(78A)Q",
	"(78KA)",
	"(78A)K",
	"(7A)88",
	"(79A)8",
	"(7A)(89)",
	"(7A)89",
	"(7TA)8",
	"(7A)(8T)",
	"(7A)8T",
	"(7JA)8",
	"(7A)(8J)",
	"(7A)8J",
	"(7QA)8",
	"(7A)(8Q)",
	"(7A)8Q",
	"(7KA)8",
	"(7A)(8K)",
	"(7A)8K",
	"(79A)9",
	"(79TA)",
	"(79A)T",
	"(79JA)",
	"(79A)J",
	"(79QA)",
	"(79A)Q",
	"(79KA)",
	"(79A)K",
	"(7A)99",
	"(7TA)9",
	"(7A)(9T)",
	"(7A)9T",
	"(7JA)9",
	"(7A)(9J)",
	"(7A)9J",
	"(7QA)9",
	"(7A)(9Q)",
	"(7A)9Q",
	"(7KA)9",
	"(7A)(9K)",
	"(7A)9K",
	"(7TA)T",
	"(7TJA)",
	"(7TA)J",
	"(7TQA)",
	"(7TA)Q",
	"(7TKA)",
	"(7TA)K",
	"(7A)TT",
	"(7JA)T",
	"(7A)(TJ)",
	"(7A)TJ",
	"(7QA)T",
	"(7A)(TQ)",
	"(7A)TQ",
	"(7KA)T",
	"(7A)(TK)",
	"(7A)TK",
	"(7JA)J",
	"(7JQA)",
	"(7JA)Q",
	"(7JKA)",
	"(7JA)K",
	"(7A)JJ",
	"(7QA)J",
	"(7A)(JQ)",
	"(7A)JQ",
	"(7KA)J",
	"(7A)(JK)",
	"(7A)JK",
	"(7QA)Q",
	"(7QKA)",
	"(7QA)K",
	"(7A)QQ",
	"(7KA)Q",
	"(7A)(QK)",
	"(7A)QK",
	"(7KA)K",
	"(7A)KK",
	"A777",
	"(8A)77",
	"A(78)7",
	"A778",
	"(9A)77",
	"A(79)7",
	"A779",
	"(TA)77",
	"A(7T)7",
	"A77T",
	"(JA)77",
	"A(7J)7",
	"A77J",
	"(QA)77",
	"A(7Q)7",
	"A77Q",
	"(KA)77",
	"A(7K)7",
	"A77K",
	"(8A)(78)",
	"(8A)78",
	"(89A)7",
	"(8A)(79)",
	"(8A)79",
	"(8TA)7",
	"(8A)(7T)",
	"(8A)7T",
	"(8JA)7",
	"(8A)(7J)",
	"(8A)7J",
	"(8QA)7",
	"(8A)(7Q)",
	"(8A)7Q",
	"(8KA)7",
	"(8A)(7K)",
	"(8A)7K",
	"A(78)8",
	"(9A)(78)",
	"A(789)",
	"A(78)9",
	"(TA)(78)",
	"A(78T)",
	"A(78)T",
	"(JA)(78)",
	"A(78J)",
	"A(78)J",
	"(QA)(78)",
	"A(78Q)",
	"A(78)Q",
	"(KA)(78)",
	"A(78K)",
	"A(78)K",
	"A788",
	"(9A)78",
	"A(79)8",
	"A7(89)",
	"A789",
	"(TA)78",
	"A(7T)8",
	"A7(8T)",
	"A78T",
	"(JA)78",
	"A(7J)8",
	"A7(8J)",
	"A78J",
	"(QA)78",
	"A(7Q)8",
	"A7(8Q)",
	"A78Q",
	"(KA)78",
	"A(7K)8",
	"A7(8K)",
	"A78K",
	"(9A)(79)",
	"(9A)79",
	"(9TA)7",
	"(9A)(7T)",
	"(9A)7T",
	"(9JA)7",
	"(9A)(7J)",
	"(9A)7J",
	"(9QA)7",
	"(9A)(7Q)",
	"(9A)7Q",
	"(9KA)7",
	"(9A)(7K)",
	"(9A)7K",
	"A(79)9",
	"(TA)(79)",
	"A(79T)",
	"A(79)T",
	"(JA)(79)",
	"A(79J)",
	"A(79)J",
	"(QA)(79)",
	"A(79Q)",
	"A(79)Q",
	"(KA)(79)",
	"A(79K)",
	"A(79)K",
	"A799",
	"(TA)79",
	"A(7T)9",
	"A7(9T)",
	"A79T",
	"(JA)79",
	"A(7J)9",
	"A7(9J)",
	"A79J",
	"(QA)79",
	"A(7Q)9",
	"A7(9Q)",
	"A79Q",
	"(KA)79",
	"A(7K)9",
	"A7(9K)",
	"A79K",
	"(TA)(7T)",
	"(TA)7T",
	"(TJA)7",
	"(TA)(7J)",
	"(TA)7J",
	"(TQA)7",
	"(TA)(7Q)",
	"(TA)7Q",
	"(TKA)7",
	"(TA)(7K)",
	"(TA)7K",
	"A(7T)T",
	"(JA)(7T)",
	"A(7TJ)",
	"A(7T)J",
	"(QA)(7T)",
	"A(7TQ)",
	"A(7T)Q",
	"(KA)(7T)",
	"A(7TK)",
	"A(7T)K",
	"A7TT",
	"(JA)7T",
	"A(7J)T",
	"A7(TJ)",
	"A7TJ",
	"(QA)7T",
	"A(7Q)T",
	"A7(TQ)",
	"A7TQ",
	"(KA)7T",
	"A(7K)T",
	"A7(TK)",
	"A7TK",
	"(JA)(7J)",
	"(JA)7J",
	"(JQA)7",
	"(JA)(7Q)",
	"(JA)7Q",
	"(JKA)7",
	"(JA)(7K)",
	"(JA)7K",
	"A(7J)J",
	"(QA)(7J)",
	"A(7JQ)",
	"A(7J)Q",
	"(KA)(7J)",
	"A(7JK)",
	"A(7J)K",
	"A7JJ",
	"(QA)7J",
	"A(7Q)J",
	"A7(JQ)",
	"A7JQ",
	"(KA)7J",
	"A(7K)J",
	"A7(JK)",
	"A7JK",
	"(QA)(7Q)",
	"(QA)7Q",
	"(QKA)7",
	"(QA)(7K)",
	"(QA)7K",
	"A(7Q)Q",
	"(KA)(7Q)",
	"A(7QK)",
	"A(7Q)K",
	"A7QQ",
	"(KA)7Q",
	"A(7K)Q",
	"A7(QK)",
	"A7QK",
	"(KA)(7K)",
	"(KA)7K",
	"A(7K)K",
	"A7KK",
	"(8A)88",
	"(89A)8",
	"(8A)(89)",
	"(8A)89",
	"(8TA)8",
	"(8A)(8T)",
	"(8A)8T",
	"(8JA)8",
	"(8A)(8J)",
	"(8A)8J",
	"(8QA)8",
	"(8A)(8Q)",
	"(8A)8Q",
	"(8KA)8",
	"(8A)(8K)",
	"(8A)8K",
	"(89A)9",
	"(89TA)",
	"(89A)T",
	"(89JA)",
	"(89A)J",
	"(89QA)",
	"(89A)Q",
	"(89KA)",
	"(89A)K",
	"(8A)99",
	"(8TA)9",
	"(8A)(9T)",
	"(8A)9T",
	"(8JA)9",
	"(8A)(9J)",
	"(8A)9J",
	"(8QA)9",
	"(8A)(9Q)",
	"(8A)9Q",
	"(8KA)9",
	"(8A)(9K)",
	"(8A)9K",
	"(8TA)T",
	"(8TJA)",
	"(8TA)J",
	"(8TQA)",
	"(8TA)Q",
	"(8TKA)",
	"(8TA)K",
	"(8A)TT",
	"(8JA)T",
	"(8A)(TJ)",
	"(8A)TJ",
	"(8QA)T",
	"(8A)(TQ)",
	"(8A)TQ",
	"(8KA)T",
	"(8A)(TK)",
	"(8A)TK",
	"(8JA)J",
	"(8JQA)",
	"(8JA)Q",
	"(8JKA)",
	"(8JA)K",
	"(8A)JJ",
	"(8QA)J",
	"(8A)(JQ)",
	"(8A)JQ",
	"(8KA)J",
	"(8A)(JK)",
	"(8A)JK",
	"(8QA)Q",
	"(8QKA)",
	"(8QA)K",
	"(8A)QQ",
	"(8KA)Q",
	"(8A)(QK)",
	"(8A)QK",
	"(8KA)K",
	"(8A)KK",
	"A888",
	"(9A)88",
	"A(89)8",
	"A889",
	"(TA)88",
	"A(8T)8",
	"A88T",
	"(JA)88",
	"A(8J)8",
	"A88J",
	"(QA)88",
	"A(8Q)8",
	"A88Q",
	"(KA)88",
	"A(8K)8",
	"A88K",
	"(9A)(89)",
	"(9A)89",
	"(9TA)8",
	"(9A)(8T)",
	"(9A)8T",
	"(9JA)8",
	"(9A)(8J)",
	"(9A)8J",
	"(9QA)8",
	"(9A)(8Q)",
	"(9A)8Q",
	"(9KA)8",
	"(9A)(8K)",
	"(9A)8K",
	"A(89)9",
	"(TA)(89)",
	"A(89T)",
	"A(89)T",
	"(JA)(89)",
	"A(89J)",
	"A(89)J",
	"(QA)(89)",
	"A(89Q)",
	"A(89)Q",
	"(KA)(89)",
	"A(89K)",
	"A(89)K",
	"A899",
	"(TA)89",
	"A(8T)9",
	"A8(9T)",
	"A89T",
	"(JA)89",
	"A(8J)9",
	"A8(9J)",
	"A89J",
	"(QA)89",
	"A(8Q)9",
	"A8(9Q)",
	"A89Q",
	"(KA)89",
	"A(8K)9",
	"A8(9K)",
	"A89K",
	"(TA)(8T)",
	"(TA)8T",
	"(TJA)8",
	"(TA)(8J)",
	"(TA)8J",
	"(TQA)8",
	"(TA)(8Q)",
	"(TA)8Q",
	"(TKA)8",
	"(TA)(8K)",
	"(TA)8K",
	"A(8T)T",
	"(JA)(8T)",
	"A(8TJ)",
	"A(8T)J",
	"(QA)(8T)",
	"A(8TQ)",
	"A(8T)Q",
	"(KA)(8T)",
	"A(8TK)",
	"A(8T)K",
	"A8TT",
	"(JA)8T",
	"A(8J)T",
	"A8(TJ)",
	"A8TJ",
	"(QA)8T",
	"A(8Q)T",
	"A8(TQ)",
	"A8TQ",
	"(KA)8T",
	"A(8K)T",
	"A8(TK)",
	"A8TK",
	"(JA)(8J)",
	"(JA)8J",
	"(JQA)8",
	"(JA)(8Q)",
	"(JA)8Q",
	"(JKA)8",
	"(JA)(8K)",
	"(JA)8K",
	"A(8J)J",
	"(QA)(8J)",
	"A(8JQ)",
	"A(8J)Q",
	"(KA)(8J)",
	"A(8JK)",
	"A(8J)K",
	"A8JJ",
	"(QA)8J",
	"A(8Q)J",
	"A8(JQ)",
	"A8JQ",
	"(KA)8J",
	"A(8K)J",
	"A8(JK)",
	"A8JK",
	"(QA)(8Q)",
	"(QA)8Q",
	"(QKA)8",
	"(QA)(8K)",
	"(QA)8K",
	"A(8Q)Q",
	"(KA)(8Q)",
	"A(8QK)",
	"A(8Q)K",
	"A8QQ",
	"(KA)8Q",
	"A(8K)Q",
	"A8(QK)",
	"A8QK",
	"(KA)(8K)",
	"(KA)8K",
	"A(8K)K",
	"A8KK",
	"(9A)99",
	"(9TA)9",
	"(9A)(9T)",
	"(9A)9T",
	"(9JA)9",
	"(9A)(9J)",
	"(9A)9J",
	"(9QA)9",
	"(9A)(9Q)",
	"(9A)9Q",
	"(9KA)9",
	"(9A)(9K)",
	"(9A)9K",
	"(9TA)T",
	"(9TJA)",
	"(9TA)J",
	"(9TQA)",
	"(9TA)Q",
	"(9TKA)",
	"(9TA)K",
	"(9A)TT",
	"(9JA)T",
	"(9A)(TJ)",
	"(9A)TJ",
	"(9QA)T",
	"(9A)(TQ)",
	"(9A)TQ",
	"(9KA)T",
	"(9A)(TK)",
	"(9A)TK",
	"(9JA)J",
	"(9JQA)",
	"(9JA)Q",
	"(9JKA)",
	"(9JA)K",
	"(9A)JJ",
	"(9QA)J",
	"(9A)(JQ)",
	"(9A)JQ",
	"(9KA)J",
	"(9A)(JK)",
	"(9A)JK",
	"(9QA)Q",
	"(9QKA)",
	"(9QA)K",
	"(9A)QQ",
	"(9KA)Q",
	"(9A)(QK)",
	"(9A)QK",
	"(9KA)K",
	"(9A)KK",
	"A999",
	"(TA)99",
	"A(9T)9",
	"A99T",
	"(JA)99",
	"A(9J)9",
	"A99J",
	"(QA)99",
	"A(9Q)9",
	"A99Q",
	"(KA)99",
	"A(9K)9",
	"A99K",
	"(TA)(9T)",
	"(TA)9T",
	"(TJA)9",
	"(TA)(9J)",
	"(TA)9J",
	"(TQA)9",
	"(TA)(9Q)",
	"(TA)9Q",
	"(TKA)9",
	"(TA)(9K)",
	"(TA)9K",
	"A(9T)T",
	"(JA)(9T)",
	"A(9TJ)",
	"A(9T)J",
	"(QA)(9T)",
	"A(9TQ)",
	"A(9T)Q",
	"(KA)(9T)",
	"A(9TK)",
	"A(9T)K",
	"A9TT",
	"(JA)9T",
	"A(9J)T",
	"A9(TJ)",
	"A9TJ",
	"(QA)9T",
	"A(9Q)T",
	"A9(TQ)",
	"A9TQ",
	"(KA)9T",
	"A(9K)T",
	"A9(TK)",
	"A9TK",
	"(JA)(9J)",
	"(JA)9J",
	"(JQA)9",
	"(JA)(9Q)",
	"(JA)9Q",
	"(JKA)9",
	"(JA)(9K)",
	"(JA)9K",
	"A(9J)J",
	"(QA)(9J)",
	"A(9JQ)",
	"A(9J)Q",
	"(KA)(9J)",
	"A(9JK)",
	"A(9J)K",
	"A9JJ",
	"(QA)9J",
	"A(9Q)J",
	"A9(JQ)",
	"A9JQ",
	"(KA)9J",
	"A(9K)J",
	"A9(JK)",
	"A9JK",
	"(QA)(9Q)",
	"(QA)9Q",
	"(QKA)9",
	"(QA)(9K)",
	"(QA)9K",
	"A(9Q)Q",
	"(KA)(9Q)",
	"A(9QK)",
	"A(9Q)K",
	"A9QQ",
	"(KA)9Q",
	"A(9K)Q",
	"A9(QK)",
	"A9QK",
	"(KA)(9K)",
	"(KA)9K",
	"A(9K)K",
	"A9KK",
	"(TA)TT",
	"(TJA)T",
	"(TA)(TJ)",
	"(TA)TJ",
	"(TQA)T",
	"(TA)(TQ)",
	"(TA)TQ",
	"(TKA)T",
	"(TA)(TK)",
	"(TA)TK",
	"(TJA)J",
	"(TJQA)",
	"(TJA)Q",
	"(TJKA)",
	"(TJA)K",
	"(TA)JJ",
	"(TQA)J",
	"(TA)(JQ)",
	"(TA)JQ",
	"(TKA)J",
	"(TA)(JK)",
	"(TA)JK",
	"(TQA)Q",
	"(TQKA)",
	"(TQA)K",
	"(TA)QQ",
	"(TKA)Q",
	"(TA)(QK)",
	"(TA)QK",
	"(TKA)K",
	"(TA)KK",
	"ATTT",
	"(JA)TT",
	"A(TJ)T",
	"ATTJ",
	"(QA)TT",
	"A(TQ)T",
	"ATTQ",
	"(KA)TT",
	"A(TK)T",
	"ATTK",
	"(JA)(TJ)",
	"(JA)TJ",
	"(JQA)T",
	"(JA)(TQ)",
	"(JA)TQ",
	"(JKA)T",
	"(JA)(TK)",
	"(JA)TK",
	"A(TJ)J",
	"(QA)(TJ)",
	"A(TJQ)",
	"A(TJ)Q",
	"(KA)(TJ)",
	"A(TJK)",
	"A(TJ)K",
	"ATJJ",
	"(QA)TJ",
	"A(TQ)J",
	"AT(JQ)",
	"ATJQ",
	"(KA)TJ",
	"A(TK)J",
	"AT(JK)",
	"ATJK",
	"(QA)(TQ)",
	"(QA)TQ",
	"(QKA)T",
	"(QA)(TK)",
	"(QA)TK",
	"A(TQ)Q",
	"(KA)(TQ)",
	"A(TQK)",
	"A(TQ)K",
	"ATQQ",
	"(KA)TQ",
	"A(TK)Q",
	"AT(QK)",
	"ATQK",
	"(KA)(TK)",
	"(KA)TK",
	"A(TK)K",
	"ATKK",
	"(JA)JJ",
	"(JQA)J",
	"(JA)(JQ)",
	"(JA)JQ",
	"(JKA)J",
	"(JA)(JK)",
	"(JA)JK",
	"(JQA)Q",
	"(JQKA)",
	"(JQA)K",
	"(JA)QQ",
	"(JKA)Q",
	"(JA)(QK)",
	"(JA)QK",
	"(JKA)K",
	"(JA)KK",
	"AJJJ",
	"(QA)JJ",
	"A(JQ)J",
	"AJJQ",
	"(KA)JJ",
	"A(JK)J",
	"AJJK",
	"(QA)(JQ)",
	"(QA)JQ",
	"(QKA)J",
	"(QA)(JK)",
	"(QA)JK",
	"A(JQ)Q",
	"(KA)(JQ)",
	"A(JQK)",
	"A(JQ)K",
	"AJQQ",
	"(KA)JQ",
	"A(JK)Q",
	"AJ(QK)",
	"AJQK",
	"(KA)(JK)",
	"(KA)JK",
	"A(JK)K",
	"AJKK",
	"(QA)QQ",
	"(QKA)Q",
	"(QA)(QK)",
	"(QA)QK",
	"(QKA)K",
	"(QA)KK",
	"AQQQ",
	"(KA)QQ",
	"A(QK)Q",
	"AQQK",
	"(KA)(QK)",
	"(KA)QK",
	"A(QK)K",
	"AQKK",
	"(KA)KK",
	"AKKK",
	"2222",
	"(23)22",
	"2223",
	"(24)22",
	"2224",
	"(25)22",
	"2225",
	"(26)22",
	"2226",
	"(27)22",
	"2227",
	"(28)22",
	"2228",
	"(29)22",
	"2229",
	"(2T)22",
	"222T",
	"(2J)22",
	"222J",
	"(2Q)22",
	"222Q",
	"(2K)22",
	"222K",
	"(23)(23)",
	"(23)23",
	"(234)2",
	"(23)(24)",
	"(23)24",
	"(235)2",
	"(23)(25)",
	"(23)25",
	"(236)2",
	"(23)(26)",
	"(23)26",
	"(237)2",
	"(23)(27)",
	"(23)27",
	"(238)2",
	"(23)(28)",
	"(23)28",
	"(239)2",
	"(23)(29)",
	"(23)29",
	"(23T)2",
	"(23)(2T)",
	"(23)2T",
	"(23J)2",
	"(23)(2J)",
	"(23)2J",
	"(23Q)2",
	"(23)(2Q)",
	"(23)2Q",
	"(23K)2",
	"(23)(2K)",
	"(23)2K",
	"2233",
	"(24)23",
	"22(34)",
	"2234",
	"(25)23",
	"22(35)",
	"2235",
	"(26)23",
	"22(36)",
	"2236",
	"(27)23",
	"22(37)",
	"2237",
	"(28)23",
	"22(38)",
	"2238",
	"(29)23",
	"22(39)",
	"2239",
	"(2T)23",
	"22(3T)",
	"223T",
	"(2J)23",
	"22(3J)",
	"223J",
	"(2Q)23",
	"22(3Q)",
	"223Q",
	"(2K)23",
	"22(3K)",
	"223K",
	"(24)(24)",
	"(24)24",
	"(245)2",
	"(24)(25)",
	"(24)25",
	"(246)2",
	"(24)(26)",
	"(24)26",
	"(247)2",
	"(24)(27)",
	"(24)27",
	"(248)2",
	"(24)(28)",
	"(24)28",
	"(249)2",
	"(24)(29)",
	"(24)29",
	"(24T)2",
	"(24)(2T)",
	"(24)2T",
	"(24J)2",
	"(24)(2J)",
	"(24)2J",
	"(24Q)2",
	"(24)(2Q)",
	"(24)2Q",
	"(24K)2",
	"(24)(2K)",
	"(24)2K",
	"2244",
	"(25)24",
	"22(45)",
	"2245",
	"(26)24",
	"22(46)",
	"2246",
	"(27)24",
	"22(47)",
	"2247",
	"(28)24",
	"22(48)",
	"2248",
	"(29)24",
	"22(49)",
	"2249",
	"(2T)24",
	"22(4T)",
	"224T",
	"(2J)24",
	"22(4J)",
	"224J",
	"(2Q)24",
	"22(4Q)",
	"224Q",
	"(2K)24",
	"22(4K)",
	"224K",
	"(25)(25)",
	"(25)25",
	"(256)2",
	"(25)(26)",
	"(25)26",
	"(257)2",
	"(25)(27)",
	"(25)27",
	"(258)2",
	"(25)(28)",
	"(25)28",
	"(259)2",
	"(25)(29)",
	"(25)29",
	"(25T)2",
	"(25)(2T)",
	"(25)2T",
	"(25J)2",
	"(25)(2J)",
	"(25)2J",
	"(25Q)2",
	"(25)(2Q)",
	"(25)2Q",
	"(25K)2",
	"(25)(2K)",
	"(25)2K",
	"2255",
	"(26)25",
	"22(56)",
	"2256",
	"(27)25",
	"22(57)",
	"2257",
	"(28)25",
	"22(58)",
	"2258",
	"(29)25",
	"22(59)",
	"2259",
	"(2T)25",
	"22(5T)",
	"225T",
	"(2J)25",
	"22(5J)",
	"225J",
	"(2Q)25",
	"22(5Q)",
	"225Q",
	"(2K)25",
	"22(5K)",
	"225K",
	"(26)(26)",
	"(26)26",
	"(267)2",
	"(26)(27)",
	"(26)27",
	"(268)2",
	"(26)(28)",
	"(26)28",
	"(269)2",
	"(26)(29)",
	"(26)29",
	"(26T)2",
	"(26)(2T)",
	"(26)2T",
	"(26J)2",
	"(26)(2J)",
	"(26)2J",
	"(26Q)2",
	"(26)(2Q)",
	"(26)2Q",
	"(26K)2",
	"(26)(2K)",
	"(26)2K",
	"2266",
	"(27)26",
	"22(67)",
	"2267",
	"(28)26",
	"22(68)",
	"2268",
	"(29)26",
	"22(69)",
	"2269",
	"(2T)26",
	"22(6T)",
	"226T",
	"(2J)26",
	"22(6J)",
	"226J",
	"(2Q)26",
	"22(6Q)",
	"226Q",
	"(2K)26",
	"22(6K)",
	"226K",
	"(27)(27)",
	"(27)27",
	"(278)2",
	"(27)(28)",
	"(27)28",
	"(279)2",
	"(27)(29)",
	"(27)29",
	"(27T)2",
	"(27)(2T)",
	"(27)2T",
	"(27J)2",
	"(27)(2J)",
	"(27)2J",
	"(27Q)2",
	"(27)(2Q)",
	"(27)2Q",
	"(27K)2",
	"(27)(2K)",
	"(27)2K",
	"2277",
	"(28)27",
	"22(78)",
	"2278",
	"(29)27",
	"22(79)",
	"2279",
	"(2T)27",
	"22(7T)",
	"227T",
	"(2J)27",
	"22(7J)",
	"227J",
	"(2Q)27",
	"22(7Q)",
	"227Q",
	"(2K)27",
	"22(7K)",
	"227K",
	"(28)(28)",
	"(28)28",
	"(289)2",
	"(28)(29)",
	"(28)29",
	"(28T)2",
	"(28)(2T)",
	"(28)2T",
	"(28J)2",
	"(28)(2J)",
	"(28)2J",
	"(28Q)2",
	"(28)(2Q)",
	"(28)2Q",
	"(28K)2",
	"(28)(2K)",
	"(28)2K",
	"2288",
	"(29)28",
	"22(89)",
	"2289",
	"(2T)28",
	"22(8T)",
	"228T",
	"(2J)28",
	"22(8J)",
	"228J",
	"(2Q)28",
	"22(8Q)",
	"228Q",
	"(2K)28",
	"22(8K)",
	"228K",
	"(29)(29)",
	"(29)29",
	"(29T)2",
	"(29)(2T)",
	"(29)2T",
	"(29J)2",
	"(29)(2J)",
	"(29)2J",
	"(29Q)2",
	"(29)(2Q)",
	"(29)2Q",
	"(29K)2",
	"(29)(2K)",
	"(29)2K",
	"2299",
	"(2T)29",
	"22(9T)",
	"229T",
	"(2J)29",
	"22(9J)",
	"229J",
	"(2Q)29",
	"22(9Q)",
	"229Q",
	"(2K)29",
	"22(9K)",
	"229K",
	"(2T)(2T)",
	"(2T)2T",
	"(2TJ)2",
	"(2T)(2J)",
	"(2T)2J",
	"(2TQ)2",
	"(2T)(2Q)",
	"(2T)2Q",
	"(2TK)2",
	"(2T)(2K)",
	"(2T)2K",
	"22TT",
	"(2J)2T",
	"22(TJ)",
	"22TJ",
	"(2Q)2T",
	"22(TQ)",
	"22TQ",
	"(2K)2T",
	"22(TK)",
	"22TK",
	"(2J)(2J)",
	"(2J)2J",
	"(2JQ)2",
	"(2J)(2Q)",
	"(2J)2Q",
	"(2JK)2",
	"(2J)(2K)",
	"(2J)2K",
	"22JJ",
	"(2Q)2J",
	"22(JQ)",
	"22JQ",
	"(2K)2J",
	"22(JK)",
	"22JK",
	"(2Q)(2Q)",
	"(2Q)2Q",
	"(2QK)2",
	"(2Q)(2K)",
	"(2Q)2K",
	"22QQ",
	"(2K)2Q",
	"22(QK)",
	"22QK",
	"(2K)(2K)",
	"(2K)2K",
	"22KK",
	"(23)33",
	"(234)3",
	"(23)(34)",
	"(23)34",
	"(235)3",
	"(23)(35)",
	"(23)35",
	"(236)3",
	"(23)(36)",
	"(23)36",
	"(237)3",
	"(23)(37)",
	"(23)37",
	"(238)3",
	"(23)(38)",
	"(23)38",
	"(239)3",
	"(23)(39)",
	"(23)39",
	"(23T)3",
	"(23)(3T)",
	"(23)3T",
	"(23J)3",
	"(23)(3J)",
	"(23)3J",
	"(23Q)3",
	"(23)(3Q)",
	"(23)3Q",
	"(23K)3",
	"(23)(3K)",
	"(23)3K",
	"(234)4",
	"(2345)",
	"(234)5",
	"(2346)",
	"(234)6",
	"(2347)",
	"(234)7",
	"(2348)",
	"(234)8",
	"(2349)",
	"(234)9",
	"(234T)",
	"(234)T",
	"(234J)",
	"(234)J",
	"(234Q)",
	"(234)Q",
	"(234K)",
	"(234)K",
	"(23)44",
	"(235)4",
	"(23)(45)",
	"(23)45",
	"(236)4",
	"(23)(46)",
	"(23)46",
	"(237)4",
	"(23)(47)",
	"(23)47",
	"(238)4",
	"(23)(48)",
	"(23)48",
	"(239)4",
	"(23)(49)",
	"(23)49",
	"(23T)4",
	"(23)(4T)",
	"(23)4T",
	"(23J)4",
	"(23)(4J)",
	"(23)4J",
	"(23Q)4",
	"(23)(4Q)",
	"(23)4Q",
	"(23K)4",
	"(23)(4K)",
	"(23)4K",
	"(235)5",
	"(2356)",
	"(235)6",
	"(2357)",
	"(235)7",
	"(2358)",
	"(235)8",
	"(2359)",
	"(235)9",
	"(235T)",
	"(235)T",
	"(235J)",
	"(235)J",
	"(235Q)",
	"(235)Q",
	"(235K)",
	"(235)K",
	"(23)55",
	"(236)5",
	"(23)(56)",
	"(23)56",
	"(237)5",
	"(23)(57)",
	"(23)57",
	"(238)5",
	"(23)(58)",
	"(23)58",
	"(239)5",
	"(23)(59)",
	"(23)59",
	"(23T)5",
	"(23)(5T)",
	"(23)5T",
	"(23J)5",
	"(23)(5J)",
	"(23)5J",
	"(23Q)5",
	"(23)(5Q)",
	"(23)5Q",
	"(23K)5",
	"(23)(5K)",
	"(23)5K",
	"(236)6",
	"(2367)",
	"(236)7",
	"(2368)",
	"(236)8",
	"(2369)",
	"(236)9",
	"(236T)",
	"(236)T",
	"(236J)",
	"(236)J",
	"(236Q)",
	"(236)Q",
	"(236K)",
	"(236)K",
	"(23)66",
	"(237)6",
	"(23)(67)",
	"(23)67",
	"(238)6",
	"(23)(68)",
	"(23)68",
	"(239)6",
	"(23)(69)",
	"(23)69",
	"(23T)6",
	"(23)(6T)",
	"(23)6T",
	"(23J)6",
	"(23)(6J)",
	"(23)6J",
	"(23Q)6",
	"(23)(6Q)",
	"(23)6Q",
	"(23K)6",
	"(23)(6K)",
	"(23)6K",
	"(237)7",
	"(2378)",
	"(237)8",
	"(2379)",
	"(237)9",
	"(237T)",
	"(237)T",
	"(237J)",
	"(237)J",
	"(237Q)",
	"(237)Q",
	"(237K)",
	"(237)K",
	"(23)77",
	"(238)7",
	"(23)(78)",
	"(23)78",
	"(239)7",
	"(23)(79)",
	"(23)79",
	"(23T)7",
	"(23)(7T)",
	"(23)7T",
	"(23J)7",
	"(23)(7J)",
	"(23)7J",
	"(23Q)7",
	"(23)(7Q)",
	"(23)7Q",
	"(23K)7",
	"(23)(7K)",
	"(23)7K",
	"(238)8",
	"(2389)",
	"(238)9",
	"(238T)",
	"(238)T",
	"(238J)",
	"(238)J",
	"(238Q)",
	"(238)Q",
	"(238K)",
	"(238)K",
	"(23)88",
	"(239)8",
	"(23)(89)",
	"(23)89",
	"(23T)8",
	"(23)(8T)",
	"(23)8T",
	"(23J)8",
	"(23)(8J)",
	"(23)8J",
	"(23Q)8",
	"(23)(8Q)",
	"(23)8Q",
	"(23K)8",
	"(23)(8K)",
	"(23)8K",
	"(239)9",
	"(239T)",
	"(239)T",
	"(239J)",
	"(239)J",
	"(239Q)",
	"(239)Q",
	"(239K)",
	"(239)K",
	"(23)99",
	"(23T)9",
	"(23)(9T)",
	"(23)9T",
	"(23J)9",
	"(23)(9J)",
	"(23)9J",
	"(23Q)9",
	"(23)(9Q)",
	"(23)9Q",
	"(23K)9",
	"(23)(9K)",
	"(23)9K",
	"(23T)T",
	"(23TJ)",
	"(23T)J",
	"(23TQ)",
	"(23T)Q",
	"(23TK)",
	"(23T)K",
	"(23)TT",
	"(23J)T",
	"(23)(TJ)",
	"(23)TJ",
	"(23Q)T",
	"(23)(TQ)",
	"(23)TQ",
	"(23K)T",
	"(23)(TK)",
	"(23)TK",
	"(23J)J",
	"(23JQ)",
	"(23J)Q",
	"(23JK)",
	"(23J)K",
	"(23)JJ",
	"(23Q)J",
	"(23)(JQ)",
	"(23)JQ",
	"(23K)J",
	"(23)(JK)",
	"(23)JK",
	"(23Q)Q",
	"(23QK)",
	"(23Q)K",
	"(23)QQ",
	"(23K)Q",
	"(23)(QK)",
	"(23)QK",
	"(23K)K",
	"(23)KK",
	"2333",
	"(24)33",
	"2(34)3",
	"2334",
	"(25)33",
	"2(35)3",
	"2335",
	"(26)33",
	"2(36)3",
	"2336",
	"(27)33",
	"2(37)3",
	"2337",
	"(28)33",
	"2(38)3",
	"2338",
	"(29)33",
	"2(39)3",
	"2339",
	"(2T)33",
	"2(3T)3",
	"233T",
	"(2J)33",
	"2(3J)3",
	"233J",
	"(2Q)33",
	"2(3Q)3",
	"233Q",
	"(2K)33",
	"2(3K)3",
	"233K",
	"(24)(34)",
	"(24)34",
	"(245)3",
	"(24)(35)",
	"(24)35",
	"(246)3",
	"(24)(36)",
	"(24)36",
	"(247)3",
	"(24)(37)",
	"(24)37",
	"(248)3",
	"(24)(38)",
	"(24)38",
	"(249)3",
	"(24)(39)",
	"(24)39",
	"(24T)3",
	"(24)(3T)",
	"(24)3T",
	"(24J)3",
	"(24)(3J)",
	"(24)3J",
	"(24Q)3",
	"(24)(3Q)",
	"(24)3Q",
	"(24K)3",
	"(24)(3K)",
	"(24)3K",
	"2(34)4",
	"(25)(34)",
	"2(345)",
	"2(34)5",
	"(26)(34)",
	"2(346)",
	"2(34)6",
	"(27)(34)",
	"2(347)",
	"2(34)7",
	"(28)(34)",
	"2(348)",
	"2(34)8",
	"(29)(34)",
	"2(349)",
	"2(34)9",
	"(2T)(34)",
	"2(34T)",
	"2(34)T",
	"(2J)(34)",
	"2(34J)",
	"2(34)J",
	"(2Q)(34)",
	"2(34Q)",
	"2(34)Q",
	"(2K)(34)",
	"2(34K)",
	"2(34)K",
	"2344",
	"(25)34",
	"2(35)4",
	"23(45)",
	"2345",
	"(26)34",
	"2(36)4",
	"23(46)",
	"2346",
	"(27)34",
	"2(37)4",
	"23(47)",
	"2347",
	"(28)34",
	"2(38)4",
	"23(48)",
	"2348",
	"(29)34",
	"2(39)4",
	"23(49)",
	"2349",
	"(2T)34",
	"2(3T)4",
	"23(4T)",
	"234T",
	"(2J)34",
	"2(3J)4",
	"23(4J)",
	"234J",
	"(2Q)34",
	"2(3Q)4",
	"23(4Q)",
	"234Q",
	"(2K)34",
	"2(3K)4",
	"23(4K)",
	"234K",
	"(25)(35)",
	"(25)35",
	"(256)3",
	"(25)(36)",
	"(25)36",
	"(257)3",
	"(25)(37)",
	"(25)37",
	"(258)3",
	"(25)(38)",
	"(25)38",
	"(259)3",
	"(25)(39)",
	"(25)39",
	"(25T)3",
	"(25)(3T)",
	"(25)3T",
	"(25J)3",
	"(25)(3J)",
	"(25)3J",
	"(25Q)3",
	"(25)(3Q)",
	"(25)3Q",
	"(25K)3",
	"(25)(3K)",
	"(25)3K",
	"2(35)5",
	"(26)(35)",
	"2(356)",
	"2(35)6",
	"(27)(35)",
	"2(357)",
	"2(35)7",
	"(28)(35)",
	"2(358)",
	"2(35)8",
	"(29)(35)",
	"2(359)",
	"2(35)9",
	"(2T)(35)",
	"2(35T)",
	"2(35)T",
	"(2J)(35)",
	"2(35J)",
	"2(35)J",
	"(2Q)(35)",
	"2(35Q)",
	"2(35)Q",
	"(2K)(35)",
	"2(35K)",
	"2(35)K",
	"2355",
	"(26)35",
	"2(36)5",
	"23(56)",
	"2356",
	"(27)35",
	"2(37)5",
	"23(57)",
	"2357",
	"(28)35",
	"2(38)5",
	"23(58)",
	"2358",
	"(29)35",
	"2(39)5",
	"23(59)",
	"2359",
	"(2T)35",
	"2(3T)5",
	"23(5T)",
	"235T",
	"(2J)35",
	"2(3J)5",
	"23(5J)",
	"235J",
	"(2Q)35",
	"2(3Q)5",
	"23(5Q)",
	"235Q",
	"(2K)35",
	"2(3K)5",
	"23(5K)",
	"235K",
	"(26)(36)",
	"(26)36",
	"(267)3",
	"(26)(37)",
	"(26)37",
	"(268)3",
	"(26)(38)",
	"(26)38",
	"(269)3",
	"(26)(39)",
	"(26)39",
	"(26T)3",
	"(26)(3T)",
	"(26)3T",
	"(26J)3",
	"(26)(3J)",
	"(26)3J",
	"(26Q)3",
	"(26)(3Q)",
	"(26)3Q",
	"(26K)3",
	"(26)(3K)",
	"(26)3K",
	"2(36)6",
	"(27)(36)",
	"2(367)",
	"2(36)7",
	"(28)(36)",
	"2(368)",
	"2(36)8",
	"(29)(36)",
	"2(369)",
	"2(36)9",
	"(2T)(36)",
	"2(36T)",
	"2(36)T",
	"(2J)(36)",
	"2(36J)",
	"2(36)J",
	"(2Q)(36)",
	"2(36Q)",
	"2(36)Q",
	"(2K)(36)",
	"2(36K)",
	"2(36)K",
	"2366",
	"(27)36",
	"2(37)6",
	"23(67)",
	"2367",
	"(28)36",
	"2(38)6",
	"23(68)",
	"2368",
	"(29)36",
	"2(39)6",
	"23(69)",
	"2369",
	"(2T)36",
	"2(3T)6",
	"23(6T)",
	"236T",
	"(2J)36",
	"2(3J)6",
	"23(6J)",
	"236J",
	"(2Q)36",
	"2(3Q)6",
	"23(6Q)",
	"236Q",
	"(2K)36",
	"2(3K)6",
	"23(6K)",
	"236K",
	"(27)(37)",
	"(27)37",
	"(278)3",
	"(27)(38)",
	"(27)38",
	"(279)3",
	"(27)(39)",
	"(27)39",
	"(27T)3",
	"(27)(3T)",
	"(27)3T",
	"(27J)3",
	"(27)(3J)",
	"(27)3J",
	"(27Q)3",
	"(27)(3Q)",
	"(27)3Q",
	"(27K)3",
	"(27)(3K)",
	"(27)3K",
	"2(37)7",
	"(28)(37)",
	"2(378)",
	"2(37)8",
	"(29)(37)",
	"2(379)",
	"2(37)9",
	"(2T)(37)",
	"2(37T)",
	"2(37)T",
	"(2J)(37)",
	"2(37J)",
	"2(37)J",
	"(2Q)(37)",
	"2(37Q)",
	"2(37)Q",
	"(2K)(37)",
	"2(37K)",
	"2(37)K",
	"2377",
	"(28)37",
	"2(38)7",
	"23(78)",
	"2378",
	"(29)37",
	"2(39)7",
	"23(79)",
	"2379",
	"(2T)37",
	"2(3T)7",
	"23(7T)",
	"237T",
	"(2J)37",
	"2(3J)7",
	"23(7J)",
	"237J",
	"(2Q)37",
	"2(3Q)7",
	"23(7Q)",
	"237Q",
	"(2K)37",
	"2(3K)7",
	"23(7K)",
	"237K",
	"(28)(38)",
	"(28)38",
	"(289)3",
	"(28)(39)",
	"(28)39",
	"(28T)3",
	"(28)(3T)",
	"(28)3T",
	"(28J)3",
	"(28)(3J)",
	"(28)3J",
	"(28Q)3",
	"(28)(3Q)",
	"(28)3Q",
	"(28K)3",
	"(28)(3K)",
	"(28)3K",
	"2(38)8",
	"(29)(38)",
	"2(389)",
	"2(38)9",
	"(2T)(38)",
	"2(38T)",
	"2(38)T",
	"(2J)(38)",
	"2(38J)",
	"2(38)J",
	"(2Q)(38)",
	"2(38Q)",
	"2(38)Q",
	"(2K)(38)",
	"2(38K)",
	"2(38)K",
	"2388",
	"(29)38",
	"2(39)8",
	"23(89)",
	"2389",
	"(2T)38",
	"2(3T)8",
	"23(8T)",
	"238T",
	"(2J)38",
	"2(3J)8",
	"23(8J)",
	"238J",
	"(2Q)38",
	"2(3Q)8",
	"23(8Q)",
	"238Q",
	"(2K)38",
	"2(3K)8",
	"23(8K)",
	"238K",
	"(29)(39)",
	"(29)39",
	"(29T)3",
	"(29)(3T)",
	"(29)3T",
	"(29J)3",
	"(29)(3J)",
	"(29)3J",
	"(29Q)3",
	"(29)(3Q)",
	"(29)3Q",
	"(29K)3",
	"(29)(3K)",
	"(29)3K",
	"2(39)9",
	"(2T)(39)",
	"2(39T)",
	"2(39)T",
	"(2J)(39)",
	"2(39J)",
	"2(39)J",
	"(2Q)(39)",
	"2(39Q)",
	"2(39)Q",
	"(2K)(39)",
	"2(39K)",
	"2(39)K",
	"2399",
	"(2T)39",
	"2(3T)9",
	"23(9T)",
	"239T",
	"(2J)39",
	"2(3J)9",
	"23(9J)",
	"239J",
	"(2Q)39",
	"2(3Q)9",
	"23(9Q)",
	"239Q",
	"(2K)39",
	"2(3K)9",
	"23(9K)",
	"239K",
	"(2T)(3T)",
	"(2T)3T",
	"(2TJ)3",
	"(2T)(3J)",
	"(2T)3J",
	"(2TQ)3",
	"(2T)(3Q)",
	"(2T)3Q",
	"(2TK)3",
	"(2T)(3K)",
	"(2T)3K",
	"2(3T)T",
	"(2J)(3T)",
	"2(3TJ)",
	"2(3T)J",
	"(2Q)(3T)",
	"2(3TQ)",
	"2(3T)Q",
	"(2K)(3T)",
	"2(3TK)",
	"2(3T)K",
	"23TT",
	"(2J)3T",
	"2(3J)T",
	"23(TJ)",
	"23TJ",
	"(2Q)3T",
	"2(3Q)T",
	"23(TQ)",
	"23TQ",
	"(2K)3T",
	"2(3K)T",
	"23(TK)",
	"23TK",
	"(2J)(3J)",
	"(2J)3J",
	"(2JQ)3",
	"(2J)(3Q)",
	"(2J)3Q",
	"(2JK)3",
	"(2J)(3K)",
	"(2J)3K",
	"2(3J)J",
	"(2Q)(3J)",
	"2(3JQ)",
	"2(3J)Q",
	"(2K)(3J)",
	"2(3JK)",
	"2(3J)K",
	"23JJ",
	"(2Q)3J",
	"2(3Q)J",
	"23(JQ)",
	"23JQ",
	"(2K)3J",
	"2(3K)J",
	"23(JK)",
	"23JK",
	"(2Q)(3Q)",
	"(2Q)3Q",
	"(2QK)3",
	"(2Q)(3K)",
	"(2Q)3K",
	"2(3Q)Q",
	"(2K)(3Q)",
	"2(3QK)",
	"2(3Q)K",
	"23QQ",
	"(2K)3Q",
	"2(3K)Q",
	"23(QK)",
	"23QK",
	"(2K)(3K)",
	"(2K)3K",
	"2(3K)K",
	"23KK",
	"(24)44",
	"(245)4",
	"(24)(45)",
	"(24)45",
	"(246)4",
	"(24)(46)",
	"(24)46",
	"(247)4",
	"(24)(47)",
	"(24)47",
	"(248)4",
	"(24)(48)",
	"(24)48",
	"(249)4",
	"(24)(49)",
	"(24)49",
	"(24T)4",
	"(24)(4T)",
	"(24)4T",
	"(24J)4",
	"(24)(4J)",
	"(24)4J",
	"(24Q)4",
	"(24)(4Q)",
	"(24)4Q",
	"(24K)4",
	"(24)(4K)",
	"(24)4K",
	"(245)5",
	"(2456)",
	"(245)6",
	"(2457)",
	"(245)7",
	"(2458)",
	"(245)8",
	"(2459)",
	"(245)9",
	"(245T)",
	"(245)T",
	"(245J)",
	"(245)J",
	"(245Q)",
	"(245)Q",
	"(245K)",
	"(245)K",
	"(24)55",
	"(246)5",
	"(24)(56)",
	"(24)56",
	"(247)5",
	"(24)(57)",
	"(24)57",
	"(248)5",
	"(24)(58)",
	"(24)58",
	"(249)5",
	"(24)(59)",
	"(24)59",
	"(24T)5",
	"(24)(5T)",
	"(24)5T",
	"(24J)5",
	"(24)(5J)",
	"(24)5J",
	"(24Q)5",
	"(24)(5Q)",
	"(24)5Q",
	"(24K)5",
	"(24)(5K)",
	"(24)5K",
	"(246)6",
	"(2467)",
	"(246)7",
	"(2468)",
	"(246)8",
	"(2469)",
	"(246)9",
	"(246T)",
	"(246)T",
	"(246J)",
	"(246)J",
	"(246Q)",
	"(246)Q",
	"(246K)",
	"(246)K",
	"(24)66",
	"(247)6",
	"(24)(67)",
	"(24)67",
	"(248)6",
	"(24)(68)",
	"(24)68",
	"(249)6",
	"(24)(69)",
	"(24)69",
	"(24T)6",
	"(24)(6T)",
	"(24)6T",
	"(24J)6",
	"(24)(6J)",
	"(24)6J",
	"(24Q)6",
	"(24)(6Q)",
	"(24)6Q",
	"(24K)6",
	"(24)(6K)",
	"(24)6K",
	"(247)7",
	"(2478)",
	"(247)8",
	"(2479)",
	"(247)9",
	"(247T)",
	"(247)T",
	"(247J)",
	"(247)J",
	"(247Q)",
	"(247)Q",
	"(247K)",
	"(247)K",
	"(24)77",
	"(248)7",
	"(24)(78)",
	"(24)78",
	"(249)7",
	"(24)(79)",
	"(24)79",
	"(24T)7",
	"(24)(7T)",
	"(24)7T",
	"(24J)7",
	"(24)(7J)",
	"(24)7J",
	"(24Q)7",
	"(24)(7Q)",
	"(24)7Q",
	"(24K)7",
	"(24)(7K)",
	"(24)7K",
	"(248)8",
	"(2489)",
	"(248)9",
	"(248T)",
	"(248)T",
	"(248J)",
	"(248)J",
	"(248Q)",
	"(248)Q",
	"(248K)",
	"(248)K",
	"(24)88",
	"(249)8",
	"(24)(89)",
	"(24)89",
	"(24T)8",
	"(24)(8T)",
	"(24)8T",
	"(24J)8",
	"(24)(8J)",
	"(24)8J",
	"(24Q)8",
	"(24)(8Q)",
	"(24)8Q",
	"(24K)8",
	"(24)(8K)",
	"(24)8K",
	"(249)9",
	"(249T)",
	"(249)T",
	"(249J)",
	"(249)J",
	"(249Q)",
	"(249)Q",
	"(249K)",
	"(249)K",
	"(24)99",
	"(24T)9",
	"(24)(9T)",
	"(24)9T",
	"(24J)9",
	"(24)(9J)",
	"(24)9J",
	"(24Q)9",
	"(24)(9Q)",
	"(24)9Q",
	"(24K)9",
	"(24)(9K)",
	"(24)9K",
	"(24T)T",
	"(24TJ)",
	"(24T)J",
	"(24TQ)",
	"(24T)Q",
	"(24TK)",
	"(24T)K",
	"(24)TT",
	"(24J)T",
	"(24)(TJ)",
	"(24)TJ",
	"(24Q)T",
	"(24)(TQ)",
	"(24)TQ",
	"(24K)T",
	"(24)(TK)",
	"(24)TK",
	"(24J)J",
	"(24JQ)",
	"(24J)Q",
	"(24JK)",
	"(24J)K",
	"(24)JJ",
	"(24Q)J",
	"(24)(JQ)",
	"(24)JQ",
	"(24K)J",
	"(24)(JK)",
	"(24)JK",
	"(24Q)Q",
	"(24QK)",
	"(24Q)K",
	"(24)QQ",
	"(24K)Q",
	"(24)(QK)",
	"(24)QK",
	"(24K)K",
	"(24)KK",
	"2444",
	"(25)44",
	"2(45)4",
	"2445",
	"(26)44",
	"2(46)4",
	"2446",
	"(27)44",
	"2(47)4",
	"2447",
	"(28)44",
	"2(48)4",
	"2448",
	"(29)44",
	"2(49)4",
	"2449",
	"(2T)44",
	"2(4T)4",
	"244T",
	"(2J)44",
	"2(4J)4",
	"244J",
	"(2Q)44",
	"2(4Q)4",
	"244Q",
	"(2K)44",
	"2(4K)4",
	"244K",
	"(25)(45)",
	"(25)45",
	"(256)4",
	"(25)(46)",
	"(25)46",
	"(257)4",
	"(25)(47)",
	"(25)47",
	"(258)4",
	"(25)(48)",
	"(25)48",
	"(259)4",
	"(25)(49)",
	"(25)49",
	"(25T)4",
	"(25)(4T)",
	"(25)4T",
	"(25J)4",
	"(25)(4J)",
	"(25)4J",
	"(25Q)4",
	"(25)(4Q)",
	"(25)4Q",
	"(25K)4",
	"(25)(4K)",
	"(25)4K",
	"2(45)5",
	"(26)(45)",
	"2(456)",
	"2(45)6",
	"(27)(45)",
	"2(457)",
	"2(45)7",
	"(28)(45)",
	"2(458)",
	"2(45)8",
	"(29)(45)",
	"2(459)",
	"2(45)9",
	"(2T)(45)",
	"2(45T)",
	"2(45)T",
	"(2J)(45)",
	"2(45J)",
	"2(45)J",
	"(2Q)(45)",
	"2(45Q)",
	"2(45)Q",
	"(2K)(45)",
	"2(45K)",
	"2(45)K",
	"2455",
	"(26)45",
	"2(46)5",
	"24(56)",
	"2456",
	"(27)45",
	"2(47)5",
	"24(57)",
	"2457",
	"(28)45",
	"2(48)5",
	"24(58)",
	"2458",
	"(29)45",
	"2(49)5",
	"24(59)",
	"2459",
	"(2T)45",
	"2(4T)5",
	"24(5T)",
	"245T",
	"(2J)45",
	"2(4J)5",
	"24(5J)",
	"245J",
	"(2Q)45",
	"2(4Q)5",
	"24(5Q)",
	"245Q",
	"(2K)45",
	"2(4K)5",
	"24(5K)",
	"245K",
	"(26)(46)",
	"(26)46",
	"(267)4",
	"(26)(47)",
	"(26)47",
	"(268)4",
	"(26)(48)",
	"(26)48",
	"(269)4",
	"(26)(49)",
	"(26)49",
	"(26T)4",
	"(26)(4T)",
	"(26)4T",
	"(26J)4",
	"(26)(4J)",
	"(26)4J",
	"(26Q)4",
	"(26)(4Q)",
	"(26)4Q",
	"(26K)4",
	"(26)(4K)",
	"(26)4K",
	"2(46)6",
	"(27)(46)",
	"2(467)",
	"2(46)7",
	"(28)(46)",
	"2(468)",
	"2(46)8",
	"(29)(46)",
	"2(469)",
	"2(46)9",
	"(2T)(46)",
	"2(46T)",
	"2(46)T",
	"(2J)(46)",
	"2(46J)",
	"2(46)J",
	"(2Q)(46)",
	"2(46Q)",
	"2(46)Q",
	"(2K)(46)",
	"2(46K)",
	"2(46)K",
	"2466",
	"(27)46",
	"2(47)6",
	"24(67)",
	"2467",
	"(28)46",
	"2(48)6",
	"24(68)",
	"2468",
	"(29)46",
	"2(49)6",
	"24(69)",
	"2469",
	"(2T)46",
	"2(4T)6",
	"24(6T)",
	"246T",
	"(2J)46",
	"2(4J)6",
	"24(6J)",
	"246J",
	"(2Q)46",
	"2(4Q)6",
	"24(6Q)",
	"246Q",
	"(2K)46",
	"2(4K)6",
	"24(6K)",
	"246K",
	"(27)(47)",
	"(27)47",
	"(278)4",
	"(27)(48)",
	"(27)48",
	"(279)4",
	"(27)(49)",
	"(27)49",
	"(27T)4",
	"(27)(4T)",
	"(27)4T",
	"(27J)4",
	"(27)(4J)",
	"(27)4J",
	"(27Q)4",
	"(27)(4Q)",
	"(27)4Q",
	"(27K)4",
	"(27)(4K)",
	"(27)4K",
	"2(47)7",
	"(28)(47)",
	"2(478)",
	"2(47)8",
	"(29)(47)",
	"2(479)",
	"2(47)9",
	"(2T)(47)",
	"2(47T)",
	"2(47)T",
	"(2J)(47)",
	"2(47J)",
	"2(47)J",
	"(2Q)(47)",
	"2(47Q)",
	"2(47)Q",
	"(2K)(47)",
	"2(47K)",
	"2(47)K",
	"2477",
	"(28)47",
	"2(48)7",
	"24(78)",
	"2478",
	"(29)47",
	"2(49)7",
	"24(79)",
	"2479",
	"(2T)47",
	"2(4T)7",
	"24(7T)",
	"247T",
	"(2J)47",
	"2(4J)7",
	"24(7J)",
	"247J",
	"(2Q)47",
	"2(4Q)7",
	"24(7Q)",
	"247Q",
	"(2K)47",
	"2(4K)7",
	"24(7K)",
	"247K",
	"(28)(48)",
	"(28)48",
	"(289)4",
	"(28)(49)",
	"(28)49",
	"(28T)4",
	"(28)(4T)",
	"(28)4T",
	"(28J)4",
	"(28)(4J)",
	"(28)4J",
	"(28Q)4",
	"(28)(4Q)",
	"(28)4Q",
	"(28K)4",
	"(28)(4K)",
	"(28)4K",
	"2(48)8",
	"(29)(48)",
	"2(489)",
	"2(48)9",
	"(2T)(48)",
	"2(48T)",
	"2(48)T",
	"(2J)(48)",
	"2(48J)",
	"2(48)J",
	"(2Q)(48)",
	"2(48Q)",
	"2(48)Q",
	"(2K)(48)",
	"2(48K)",
	"2(48)K",
	"2488",
	"(29)48",
	"2(49)8",
	"24(89)",
	"2489",
	"(2T)48",
	"2(4T)8",
	"24(8T)",
	"248T",
	"(2J)48",
	"2(4J)8",
	"24(8J)",
	"248J",
	"(2Q)48",
	"2(4Q)8",
	"24(8Q)",
	"248Q",
	"(2K)48",
	"2(4K)8",
	"24(8K)",
	"248K",
	"(29)(49)",
	"(29)49",
	"(29T)4",
	"(29)(4T)",
	"(29)4T",
	"(29J)4",
	"(29)(4J)",
	"(29)4J",
	"(29Q)4",
	"(29)(4Q)",
	"(29)4Q",
	"(29K)4",
	"(29)(4K)",
	"(29)4K",
	"2(49)9",
	"(2T)(49)",
	"2(49T)",
	"2(49)T",
	"(2J)(49)",
	"2(49J)",
	"2(49)J",
	"(2Q)(49)",
	"2(49Q)",
	"2(49)Q",
	"(2K)(49)",
	"2(49K)",
	"2(49)K",
	"2499",
	"(2T)49",
	"2(4T)9",
	"24(9T)",
	"249T",
	"(2J)49",
	"2(4J)9",
	"24(9J)",
	"249J",
	"(2Q)49",
	"2(4Q)9",
	"24(9Q)",
	"249Q",
	"(2K)49",
	"2(4K)9",
	"24(9K)",
	"249K",
	"(2T)(4T)",
	"(2T)4T",
	"(2TJ)4",
	"(2T)(4J)",
	"(2T)4J",
	"(2TQ)4",
	"(2T)(4Q)",
	"(2T)4Q",
	"(2TK)4",
	"(2T)(4K)",
	"(2T)4K",
	"2(4T)T",
	"(2J)(4T)",
	"2(4TJ)",
	"2(4T)J",
	"(2Q)(4T)",
	"2(4TQ)",
	"2(4T)Q",
	"(2K)(4T)",
	"2(4TK)",
	"2(4T)K",
	"24TT",
	"(2J)4T",
	"2(4J)T",
	"24(TJ)",
	"24TJ",
	"(2Q)4T",
	"2(4Q)T",
	"24(TQ)",
	"24TQ",
	"(2K)4T",
	"2(4K)T",
	"24(TK)",
	"24TK",
	"(2J)(4J)",
	"(2J)4J",
	"(2JQ)4",
	"(2J)(4Q)",
	"(2J)4Q",
	"(2JK)4",
	"(2J)(4K)",
	"(2J)4K",
	"2(4J)J",
	"(2Q)(4J)",
	"2(4JQ)",
	"2(4J)Q",
	"(2K)(4J)",
	"2(4JK)",
	"2(4J)K",
	"24JJ",
	"(2Q)4J",
	"2(4Q)J",
	"24(JQ)",
	"24JQ",
	"(2K)4J",
	"2(4K)J",
	"24(JK)",
	"24JK",
	"(2Q)(4Q)",
	"(2Q)4Q",
	"(2QK)4",
	"(2Q)(4K)",
	"(2Q)4K",
	"2(4Q)Q",
	"(2K)(4Q)",
	"2(4QK)",
	"2(4Q)K",
	"24QQ",
	"(2K)4Q",
	"2(4K)Q",
	"24(QK)",
	"24QK",
	"(2K)(4K)",
	"(2K)4K",
	"2(4K)K",
	"24KK",
	"(25)55",
	"(256)5",
	"(25)(56)",
	"(25)56",
	"(257)5",
	"(25)(57)",
	"(25)57",
	"(258)5",
	"(25)(58)",
	"(25)58",
	"(259)5",
	"(25)(59)",
	"(25)59",
	"(25T)5",
	"(25)(5T)",
	"(25)5T",
	"(25J)5",
	"(25)(5J)",
	"(25)5J",
	"(25Q)5",
	"(25)(5Q)",
	"(25)5Q",
	"(25K)5",
	"(25)(5K)",
	"(25)5K",
	"(256)6",
	"(2567)",
	"(256)7",
	"(2568)",
	"(256)8",
	"(2569)",
	"(256)9",
	"(256T)",
	"(256)T",
	"(256J)",
	"(256)J",
	"(256Q)",
	"(256)Q",
	"(256K)",
	"(256)K",
	"(25)66",
	"(257)6",
	"(25)(67)",
	"(25)67",
	"(258)6",
	"(25)(68)",
	"(25)68",
	"(259)6",
	"(25)(69)",
	"(25)69",
	"(25T)6",
	"(25)(6T)",
	"(25)6T",
	"(25J)6",
	"(25)(6J)",
	"(25)6J",
	"(25Q)6",
	"(25)(6Q)",
	"(25)6Q",
	"(25K)6",
	"(25)(6K)",
	"(25)6K",
	"(257)7",
	"(2578)",
	"(257)8",
	"(2579)",
	"(257)9",
	"(257T)",
	"(257)T",
	"(257J)",
	"(257)J",
	"(257Q)",
	"(257)Q",
	"(257K)",
	"(257)K",
	"(25)77",
	"(258)7",
	"(25)(78)",
	"(25)78",
	"(259)7",
	"(25)(79)",
	"(25)79",
	"(25T)7",
	"(25)(7T)",
	"(25)7T",
	"(25J)7",
	"(25)(7J)",
	"(25)7J",
	"(25Q)7",
	"(25)(7Q)",
	"(25)7Q",
	"(25K)7",
	"(25)(7K)",
	"(25)7K",
	"(258)8",
	"(2589)",
	"(258)9",
	"(258T)",
	"(258)T",
	"(258J)",
	"(258)J",
	"(258Q)",
	"(258)Q",
	"(258K)",
	"(258)K",
	"(25)88",
	"(259)8",
	"(25)(89)",
	"(25)89",
	"(25T)8",
	"(25)(8T)",
	"(25)8T",
	"(25J)8",
	"(25)(8J)",
	"(25)8J",
	"(25Q)8",
	"(25)(8Q)",
	"(25)8Q",
	"(25K)8",
	"(25)(8K)",
	"(25)8K",
	"(259)9",
	"(259T)",
	"(259)T",
	"(259J)",
	"(259)J",
	"(259Q)",
	"(259)Q",
	"(259K)",
	"(259)K",
	"(25)99",
	"(25T)9",
	"(25)(9T)",
	"(25)9T",
	"(25J)9",
	"(25)(9J)",
	"(25)9J",
	"(25Q)9",
	"(25)(9Q)",
	"(25)9Q",
	"(25K)9",
	"(25)(9K)",
	"(25)9K",
	"(25T)T",
	"(25TJ)",
	"(25T)J",
	"(25TQ)",
	"(25T)Q",
	"(25TK)",
	"(25T)K",
	"(25)TT",
	"(25J)T",
	"(25)(TJ)",
	"(25)TJ",
	"(25Q)T",
	"(25)(TQ)",
	"(25)TQ",
	"(25K)T",
	"(25)(TK)",
	"(25)TK",
	"(25J)J",
	"(25JQ)",
	"(25J)Q",
	"(25JK)",
	"(25J)K",
	"(25)JJ",
	"(25Q)J",
	"(25)(JQ)",
	"(25)JQ",
	"(25K)J",
	"(25)(JK)",
	"(25)JK",
	"(25Q)Q",
	"(25QK)",
	"(25Q)K",
	"(25)QQ",
	"(25K)Q",
	"(25)(QK)",
	"(25)QK",
	"(25K)K",
	"(25)KK",
	"2555",
	"(26)55",
	"2(56)5",
	"2556",
	"(27)55",
	"2(57)5",
	"2557",
	"(28)55",
	"2(58)5",
	"2558",
	"(29)55",
	"2(59)5",
	"2559",
	"(2T)55",
	"2(5T)5",
	"255T",
	"(2J)55",
	"2(5J)5",
	"255J",
	"(2Q)55",
	"2(5Q)5",
	"255Q",
	"(2K)55",
	"2(5K)5",
	"255K",
	"(26)(56)",
	"(26)56",
	"(267)5",
	"(26)(57)",
	"(26)57",
	"(268)5",
	"(26)(58)",
	"(26)58",
	"(269)5",
	"(26)(59)",
	"(26)59",
	"(26T)5",
	"(26)(5T)",
	"(26)5T",
	"(26J)5",
	"(26)(5J)",
	"(26)5J",
	"(26Q)5",
	"(26)(5Q)",
	"(26)5Q",
	"(26K)5",
	"(26)(5K)",
	"(26)5K",
	"2(56)6",
	"(27)(56)",
	"2(567)",
	"2(56)7",
	"(28)(56)",
	"2(568)",
	"2(56)8",
	"(29)(56)",
	"2(569)",
	"2(56)9",
	"(2T)(56)",
	"2(56T)",
	"2(56)T",
	"(2J)(56)",
	"2(56J)",
	"2(56)J",
	"(2Q)(56)",
	"2(56Q)",
	"2(56)Q",
	"(2K)(56)",
	"2(56K)",
	"2(56)K",
	"2566",
	"(27)56",
	"2(57)6",
	"25(67)",
	"2567",
	"(28)56",
	"2(58)6",
	"25(68)",
	"2568",
	"(29)56",
	"2(59)6",
	"25(69)",
	"2569",
	"(2T)56",
	"2(5T)6",
	"25(6T)",
	"256T",
	"(2J)56",
	"2(5J)6",
	"25(6J)",
	"256J",
	"(2Q)56",
	"2(5Q)6",
	"25(6Q)",
	"256Q",
	"(2K)56",
	"2(5K)6",
	"25(6K)",
	"256K",
	"(27)(57)",
	"(27)57",
	"(278)5",
	"(27)(58)",
	"(27)58",
	"(279)5",
	"(27)(59)",
	"(27)59",
	"(27T)5",
	"(27)(5T)",
	"(27)5T",
	"(27J)5",
	"(27)(5J)",
	"(27)5J",
	"(27Q)5",
	"(27)(5Q)",
	"(27)5Q",
	"(27K)5",
	"(27)(5K)",
	"(27)5K",
	"2(57)7",
	"(28)(57)",
	"2(578)",
	"2(57)8",
	"(29)(57)",
	"2(579)",
	"2(57)9",
	"(2T)(57)",
	"2(57T)",
	"2(57)T",
	"(2J)(57)",
	"2(57J)",
	"2(57)J",
	"(2Q)(57)",
	"2(57Q)",
	"2(57)Q",
	"(2K)(57)",
	"2(57K)",
	"2(57)K",
	"2577",
	"(28)57",
	"2(58)7",
	"25(78)",
	"2578",
	"(29)57",
	"2(59)7",
	"25(79)",
	"2579",
	"(2T)57",
	"2(5T)7",
	"25(7T)",
	"257T",
	"(2J)57",
	"2(5J)7",
	"25(7J)",
	"257J",
	"(2Q)57",
	"2(5Q)7",
	"25(7Q)",
	"257Q",
	"(2K)57",
	"2(5K)7",
	"25(7K)",
	"257K",
	"(28)(58)",
	"(28)58",
	"(289)5",
	"(28)(59)",
	"(28)59",
	"(28T)5",
	"(28)(5T)",
	"(28)5T",
	"(28J)5",
	"(28)(5J)",
	"(28)5J",
	"(28Q)5",
	"(28)(5Q)",
	"(28)5Q",
	"(28K)5",
	"(28)(5K)",
	"(28)5K",
	"2(58)8",
	"(29)(58)",
	"2(589)",
	"2(58)9",
	"(2T)(58)",
	"2(58T)",
	"2(58)T",
	"(2J)(58)",
	"2(58J)",
	"2(58)J",
	"(2Q)(58)",
	"2(58Q)",
	"2(58)Q",
	"(2K)(58)",
	"2(58K)",
	"2(58)K",
	"2588",
	"(29)58",
	"2(59)8",
	"25(89)",
	"2589",
	"(2T)58",
	"2(5T)8",
	"25(8T)",
	"258T",
	"(2J)58",
	"2(5J)8",
	"25(8J)",
	"258J",
	"(2Q)58",
	"2(5Q)8",
	"25(8Q)",
	"258Q",
	"(2K)58",
	"2(5K)8",
	"25(8K)",
	"258K",
	"(29)(59)",
	"(29)59",
	"(29T)5",
	"(29)(5T)",
	"(29)5T",
	"(29J)5",
	"(29)(5J)",
	"(29)5J",
	"(29Q)5",
	"(29)(5Q)",
	"(29)5Q",
	"(29K)5",
	"(29)(5K)",
	"(29)5K",
	"2(59)9",
	"(2T)(59)",
	"2(59T)",
	"2(59)T",
	"(2J)(59)",
	"2(59J)",
	"2(59)J",
	"(2Q)(59)",
	"2(59Q)",
	"2(59)Q",
	"(2K)(59)",
	"2(59K)",
	"2(59)K",
	"2599",
	"(2T)59",
	"2(5T)9",
	"25(9T)",
	"259T",
	"(2J)59",
	"2(5J)9",
	"25(9J)",
	"259J",
	"(2Q)59",
	"2(5Q)9",
	"25(9Q)",
	"259Q",
	"(2K)59",
	"2(5K)9",
	"25(9K)",
	"259K",
	"(2T)(5T)",
	"(2T)5T",
	"(2TJ)5",
	"(2T)(5J)",
	"(2T)5J",
	"(2TQ)5",
	"(2T)(5Q)",
	"(2T)5Q",
	"(2TK)5",
	"(2T)(5K)",
	"(2T)5K",
	"2(5T)T",
	"(2J)(5T)",
	"2(5TJ)",
	"2(5T)J",
	"(2Q)(5T)",
	"2(5TQ)",
	"2(5T)Q",
	"(2K)(5T)",
	"2(5TK)",
	"2(5T)K",
	"25TT",
	"(2J)5T",
	"2(5J)T",
	"25(TJ)",
	"25TJ",
	"(2Q)5T",
	"2(5Q)T",
	"25(TQ)",
	"25TQ",
	"(2K)5T",
	"2(5K)T",
	"25(TK)",
	"25TK",
	"(2J)(5J)",
	"(2J)5J",
	"(2JQ)5",
	"(2J)(5Q)",
	"(2J)5Q",
	"(2JK)5",
	"(2J)(5K)",
	"(2J)5K",
	"2(5J)J",
	"(2Q)(5J)",
	"2(5JQ)",
	"2(5J)Q",
	"(2K)(5J)",
	"2(5JK)",
	"2(5J)K",
	"25JJ",
	"(2Q)5J",
	"2(5Q)J",
	"25(JQ)",
	"25JQ",
	"(2K)5J",
	"2(5K)J",
	"25(JK)",
	"25JK",
	"(2Q)(5Q)",
	"(2Q)5Q",
	"(2QK)5",
	"(2Q)(5K)",
	"(2Q)5K",
	"2(5Q)Q",
	"(2K)(5Q)",
	"2(5QK)",
	"2(5Q)K",
	"25QQ",
	"(2K)5Q",
	"2(5K)Q",
	"25(QK)",
	"25QK",
	"(2K)(5K)",
	"(2K)5K",
	"2(5K)K",
	"25KK",
	"(26)66",
	"(267)6",
	"(26)(67)",
	"(26)67",
	"(268)6",
	"(26)(68)",
	"(26)68",
	"(269)6",
	"(26)(69)",
	"(26)69",
	"(26T)6",
	"(26)(6T)",
	"(26)6T",
	"(26J)6",
	"(26)(6J)",
	"(26)6J",
	"(26Q)6",
	"(26)(6Q)",
	"(26)6Q",
	"(26K)6",
	"(26)(6K)",
	"(26)6K",
	"(267)7",
	"(2678)",
	"(267)8",
	"(2679)",
	"(267)9",
	"(267T)",
	"(267)T",
	"(267J)",
	"(267)J",
	"(267Q)",
	"(267)Q",
	"(267K)",
	"(267)K",
	"(26)77",
	"(268)7",
	"(26)(78)",
	"(26)78",
	"(269)7",
	"(26)(79)",
	"(26)79",
	"(26T)7",
	"(26)(7T)",
	"(26)7T",
	"(26J)7",
	"(26)(7J)",
	"(26)7J",
	"(26Q)7",
	"(26)(7Q)",
	"(26)7Q",
	"(26K)7",
	"(26)(7K)",
	"(26)7K",
	"(268)8",
	"(2689)",
	"(268)9",
	"(268T)",
	"(268)T",
	"(268J)",
	"(268)J",
	"(268Q)",
	"(268)Q",
	"(268K)",
	"(268)K",
	"(26)88",
	"(269)8",
	"(26)(89)",
	"(26)89",
	"(26T)8",
	"(26)(8T)",
	"(26)8T",
	"(26J)8",
	"(26)(8J)",
	"(26)8J",
	"(26Q)8",
	"(26)(8Q)",
	"(26)8Q",
	"(26K)8",
	"(26)(8K)",
	"(26)8K",
	"(269)9",
	"(269T)",
	"(269)T",
	"(269J)",
	"(269)J",
	"(269Q)",
	"(269)Q",
	"(269K)",
	"(269)K",
	"(26)99",
	"(26T)9",
	"(26)(9T)",
	"(26)9T",
	"(26J)9",
	"(26)(9J)",
	"(26)9J",
	"(26Q)9",
	"(26)(9Q)",
	"(26)9Q",
	"(26K)9",
	"(26)(9K)",
	"(26)9K",
	"(26T)T",
	"(26TJ)",
	"(26T)J",
	"(26TQ)",
	"(26T)Q",
	"(26TK)",
	"(26T)K",
	"(26)TT",
	"(26J)T",
	"(26)(TJ)",
	"(26)TJ",
	"(26Q)T",
	"(26)(TQ)",
	"(26)TQ",
	"(26K)T",
	"(26)(TK)",
	"(26)TK",
	"(26J)J",
	"(26JQ)",
	"(26J)Q",
	"(26JK)",
	"(26J)K",
	"(26)JJ",
	"(26Q)J",
	"(26)(JQ)",
	"(26)JQ",
	"(26K)J",
	"(26)(JK)",
	"(26)JK",
	"(26Q)Q",
	"(26QK)",
	"(26Q)K",
	"(26)QQ",
	"(26K)Q",
	"(26)(QK)",
	"(26)QK",
	"(26K)K",
	"(26)KK",
	"2666",
	"(27)66",
	"2(67)6",
	"2667",
	"(28)66",
	"2(68)6",
	"2668",
	"(29)66",
	"2(69)6",
	"2669",
	"(2T)66",
	"2(6T)6",
	"266T",
	"(2J)66",
	"2(6J)6",
	"266J",
	"(2Q)66",
	"2(6Q)6",
	"266Q",
	"(2K)66",
	"2(6K)6",
	"266K",
	"(27)(67)",
	"(27)67",
	"(278)6",
	"(27)(68)",
	"(27)68",
	"(279)6",
	"(27)(69)",
	"(27)69",
	"(27T)6",
	"(27)(6T)",
	"(27)6T",
	"(27J)6",
	"(27)(6J)",
	"(27)6J",
	"(27Q)6",
	"(27)(6Q)",
	"(27)6Q",
	"(27K)6",
	"(27)(6K)",
	"(27)6K",
	"2(67)7",
	"(28)(67)",
	"2(678)",
	"2(67)8",
	"(29)(67)",
	"2(679)",
	"2(67)9",
	"(2T)(67)",
	"2(67T)",
	"2(67)T",
	"(2J)(67)",
	"2(67J)",
	"2(67)J",
	"(2Q)(67)",
	"2(67Q)",
	"2(67)Q",
	"(2K)(67)",
	"2(67K)",
	"2(67)K",
	"2677",
	"(28)67",
	"2(68)7",
	"26(78)",
	"2678",
	"(29)67",
	"2(69)7",
	"26(79)",
	"2679",
	"(2T)67",
	"2(6T)7",
	"26(7T)",
	"267T",
	"(2J)67",
	"2(6J)7",
	"26(7J)",
	"267J",
	"(2Q)67",
	"2(6Q)7",
	"26(7Q)",
	"267Q",
	"(2K)67",
	"2(6K)7",
	"26(7K)",
	"267K",
	"(28)(68)",
	"(28)68",
	"(289)6",
	"(28)(69)",
	"(28)69",
	"(28T)6",
	"(28)(6T)",
	"(28)6T",
	"(28J)6",
	"(28)(6J)",
	"(28)6J",
	"(28Q)6",
	"(28)(6Q)",
	"(28)6Q",
	"(28K)6",
	"(28)(6K)",
	"(28)6K",
	"2(68)8",
	"(29)(68)",
	"2(689)",
	"2(68)9",
	"(2T)(68)",
	"2(68T)",
	"2(68)T",
	"(2J)(68)",
	"2(68J)",
	"2(68)J",
	"(2Q)(68)",
	"2(68Q)",
	"2(68)Q",
	"(2K)(68)",
	"2(68K)",
	"2(68)K",
	"2688",
	"(29)68",
	"2(69)8",
	"26(89)",
	"2689",
	"(2T)68",
	"2(6T)8",
	"26(8T)",
	"268T",
	"(2J)68",
	"2(6J)8",
	"26(8J)",
	"268J",
	"(2Q)68",
	"2(6Q)8",
	"26(8Q)",
	"268Q",
	"(2K)68",
	"2(6K)8",
	"26(8K)",
	"268K",
	"(29)(69)",
	"(29)69",
	"(29T)6",
	"(29)(6T)",
	"(29)6T",
	"(29J)6",
	"(29)(6J)",
	"(29)6J",
	"(29Q)6",
	"(29)(6Q)",
	"(29)6Q",
	"(29K)6",
	"(29)(6K)",
	"(29)6K",
	"2(69)9",
	"(2T)(69)",
	"2(69T)",
	"2(69)T",
	"(2J)(69)",
	"2(69J)",
	"2(69)J",
	"(2Q)(69)",
	"2(69Q)",
	"2(69)Q",
	"(2K)(69)",
	"2(69K)",
	"2(69)K",
	"2699",
	"(2T)69",
	"2(6T)9",
	"26(9T)",
	"269T",
	"(2J)69",
	"2(6J)9",
	"26(9J)",
	"269J",
	"(2Q)69",
	"2(6Q)9",
	"26(9Q)",
	"269Q",
	"(2K)69",
	"2(6K)9",
	"26(9K)",
	"269K",
	"(2T)(6T)",
	"(2T)6T",
	"(2TJ)6",
	"(2T)(6J)",
	"(2T)6J",
	"(2TQ)6",
	"(2T)(6Q)",
	"(2T)6Q",
	"(2TK)6",
	"(2T)(6K)",
	"(2T)6K",
	"2(6T)T",
	"(2J)(6T)",
	"2(6TJ)",
	"2(6T)J",
	"(2Q)(6T)",
	"2(6TQ)",
	"2(6T)Q",
	"(2K)(6T)",
	"2(6TK)",
	"2(6T)K",
	"26TT",
	"(2J)6T",
	"2(6J)T",
	"26(TJ)",
	"26TJ",
	"(2Q)6T",
	"2(6Q)T",
	"26(TQ)",
	"26TQ",
	"(2K)6T",
	"2(6K)T",
	"26(TK)",
	"26TK",
	"(2J)(6J)",
	"(2J)6J",
	"(2JQ)6",
	"(2J)(6Q)",
	"(2J)6Q",
	"(2JK)6",
	"(2J)(6K)",
	"(2J)6K",
	"2(6J)J",
	"(2Q)(6J)",
	"2(6JQ)",
	"2(6J)Q",
	"(2K)(6J)",
	"2(6JK)",
	"2(6J)K",
	"26JJ",
	"(2Q)6J",
	"2(6Q)J",
	"26(JQ)",
	"26JQ",
	"(2K)6J",
	"2(6K)J",
	"26(JK)",
	"26JK",
	"(2Q)(6Q)",
	"(2Q)6Q",
	"(2QK)6",
	"(2Q)(6K)",
	"(2Q)6K",
	"2(6Q)Q",
	"(2K)(6Q)",
	"2(6QK)",
	"2(6Q)K",
	"26QQ",
	"(2K)6Q",
	"2(6K)Q",
	"26(QK)",
	"26QK",
	"(2K)(6K)",
	"(2K)6K",
	"2(6K)K",
	"26KK",
	"(27)77",
	"(278)7",
	"(27)(78)",
	"(27)78",
	"(279)7",
	"(27)(79)",
	"(27)79",
	"(27T)7",
	"(27)(7T)",
	"(27)7T",
	"(27J)7",
	"(27)(7J)",
	"(27)7J",
	"(27Q)7",
	"(27)(7Q)",
	"(27)7Q",
	"(27K)7",
	"(27)(7K)",
	"(27)7K",
	"(278)8",
	"(2789)",
	"(278)9",
	"(278T)",
	"(278)T",
	"(278J)",
	"(278)J",
	"(278Q)",
	"(278)Q",
	"(278K)",
	"(278)K",
	"(27)88",
	"(279)8",
	"(27)(89)",
	"(27)89",
	"(27T)8",
	"(27)(8T)",
	"(27)8T",
	"(27J)8",
	"(27)(8J)",
	"(27)8J",
	"(27Q)8",
	"(27)(8Q)",
	"(27)8Q",
	"(27K)8",
	"(27)(8K)",
	"(27)8K",
	"(279)9",
	"(279T)",
	"(279)T",
	"(279J)",
	"(279)J",
	"(279Q)",
	"(279)Q",
	"(279K)",
	"(279)K",
	"(27)99",
	"(27T)9",
	"(27)(9T)",
	"(27)9T",
	"(27J)9",
	"(27)(9J)",
	"(27)9J",
	"(27Q)9",
	"(27)(9Q)",
	"(27)9Q",
	"(27K)9",
	"(27)(9K)",
	"(27)9K",
	"(27T)T",
	"(27TJ)",
	"(27T)J",
	"(27TQ)",
	"(27T)Q",
	"(27TK)",
	"(27T)K",
	"(27)TT",
	"(27J)T",
	"(27)(TJ)",
	"(27)TJ",
	"(27Q)T",
	"(27)(TQ)",
	"(27)TQ",
	"(27K)T",
	"(27)(TK)",
	"(27)TK",
	"(27J)J",
	"(27JQ)",
	"(27J)Q",
	"(27JK)",
	"(27J)K",
	"(27)JJ",
	"(27Q)J",
	"(27)(JQ)",
	"(27)JQ",
	"(27K)J",
	"(27)(JK)",
	"(27)JK",
	"(27Q)Q",
	"(27QK)",
	"(27Q)K",
	"(27)QQ",
	"(27K)Q",
	"(27)(QK)",
	"(27)QK",
	"(27K)K",
	"(27)KK",
	"2777",
	"(28)77",
	"2(78)7",
	"2778",
	"(29)77",
	"2(79)7",
	"2779",
	"(2T)77",
	"2(7T)7",
	"277T",
	"(2J)77",
	"2(7J)7",
	"277J",
	"(2Q)77",
	"2(7Q)7",
	"277Q",
	"(2K)77",
	"2(7K)7",
	"277K",
	"(28)(78)",
	"(28)78",
	"(289)7",
	"(28)(79)",
	"(28)79",
	"(28T)7",
	"(28)(7T)",
	"(28)7T",
	"(28J)7",
	"(28)(7J)",
	"(28)7J",
	"(28Q)7",
	"(28)(7Q)",
	"(28)7Q",
	"(28K)7",
	"(28)(7K)",
	"(28)7K",
	"2(78)8",
	"(29)(78)",
	"2(789)",
	"2(78)9",
	"(2T)(78)",
	"2(78T)",
	"2(78)T",
	"(2J)(78)",
	"2(78J)",
	"2(78)J",
	"(2Q)(78)",
	"2(78Q)",
	"2(78)Q",
	"(2K)(78)",
	"2(78K)",
	"2(78)K",
	"2788",
	"(29)78",
	"2(79)8",
	"27(89)",
	"2789",
	"(2T)78",
	"2(7T)8",
	"27(8T)",
	"278T",
	"(2J)78",
	"2(7J)8",
	"27(8J)",
	"278J",
	"(2Q)78",
	"2(7Q)8",
	"27(8Q)",
	"278Q",
	"(2K)78",
	"2(7K)8",
	"27(8K)",
	"278K",
	"(29)(79)",
	"(29)79",
	"(29T)7",
	"(29)(7T)",
	"(29)7T",
	"(29J)7",
	"(29)(7J)",
	"(29)7J",
	"(29Q)7",
	"(29)(7Q)",
	"(29)7Q",
	"(29K)7",
	"(29)(7K)",
	"(29)7K",
	"2(79)9",
	"(2T)(79)",
	"2(79T)",
	"2(79)T",
	"(2J)(79)",
	"2(79J)",
	"2(79)J",
	"(2Q)(79)",
	"2(79Q)",
	"2(79)Q",
	"(2K)(79)",
	"2(79K)",
	"2(79)K",
	"2799",
	"(2T)79",
	"2(7T)9",
	"27(9T)",
	"279T",
	"(2J)79",
	"2(7J)9",
	"27(9J)",
	"279J",
	"(2Q)79",
	"2(7Q)9",
	"27(9Q)",
	"279Q",
	"(2K)79",
	"2(7K)9",
	"27(9K)",
	"279K",
	"(2T)(7T)",
	"(2T)7T",
	"(2TJ)7",
	"(2T)(7J)",
	"(2T)7J",
	"(2TQ)7",
	"(2T)(7Q)",
	"(2T)7Q",
	"(2TK)7",
	"(2T)(7K)",
	"(2T)7K",
	"2(7T)T",
	"(2J)(7T)",
	"2(7TJ)",
	"2(7T)J",
	"(2Q)(7T)",
	"2(7TQ)",
	"2(7T)Q",
	"(2K)(7T)",
	"2(7TK)",
	"2(7T)K",
	"27TT",
	"(2J)7T",
	"2(7J)T",
	"27(TJ)",
	"27TJ",
	"(2Q)7T",
	"2(7Q)T",
	"27(TQ)",
	"27TQ",
	"(2K)7T",
	"2(7K)T",
	"27(TK)",
	"27TK",
	"(2J)(7J)",
	"(2J)7J",
	"(2JQ)7",
	"(2J)(7Q)",
	"(2J)7Q",
	"(2JK)7",
	"(2J)(7K)",
	"(2J)7K",
	"2(7J)J",
	"(2Q)(7J)",
	"2(7JQ)",
	"2(7J)Q",
	"(2K)(7J)",
	"2(7JK)",
	"2(7J)K",
	"27JJ",
	"(2Q)7J",
	"2(7Q)J",
	"27(JQ)",
	"27JQ",
	"(2K)7J",
	"2(7K)J",
	"27(JK)",
	"27JK",
	"(2Q)(7Q)",
	"(2Q)7Q",
	"(2QK)7",
	"(2Q)(7K)",
	"(2Q)7K",
	"2(7Q)Q",
	"(2K)(7Q)",
	"2(7QK)",
	"2(7Q)K",
	"27QQ",
	"(2K)7Q",
	"2(7K)Q",
	"27(QK)",
	"27QK",
	"(2K)(7K)",
	"(2K)7K",
	"2(7K)K",
	"27KK",
	"(28)88",
	"(289)8",
	"(28)(89)",
	"(28)89",
	"(28T)8",
	"(28)(8T)",
	"(28)8T",
	"(28J)8",
	"(28)(8J)",
	"(28)8J",
	"(28Q)8",
	"(28)(8Q)",
	"(28)8Q",
	"(28K)8",
	"(28)(8K)",
	"(28)8K",
	"(289)9",
	"(289T)",
	"(289)T",
	"(289J)",
	"(289)J",
	"(289Q)",
	"(289)Q",
	"(289K)",
	"(289)K",
	"(28)99",
	"(28T)9",
	"(28)(9T)",
	"(28)9T",
	"(28J)9",
	"(28)(9J)",
	"(28)9J",
	"(28Q)9",
	"(28)(9Q)",
	"(28)9Q",
	"(28K)9",
	"(28)(9K)",
	"(28)9K",
	"(28T)T",
	"(28TJ)",
	"(28T)J",
	"(28TQ)",
	"(28T)Q",
	"(28TK)",
	"(28T)K",
	"(28)TT",
	"(28J)T",
	"(28)(TJ)",
	"(28)TJ",
	"(28Q)T",
	"(28)(TQ)",
	"(28)TQ",
	"(28K)T",
	"(28)(TK)",
	"(28)TK",
	"(28J)J",
	"(28JQ)",
	"(28J)Q",
	"(28JK)",
	"(28J)K",
	"(28)JJ",
	"(28Q)J",
	"(28)(JQ)",
	"(28)JQ",
	"(28K)J",
	"(28)(JK)",
	"(28)JK",
	"(28Q)Q",
	"(28QK)",
	"(28Q)K",
	"(28)QQ",
	"(28K)Q",
	"(28)(QK)",
	"(28)QK",
	"(28K)K",
	"(28)KK",
	"2888",
	"(29)88",
	"2(89)8",
	"2889",
	"(2T)88",
	"2(8T)8",
	"288T",
	"(2J)88",
	"2(8J)8",
	"288J",
	"(2Q)88",
	"2(8Q)8",
	"288Q",
	"(2K)88",
	"2(8K)8",
	"288K",
	"(29)(89)",
	"(29)89",
	"(29T)8",
	"(29)(8T)",
	"(29)8T",
	"(29J)8",
	"(29)(8J)",
	"(29)8J",
	"(29Q)8",
	"(29)(8Q)",
	"(29)8Q",
	"(29K)8",
	"(29)(8K)",
	"(29)8K",
	"2(89)9",
	"(2T)(89)",
	"2(89T)",
	"2(89)T",
	"(2J)(89)",
	"2(89J)",
	"2(89)J",
	"(2Q)(89)",
	"2(89Q)",
	"2(89)Q",
	"(2K)(89)",
	"2(89K)",
	"2(89)K",
	"2899",
	"(2T)89",
	"2(8T)9",
	"28(9T)",
	"289T",
	"(2J)89",
	"2(8J)9",
	"28(9J)",
	"289J",
	"(2Q)89",
	"2(8Q)9",
	"28(9Q)",
	"289Q",
	"(2K)89",
	"2(8K)9",
	"28(9K)",
	"289K",
	"(2T)(8T)",
	"(2T)8T",
	"(2TJ)8",
	"(2T)(8J)",
	"(2T)8J",
	"(2TQ)8",
	"(2T)(8Q)",
	"(2T)8Q",
	"(2TK)8",
	"(2T)(8K)",
	"(2T)8K",
	"2(8T)T",
	"(2J)(8T)",
	"2(8TJ)",
	"2(8T)J",
	"(2Q)(8T)",
	"2(8TQ)",
	"2(8T)Q",
	"(2K)(8T)",
	"2(8TK)",
	"2(8T)K",
	"28TT",
	"(2J)8T",
	"2(8J)T",
	"28(TJ)",
	"28TJ",
	"(2Q)8T",
	"2(8Q)T",
	"28(TQ)",
	"28TQ",
	"(2K)8T",
	"2(8K)T",
	"28(TK)",
	"28TK",
	"(2J)(8J)",
	"(2J)8J",
	"(2JQ)8",
	"(2J)(8Q)",
	"(2J)8Q",
	"(2JK)8",
	"(2J)(8K)",
	"(2J)8K",
	"2(8J)J",
	"(2Q)(8J)",
	"2(8JQ)",
	"2(8J)Q",
	"(2K)(8J)",
	"2(8JK)",
	"2(8J)K",
	"28JJ",
	"(2Q)8J",
	"2(8Q)J",
	"28(JQ)",
	"28JQ",
	"(2K)8J",
	"2(8K)J",
	"28(JK)",
	"28JK",
	"(2Q)(8Q)",
	"(2Q)8Q",
	"(2QK)8",
	"(2Q)(8K)",
	"(2Q)8K",
	"2(8Q)Q",
	"(2K)(8Q)",
	"2(8QK)",
	"2(8Q)K",
	"28QQ",
	"(2K)8Q",
	"2(8K)Q",
	"28(QK)",
	"28QK",
	"(2K)(8K)",
	"(2K)8K",
	"2(8K)K",
	"28KK",
	"(29)99",
	"(29T)9",
	"(29)(9T)",
	"(29)9T",
	"(29J)9",
	"(29)(9J)",
	"(29)9J",
	"(29Q)9",
	"(29)(9Q)",
	"(29)9Q",
	"(29K)9",
	"(29)(9K)",
	"(29)9K",
	"(29T)T",
	"(29TJ)",
	"(29T)J",
	"(29TQ)",
	"(29T)Q",
	"(29TK)",
	"(29T)K",
	"(29)TT",
	"(29J)T",
	"(29)(TJ)",
	"(29)TJ",
	"(29Q)T",
	"(29)(TQ)",
	"(29)TQ",
	"(29K)T",
	"(29)(TK)",
	"(29)TK",
	"(29J)J",
	"(29JQ)",
	"(29J)Q",
	"(29JK)",
	"(29J)K",
	"(29)JJ",
	"(29Q)J",
	"(29)(JQ)",
	"(29)JQ",
	"(29K)J",
	"(29)(JK)",
	"(29)JK",
	"(29Q)Q",
	"(29QK)",
	"(29Q)K",
	"(29)QQ",
	"(29K)Q",
	"(29)(QK)",
	"(29)QK",
	"(29K)K",
	"(29)KK",
	"2999",
	"(2T)99",
	"2(9T)9",
	"299T",
	"(2J)99",
	"2(9J)9",
	"299J",
	"(2Q)99",
	"2(9Q)9",
	"299Q",
	"(2K)99",
	"2(9K)9",
	"299K",
	"(2T)(9T)",
	"(2T)9T",
	"(2TJ)9",
	"(2T)(9J)",
	"(2T)9J",
	"(2TQ)9",
	"(2T)(9Q)",
	"(2T)9Q",
	"(2TK)9",
	"(2T)(9K)",
	"(2T)9K",
	"2(9T)T",
	"(2J)(9T)",
	"2(9TJ)",
	"2(9T)J",
	"(2Q)(9T)",
	"2(9TQ)",
	"2(9T)Q",
	"(2K)(9T)",
	"2(9TK)",
	"2(9T)K",
	"29TT",
	"(2J)9T",
	"2(9J)T",
	"29(TJ)",
	"29TJ",
	"(2Q)9T",
	"2(9Q)T",
	"29(TQ)",
	"29TQ",
	"(2K)9T",
	"2(9K)T",
	"29(TK)",
	"29TK",
	"(2J)(9J)",
	"(2J)9J",
	"(2JQ)9",
	"(2J)(9Q)",
	"(2J)9Q",
	"(2JK)9",
	"(2J)(9K)",
	"(2J)9K",
	"2(9J)J",
	"(2Q)(9J)",
	"2(9JQ)",
	"2(9J)Q",
	"(2K)(9J)",
	"2(9JK)",
	"2(9J)K",
	"29JJ",
	"(2Q)9J",
	"2(9Q)J",
	"29(JQ)",
	"29JQ",
	"(2K)9J",
	"2(9K)J",
	"29(JK)",
	"29JK",
	"(2Q)(9Q)",
	"(2Q)9Q",
	"(2QK)9",
	"(2Q)(9K)",
	"(2Q)9K",
	"2(9Q)Q",
	"(2K)(9Q)",
	"2(9QK)",
	"2(9Q)K",
	"29QQ",
	"(2K)9Q",
	"2(9K)Q",
	"29(QK)",
	"29QK",
	"(2K)(9K)",
	"(2K)9K",
	"2(9K)K",
	"29KK",
	"(2T)TT",
	"(2TJ)T",
	"(2T)(TJ)",
	"(2T)TJ",
	"(2TQ)T",
	"(2T)(TQ)",
	"(2T)TQ",
	"(2TK)T",
	"(2T)(TK)",
	"(2T)TK",
	"(2TJ)J",
	"(2TJQ)",
	"(2TJ)Q",
	"(2TJK)",
	"(2TJ)K",
	"(2T)JJ",
	"(2TQ)J",
	"(2T)(JQ)",
	"(2T)JQ",
	"(2TK)J",
	"(2T)(JK)",
	"(2T)JK",
	"(2TQ)Q",
	"(2TQK)",
	"(2TQ)K",
	"(2T)QQ",
	"(2TK)Q",
	"(2T)(QK)",
	"(2T)QK",
	"(2TK)K",
	"(2T)KK",
	"2TTT",
	"(2J)TT",
	"2(TJ)T",
	"2TTJ",
	"(2Q)TT",
	"2(TQ)T",
	"2TTQ",
	"(2K)TT",
	"2(TK)T",
	"2TTK",
	"(2J)(TJ)",
	"(2J)TJ",
	"(2JQ)T",
	"(2J)(TQ)",
	"(2J)TQ",
	"(2JK)T",
	"(2J)(TK)",
	"(2J)TK",
	"2(TJ)J",
	"(2Q)(TJ)",
	"2(TJQ)",
	"2(TJ)Q",
	"(2K)(TJ)",
	"2(TJK)",
	"2(TJ)K",
	"2TJJ",
	"(2Q)TJ",
	"2(TQ)J",
	"2T(JQ)",
	"2TJQ",
	"(2K)TJ",
	"2(TK)J",
	"2T(JK)",
	"2TJK",
	"(2Q)(TQ)",
	"(2Q)TQ",
	"(2QK)T",
	"(2Q)(TK)",
	"(2Q)TK",
	"2(TQ)Q",
	"(2K)(TQ)",
	"2(TQK)",
	"2(TQ)K",
	"2TQQ",
	"(2K)TQ",
	"2(TK)Q",
	"2T(QK)",
	"2TQK",
	"(2K)(TK)",
	"(2K)TK",
	"2(TK)K",
	"2TKK",
	"(2J)JJ",
	"(2JQ)J",
	"(2J)(JQ)",
	"(2J)JQ",
	"(2JK)J",
	"(2J)(JK)",
	"(2J)JK",
	"(2JQ)Q",
	"(2JQK)",
	"(2JQ)K",
	"(2J)QQ",
	"(2JK)Q",
	"(2J)(QK)",
	"(2J)QK",
	"(2JK)K",
	"(2J)KK",
	"2JJJ",
	"(2Q)JJ",
	"2(JQ)J",
	"2JJQ",
	"(2K)JJ",
	"2(JK)J",
	"2JJK",
	"(2Q)(JQ)",
	"(2Q)JQ",
	"(2QK)J",
	"(2Q)(JK)",
	"(2Q)JK",
	"2(JQ)Q",
	"(2K)(JQ)",
	"2(JQK)",
	"2(JQ)K",
	"2JQQ",
	"(2K)JQ",
	"2(JK)Q",
	"2J(QK)",
	"2JQK",
	"(2K)(JK)",
	"(2K)JK",
	"2(JK)K",
	"2JKK",
	"(2Q)QQ",
	"(2QK)Q",
	"(2Q)(QK)",
	"(2Q)QK",
	"(2QK)K",
	"(2Q)KK",
	"2QQQ",
	"(2K)QQ",
	"2(QK)Q",
	"2QQK",
	"(2K)(QK)",
	"(2K)QK",
	"2(QK)K",
	"2QKK",
	"(2K)KK",
	"2KKK",
	"3333",
	"(34)33",
	"3334",
	"(35)33",
	"3335",
	"(36)33",
	"3336",
	"(37)33",
	"3337",
	"(38)33",
	"3338",
	"(39)33",
	"3339",
	"(3T)33",
	"333T",
	"(3J)33",
	"333J",
	"(3Q)33",
	"333Q",
	"(3K)33",
	"333K",
	"(34)(34)",
	"(34)34",
	"(345)3",
	"(34)(35)",
	"(34)35",
	"(346)3",
	"(34)(36)",
	"(34)36",
	"(347)3",
	"(34)(37)",
	"(34)37",
	"(348)3",
	"(34)(38)",
	"(34)38",
	"(349)3",
	"(34)(39)",
	"(34)39",
	"(34T)3",
	"(34)(3T)",
	"(34)3T",
	"(34J)3",
	"(34)(3J)",
	"(34)3J",
	"(34Q)3",
	"(34)(3Q)",
	"(34)3Q",
	"(34K)3",
	"(34)(3K)",
	"(34)3K",
	"3344",
	"(35)34",
	"33(45)",
	"3345",
	"(36)34",
	"33(46)",
	"3346",
	"(37)34",
	"33(47)",
	"3347",
	"(38)34",
	"33(48)",
	"3348",
	"(39)34",
	"33(49)",
	"3349",
	"(3T)34",
	"33(4T)",
	"334T",
	"(3J)34",
	"33(4J)",
	"334J",
	"(3Q)34",
	"33(4Q)",
	"334Q",
	"(3K)34",
	"33(4K)",
	"334K",
	"(35)(35)",
	"(35)35",
	"(356)3",
	"(35)(36)",
	"(35)36",
	"(357)3",
	"(35)(37)",
	"(35)37",
	"(358)3",
	"(35)(38)",
	"(35)38",
	"(359)3",
	"(35)(39)",
	"(35)39",
	"(35T)3",
	"(35)(3T)",
	"(35)3T",
	"(35J)3",
	"(35)(3J)",
	"(35)3J",
	"(35Q)3",
	"(35)(3Q)",
	"(35)3Q",
	"(35K)3",
	"(35)(3K)",
	"(35)3K",
	"3355",
	"(36)35",
	"33(56)",
	"3356",
	"(37)35",
	"33(57)",
	"3357",
	"(38)35",
	"33(58)",
	"3358",
	"(39)35",
	"33(59)",
	"3359",
	"(3T)35",
	"33(5T)",
	"335T",
	"(3J)35",
	"33(5J)",
	"335J",
	"(3Q)35",
	"33(5Q)",
	"335Q",
	"(3K)35",
	"33(5K)",
	"335K",
	"(36)(36)",
	"(36)36",
	"(367)3",
	"(36)(37)",
	"(36)37",
	"(368)3",
	"(36)(38)",
	"(36)38",
	"(369)3",
	"(36)(39)",
	"(36)39",
	"(36T)3",
	"(36)(3T)",
	"(36)3T",
	"(36J)3",
	"(36)(3J)",
	"(36)3J",
	"(36Q)3",
	"(36)(3Q)",
	"(36)3Q",
	"(36K)3",
	"(36)(3K)",
	"(36)3K",
	"3366",
	"(37)36",
	"33(67)",
	"3367",
	"(38)36",
	"33(68)",
	"3368",
	"(39)36",
	"33(69)",
	"3369",
	"(3T)36",
	"33(6T)",
	"336T",
	"(3J)36",
	"33(6J)",
	"336J",
	"(3Q)36",
	"33(6Q)",
	"336Q",
	"(3K)36",
	"33(6K)",
	"336K",
	"(37)(37)",
	"(37)37",
	"(378)3",
	"(37)(38)",
	"(37)38",
	"(379)3",
	"(37)(39)",
	"(37)39",
	"(37T)3",
	"(37)(3T)",
	"(37)3T",
	"(37J)3",
	"(37)(3J)",
	"(37)3J",
	"(37Q)3",
	"(37)(3Q)",
	"(37)3Q",
	"(37K)3",
	"(37)(3K)",
	"(37)3K",
	"3377",
	"(38)37",
	"33(78)",
	"3378",
	"(39)37",
	"33(79)",
	"3379",
	"(3T)37",
	"33(7T)",
	"337T",
	"(3J)37",
	"33(7J)",
	"337J",
	"(3Q)37",
	"33(7Q)",
	"337Q",
	"(3K)37",
	"33(7K)",
	"337K",
	"(38)(38)",
	"(38)38",
	"(389)3",
	"(38)(39)",
	"(38)39",
	"(38T)3",
	"(38)(3T)",
	"(38)3T",
	"(38J)3",
	"(38)(3J)",
	"(38)3J",
	"(38Q)3",
	"(38)(3Q)",
	"(38)3Q",
	"(38K)3",
	"(38)(3K)",
	"(38)3K",
	"3388",
	"(39)38",
	"33(89)",
	"3389",
	"(3T)38",
	"33(8T)",
	"338T",
	"(3J)38",
	"33(8J)",
	"338J",
	"(3Q)38",
	"33(8Q)",
	"338Q",
	"(3K)38",
	"33(8K)",
	"338K",
	"(39)(39)",
	"(39)39",
	"(39T)3",
	"(39)(3T)",
	"(39)3T",
	"(39J)3",
	"(39)(3J)",
	"(39)3J",
	"(39Q)3",
	"(39)(3Q)",
	"(39)3Q",
	"(39K)3",
	"(39)(3K)",
	"(39)3K",
	"3399",
	"(3T)39",
	"33(9T)",
	"339T",
	"(3J)39",
	"33(9J)",
	"339J",
	"(3Q)39",
	"33(9Q)",
	"339Q",
	"(3K)39",
	"33(9K)",
	"339K",
	"(3T)(3T)",
	"(3T)3T",
	"(3TJ)3",
	"(3T)(3J)",
	"(3T)3J",
	"(3TQ)3",
	"(3T)(3Q)",
	"(3T)3Q",
	"(3TK)3",
	"(3T)(3K)",
	"(3T)3K",
	"33TT",
	"(3J)3T",
	"33(TJ)",
	"33TJ",
	"(3Q)3T",
	"33(TQ)",
	"33TQ",
	"(3K)3T",
	"33(TK)",
	"33TK",
	"(3J)(3J)",
	"(3J)3J",
	"(3JQ)3",
	"(3J)(3Q)",
	"(3J)3Q",
	"(3JK)3",
	"(3J)(3K)",
	"(3J)3K",
	"33JJ",
	"(3Q)3J",
	"33(JQ)",
	"33JQ",
	"(3K)3J",
	"33(JK)",
	"33JK",
	"(3Q)(3Q)",
	"(3Q)3Q",
	"(3QK)3",
	"(3Q)(3K)",
	"(3Q)3K",
	"33QQ",
	"(3K)3Q",
	"33(QK)",
	"33QK",
	"(3K)(3K)",
	"(3K)3K",
	"33KK",
	"(34)44",
	"(345)4",
	"(34)(45)",
	"(34)45",
	"(346)4",
	"(34)(46)",
	"(34)46",
	"(347)4",
	"(34)(47)",
	"(34)47",
	"(348)4",
	"(34)(48)",
	"(34)48",
	"(349)4",
	"(34)(49)",
	"(34)49",
	"(34T)4",
	"(34)(4T)",
	"(34)4T",
	"(34J)4",
	"(34)(4J)",
	"(34)4J",
	"(34Q)4",
	"(34)(4Q)",
	"(34)4Q",
	"(34K)4",
	"(34)(4K)",
	"(34)4K",
	"(345)5",
	"(3456)",
	"(345)6",
	"(3457)",
	"(345)7",
	"(3458)",
	"(345)8",
	"(3459)",
	"(345)9",
	"(345T)",
	"(345)T",
	"(345J)",
	"(345)J",
	"(345Q)",
	"(345)Q",
	"(345K)",
	"(345)K",
	"(34)55",
	"(346)5",
	"(34)(56)",
	"(34)56",
	"(347)5",
	"(34)(57)",
	"(34)57",
	"(348)5",
	"(34)(58)",
	"(34)58",
	"(349)5",
	"(34)(59)",
	"(34)59",
	"(34T)5",
	"(34)(5T)",
	"(34)5T",
	"(34J)5",
	"(34)(5J)",
	"(34)5J",
	"(34Q)5",
	"(34)(5Q)",
	"(34)5Q",
	"(34K)5",
	"(34)(5K)",
	"(34)5K",
	"(346)6",
	"(3467)",
	"(346)7",
	"(3468)",
	"(346)8",
	"(3469)",
	"(346)9",
	"(346T)",
	"(346)T",
	"(346J)",
	"(346)J",
	"(346Q)",
	"(346)Q",
	"(346K)",
	"(346)K",
	"(34)66",
	"(347)6",
	"(34)(67)",
	"(34)67",
	"(348)6",
	"(34)(68)",
	"(34)68",
	"(349)6",
	"(34)(69)",
	"(34)69",
	"(34T)6",
	"(34)(6T)",
	"(34)6T",
	"(34J)6",
	"(34)(6J)",
	"(34)6J",
	"(34Q)6",
	"(34)(6Q)",
	"(34)6Q",
	"(34K)6",
	"(34)(6K)",
	"(34)6K",
	"(347)7",
	"(3478)",
	"(347)8",
	"(3479)",
	"(347)9",
	"(347T)",
	"(347)T",
	"(347J)",
	"(347)J",
	"(347Q)",
	"(347)Q",
	"(347K)",
	"(347)K",
	"(34)77",
	"(348)7",
	"(34)(78)",
	"(34)78",
	"(349)7",
	"(34)(79)",
	"(34)79",
	"(34T)7",
	"(34)(7T)",
	"(34)7T",
	"(34J)7",
	"(34)(7J)",
	"(34)7J",
	"(34Q)7",
	"(34)(7Q)",
	"(34)7Q",
	"(34K)7",
	"(34)(7K)",
	"(34)7K",
	"(348)8",
	"(3489)",
	"(348)9",
	"(348T)",
	"(348)T",
	"(348J)",
	"(348)J",
	"(348Q)",
	"(348)Q",
	"(348K)",
	"(348)K",
	"(34)88",
	"(349)8",
	"(34)(89)",
	"(34)89",
	"(34T)8",
	"(34)(8T)",
	"(34)8T",
	"(34J)8",
	"(34)(8J)",
	"(34)8J",
	"(34Q)8",
	"(34)(8Q)",
	"(34)8Q",
	"(34K)8",
	"(34)(8K)",
	"(34)8K",
	"(349)9",
	"(349T)",
	"(349)T",
	"(349J)",
	"(349)J",
	"(349Q)",
	"(349)Q",
	"(349K)",
	"(349)K",
	"(34)99",
	"(34T)9",
	"(34)(9T)",
	"(34)9T",
	"(34J)9",
	"(34)(9J)",
	"(34)9J",
	"(34Q)9",
	"(34)(9Q)",
	"(34)9Q",
	"(34K)9",
	"(34)(9K)",
	"(34)9K",
	"(34T)T",
	"(34TJ)",
	"(34T)J",
	"(34TQ)",
	"(34T)Q",
	"(34TK)",
	"(34T)K",
	"(34)TT",
	"(34J)T",
	"(34)(TJ)",
	"(34)TJ",
	"(34Q)T",
	"(34)(TQ)",
	"(34)TQ",
	"(34K)T",
	"(34)(TK)",
	"(34)TK",
	"(34J)J",
	"(34JQ)",
	"(34J)Q",
	"(34JK)",
	"(34J)K",
	"(34)JJ",
	"(34Q)J",
	"(34)(JQ)",
	"(34)JQ",
	"(34K)J",
	"(34)(JK)",
	"(34)JK",
	"(34Q)Q",
	"(34QK)",
	"(34Q)K",
	"(34)QQ",
	"(34K)Q",
	"(34)(QK)",
	"(34)QK",
	"(34K)K",
	"(34)KK",
	"3444",
	"(35)44",
	"3(45)4",
	"3445",
	"(36)44",
	"3(46)4",
	"3446",
	"(37)44",
	"3(47)4",
	"3447",
	"(38)44",
	"3(48)4",
	"3448",
	"(39)44",
	"3(49)4",
	"3449",
	"(3T)44",
	"3(4T)4",
	"344T",
	"(3J)44",
	"3(4J)4",
	"344J",
	"(3Q)44",
	"3(4Q)4",
	"344Q",
	"(3K)44",
	"3(4K)4",
	"344K",
	"(35)(45)",
	"(35)45",
	"(356)4",
	"(35)(46)",
	"(35)46",
	"(357)4",
	"(35)(47)",
	"(35)47",
	"(358)4",
	"(35)(48)",
	"(35)48",
	"(359)4",
	"(35)(49)",
	"(35)49",
	"(35T)4",
	"(35)(4T)",
	"(35)4T",
	"(35J)4",
	"(35)(4J)",
	"(35)4J",
	"(35Q)4",
	"(35)(4Q)",
	"(35)4Q",
	"(35K)4",
	"(35)(4K)",
	"(35)4K",
	"3(45)5",
	"(36)(45)",
	"3(456)",
	"3(45)6",
	"(37)(45)",
	"3(457)",
	"3(45)7",
	"(38)(45)",
	"3(458)",
	"3(45)8",
	"(39)(45)",
	"3(459)",
	"3(45)9",
	"(3T)(45)",
	"3(45T)",
	"3(45)T",
	"(3J)(45)",
	"3(45J)",
	"3(45)J",
	"(3Q)(45)",
	"3(45Q)",
	"3(45)Q",
	"(3K)(45)",
	"3(45K)",
	"3(45)K",
	"3455",
	"(36)45",
	"3(46)5",
	"34(56)",
	"3456",
	"(37)45",
	"3(47)5",
	"34(57)",
	"3457",
	"(38)45",
	"3(48)5",
	"34(58)",
	"3458",
	"(39)45",
	"3(49)5",
	"34(59)",
	"3459",
	"(3T)45",
	"3(4T)5",
	"34(5T)",
	"345T",
	"(3J)45",
	"3(4J)5",
	"34(5J)",
	"345J",
	"(3Q)45",
	"3(4Q)5",
	"34(5Q)",
	"345Q",
	"(3K)45",
	"3(4K)5",
	"34(5K)",
	"345K",
	"(36)(46)",
	"(36)46",
	"(367)4",
	"(36)(47)",
	"(36)47",
	"(368)4",
	"(36)(48)",
	"(36)48",
	"(369)4",
	"(36)(49)",
	"(36)49",
	"(36T)4",
	"(36)(4T)",
	"(36)4T",
	"(36J)4",
	"(36)(4J)",
	"(36)4J",
	"(36Q)4",
	"(36)(4Q)",
	"(36)4Q",
	"(36K)4",
	"(36)(4K)",
	"(36)4K",
	"3(46)6",
	"(37)(46)",
	"3(467)",
	"3(46)7",
	"(38)(46)",
	"3(468)",
	"3(46)8",
	"(39)(46)",
	"3(469)",
	"3(46)9",
	"(3T)(46)",
	"3(46T)",
	"3(46)T",
	"(3J)(46)",
	"3(46J)",
	"3(46)J",
	"(3Q)(46)",
	"3(46Q)",
	"3(46)Q",
	"(3K)(46)",
	"3(46K)",
	"3(46)K",
	"3466",
	"(37)46",
	"3(47)6",
	"34(67)",
	"3467",
	"(38)46",
	"3(48)6",
	"34(68)",
	"3468",
	"(39)46",
	"3(49)6",
	"34(69)",
	"3469",
	"(3T)46",
	"3(4T)6",
	"34(6T)",
	"346T",
	"(3J)46",
	"3(4J)6",
	"34(6J)",
	"346J",
	"(3Q)46",
	"3(4Q)6",
	"34(6Q)",
	"346Q",
	"(3K)46",
	"3(4K)6",
	"34(6K)",
	"346K",
	"(37)(47)",
	"(37)47",
	"(378)4",
	"(37)(48)",
	"(37)48",
	"(379)4",
	"(37)(49)",
	"(37)49",
	"(37T)4",
	"(37)(4T)",
	"(37)4T",
	"(37J)4",
	"(37)(4J)",
	"(37)4J",
	"(37Q)4",
	"(37)(4Q)",
	"(37)4Q",
	"(37K)4",
	"(37)(4K)",
	"(37)4K",
	"3(47)7",
	"(38)(47)",
	"3(478)",
	"3(47)8",
	"(39)(47)",
	"3(479)",
	"3(47)9",
	"(3T)(47)",
	"3(47T)",
	"3(47)T",
	"(3J)(47)",
	"3(47J)",
	"3(47)J",
	"(3Q)(47)",
	"3(47Q)",
	"3(47)Q",
	"(3K)(47)",
	"3(47K)",
	"3(47)K",
	"3477",
	"(38)47",
	"3(48)7",
	"34(78)",
	"3478",
	"(39)47",
	"3(49)7",
	"34(79)",
	"3479",
	"(3T)47",
	"3(4T)7",
	"34(7T)",
	"347T",
	"(3J)47",
	"3(4J)7",
	"34(7J)",
	"347J",
	"(3Q)47",
	"3(4Q)7",
	"34(7Q)",
	"347Q",
	"(3K)47",
	"3(4K)7",
	"34(7K)",
	"347K",
	"(38)(48)",
	"(38)48",
	"(389)4",
	"(38)(49)",
	"(38)49",
	"(38T)4",
	"(38)(4T)",
	"(38)4T",
	"(38J)4",
	"(38)(4J)",
	"(38)4J",
	"(38Q)4",
	"(38)(4Q)",
	"(38)4Q",
	"(38K)4",
	"(38)(4K)",
	"(38)4K",
	"3(48)8",
	"(39)(48)",
	"3(489)",
	"3(48)9",
	"(3T)(48)",
	"3(48T)",
	"3(48)T",
	"(3J)(48)",
	"3(48J)",
	"3(48)J",
	"(3Q)(48)",
	"3(48Q)",
	"3(48)Q",
	"(3K)(48)",
	"3(48K)",
	"3(48)K",
	"3488",
	"(39)48",
	"3(49)8",
	"34(89)",
	"3489",
	"(3T)48",
	"3(4T)8",
	"34(8T)",
	"348T",
	"(3J)48",
	"3(4J)8",
	"34(8J)",
	"348J",
	"(3Q)48",
	"3(4Q)8",
	"34(8Q)",
	"348Q",
	"(3K)48",
	"3(4K)8",
	"34(8K)",
	"348K",
	"(39)(49)",
	"(39)49",
	"(39T)4",
	"(39)(4T)",
	"(39)4T",
	"(39J)4",
	"(39)(4J)",
	"(39)4J",
	"(39Q)4",
	"(39)(4Q)",
	"(39)4Q",
	"(39K)4",
	"(39)(4K)",
	"(39)4K",
	"3(49)9",
	"(3T)(49)",
	"3(49T)",
	"3(49)T",
	"(3J)(49)",
	"3(49J)",
	"3(49)J",
	"(3Q)(49)",
	"3(49Q)",
	"3(49)Q",
	"(3K)(49)",
	"3(49K)",
	"3(49)K",
	"3499",
	"(3T)49",
	"3(4T)9",
	"34(9T)",
	"349T",
	"(3J)49",
	"3(4J)9",
	"34(9J)",
	"349J",
	"(3Q)49",
	"3(4Q)9",
	"34(9Q)",
	"349Q",
	"(3K)49",
	"3(4K)9",
	"34(9K)",
	"349K",
	"(3T)(4T)",
	"(3T)4T",
	"(3TJ)4",
	"(3T)(4J)",
	"(3T)4J",
	"(3TQ)4",
	"(3T)(4Q)",
	"(3T)4Q",
	"(3TK)4",
	"(3T)(4K)",
	"(3T)4K",
	"3(4T)T",
	"(3J)(4T)",
	"3(4TJ)",
	"3(4T)J",
	"(3Q)(4T)",
	"3(4TQ)",
	"3(4T)Q",
	"(3K)(4T)",
	"3(4TK)",
	"3(4T)K",
	"34TT",
	"(3J)4T",
	"3(4J)T",
	"34(TJ)",
	"34TJ",
	"(3Q)4T",
	"3(4Q)T",
	"34(TQ)",
	"34TQ",
	"(3K)4T",
	"3(4K)T",
	"34(TK)",
	"34TK",
	"(3J)(4J)",
	"(3J)4J",
	"(3JQ)4",
	"(3J)(4Q)",
	"(3J)4Q",
	"(3JK)4",
	"(3J)(4K)",
	"(3J)4K",
	"3(4J)J",
	"(3Q)(4J)",
	"3(4JQ)",
	"3(4J)Q",
	"(3K)(4J)",
	"3(4JK)",
	"3(4J)K",
	"34JJ",
	"(3Q)4J",
	"3(4Q)J",
	"34(JQ)",
	"34JQ",
	"(3K)4J",
	"3(4K)J",
	"34(JK)",
	"34JK",
	"(3Q)(4Q)",
	"(3Q)4Q",
	"(3QK)4",
	"(3Q)(4K)",
	"(3Q)4K",
	"3(4Q)Q",
	"(3K)(4Q)",
	"3(4QK)",
	"3(4Q)K",
	"34QQ",
	"(3K)4Q",
	"3(4K)Q",
	"34(QK)",
	"34QK",
	"(3K)(4K)",
	"(3K)4K",
	"3(4K)K",
	"34KK",
	"(35)55",
	"(356)5",
	"(35)(56)",
	"(35)56",
	"(357)5",
	"(35)(57)",
	"(35)57",
	"(358)5",
	"(35)(58)",
	"(35)58",
	"(359)5",
	"(35)(59)",
	"(35)59",
	"(35T)5",
	"(35)(5T)",
	"(35)5T",
	"(35J)5",
	"(35)(5J)",
	"(35)5J",
	"(35Q)5",
	"(35)(5Q)",
	"(35)5Q",
	"(35K)5",
	"(35)(5K)",
	"(35)5K",
	"(356)6",
	"(3567)",
	"(356)7",
	"(3568)",
	"(356)8",
	"(3569)",
	"(356)9",
	"(356T)",
	"(356)T",
	"(356J)",
	"(356)J",
	"(356Q)",
	"(356)Q",
	"(356K)",
	"(356)K",
	"(35)66",
	"(357)6",
	"(35)(67)",
	"(35)67",
	"(358)6",
	"(35)(68)",
	"(35)68",
	"(359)6",
	"(35)(69)",
	"(35)69",
	"(35T)6",
	"(35)(6T)",
	"(35)6T",
	"(35J)6",
	"(35)(6J)",
	"(35)6J",
	"(35Q)6",
	"(35)(6Q)",
	"(35)6Q",
	"(35K)6",
	"(35)(6K)",
	"(35)6K",
	"(357)7",
	"(3578)",
	"(357)8",
	"(3579)",
	"(357)9",
	"(357T)",
	"(357)T",
	"(357J)",
	"(357)J",
	"(357Q)",
	"(357)Q",
	"(357K)",
	"(357)K",
	"(35)77",
	"(358)7",
	"(35)(78)",
	"(35)78",
	"(359)7",
	"(35)(79)",
	"(35)79",
	"(35T)7",
	"(35)(7T)",
	"(35)7T",
	"(35J)7",
	"(35)(7J)",
	"(35)7J",
	"(35Q)7",
	"(35)(7Q)",
	"(35)7Q",
	"(35K)7",
	"(35)(7K)",
	"(35)7K",
	"(358)8",
	"(3589)",
	"(358)9",
	"(358T)",
	"(358)T",
	"(358J)",
	"(358)J",
	"(358Q)",
	"(358)Q",
	"(358K)",
	"(358)K",
	"(35)88",
	"(359)8",
	"(35)(89)",
	"(35)89",
	"(35T)8",
	"(35)(8T)",
	"(35)8T",
	"(35J)8",
	"(35)(8J)",
	"(35)8J",
	"(35Q)8",
	"(35)(8Q)",
	"(35)8Q",
	"(35K)8",
	"(35)(8K)",
	"(35)8K",
	"(359)9",
	"(359T)",
	"(359)T",
	"(359J)",
	"(359)J",
	"(359Q)",
	"(359)Q",
	"(359K)",
	"(359)K",
	"(35)99",
	"(35T)9",
	"(35)(9T)",
	"(35)9T",
	"(35J)9",
	"(35)(9J)",
	"(35)9J",
	"(35Q)9",
	"(35)(9Q)",
	"(35)9Q",
	"(35K)9",
	"(35)(9K)",
	"(35)9K",
	"(35T)T",
	"(35TJ)",
	"(35T)J",
	"(35TQ)",
	"(35T)Q",
	"(35TK)",
	"(35T)K",
	"(35)TT",
	"(35J)T",
	"(35)(TJ)",
	"(35)TJ",
	"(35Q)T",
	"(35)(TQ)",
	"(35)TQ",
	"(35K)T",
	"(35)(TK)",
	"(35)TK",
	"(35J)J",
	"(35JQ)",
	"(35J)Q",
	"(35JK)",
	"(35J)K",
	"(35)JJ",
	"(35Q)J",
	"(35)(JQ)",
	"(35)JQ",
	"(35K)J",
	"(35)(JK)",
	"(35)JK",
	"(35Q)Q",
	"(35QK)",
	"(35Q)K",
	"(35)QQ",
	"(35K)Q",
	"(35)(QK)",
	"(35)QK",
	"(35K)K",
	"(35)KK",
	"3555",
	"(36)55",
	"3(56)5",
	"3556",
	"(37)55",
	"3(57)5",
	"3557",
	"(38)55",
	"3(58)5",
	"3558",
	"(39)55",
	"3(59)5",
	"3559",
	"(3T)55",
	"3(5T)5",
	"355T",
	"(3J)55",
	"3(5J)5",
	"355J",
	"(3Q)55",
	"3(5Q)5",
	"355Q",
	"(3K)55",
	"3(5K)5",
	"355K",
	"(36)(56)",
	"(36)56",
	"(367)5",
	"(36)(57)",
	"(36)57",
	"(368)5",
	"(36)(58)",
	"(36)58",
	"(369)5",
	"(36)(59)",
	"(36)59",
	"(36T)5",
	"(36)(5T)",
	"(36)5T",
	"(36J)5",
	"(36)(5J)",
	"(36)5J",
	"(36Q)5",
	"(36)(5Q)",
	"(36)5Q",
	"(36K)5",
	"(36)(5K)",
	"(36)5K",
	"3(56)6",
	"(37)(56)",
	"3(567)",
	"3(56)7",
	"(38)(56)",
	"3(568)",
	"3(56)8",
	"(39)(56)",
	"3(569)",
	"3(56)9",
	"(3T)(56)",
	"3(56T)",
	"3(56)T",
	"(3J)(56)",
	"3(56J)",
	"3(56)J",
	"(3Q)(56)",
	"3(56Q)",
	"3(56)Q",
	"(3K)(56)",
	"3(56K)",
	"3(56)K",
	"3566",
	"(37)56",
	"3(57)6",
	"35(67)",
	"3567",
	"(38)56",
	"3(58)6",
	"35(68)",
	"3568",
	"(39)56",
	"3(59)6",
	"35(69)",
	"3569",
	"(3T)56",
	"3(5T)6",
	"35(6T)",
	"356T",
	"(3J)56",
	"3(5J)6",
	"35(6J)",
	"356J",
	"(3Q)56",
	"3(5Q)6",
	"35(6Q)",
	"356Q",
	"(3K)56",
	"3(5K)6",
	"35(6K)",
	"356K",
	"(37)(57)",
	"(37)57",
	"(378)5",
	"(37)(58)",
	"(37)58",
	"(379)5",
	"(37)(59)",
	"(37)59",
	"(37T)5",
	"(37)(5T)",
	"(37)5T",
	"(37J)5",
	"(37)(5J)",
	"(37)5J",
	"(37Q)5",
	"(37)(5Q)",
	"(37)5Q",
	"(37K)5",
	"(37)(5K)",
	"(37)5K",
	"3(57)7",
	"(38)(57)",
	"3(578)",
	"3(57)8",
	"(39)(57)",
	"3(579)",
	"3(57)9",
	"(3T)(57)",
	"3(57T)",
	"3(57)T",
	"(3J)(57)",
	"3(57J)",
	"3(57)J",
	"(3Q)(57)",
	"3(57Q)",
	"3(57)Q",
	"(3K)(57)",
	"3(57K)",
	"3(57)K",
	"3577",
	"(38)57",
	"3(58)7",
	"35(78)",
	"3578",
	"(39)57",
	"3(59)7",
	"35(79)",
	"3579",
	"(3T)57",
	"3(5T)7",
	"35(7T)",
	"357T",
	"(3J)57",
	"3(5J)7",
	"35(7J)",
	"357J",
	"(3Q)57",
	"3(5Q)7",
	"35(7Q)",
	"357Q",
	"(3K)57",
	"3(5K)7",
	"35(7K)",
	"357K",
	"(38)(58)",
	"(38)58",
	"(389)5",
	"(38)(59)",
	"(38)59",
	"(38T)5",
	"(38)(5T)",
	"(38)5T",
	"(38J)5",
	"(38)(5J)",
	"(38)5J",
	"(38Q)5",
	"(38)(5Q)",
	"(38)5Q",
	"(38K)5",
	"(38)(5K)",
	"(38)5K",
	"3(58)8",
	"(39)(58)",
	"3(589)",
	"3(58)9",
	"(3T)(58)",
	"3(58T)",
	"3(58)T",
	"(3J)(58)",
	"3(58J)",
	"3(58)J",
	"(3Q)(58)",
	"3(58Q)",
	"3(58)Q",
	"(3K)(58)",
	"3(58K)",
	"3(58)K",
	"3588",
	"(39)58",
	"3(59)8",
	"35(89)",
	"3589",
	"(3T)58",
	"3(5T)8",
	"35(8T)",
	"358T",
	"(3J)58",
	"3(5J)8",
	"35(8J)",
	"358J",
	"(3Q)58",
	"3(5Q)8",
	"35(8Q)",
	"358Q",
	"(3K)58",
	"3(5K)8",
	"35(8K)",
	"358K",
	"(39)(59)",
	"(39)59",
	"(39T)5",
	"(39)(5T)",
	"(39)5T",
	"(39J)5",
	"(39)(5J)",
	"(39)5J",
	"(39Q)5",
	"(39)(5Q)",
	"(39)5Q",
	"(39K)5",
	"(39)(5K)",
	"(39)5K",
	"3(59)9",
	"(3T)(59)",
	"3(59T)",
	"3(59)T",
	"(3J)(59)",
	"3(59J)",
	"3(59)J",
	"(3Q)(59)",
	"3(59Q)",
	"3(59)Q",
	"(3K)(59)",
	"3(59K)",
	"3(59)K",
	"3599",
	"(3T)59",
	"3(5T)9",
	"35(9T)",
	"359T",
	"(3J)59",
	"3(5J)9",
	"35(9J)",
	"359J",
	"(3Q)59",
	"3(5Q)9",
	"35(9Q)",
	"359Q",
	"(3K)59",
	"3(5K)9",
	"35(9K)",
	"359K",
	"(3T)(5T)",
	"(3T)5T",
	"(3TJ)5",
	"(3T)(5J)",
	"(3T)5J",
	"(3TQ)5",
	"(3T)(5Q)",
	"(3T)5Q",
	"(3TK)5",
	"(3T)(5K)",
	"(3T)5K",
	"3(5T)T",
	"(3J)(5T)",
	"3(5TJ)",
	"3(5T)J",
	"(3Q)(5T)",
	"3(5TQ)",
	"3(5T)Q",
	"(3K)(5T)",
	"3(5TK)",
	"3(5T)K",
	"35TT",
	"(3J)5T",
	"3(5J)T",
	"35(TJ)",
	"35TJ",
	"(3Q)5T",
	"3(5Q)T",
	"35(TQ)",
	"35TQ",
	"(3K)5T",
	"3(5K)T",
	"35(TK)",
	"35TK",
	"(3J)(5J)",
	"(3J)5J",
	"(3JQ)5",
	"(3J)(5Q)",
	"(3J)5Q",
	"(3JK)5",
	"(3J)(5K)",
	"(3J)5K",
	"3(5J)J",
	"(3Q)(5J)",
	"3(5JQ)",
	"3(5J)Q",
	"(3K)(5J)",
	"3(5JK)",
	"3(5J)K",
	"35JJ",
	"(3Q)5J",
	"3(5Q)J",
	"35(JQ)",
	"35JQ",
	"(3K)5J",
	"3(5K)J",
	"35(JK)",
	"35JK",
	"(3Q)(5Q)",
	"(3Q)5Q",
	"(3QK)5",
	"(3Q)(5K)",
	"(3Q)5K",
	"3(5Q)Q",
	"(3K)(5Q)",
	"3(5QK)",
	"3(5Q)K",
	"35QQ",
	"(3K)5Q",
	"3(5K)Q",
	"35(QK)",
	"35QK",
	"(3K)(5K)",
	"(3K)5K",
	"3(5K)K",
	"35KK",
	"(36)66",
	"(367)6",
	"(36)(67)",
	"(36)67",
	"(368)6",
	"(36)(68)",
	"(36)68",
	"(369)6",
	"(36)(69)",
	"(36)69",
	"(36T)6",
	"(36)(6T)",
	"(36)6T",
	"(36J)6",
	"(36)(6J)",
	"(36)6J",
	"(36Q)6",
	"(36)(6Q)",
	"(36)6Q",
	"(36K)6",
	"(36)(6K)",
	"(36)6K",
	"(367)7",
	"(3678)",
	"(367)8",
	"(3679)",
	"(367)9",
	"(367T)",
	"(367)T",
	"(367J)",
	"(367)J",
	"(367Q)",
	"(367)Q",
	"(367K)",
	"(367)K",
	"(36)77",
	"(368)7",
	"(36)(78)",
	"(36)78",
	"(369)7",
	"(36)(79)",
	"(36)79",
	"(36T)7",
	"(36)(7T)",
	"(36)7T",
	"(36J)7",
	"(36)(7J)",
	"(36)7J",
	"(36Q)7",
	"(36)(7Q)",
	"(36)7Q",
	"(36K)7",
	"(36)(7K)",
	"(36)7K",
	"(368)8",
	"(3689)",
	"(368)9",
	"(368T)",
	"(368)T",
	"(368J)",
	"(368)J",
	"(368Q)",
	"(368)Q",
	"(368K)",
	"(368)K",
	"(36)88",
	"(369)8",
	"(36)(89)",
	"(36)89",
	"(36T)8",
	"(36)(8T)",
	"(36)8T",
	"(36J)8",
	"(36)(8J)",
	"(36)8J",
	"(36Q)8",
	"(36)(8Q)",
	"(36)8Q",
	"(36K)8",
	"(36)(8K)",
	"(36)8K",
	"(369)9",
	"(369T)",
	"(369)T",
	"(369J)",
	"(369)J",
	"(369Q)",
	"(369)Q",
	"(369K)",
	"(369)K",
	"(36)99",
	"(36T)9",
	"(36)(9T)",
	"(36)9T",
	"(36J)9",
	"(36)(9J)",
	"(36)9J",
	"(36Q)9",
	"(36)(9Q)",
	"(36)9Q",
	"(36K)9",
	"(36)(9K)",
	"(36)9K",
	"(36T)T",
	"(36TJ)",
	"(36T)J",
	"(36TQ)",
	"(36T)Q",
	"(36TK)",
	"(36T)K",
	"(36)TT",
	"(36J)T",
	"(36)(TJ)",
	"(36)TJ",
	"(36Q)T",
	"(36)(TQ)",
	"(36)TQ",
	"(36K)T",
	"(36)(TK)",
	"(36)TK",
	"(36J)J",
	"(36JQ)",
	"(36J)Q",
	"(36JK)",
	"(36J)K",
	"(36)JJ",
	"(36Q)J",
	"(36)(JQ)",
	"(36)JQ",
	"(36K)J",
	"(36)(JK)",
	"(36)JK",
	"(36Q)Q",
	"(36QK)",
	"(36Q)K",
	"(36)QQ",
	"(36K)Q",
	"(36)(QK)",
	"(36)QK",
	"(36K)K",
	"(36)KK",
	"3666",
	"(37)66",
	"3(67)6",
	"3667",
	"(38)66",
	"3(68)6",
	"3668",
	"(39)66",
	"3(69)6",
	"3669",
	"(3T)66",
	"3(6T)6",
	"366T",
	"(3J)66",
	"3(6J)6",
	"366J",
	"(3Q)66",
	"3(6Q)6",
	"366Q",
	"(3K)66",
	"3(6K)6",
	"366K",
	"(37)(67)",
	"(37)67",
	"(378)6",
	"(37)(68)",
	"(37)68",
	"(379)6",
	"(37)(69)",
	"(37)69",
	"(37T)6",
	"(37)(6T)",
	"(37)6T",
	"(37J)6",
	"(37)(6J)",
	"(37)6J",
	"(37Q)6",
	"(37)(6Q)",
	"(37)6Q",
	"(37K)6",
	"(37)(6K)",
	"(37)6K",
	"3(67)7",
	"(38)(67)",
	"3(678)",
	"3(67)8",
	"(39)(67)",
	"3(679)",
	"3(67)9",
	"(3T)(67)",
	"3(67T)",
	"3(67)T",
	"(3J)(67)",
	"3(67J)",
	"3(67)J",
	"(3Q)(67)",
	"3(67Q)",
	"3(67)Q",
	"(3K)(67)",
	"3(67K)",
	"3(67)K",
	"3677",
	"(38)67",
	"3(68)7",
	"36(78)",
	"3678",
	"(39)67",
	"3(69)7",
	"36(79)",
	"3679",
	"(3T)67",
	"3(6T)7",
	"36(7T)",
	"367T",
	"(3J)67",
	"3(6J)7",
	"36(7J)",
	"367J",
	"(3Q)67",
	"3(6Q)7",
	"36(7Q)",
	"367Q",
	"(3K)67",
	"3(6K)7",
	"36(7K)",
	"367K",
	"(38)(68)",
	"(38)68",
	"(389)6",
	"(38)(69)",
	"(38)69",
	"(38T)6",
	"(38)(6T)",
	"(38)6T",
	"(38J)6",
	"(38)(6J)",
	"(38)6J",
	"(38Q)6",
	"(38)(6Q)",
	"(38)6Q",
	"(38K)6",
	"(38)(6K)",
	"(38)6K",
	"3(68)8",
	"(39)(68)",
	"3(689)",
	"3(68)9",
	"(3T)(68)",
	"3(68T)",
	"3(68)T",
	"(3J)(68)",
	"3(68J)",
	"3(68)J",
	"(3Q)(68)",
	"3(68Q)",
	"3(68)Q",
	"(3K)(68)",
	"3(68K)",
	"3(68)K",
	"3688",
	"(39)68",
	"3(69)8",
	"36(89)",
	"3689",
	"(3T)68",
	"3(6T)8",
	"36(8T)",
	"368T",
	"(3J)68",
	"3(6J)8",
	"36(8J)",
	"368J",
	"(3Q)68",
	"3(6Q)8",
	"36(8Q)",
	"368Q",
	"(3K)68",
	"3(6K)8",
	"36(8K)",
	"368K",
	"(39)(69)",
	"(39)69",
	"(39T)6",
	"(39)(6T)",
	"(39)6T",
	"(39J)6",
	"(39)(6J)",
	"(39)6J",
	"(39Q)6",
	"(39)(6Q)",
	"(39)6Q",
	"(39K)6",
	"(39)(6K)",
	"(39)6K",
	"3(69)9",
	"(3T)(69)",
	"3(69T)",
	"3(69)T",
	"(3J)(69)",
	"3(69J)",
	"3(69)J",
	"(3Q)(69)",
	"3(69Q)",
	"3(69)Q",
	"(3K)(69)",
	"3(69K)",
	"3(69)K",
	"3699",
	"(3T)69",
	"3(6T)9",
	"36(9T)",
	"369T",
	"(3J)69",
	"3(6J)9",
	"36(9J)",
	"369J",
	"(3Q)69",
	"3(6Q)9",
	"36(9Q)",
	"369Q",
	"(3K)69",
	"3(6K)9",
	"36(9K)",
	"369K",
	"(3T)(6T)",
	"(3T)6T",
	"(3TJ)6",
	"(3T)(6J)",
	"(3T)6J",
	"(3TQ)6",
	"(3T)(6Q)",
	"(3T)6Q",
	"(3TK)6",
	"(3T)(6K)",
	"(3T)6K",
	"3(6T)T",
	"(3J)(6T)",
	"3(6TJ)",
	"3(6T)J",
	"(3Q)(6T)",
	"3(6TQ)",
	"3(6T)Q",
	"(3K)(6T)",
	"3(6TK)",
	"3(6T)K",
	"36TT",
	"(3J)6T",
	"3(6J)T",
	"36(TJ)",
	"36TJ",
	"(3Q)6T",
	"3(6Q)T",
	"36(TQ)",
	"36TQ",
	"(3K)6T",
	"3(6K)T",
	"36(TK)",
	"36TK",
	"(3J)(6J)",
	"(3J)6J",
	"(3JQ)6",
	"(3J)(6Q)",
	"(3J)6Q",
	"(3JK)6",
	"(3J)(6K)",
	"(3J)6K",
	"3(6J)J",
	"(3Q)(6J)",
	"3(6JQ)",
	"3(6J)Q",
	"(3K)(6J)",
	"3(6JK)",
	"3(6J)K",
	"36JJ",
	"(3Q)6J",
	"3(6Q)J",
	"36(JQ)",
	"36JQ",
	"(3K)6J",
	"3(6K)J",
	"36(JK)",
	"36JK",
	"(3Q)(6Q)",
	"(3Q)6Q",
	"(3QK)6",
	"(3Q)(6K)",
	"(3Q)6K",
	"3(6Q)Q",
	"(3K)(6Q)",
	"3(6QK)",
	"3(6Q)K",
	"36QQ",
	"(3K)6Q",
	"3(6K)Q",
	"36(QK)",
	"36QK",
	"(3K)(6K)",
	"(3K)6K",
	"3(6K)K",
	"36KK",
	"(37)77",
	"(378)7",
	"(37)(78)",
	"(37)78",
	"(379)7",
	"(37)(79)",
	"(37)79",
	"(37T)7",
	"(37)(7T)",
	"(37)7T",
	"(37J)7",
	"(37)(7J)",
	"(37)7J",
	"(37Q)7",
	"(37)(7Q)",
	"(37)7Q",
	"(37K)7",
	"(37)(7K)",
	"(37)7K",
	"(378)8",
	"(3789)",
	"(378)9",
	"(378T)",
	"(378)T",
	"(378J)",
	"(378)J",
	"(378Q)",
	"(378)Q",
	"(378K)",
	"(378)K",
	"(37)88",
	"(379)8",
	"(37)(89)",
	"(37)89",
	"(37T)8",
	"(37)(8T)",
	"(37)8T",
	"(37J)8",
	"(37)(8J)",
	"(37)8J",
	"(37Q)8",
	"(37)(8Q)",
	"(37)8Q",
	"(37K)8",
	"(37)(8K)",
	"(37)8K",
	"(379)9",
	"(379T)",
	"(379)T",
	"(379J)",
	"(379)J",
	"(379Q)",
	"(379)Q",
	"(379K)",
	"(379)K",
	"(37)99",
	"(37T)9",
	"(37)(9T)",
	"(37)9T",
	"(37J)9",
	"(37)(9J)",
	"(37)9J",
	"(37Q)9",
	"(37)(9Q)",
	"(37)9Q",
	"(37K)9",
	"(37)(9K)",
	"(37)9K",
	"(37T)T",
	"(37TJ)",
	"(37T)J",
	"(37TQ)",
	"(37T)Q",
	"(37TK)",
	"(37T)K",
	"(37)TT",
	"(37J)T",
	"(37)(TJ)",
	"(37)TJ",
	"(37Q)T",
	"(37)(TQ)",
	"(37)TQ",
	"(37K)T",
	"(37)(TK)",
	"(37)TK",
	"(37J)J",
	"(37JQ)",
	"(37J)Q",
	"(37JK)",
	"(37J)K",
	"(37)JJ",
	"(37Q)J",
	"(37)(JQ)",
	"(37)JQ",
	"(37K)J",
	"(37)(JK)",
	"(37)JK",
	"(37Q)Q",
	"(37QK)",
	"(37Q)K",
	"(37)QQ",
	"(37K)Q",
	"(37)(QK)",
	"(37)QK",
	"(37K)K",
	"(37)KK",
	"3777",
	"(38)77",
	"3(78)7",
	"3778",
	"(39)77",
	"3(79)7",
	"3779",
	"(3T)77",
	"3(7T)7",
	"377T",
	"(3J)77",
	"3(7J)7",
	"377J",
	"(3Q)77",
	"3(7Q)7",
	"377Q",
	"(3K)77",
	"3(7K)7",
	"377K",
	"(38)(78)",
	"(38)78",
	"(389)7",
	"(38)(79)",
	"(38)79",
	"(38T)7",
	"(38)(7T)",
	"(38)7T",
	"(38J)7",
	"(38)(7J)",
	"(38)7J",
	"(38Q)7",
	"(38)(7Q)",
	"(38)7Q",
	"(38K)7",
	"(38)(7K)",
	"(38)7K",
	"3(78)8",
	"(39)(78)",
	"3(789)",
	"3(78)9",
	"(3T)(78)",
	"3(78T)",
	"3(78)T",
	"(3J)(78)",
	"3(78J)",
	"3(78)J",
	"(3Q)(78)",
	"3(78Q)",
	"3(78)Q",
	"(3K)(78)",
	"3(78K)",
	"3(78)K",
	"3788",
	"(39)78",
	"3(79)8",
	"37(89)",
	"3789",
	"(3T)78",
	"3(7T)8",
	"37(8T)",
	"378T",
	"(3J)78",
	"3(7J)8",
	"37(8J)",
	"378J",
	"(3Q)78",
	"3(7Q)8",
	"37(8Q)",
	"378Q",
	"(3K)78",
	"3(7K)8",
	"37(8K)",
	"378K",
	"(39)(79)",
	"(39)79",
	"(39T)7",
	"(39)(7T)",
	"(39)7T",
	"(39J)7",
	"(39)(7J)",
	"(39)7J",
	"(39Q)7",
	"(39)(7Q)",
	"(39)7Q",
	"(39K)7",
	"(39)(7K)",
	"(39)7K",
	"3(79)9",
	"(3T)(79)",
	"3(79T)",
	"3(79)T",
	"(3J)(79)",
	"3(79J)",
	"3(79)J",
	"(3Q)(79)",
	"3(79Q)",
	"3(79)Q",
	"(3K)(79)",
	"3(79K)",
	"3(79)K",
	"3799",
	"(3T)79",
	"3(7T)9",
	"37(9T)",
	"379T",
	"(3J)79",
	"3(7J)9",
	"37(9J)",
	"379J",
	"(3Q)79",
	"3(7Q)9",
	"37(9Q)",
	"379Q",
	"(3K)79",
	"3(7K)9",
	"37(9K)",
	"379K",
	"(3T)(7T)",
	"(3T)7T",
	"(3TJ)7",
	"(3T)(7J)",
	"(3T)7J",
	"(3TQ)7",
	"(3T)(7Q)",
	"(3T)7Q",
	"(3TK)7",
	"(3T)(7K)",
	"(3T)7K",
	"3(7T)T",
	"(3J)(7T)",
	"3(7TJ)",
	"3(7T)J",
	"(3Q)(7T)",
	"3(7TQ)",
	"3(7T)Q",
	"(3K)(7T)",
	"3(7TK)",
	"3(7T)K",
	"37TT",
	"(3J)7T",
	"3(7J)T",
	"37(TJ)",
	"37TJ",
	"(3Q)7T",
	"3(7Q)T",
	"37(TQ)",
	"37TQ",
	"(3K)7T",
	"3(7K)T",
	"37(TK)",
	"37TK",
	"(3J)(7J)",
	"(3J)7J",
	"(3JQ)7",
	"(3J)(7Q)",
	"(3J)7Q",
	"(3JK)7",
	"(3J)(7K)",
	"(3J)7K",
	"3(7J)J",
	"(3Q)(7J)",
	"3(7JQ)",
	"3(7J)Q",
	"(3K)(7J)",
	"3(7JK)",
	"3(7J)K",
	"37JJ",
	"(3Q)7J",
	"3(7Q)J",
	"37(JQ)",
	"37JQ",
	"(3K)7J",
	"3(7K)J",
	"37(JK)",
	"37JK",
	"(3Q)(7Q)",
	"(3Q)7Q",
	"(3QK)7",
	"(3Q)(7K)",
	"(3Q)7K",
	"3(7Q)Q",
	"(3K)(7Q)",
	"3(7QK)",
	"3(7Q)K",
	"37QQ",
	"(3K)7Q",
	"3(7K)Q",
	"37(QK)",
	"37QK",
	"(3K)(7K)",
	"(3K)7K",
	"3(7K)K",
	"37KK",
	"(38)88",
	"(389)8",
	"(38)(89)",
	"(38)89",
	"(38T)8",
	"(38)(8T)",
	"(38)8T",
	"(38J)8",
	"(38)(8J)",
	"(38)8J",
	"(38Q)8",
	"(38)(8Q)",
	"(38)8Q",
	"(38K)8",
	"(38)(8K)",
	"(38)8K",
	"(389)9",
	"(389T)",
	"(389)T",
	"(389J)",
	"(389)J",
	"(389Q)",
	"(389)Q",
	"(389K)",
	"(389)K",
	"(38)99",
	"(38T)9",
	"(38)(9T)",
	"(38)9T",
	"(38J)9",
	"(38)(9J)",
	"(38)9J",
	"(38Q)9",
	"(38)(9Q)",
	"(38)9Q",
	"(38K)9",
	"(38)(9K)",
	"(38)9K",
	"(38T)T",
	"(38TJ)",
	"(38T)J",
	"(38TQ)",
	"(38T)Q",
	"(38TK)",
	"(38T)K",
	"(38)TT",
	"(38J)T",
	"(38)(TJ)",
	"(38)TJ",
	"(38Q)T",
	"(38)(TQ)",
	"(38)TQ",
	"(38K)T",
	"(38)(TK)",
	"(38)TK",
	"(38J)J",
	"(38JQ)",
	"(38J)Q",
	"(38JK)",
	"(38J)K",
	"(38)JJ",
	"(38Q)J",
	"(38)(JQ)",
	"(38)JQ",
	"(38K)J",
	"(38)(JK)",
	"(38)JK",
	"(38Q)Q",
	"(38QK)",
	"(38Q)K",
	"(38)QQ",
	"(38K)Q",
	"(38)(QK)",
	"(38)QK",
	"(38K)K",
	"(38)KK",
	"3888",
	"(39)88",
	"3(89)8",
	"3889",
	"(3T)88",
	"3(8T)8",
	"388T",
	"(3J)88",
	"3(8J)8",
	"388J",
	"(3Q)88",
	"3(8Q)8",
	"388Q",
	"(3K)88",
	"3(8K)8",
	"388K",
	"(39)(89)",
	"(39)89",
	"(39T)8",
	"(39)(8T)",
	"(39)8T",
	"(39J)8",
	"(39)(8J)",
	"(39)8J",
	"(39Q)8",
	"(39)(8Q)",
	"(39)8Q",
	"(39K)8",
	"(39)(8K)",
	"(39)8K",
	"3(89)9",
	"(3T)(89)",
	"3(89T)",
	"3(89)T",
	"(3J)(89)",
	"3(89J)",
	"3(89)J",
	"(3Q)(89)",
	"3(89Q)",
	"3(89)Q",
	"(3K)(89)",
	"3(89K)",
	"3(89)K",
	"3899",
	"(3T)89",
	"3(8T)9",
	"38(9T)",
	"389T",
	"(3J)89",
	"3(8J)9",
	"38(9J)",
	"389J",
	"(3Q)89",
	"3(8Q)9",
	"38(9Q)",
	"389Q",
	"(3K)89",
	"3(8K)9",
	"38(9K)",
	"389K",
	"(3T)(8T)",
	"(3T)8T",
	"(3TJ)8",
	"(3T)(8J)",
	"(3T)8J",
	"(3TQ)8",
	"(3T)(8Q)",
	"(3T)8Q",
	"(3TK)8",
	"(3T)(8K)",
	"(3T)8K",
	"3(8T)T",
	"(3J)(8T)",
	"3(8TJ)",
	"3(8T)J",
	"(3Q)(8T)",
	"3(8TQ)",
	"3(8T)Q",
	"(3K)(8T)",
	"3(8TK)",
	"3(8T)K",
	"38TT",
	"(3J)8T",
	"3(8J)T",
	"38(TJ)",
	"38TJ",
	"(3Q)8T",
	"3(8Q)T",
	"38(TQ)",
	"38TQ",
	"(3K)8T",
	"3(8K)T",
	"38(TK)",
	"38TK",
	"(3J)(8J)",
	"(3J)8J",
	"(3JQ)8",
	"(3J)(8Q)",
	"(3J)8Q",
	"(3JK)8",
	"(3J)(8K)",
	"(3J)8K",
	"3(8J)J",
	"(3Q)(8J)",
	"3(8JQ)",
	"3(8J)Q",
	"(3K)(8J)",
	"3(8JK)",
	"3(8J)K",
	"38JJ",
	"(3Q)8J",
	"3(8Q)J",
	"38(JQ)",
	"38JQ",
	"(3K)8J",
	"3(8K)J",
	"38(JK)",
	"38JK",
	"(3Q)(8Q)",
	"(3Q)8Q",
	"(3QK)8",
	"(3Q)(8K)",
	"(3Q)8K",
	"3(8Q)Q",
	"(3K)(8Q)",
	"3(8QK)",
	"3(8Q)K",
	"38QQ",
	"(3K)8Q",
	"3(8K)Q",
	"38(QK)",
	"38QK",
	"(3K)(8K)",
	"(3K)8K",
	"3(8K)K",
	"38KK",
	"(39)99",
	"(39T)9",
	"(39)(9T)",
	"(39)9T",
	"(39J)9",
	"(39)(9J)",
	"(39)9J",
	"(39Q)9",
	"(39)(9Q)",
	"(39)9Q",
	"(39K)9",
	"(39)(9K)",
	"(39)9K",
	"(39T)T",
	"(39TJ)",
	"(39T)J",
	"(39TQ)",
	"(39T)Q",
	"(39TK)",
	"(39T)K",
	"(39)TT",
	"(39J)T",
	"(39)(TJ)",
	"(39)TJ",
	"(39Q)T",
	"(39)(TQ)",
	"(39)TQ",
	"(39K)T",
	"(39)(TK)",
	"(39)TK",
	"(39J)J",
	"(39JQ)",
	"(39J)Q",
	"(39JK)",
	"(39J)K",
	"(39)JJ",
	"(39Q)J",
	"(39)(JQ)",
	"(39)JQ",
	"(39K)J",
	"(39)(JK)",
	"(39)JK",
	"(39Q)Q",
	"(39QK)",
	"(39Q)K",
	"(39)QQ",
	"(39K)Q",
	"(39)(QK)",
	"(39)QK",
	"(39K)K",
	"(39)KK",
	"3999",
	"(3T)99",
	"3(9T)9",
	"399T",
	"(3J)99",
	"3(9J)9",
	"399J",
	"(3Q)99",
	"3(9Q)9",
	"399Q",
	"(3K)99",
	"3(9K)9",
	"399K",
	"(3T)(9T)",
	"(3T)9T",
	"(3TJ)9",
	"(3T)(9J)",
	"(3T)9J",
	"(3TQ)9",
	"(3T)(9Q)",
	"(3T)9Q",
	"(3TK)9",
	"(3T)(9K)",
	"(3T)9K",
	"3(9T)T",
	"(3J)(9T)",
	"3(9TJ)",
	"3(9T)J",
	"(3Q)(9T)",
	"3(9TQ)",
	"3(9T)Q",
	"(3K)(9T)",
	"3(9TK)",
	"3(9T)K",
	"39TT",
	"(3J)9T",
	"3(9J)T",
	"39(TJ)",
	"39TJ",
	"(3Q)9T",
	"3(9Q)T",
	"39(TQ)",
	"39TQ",
	"(3K)9T",
	"3(9K)T",
	"39(TK)",
	"39TK",
	"(3J)(9J)",
	"(3J)9J",
	"(3JQ)9",
	"(3J)(9Q)",
	"(3J)9Q",
	"(3JK)9",
	"(3J)(9K)",
	"(3J)9K",
	"3(9J)J",
	"(3Q)(9J)",
	"3(9JQ)",
	"3(9J)Q",
	"(3K)(9J)",
	"3(9JK)",
	"3(9J)K",
	"39JJ",
	"(3Q)9J",
	"3(9Q)J",
	"39(JQ)",
	"39JQ",
	"(3K)9J",
	"3(9K)J",
	"39(JK)",
	"39JK",
	"(3Q)(9Q)",
	"(3Q)9Q",
	"(3QK)9",
	"(3Q)(9K)",
	"(3Q)9K",
	"3(9Q)Q",
	"(3K)(9Q)",
	"3(9QK)",
	"3(9Q)K",
	"39QQ",
	"(3K)9Q",
	"3(9K)Q",
	"39(QK)",
	"39QK",
	"(3K)(9K)",
	"(3K)9K",
	"3(9K)K",
	"39KK",
	"(3T)TT",
	"(3TJ)T",
	"(3T)(TJ)",
	"(3T)TJ",
	"(3TQ)T",
	"(3T)(TQ)",
	"(3T)TQ",
	"(3TK)T",
	"(3T)(TK)",
	"(3T)TK",
	"(3TJ)J",
	"(3TJQ)",
	"(3TJ)Q",
	"(3TJK)",
	"(3TJ)K",
	"(3T)JJ",
	"(3TQ)J",
	"(3T)(JQ)",
	"(3T)JQ",
	"(3TK)J",
	"(3T)(JK)",
	"(3T)JK",
	"(3TQ)Q",
	"(3TQK)",
	"(3TQ)K",
	"(3T)QQ",
	"(3TK)Q",
	"(3T)(QK)",
	"(3T)QK",
	"(3TK)K",
	"(3T)KK",
	"3TTT",
	"(3J)TT",
	"3(TJ)T",
	"3TTJ",
	"(3Q)TT",
	"3(TQ)T",
	"3TTQ",
	"(3K)TT",
	"3(TK)T",
	"3TTK",
	"(3J)(TJ)",
	"(3J)TJ",
	"(3JQ)T",
	"(3J)(TQ)",
	"(3J)TQ",
	"(3JK)T",
	"(3J)(TK)",
	"(3J)TK",
	"3(TJ)J",
	"(3Q)(TJ)",
	"3(TJQ)",
	"3(TJ)Q",
	"(3K)(TJ)",
	"3(TJK)",
	"3(TJ)K",
	"3TJJ",
	"(3Q)TJ",
	"3(TQ)J",
	"3T(JQ)",
	"3TJQ",
	"(3K)TJ",
	"3(TK)J",
	"3T(JK)",
	"3TJK",
	"(3Q)(TQ)",
	"(3Q)TQ",
	"(3QK)T",
	"(3Q)(TK)",
	"(3Q)TK",
	"3(TQ)Q",
	"(3K)(TQ)",
	"3(TQK)",
	"3(TQ)K",
	"3TQQ",
	"(3K)TQ",
	"3(TK)Q",
	"3T(QK)",
	"3TQK",
	"(3K)(TK)",
	"(3K)TK",
	"3(TK)K",
	"3TKK",
	"(3J)JJ",
	"(3JQ)J",
	"(3J)(JQ)",
	"(3J)JQ",
	"(3JK)J",
	"(3J)(JK)",
	"(3J)JK",
	"(3JQ)Q",
	"(3JQK)",
	"(3JQ)K",
	"(3J)QQ",
	"(3JK)Q",
	"(3J)(QK)",
	"(3J)QK",
	"(3JK)K",
	"(3J)KK",
	"3JJJ",
	"(3Q)JJ",
	"3(JQ)J",
	"3JJQ",
	"(3K)JJ",
	"3(JK)J",
	"3JJK",
	"(3Q)(JQ)",
	"(3Q)JQ",
	"(3QK)J",
	"(3Q)(JK)",
	"(3Q)JK",
	"3(JQ)Q",
	"(3K)(JQ)",
	"3(JQK)",
	"3(JQ)K",
	"3JQQ",
	"(3K)JQ",
	"3(JK)Q",
	"3J(QK)",
	"3JQK",
	"(3K)(JK)",
	"(3K)JK",
	"3(JK)K",
	"3JKK",
	"(3Q)QQ",
	"(3QK)Q",
	"(3Q)(QK)",
	"(3Q)QK",
	"(3QK)K",
	"(3Q)KK",
	"3QQQ",
	"(3K)QQ",
	"3(QK)Q",
	"3QQK",
	"(3K)(QK)",
	"(3K)QK",
	"3(QK)K",
	"3QKK",
	"(3K)KK",
	"3KKK",
	"4444",
	"(45)44",
	"4445",
	"(46)44",
	"4446",
	"(47)44",
	"4447",
	"(48)44",
	"4448",
	"(49)44",
	"4449",
	"(4T)44",
	"444T",
	"(4J)44",
	"444J",
	"(4Q)44",
	"444Q",
	"(4K)44",
	"444K",
	"(45)(45)",
	"(45)45",
	"(456)4",
	"(45)(46)",
	"(45)46",
	"(457)4",
	"(45)(47)",
	"(45)47",
	"(458)4",
	"(45)(48)",
	"(45)48",
	"(459)4",
	"(45)(49)",
	"(45)49",
	"(45T)4",
	"(45)(4T)",
	"(45)4T",
	"(45J)4",
	"(45)(4J)",
	"(45)4J",
	"(45Q)4",
	"(45)(4Q)",
	"(45)4Q",
	"(45K)4",
	"(45)(4K)",
	"(45)4K",
	"4455",
	"(46)45",
	"44(56)",
	"4456",
	"(47)45",
	"44(57)",
	"4457",
	"(48)45",
	"44(58)",
	"4458",
	"(49)45",
	"44(59)",
	"4459",
	"(4T)45",
	"44(5T)",
	"445T",
	"(4J)45",
	"44(5J)",
	"445J",
	"(4Q)45",
	"44(5Q)",
	"445Q",
	"(4K)45",
	"44(5K)",
	"445K",
	"(46)(46)",
	"(46)46",
	"(467)4",
	"(46)(47)",
	"(46)47",
	"(468)4",
	"(46)(48)",
	"(46)48",
	"(469)4",
	"(46)(49)",
	"(46)49",
	"(46T)4",
	"(46)(4T)",
	"(46)4T",
	"(46J)4",
	"(46)(4J)",
	"(46)4J",
	"(46Q)4",
	"(46)(4Q)",
	"(46)4Q",
	"(46K)4",
	"(46)(4K)",
	"(46)4K",
	"4466",
	"(47)46",
	"44(67)",
	"4467",
	"(48)46",
	"44(68)",
	"4468",
	"(49)46",
	"44(69)",
	"4469",
	"(4T)46",
	"44(6T)",
	"446T",
	"(4J)46",
	"44(6J)",
	"446J",
	"(4Q)46",
	"44(6Q)",
	"446Q",
	"(4K)46",
	"44(6K)",
	"446K",
	"(47)(47)",
	"(47)47",
	"(478)4",
	"(47)(48)",
	"(47)48",
	"(479)4",
	"(47)(49)",
	"(47)49",
	"(47T)4",
	"(47)(4T)",
	"(47)4T",
	"(47J)4",
	"(47)(4J)",
	"(47)4J",
	"(47Q)4",
	"(47)(4Q)",
	"(47)4Q",
	"(47K)4",
	"(47)(4K)",
	"(47)4K",
	"4477",
	"(48)47",
	"44(78)",
	"4478",
	"(49)47",
	"44(79)",
	"4479",
	"(4T)47",
	"44(7T)",
	"447T",
	"(4J)47",
	"44(7J)",
	"447J",
	"(4Q)47",
	"44(7Q)",
	"447Q",
	"(4K)47",
	"44(7K)",
	"447K",
	"(48)(48)",
	"(48)48",
	"(489)4",
	"(48)(49)",
	"(48)49",
	"(48T)4",
	"(48)(4T)",
	"(48)4T",
	"(48J)4",
	"(48)(4J)",
	"(48)4J",
	"(48Q)4",
	"(48)(4Q)",
	"(48)4Q",
	"(48K)4",
	"(48)(4K)",
	"(48)4K",
	"4488",
	"(49)48",
	"44(89)",
	"4489",
	"(4T)48",
	"44(8T)",
	"448T",
	"(4J)48",
	"44(8J)",
	"448J",
	"(4Q)48",
	"44(8Q)",
	"448Q",
	"(4K)48",
	"44(8K)",
	"448K",
	"(49)(49)",
	"(49)49",
	"(49T)4",
	"(49)(4T)",
	"(49)4T",
	"(49J)4",
	"(49)(4J)",
	"(49)4J",
	"(49Q)4",
	"(49)(4Q)",
	"(49)4Q",
	"(49K)4",
	"(49)(4K)",
	"(49)4K",
	"4499",
	"(4T)49",
	"44(9T)",
	"449T",
	"(4J)49",
	"44(9J)",
	"449J",
	"(4Q)49",
	"44(9Q)",
	"449Q",
	"(4K)49",
	"44(9K)",
	"449K",
	"(4T)(4T)",
	"(4T)4T",
	"(4TJ)4",
	"(4T)(4J)",
	"(4T)4J",
	"(4TQ)4",
	"(4T)(4Q)",
	"(4T)4Q",
	"(4TK)4",
	"(4T)(4K)",
	"(4T)4K",
	"44TT",
	"(4J)4T",
	"44(TJ)",
	"44TJ",
	"(4Q)4T",
	"44(TQ)",
	"44TQ",
	"(4K)4T",
	"44(TK)",
	"44TK",
	"(4J)(4J)",
	"(4J)4J",
	"(4JQ)4",
	"(4J)(4Q)",
	"(4J)4Q",
	"(4JK)4",
	"(4J)(4K)",
	"(4J)4K",
	"44JJ",
	"(4Q)4J",
	"44(JQ)",
	"44JQ",
	"(4K)4J",
	"44(JK)",
	"44JK",
	"(4Q)(4Q)",
	"(4Q)4Q",
	"(4QK)4",
	"(4Q)(4K)",
	"(4Q)4K",
	"44QQ",
	"(4K)4Q",
	"44(QK)",
	"44QK",
	"(4K)(4K)",
	"(4K)4K",
	"44KK",
	"(45)55",
	"(456)5",
	"(45)(56)",
	"(45)56",
	"(457)5",
	"(45)(57)",
	"(45)57",
	"(458)5",
	"(45)(58)",
	"(45)58",
	"(459)5",
	"(45)(59)",
	"(45)59",
	"(45T)5",
	"(45)(5T)",
	"(45)5T",
	"(45J)5",
	"(45)(5J)",
	"(45)5J",
	"(45Q)5",
	"(45)(5Q)",
	"(45)5Q",
	"(45K)5",
	"(45)(5K)",
	"(45)5K",
	"(456)6",
	"(4567)",
	"(456)7",
	"(4568)",
	"(456)8",
	"(4569)",
	"(456)9",
	"(456T)",
	"(456)T",
	"(456J)",
	"(456)J",
	"(456Q)",
	"(456)Q",
	"(456K)",
	"(456)K",
	"(45)66",
	"(457)6",
	"(45)(67)",
	"(45)67",
	"(458)6",
	"(45)(68)",
	"(45)68",
	"(459)6",
	"(45)(69)",
	"(45)69",
	"(45T)6",
	"(45)(6T)",
	"(45)6T",
	"(45J)6",
	"(45)(6J)",
	"(45)6J",
	"(45Q)6",
	"(45)(6Q)",
	"(45)6Q",
	"(45K)6",
	"(45)(6K)",
	"(45)6K",
	"(457)7",
	"(4578)",
	"(457)8",
	"(4579)",
	"(457)9",
	"(457T)",
	"(457)T",
	"(457J)",
	"(457)J",
	"(457Q)",
	"(457)Q",
	"(457K)",
	"(457)K",
	"(45)77",
	"(458)7",
	"(45)(78)",
	"(45)78",
	"(459)7",
	"(45)(79)",
	"(45)79",
	"(45T)7",
	"(45)(7T)",
	"(45)7T",
	"(45J)7",
	"(45)(7J)",
	"(45)7J",
	"(45Q)7",
	"(45)(7Q)",
	"(45)7Q",
	"(45K)7",
	"(45)(7K)",
	"(45)7K",
	"(458)8",
	"(4589)",
	"(458)9",
	"(458T)",
	"(458)T",
	"(458J)",
	"(458)J",
	"(458Q)",
	"(458)Q",
	"(458K)",
	"(458)K",
	"(45)88",
	"(459)8",
	"(45)(89)",
	"(45)89",
	"(45T)8",
	"(45)(8T)",
	"(45)8T",
	"(45J)8",
	"(45)(8J)",
	"(45)8J",
	"(45Q)8",
	"(45)(8Q)",
	"(45)8Q",
	"(45K)8",
	"(45)(8K)",
	"(45)8K",
	"(459)9",
	"(459T)",
	"(459)T",
	"(459J)",
	"(459)J",
	"(459Q)",
	"(459)Q",
	"(459K)",
	"(459)K",
	"(45)99",
	"(45T)9",
	"(45)(9T)",
	"(45)9T",
	"(45J)9",
	"(45)(9J)",
	"(45)9J",
	"(45Q)9",
	"(45)(9Q)",
	"(45)9Q",
	"(45K)9",
	"(45)(9K)",
	"(45)9K",
	"(45T)T",
	"(45TJ)",
	"(45T)J",
	"(45TQ)",
	"(45T)Q",
	"(45TK)",
	"(45T)K",
	"(45)TT",
	"(45J)T",
	"(45)(TJ)",
	"(45)TJ",
	"(45Q)T",
	"(45)(TQ)",
	"(45)TQ",
	"(45K)T",
	"(45)(TK)",
	"(45)TK",
	"(45J)J",
	"(45JQ)",
	"(45J)Q",
	"(45JK)",
	"(45J)K",
	"(45)JJ",
	"(45Q)J",
	"(45)(JQ)",
	"(45)JQ",
	"(45K)J",
	"(45)(JK)",
	"(45)JK",
	"(45Q)Q",
	"(45QK)",
	"(45Q)K",
	"(45)QQ",
	"(45K)Q",
	"(45)(QK)",
	"(45)QK",
	"(45K)K",
	"(45)KK",
	"4555",
	"(46)55",
	"4(56)5",
	"4556",
	"(47)55",
	"4(57)5",
	"4557",
	"(48)55",
	"4(58)5",
	"4558",
	"(49)55",
	"4(59)5",
	"4559",
	"(4T)55",
	"4(5T)5",
	"455T",
	"(4J)55",
	"4(5J)5",
	"455J",
	"(4Q)55",
	"4(5Q)5",
	"455Q",
	"(4K)55",
	"4(5K)5",
	"455K",
	"(46)(56)",
	"(46)56",
	"(467)5",
	"(46)(57)",
	"(46)57",
	"(468)5",
	"(46)(58)",
	"(46)58",
	"(469)5",
	"(46)(59)",
	"(46)59",
	"(46T)5",
	"(46)(5T)",
	"(46)5T",
	"(46J)5",
	"(46)(5J)",
	"(46)5J",
	"(46Q)5",
	"(46)(5Q)",
	"(46)5Q",
	"(46K)5",
	"(46)(5K)",
	"(46)5K",
	"4(56)6",
	"(47)(56)",
	"4(567)",
	"4(56)7",
	"(48)(56)",
	"4(568)",
	"4(56)8",
	"(49)(56)",
	"4(569)",
	"4(56)9",
	"(4T)(56)",
	"4(56T)",
	"4(56)T",
	"(4J)(56)",
	"4(56J)",
	"4(56)J",
	"(4Q)(56)",
	"4(56Q)",
	"4(56)Q",
	"(4K)(56)",
	"4(56K)",
	"4(56)K",
	"4566",
	"(47)56",
	"4(57)6",
	"45(67)",
	"4567",
	"(48)56",
	"4(58)6",
	"45(68)",
	"4568",
	"(49)56",
	"4(59)6",
	"45(69)",
	"4569",
	"(4T)56",
	"4(5T)6",
	"45(6T)",
	"456T",
	"(4J)56",
	"4(5J)6",
	"45(6J)",
	"456J",
	"(4Q)56",
	"4(5Q)6",
	"45(6Q)",
	"456Q",
	"(4K)56",
	"4(5K)6",
	"45(6K)",
	"456K",
	"(47)(57)",
	"(47)57",
	"(478)5",
	"(47)(58)",
	"(47)58",
	"(479)5",
	"(47)(59)",
	"(47)59",
	"(47T)5",
	"(47)(5T)",
	"(47)5T",
	"(47J)5",
	"(47)(5J)",
	"(47)5J",
	"(47Q)5",
	"(47)(5Q)",
	"(47)5Q",
	"(47K)5",
	"(47)(5K)",
	"(47)5K",
	"4(57)7",
	"(48)(57)",
	"4(578)",
	"4(57)8",
	"(49)(57)",
	"4(579)",
	"4(57)9",
	"(4T)(57)",
	"4(57T)",
	"4(57)T",
	"(4J)(57)",
	"4(57J)",
	"4(57)J",
	"(4Q)(57)",
	"4(57Q)",
	"4(57)Q",
	"(4K)(57)",
	"4(57K)",
	"4(57)K",
	"4577",
	"(48)57",
	"4(58)7",
	"45(78)",
	"4578",
	"(49)57",
	"4(59)7",
	"45(79)",
	"4579",
	"(4T)57",
	"4(5T)7",
	"45(7T)",
	"457T",
	"(4J)57",
	"4(5J)7",
	"45(7J)",
	"457J",
	"(4Q)57",
	"4(5Q)7",
	"45(7Q)",
	"457Q",
	"(4K)57",
	"4(5K)7",
	"45(7K)",
	"457K",
	"(48)(58)",
	"(48)58",
	"(489)5",
	"(48)(59)",
	"(48)59",
	"(48T)5",
	"(48)(5T)",
	"(48)5T",
	"(48J)5",
	"(48)(5J)",
	"(48)5J",
	"(48Q)5",
	"(48)(5Q)",
	"(48)5Q",
	"(48K)5",
	"(48)(5K)",
	"(48)5K",
	"4(58)8",
	"(49)(58)",
	"4(589)",
	"4(58)9",
	"(4T)(58)",
	"4(58T)",
	"4(58)T",
	"(4J)(58)",
	"4(58J)",
	"4(58)J",
	"(4Q)(58)",
	"4(58Q)",
	"4(58)Q",
	"(4K)(58)",
	"4(58K)",
	"4(58)K",
	"4588",
	"(49)58",
	"4(59)8",
	"45(89)",
	"4589",
	"(4T)58",
	"4(5T)8",
	"45(8T)",
	"458T",
	"(4J)58",
	"4(5J)8",
	"45(8J)",
	"458J",
	"(4Q)58",
	"4(5Q)8",
	"45(8Q)",
	"458Q",
	"(4K)58",
	"4(5K)8",
	"45(8K)",
	"458K",
	"(49)(59)",
	"(49)59",
	"(49T)5",
	"(49)(5T)",
	"(49)5T",
	"(49J)5",
	"(49)(5J)",
	"(49)5J",
	"(49Q)5",
	"(49)(5Q)",
	"(49)5Q",
	"(49K)5",
	"(49)(5K)",
	"(49)5K",
	"4(59)9",
	"(4T)(59)",
	"4(59T)",
	"4(59)T",
	"(4J)(59)",
	"4(59J)",
	"4(59)J",
	"(4Q)(59)",
	"4(59Q)",
	"4(59)Q",
	"(4K)(59)",
	"4(59K)",
	"4(59)K",
	"4599",
	"(4T)59",
	"4(5T)9",
	"45(9T)",
	"459T",
	"(4J)59",
	"4(5J)9",
	"45(9J)",
	"459J",
	"(4Q)59",
	"4(5Q)9",
	"45(9Q)",
	"459Q",
	"(4K)59",
	"4(5K)9",
	"45(9K)",
	"459K",
	"(4T)(5T)",
	"(4T)5T",
	"(4TJ)5",
	"(4T)(5J)",
	"(4T)5J",
	"(4TQ)5",
	"(4T)(5Q)",
	"(4T)5Q",
	"(4TK)5",
	"(4T)(5K)",
	"(4T)5K",
	"4(5T)T",
	"(4J)(5T)",
	"4(5TJ)",
	"4(5T)J",
	"(4Q)(5T)",
	"4(5TQ)",
	"4(5T)Q",
	"(4K)(5T)",
	"4(5TK)",
	"4(5T)K",
	"45TT",
	"(4J)5T",
	"4(5J)T",
	"45(TJ)",
	"45TJ",
	"(4Q)5T",
	"4(5Q)T",
	"45(TQ)",
	"45TQ",
	"(4K)5T",
	"4(5K)T",
	"45(TK)",
	"45TK",
	"(4J)(5J)",
	"(4J)5J",
	"(4JQ)5",
	"(4J)(5Q)",
	"(4J)5Q",
	"(4JK)5",
	"(4J)(5K)",
	"(4J)5K",
	"4(5J)J",
	"(4Q)(5J)",
	"4(5JQ)",
	"4(5J)Q",
	"(4K)(5J)",
	"4(5JK)",
	"4(5J)K",
	"45JJ",
	"(4Q)5J",
	"4(5Q)J",
	"45(JQ)",
	"45JQ",
	"(4K)5J",
	"4(5K)J",
	"45(JK)",
	"45JK",
	"(4Q)(5Q)",
	"(4Q)5Q",
	"(4QK)5",
	"(4Q)(5K)",
	"(4Q)5K",
	"4(5Q)Q",
	"(4K)(5Q)",
	"4(5QK)",
	"4(5Q)K",
	"45QQ",
	"(4K)5Q",
	"4(5K)Q",
	"45(QK)",
	"45QK",
	"(4K)(5K)",
	"(4K)5K",
	"4(5K)K",
	"45KK",
	"(46)66",
	"(467)6",
	"(46)(67)",
	"(46)67",
	"(468)6",
	"(46)(68)",
	"(46)68",
	"(469)6",
	"(46)(69)",
	"(46)69",
	"(46T)6",
	"(46)(6T)",
	"(46)6T",
	"(46J)6",
	"(46)(6J)",
	"(46)6J",
	"(46Q)6",
	"(46)(6Q)",
	"(46)6Q",
	"(46K)6",
	"(46)(6K)",
	"(46)6K",
	"(467)7",
	"(4678)",
	"(467)8",
	"(4679)",
	"(467)9",
	"(467T)",
	"(467)T",
	"(467J)",
	"(467)J",
	"(467Q)",
	"(467)Q",
	"(467K)",
	"(467)K",
	"(46)77",
	"(468)7",
	"(46)(78)",
	"(46)78",
	"(469)7",
	"(46)(79)",
	"(46)79",
	"(46T)7",
	"(46)(7T)",
	"(46)7T",
	"(46J)7",
	"(46)(7J)",
	"(46)7J",
	"(46Q)7",
	"(46)(7Q)",
	"(46)7Q",
	"(46K)7",
	"(46)(7K)",
	"(46)7K",
	"(468)8",
	"(4689)",
	"(468)9",
	"(468T)",
	"(468)T",
	"(468J)",
	"(468)J",
	"(468Q)",
	"(468)Q",
	"(468K)",
	"(468)K",
	"(46)88",
	"(469)8",
	"(46)(89)",
	"(46)89",
	"(46T)8",
	"(46)(8T)",
	"(46)8T",
	"(46J)8",
	"(46)(8J)",
	"(46)8J",
	"(46Q)8",
	"(46)(8Q)",
	"(46)8Q",
	"(46K)8",
	"(46)(8K)",
	"(46)8K",
	"(469)9",
	"(469T)",
	"(469)T",
	"(469J)",
	"(469)J",
	"(469Q)",
	"(469)Q",
	"(469K)",
	"(469)K",
	"(46)99",
	"(46T)9",
	"(46)(9T)",
	"(46)9T",
	"(46J)9",
	"(46)(9J)",
	"(46)9J",
	"(46Q)9",
	"(46)(9Q)",
	"(46)9Q",
	"(46K)9",
	"(46)(9K)",
	"(46)9K",
	"(46T)T",
	"(46TJ)",
	"(46T)J",
	"(46TQ)",
	"(46T)Q",
	"(46TK)",
	"(46T)K",
	"(46)TT",
	"(46J)T",
	"(46)(TJ)",
	"(46)TJ",
	"(46Q)T",
	"(46)(TQ)",
	"(46)TQ",
	"(46K)T",
	"(46)(TK)",
	"(46)TK",
	"(46J)J",
	"(46JQ)",
	"(46J)Q",
	"(46JK)",
	"(46J)K",
	"(46)JJ",
	"(46Q)J",
	"(46)(JQ)",
	"(46)JQ",
	"(46K)J",
	"(46)(JK)",
	"(46)JK",
	"(46Q)Q",
	"(46QK)",
	"(46Q)K",
	"(46)QQ",
	"(46K)Q",
	"(46)(QK)",
	"(46)QK",
	"(46K)K",
	"(46)KK",
	"4666",
	"(47)66",
	"4(67)6",
	"4667",
	"(48)66",
	"4(68)6",
	"4668",
	"(49)66",
	"4(69)6",
	"4669",
	"(4T)66",
	"4(6T)6",
	"466T",
	"(4J)66",
	"4(6J)6",
	"466J",
	"(4Q)66",
	"4(6Q)6",
	"466Q",
	"(4K)66",
	"4(6K)6",
	"466K",
	"(47)(67)",
	"(47)67",
	"(478)6",
	"(47)(68)",
	"(47)68",
	"(479)6",
	"(47)(69)",
	"(47)69",
	"(47T)6",
	"(47)(6T)",
	"(47)6T",
	"(47J)6",
	"(47)(6J)",
	"(47)6J",
	"(47Q)6",
	"(47)(6Q)",
	"(47)6Q",
	"(47K)6",
	"(47)(6K)",
	"(47)6K",
	"4(67)7",
	"(48)(67)",
	"4(678)",
	"4(67)8",
	"(49)(67)",
	"4(679)",
	"4(67)9",
	"(4T)(67)",
	"4(67T)",
	"4(67)T",
	"(4J)(67)",
	"4(67J)",
	"4(67)J",
	"(4Q)(67)",
	"4(67Q)",
	"4(67)Q",
	"(4K)(67)",
	"4(67K)",
	"4(67)K",
	"4677",
	"(48)67",
	"4(68)7",
	"46(78)",
	"4678",
	"(49)67",
	"4(69)7",
	"46(79)",
	"4679",
	"(4T)67",
	"4(6T)7",
	"46(7T)",
	"467T",
	"(4J)67",
	"4(6J)7",
	"46(7J)",
	"467J",
	"(4Q)67",
	"4(6Q)7",
	"46(7Q)",
	"467Q",
	"(4K)67",
	"4(6K)7",
	"46(7K)",
	"467K",
	"(48)(68)",
	"(48)68",
	"(489)6",
	"(48)(69)",
	"(48)69",
	"(48T)6",
	"(48)(6T)",
	"(48)6T",
	"(48J)6",
	"(48)(6J)",
	"(48)6J",
	"(48Q)6",
	"(48)(6Q)",
	"(48)6Q",
	"(48K)6",
	"(48)(6K)",
	"(48)6K",
	"4(68)8",
	"(49)(68)",
	"4(689)",
	"4(68)9",
	"(4T)(68)",
	"4(68T)",
	"4(68)T",
	"(4J)(68)",
	"4(68J)",
	"4(68)J",
	"(4Q)(68)",
	"4(68Q)",
	"4(68)Q",
	"(4K)(68)",
	"4(68K)",
	"4(68)K",
	"4688",
	"(49)68",
	"4(69)8",
	"46(89)",
	"4689",
	"(4T)68",
	"4(6T)8",
	"46(8T)",
	"468T",
	"(4J)68",
	"4(6J)8",
	"46(8J)",
	"468J",
	"(4Q)68",
	"4(6Q)8",
	"46(8Q)",
	"468Q",
	"(4K)68",
	"4(6K)8",
	"46(8K)",
	"468K",
	"(49)(69)",
	"(49)69",
	"(49T)6",
	"(49)(6T)",
	"(49)6T",
	"(49J)6",
	"(49)(6J)",
	"(49)6J",
	"(49Q)6",
	"(49)(6Q)",
	"(49)6Q",
	"(49K)6",
	"(49)(6K)",
	"(49)6K",
	"4(69)9",
	"(4T)(69)",
	"4(69T)",
	"4(69)T",
	"(4J)(69)",
	"4(69J)",
	"4(69)J",
	"(4Q)(69)",
	"4(69Q)",
	"4(69)Q",
	"(4K)(69)",
	"4(69K)",
	"4(69)K",
	"4699",
	"(4T)69",
	"4(6T)9",
	"46(9T)",
	"469T",
	"(4J)69",
	"4(6J)9",
	"46(9J)",
	"469J",
	"(4Q)69",
	"4(6Q)9",
	"46(9Q)",
	"469Q",
	"(4K)69",
	"4(6K)9",
	"46(9K)",
	"469K",
	"(4T)(6T)",
	"(4T)6T",
	"(4TJ)6",
	"(4T)(6J)",
	"(4T)6J",
	"(4TQ)6",
	"(4T)(6Q)",
	"(4T)6Q",
	"(4TK)6",
	"(4T)(6K)",
	"(4T)6K",
	"4(6T)T",
	"(4J)(6T)",
	"4(6TJ)",
	"4(6T)J",
	"(4Q)(6T)",
	"4(6TQ)",
	"4(6T)Q",
	"(4K)(6T)",
	"4(6TK)",
	"4(6T)K",
	"46TT",
	"(4J)6T",
	"4(6J)T",
	"46(TJ)",
	"46TJ",
	"(4Q)6T",
	"4(6Q)T",
	"46(TQ)",
	"46TQ",
	"(4K)6T",
	"4(6K)T",
	"46(TK)",
	"46TK",
	"(4J)(6J)",
	"(4J)6J",
	"(4JQ)6",
	"(4J)(6Q)",
	"(4J)6Q",
	"(4JK)6",
	"(4J)(6K)",
	"(4J)6K",
	"4(6J)J",
	"(4Q)(6J)",
	"4(6JQ)",
	"4(6J)Q",
	"(4K)(6J)",
	"4(6JK)",
	"4(6J)K",
	"46JJ",
	"(4Q)6J",
	"4(6Q)J",
	"46(JQ)",
	"46JQ",
	"(4K)6J",
	"4(6K)J",
	"46(JK)",
	"46JK",
	"(4Q)(6Q)",
	"(4Q)6Q",
	"(4QK)6",
	"(4Q)(6K)",
	"(4Q)6K",
	"4(6Q)Q",
	"(4K)(6Q)",
	"4(6QK)",
	"4(6Q)K",
	"46QQ",
	"(4K)6Q",
	"4(6K)Q",
	"46(QK)",
	"46QK",
	"(4K)(6K)",
	"(4K)6K",
	"4(6K)K",
	"46KK",
	"(47)77",
	"(478)7",
	"(47)(78)",
	"(47)78",
	"(479)7",
	"(47)(79)",
	"(47)79",
	"(47T)7",
	"(47)(7T)",
	"(47)7T",
	"(47J)7",
	"(47)(7J)",
	"(47)7J",
	"(47Q)7",
	"(47)(7Q)",
	"(47)7Q",
	"(47K)7",
	"(47)(7K)",
	"(47)7K",
	"(478)8",
	"(4789)",
	"(478)9",
	"(478T)",
	"(478)T",
	"(478J)",
	"(478)J",
	"(478Q)",
	"(478)Q",
	"(478K)",
	"(478)K",
	"(47)88",
	"(479)8",
	"(47)(89)",
	"(47)89",
	"(47T)8",
	"(47)(8T)",
	"(47)8T",
	"(47J)8",
	"(47)(8J)",
	"(47)8J",
	"(47Q)8",
	"(47)(8Q)",
	"(47)8Q",
	"(47K)8",
	"(47)(8K)",
	"(47)8K",
	"(479)9",
	"(479T)",
	"(479)T",
	"(479J)",
	"(479)J",
	"(479Q)",
	"(479)Q",
	"(479K)",
	"(479)K",
	"(47)99",
	"(47T)9",
	"(47)(9T)",
	"(47)9T",
	"(47J)9",
	"(47)(9J)",
	"(47)9J",
	"(47Q)9",
	"(47)(9Q)",
	"(47)9Q",
	"(47K)9",
	"(47)(9K)",
	"(47)9K",
	"(47T)T",
	"(47TJ)",
	"(47T)J",
	"(47TQ)",
	"(47T)Q",
	"(47TK)",
	"(47T)K",
	"(47)TT",
	"(47J)T",
	"(47)(TJ)",
	"(47)TJ",
	"(47Q)T",
	"(47)(TQ)",
	"(47)TQ",
	"(47K)T",
	"(47)(TK)",
	"(47)TK",
	"(47J)J",
	"(47JQ)",
	"(47J)Q",
	"(47JK)",
	"(47J)K",
	"(47)JJ",
	"(47Q)J",
	"(47)(JQ)",
	"(47)JQ",
	"(47K)J",
	"(47)(JK)",
	"(47)JK",
	"(47Q)Q",
	"(47QK)",
	"(47Q)K",
	"(47)QQ",
	"(47K)Q",
	"(47)(QK)",
	"(47)QK",
	"(47K)K",
	"(47)KK",
	"4777",
	"(48)77",
	"4(78)7",
	"4778",
	"(49)77",
	"4(79)7",
	"4779",
	"(4T)77",
	"4(7T)7",
	"477T",
	"(4J)77",
	"4(7J)7",
	"477J",
	"(4Q)77",
	"4(7Q)7",
	"477Q",
	"(4K)77",
	"4(7K)7",
	"477K",
	"(48)(78)",
	"(48)78",
	"(489)7",
	"(48)(79)",
	"(48)79",
	"(48T)7",
	"(48)(7T)",
	"(48)7T",
	"(48J)7",
	"(48)(7J)",
	"(48)7J",
	"(48Q)7",
	"(48)(7Q)",
	"(48)7Q",
	"(48K)7",
	"(48)(7K)",
	"(48)7K",
	"4(78)8",
	"(49)(78)",
	"4(789)",
	"4(78)9",
	"(4T)(78)",
	"4(78T)",
	"4(78)T",
	"(4J)(78)",
	"4(78J)",
	"4(78)J",
	"(4Q)(78)",
	"4(78Q)",
	"4(78)Q",
	"(4K)(78)",
	"4(78K)",
	"4(78)K",
	"4788",
	"(49)78",
	"4(79)8",
	"47(89)",
	"4789",
	"(4T)78",
	"4(7T)8",
	"47(8T)",
	"478T",
	"(4J)78",
	"4(7J)8",
	"47(8J)",
	"478J",
	"(4Q)78",
	"4(7Q)8",
	"47(8Q)",
	"478Q",
	"(4K)78",
	"4(7K)8",
	"47(8K)",
	"478K",
	"(49)(79)",
	"(49)79",
	"(49T)7",
	"(49)(7T)",
	"(49)7T",
	"(49J)7",
	"(49)(7J)",
	"(49)7J",
	"(49Q)7",
	"(49)(7Q)",
	"(49)7Q",
	"(49K)7",
	"(49)(7K)",
	"(49)7K",
	"4(79)9",
	"(4T)(79)",
	"4(79T)",
	"4(79)T",
	"(4J)(79)",
	"4(79J)",
	"4(79)J",
	"(4Q)(79)",
	"4(79Q)",
	"4(79)Q",
	"(4K)(79)",
	"4(79K)",
	"4(79)K",
	"4799",
	"(4T)79",
	"4(7T)9",
	"47(9T)",
	"479T",
	"(4J)79",
	"4(7J)9",
	"47(9J)",
	"479J",
	"(4Q)79",
	"4(7Q)9",
	"47(9Q)",
	"479Q",
	"(4K)79",
	"4(7K)9",
	"47(9K)",
	"479K",
	"(4T)(7T)",
	"(4T)7T",
	"(4TJ)7",
	"(4T)(7J)",
	"(4T)7J",
	"(4TQ)7",
	"(4T)(7Q)",
	"(4T)7Q",
	"(4TK)7",
	"(4T)(7K)",
	"(4T)7K",
	"4(7T)T",
	"(4J)(7T)",
	"4(7TJ)",
	"4(7T)J",
	"(4Q)(7T)",
	"4(7TQ)",
	"4(7T)Q",
	"(4K)(7T)",
	"4(7TK)",
	"4(7T)K",
	"47TT",
	"(4J)7T",
	"4(7J)T",
	"47(TJ)",
	"47TJ",
	"(4Q)7T",
	"4(7Q)T",
	"47(TQ)",
	"47TQ",
	"(4K)7T",
	"4(7K)T",
	"47(TK)",
	"47TK",
	"(4J)(7J)",
	"(4J)7J",
	"(4JQ)7",
	"(4J)(7Q)",
	"(4J)7Q",
	"(4JK)7",
	"(4J)(7K)",
	"(4J)7K",
	"4(7J)J",
	"(4Q)(7J)",
	"4(7JQ)",
	"4(7J)Q",
	"(4K)(7J)",
	"4(7JK)",
	"4(7J)K",
	"47JJ",
	"(4Q)7J",
	"4(7Q)J",
	"47(JQ)",
	"47JQ",
	"(4K)7J",
	"4(7K)J",
	"47(JK)",
	"47JK",
	"(4Q)(7Q)",
	"(4Q)7Q",
	"(4QK)7",
	"(4Q)(7K)",
	"(4Q)7K",
	"4(7Q)Q",
	"(4K)(7Q)",
	"4(7QK)",
	"4(7Q)K",
	"47QQ",
	"(4K)7Q",
	"4(7K)Q",
	"47(QK)",
	"47QK",
	"(4K)(7K)",
	"(4K)7K",
	"4(7K)K",
	"47KK",
	"(48)88",
	"(489)8",
	"(48)(89)",
	"(48)89",
	"(48T)8",
	"(48)(8T)",
	"(48)8T",
	"(48J)8",
	"(48)(8J)",
	"(48)8J",
	"(48Q)8",
	"(48)(8Q)",
	"(48)8Q",
	"(48K)8",
	"(48)(8K)",
	"(48)8K",
	"(489)9",
	"(489T)",
	"(489)T",
	"(489J)",
	"(489)J",
	"(489Q)",
	"(489)Q",
	"(489K)",
	"(489)K",
	"(48)99",
	"(48T)9",
	"(48)(9T)",
	"(48)9T",
	"(48J)9",
	"(48)(9J)",
	"(48)9J",
	"(48Q)9",
	"(48)(9Q)",
	"(48)9Q",
	"(48K)9",
	"(48)(9K)",
	"(48)9K",
	"(48T)T",
	"(48TJ)",
	"(48T)J",
	"(48TQ)",
	"(48T)Q",
	"(48TK)",
	"(48T)K",
	"(48)TT",
	"(48J)T",
	"(48)(TJ)",
	"(48)TJ",
	"(48Q)T",
	"(48)(TQ)",
	"(48)TQ",
	"(48K)T",
	"(48)(TK)",
	"(48)TK",
	"(48J)J",
	"(48JQ)",
	"(48J)Q",
	"(48JK)",
	"(48J)K",
	"(48)JJ",
	"(48Q)J",
	"(48)(JQ)",
	"(48)JQ",
	"(48K)J",
	"(48)(JK)",
	"(48)JK",
	"(48Q)Q",
	"(48QK)",
	"(48Q)K",
	"(48)QQ",
	"(48K)Q",
	"(48)(QK)",
	"(48)QK",
	"(48K)K",
	"(48)KK",
	"4888",
	"(49)88",
	"4(89)8",
	"4889",
	"(4T)88",
	"4(8T)8",
	"488T",
	"(4J)88",
	"4(8J)8",
	"488J",
	"(4Q)88",
	"4(8Q)8",
	"488Q",
	"(4K)88",
	"4(8K)8",
	"488K",
	"(49)(89)",
	"(49)89",
	"(49T)8",
	"(49)(8T)",
	"(49)8T",
	"(49J)8",
	"(49)(8J)",
	"(49)8J",
	"(49Q)8",
	"(49)(8Q)",
	"(49)8Q",
	"(49K)8",
	"(49)(8K)",
	"(49)8K",
	"4(89)9",
	"(4T)(89)",
	"4(89T)",
	"4(89)T",
	"(4J)(89)",
	"4(89J)",
	"4(89)J",
	"(4Q)(89)",
	"4(89Q)",
	"4(89)Q",
	"(4K)(89)",
	"4(89K)",
	"4(89)K",
	"4899",
	"(4T)89",
	"4(8T)9",
	"48(9T)",
	"489T",
	"(4J)89",
	"4(8J)9",
	"48(9J)",
	"489J",
	"(4Q)89",
	"4(8Q)9",
	"48(9Q)",
	"489Q",
	"(4K)89",
	"4(8K)9",
	"48(9K)",
	"489K",
	"(4T)(8T)",
	"(4T)8T",
	"(4TJ)8",
	"(4T)(8J)",
	"(4T)8J",
	"(4TQ)8",
	"(4T)(8Q)",
	"(4T)8Q",
	"(4TK)8",
	"(4T)(8K)",
	"(4T)8K",
	"4(8T)T",
	"(4J)(8T)",
	"4(8TJ)",
	"4(8T)J",
	"(4Q)(8T)",
	"4(8TQ)",
	"4(8T)Q",
	"(4K)(8T)",
	"4(8TK)",
	"4(8T)K",
	"48TT",
	"(4J)8T",
	"4(8J)T",
	"48(TJ)",
	"48TJ",
	"(4Q)8T",
	"4(8Q)T",
	"48(TQ)",
	"48TQ",
	"(4K)8T",
	"4(8K)T",
	"48(TK)",
	"48TK",
	"(4J)(8J)",
	"(4J)8J",
	"(4JQ)8",
	"(4J)(8Q)",
	"(4J)8Q",
	"(4JK)8",
	"(4J)(8K)",
	"(4J)8K",
	"4(8J)J",
	"(4Q)(8J)",
	"4(8JQ)",
	"4(8J)Q",
	"(4K)(8J)",
	"4(8JK)",
	"4(8J)K",
	"48JJ",
	"(4Q)8J",
	"4(8Q)J",
	"48(JQ)",
	"48JQ",
	"(4K)8J",
	"4(8K)J",
	"48(JK)",
	"48JK",
	"(4Q)(8Q)",
	"(4Q)8Q",
	"(4QK)8",
	"(4Q)(8K)",
	"(4Q)8K",
	"4(8Q)Q",
	"(4K)(8Q)",
	"4(8QK)",
	"4(8Q)K",
	"48QQ",
	"(4K)8Q",
	"4(8K)Q",
	"48(QK)",
	"48QK",
	"(4K)(8K)",
	"(4K)8K",
	"4(8K)K",
	"48KK",
	"(49)99",
	"(49T)9",
	"(49)(9T)",
	"(49)9T",
	"(49J)9",
	"(49)(9J)",
	"(49)9J",
	"(49Q)9",
	"(49)(9Q)",
	"(49)9Q",
	"(49K)9",
	"(49)(9K)",
	"(49)9K",
	"(49T)T",
	"(49TJ)",
	"(49T)J",
	"(49TQ)",
	"(49T)Q",
	"(49TK)",
	"(49T)K",
	"(49)TT",
	"(49J)T",
	"(49)(TJ)",
	"(49)TJ",
	"(49Q)T",
	"(49)(TQ)",
	"(49)TQ",
	"(49K)T",
	"(49)(TK)",
	"(49)TK",
	"(49J)J",
	"(49JQ)",
	"(49J)Q",
	"(49JK)",
	"(49J)K",
	"(49)JJ",
	"(49Q)J",
	"(49)(JQ)",
	"(49)JQ",
	"(49K)J",
	"(49)(JK)",
	"(49)JK",
	"(49Q)Q",
	"(49QK)",
	"(49Q)K",
	"(49)QQ",
	"(49K)Q",
	"(49)(QK)",
	"(49)QK",
	"(49K)K",
	"(49)KK",
	"4999",
	"(4T)99",
	"4(9T)9",
	"499T",
	"(4J)99",
	"4(9J)9",
	"499J",
	"(4Q)99",
	"4(9Q)9",
	"499Q",
	"(4K)99",
	"4(9K)9",
	"499K",
	"(4T)(9T)",
	"(4T)9T",
	"(4TJ)9",
	"(4T)(9J)",
	"(4T)9J",
	"(4TQ)9",
	"(4T)(9Q)",
	"(4T)9Q",
	"(4TK)9",
	"(4T)(9K)",
	"(4T)9K",
	"4(9T)T",
	"(4J)(9T)",
	"4(9TJ)",
	"4(9T)J",
	"(4Q)(9T)",
	"4(9TQ)",
	"4(9T)Q",
	"(4K)(9T)",
	"4(9TK)",
	"4(9T)K",
	"49TT",
	"(4J)9T",
	"4(9J)T",
	"49(TJ)",
	"49TJ",
	"(4Q)9T",
	"4(9Q)T",
	"49(TQ)",
	"49TQ",
	"(4K)9T",
	"4(9K)T",
	"49(TK)",
	"49TK",
	"(4J)(9J)",
	"(4J)9J",
	"(4JQ)9",
	"(4J)(9Q)",
	"(4J)9Q",
	"(4JK)9",
	"(4J)(9K)",
	"(4J)9K",
	"4(9J)J",
	"(4Q)(9J)",
	"4(9JQ)",
	"4(9J)Q",
	"(4K)(9J)",
	"4(9JK)",
	"4(9J)K",
	"49JJ",
	"(4Q)9J",
	"4(9Q)J",
	"49(JQ)",
	"49JQ",
	"(4K)9J",
	"4(9K)J",
	"49(JK)",
	"49JK",
	"(4Q)(9Q)",
	"(4Q)9Q",
	"(4QK)9",
	"(4Q)(9K)",
	"(4Q)9K",
	"4(9Q)Q",
	"(4K)(9Q)",
	"4(9QK)",
	"4(9Q)K",
	"49QQ",
	"(4K)9Q",
	"4(9K)Q",
	"49(QK)",
	"49QK",
	"(4K)(9K)",
	"(4K)9K",
	"4(9K)K",
	"49KK",
	"(4T)TT",
	"(4TJ)T",
	"(4T)(TJ)",
	"(4T)TJ",
	"(4TQ)T",
	"(4T)(TQ)",
	"(4T)TQ",
	"(4TK)T",
	"(4T)(TK)",
	"(4T)TK",
	"(4TJ)J",
	"(4TJQ)",
	"(4TJ)Q",
	"(4TJK)",
	"(4TJ)K",
	"(4T)JJ",
	"(4TQ)J",
	"(4T)(JQ)",
	"(4T)JQ",
	"(4TK)J",
	"(4T)(JK)",
	"(4T)JK",
	"(4TQ)Q",
	"(4TQK)",
	"(4TQ)K",
	"(4T)QQ",
	"(4TK)Q",
	"(4T)(QK)",
	"(4T)QK",
	"(4TK)K",
	"(4T)KK",
	"4TTT",
	"(4J)TT",
	"4(TJ)T",
	"4TTJ",
	"(4Q)TT",
	"4(TQ)T",
	"4TTQ",
	"(4K)TT",
	"4(TK)T",
	"4TTK",
	"(4J)(TJ)",
	"(4J)TJ",
	"(4JQ)T",
	"(4J)(TQ)",
	"(4J)TQ",
	"(4JK)T",
	"(4J)(TK)",
	"(4J)TK",
	"4(TJ)J",
	"(4Q)(TJ)",
	"4(TJQ)",
	"4(TJ)Q",
	"(4K)(TJ)",
	"4(TJK)",
	"4(TJ)K",
	"4TJJ",
	"(4Q)TJ",
	"4(TQ)J",
	"4T(JQ)",
	"4TJQ",
	"(4K)TJ",
	"4(TK)J",
	"4T(JK)",
	"4TJK",
	"(4Q)(TQ)",
	"(4Q)TQ",
	"(4QK)T",
	"(4Q)(TK)",
	"(4Q)TK",
	"4(TQ)Q",
	"(4K)(TQ)",
	"4(TQK)",
	"4(TQ)K",
	"4TQQ",
	"(4K)TQ",
	"4(TK)Q",
	"4T(QK)",
	"4TQK",
	"(4K)(TK)",
	"(4K)TK",
	"4(TK)K",
	"4TKK",
	"(4J)JJ",
	"(4JQ)J",
	"(4J)(JQ)",
	"(4J)JQ",
	"(4JK)J",
	"(4J)(JK)",
	"(4J)JK",
	"(4JQ)Q",
	"(4JQK)",
	"(4JQ)K",
	"(4J)QQ",
	"(4JK)Q",
	"(4J)(QK)",
	"(4J)QK",
	"(4JK)K",
	"(4J)KK",
	"4JJJ",
	"(4Q)JJ",
	"4(JQ)J",
	"4JJQ",
	"(4K)JJ",
	"4(JK)J",
	"4JJK",
	"(4Q)(JQ)",
	"(4Q)JQ",
	"(4QK)J",
	"(4Q)(JK)",
	"(4Q)JK",
	"4(JQ)Q",
	"(4K)(JQ)",
	"4(JQK)",
	"4(JQ)K",
	"4JQQ",
	"(4K)JQ",
	"4(JK)Q",
	"4J(QK)",
	"4JQK",
	"(4K)(JK)",
	"(4K)JK",
	"4(JK)K",
	"4JKK",
	"(4Q)QQ",
	"(4QK)Q",
	"(4Q)(QK)",
	"(4Q)QK",
	"(4QK)K",
	"(4Q)KK",
	"4QQQ",
	"(4K)QQ",
	"4(QK)Q",
	"4QQK",
	"(4K)(QK)",
	"(4K)QK",
	"4(QK)K",
	"4QKK",
	"(4K)KK",
	"4KKK",
	"5555",
	"(56)55",
	"5556",
	"(57)55",
	"5557",
	"(58)55",
	"5558",
	"(59)55",
	"5559",
	"(5T)55",
	"555T",
	"(5J)55",
	"555J",
	"(5Q)55",
	"555Q",
	"(5K)55",
	"555K",
	"(56)(56)",
	"(56)56",
	"(567)5",
	"(56)(57)",
	"(56)57",
	"(568)5",
	"(56)(58)",
	"(56)58",
	"(569)5",
	"(56)(59)",
	"(56)59",
	"(56T)5",
	"(56)(5T)",
	"(56)5T",
	"(56J)5",
	"(56)(5J)",
	"(56)5J",
	"(56Q)5",
	"(56)(5Q)",
	"(56)5Q",
	"(56K)5",
	"(56)(5K)",
	"(56)5K",
	"5566",
	"(57)56",
	"55(67)",
	"5567",
	"(58)56",
	"55(68)",
	"5568",
	"(59)56",
	"55(69)",
	"5569",
	"(5T)56",
	"55(6T)",
	"556T",
	"(5J)56",
	"55(6J)",
	"556J",
	"(5Q)56",
	"55(6Q)",
	"556Q",
	"(5K)56",
	"55(6K)",
	"556K",
	"(57)(57)",
	"(57)57",
	"(578)5",
	"(57)(58)",
	"(57)58",
	"(579)5",
	"(57)(59)",
	"(57)59",
	"(57T)5",
	"(57)(5T)",
	"(57)5T",
	"(57J)5",
	"(57)(5J)",
	"(57)5J",
	"(57Q)5",
	"(57)(5Q)",
	"(57)5Q",
	"(57K)5",
	"(57)(5K)",
	"(57)5K",
	"5577",
	"(58)57",
	"55(78)",
	"5578",
	"(59)57",
	"55(79)",
	"5579",
	"(5T)57",
	"55(7T)",
	"557T",
	"(5J)57",
	"55(7J)",
	"557J",
	"(5Q)57",
	"55(7Q)",
	"557Q",
	"(5K)57",
	"55(7K)",
	"557K",
	"(58)(58)",
	"(58)58",
	"(589)5",
	"(58)(59)",
	"(58)59",
	"(58T)5",
	"(58)(5T)",
	"(58)5T",
	"(58J)5",
	"(58)(5J)",
	"(58)5J",
	"(58Q)5",
	"(58)(5Q)",
	"(58)5Q",
	"(58K)5",
	"(58)(5K)",
	"(58)5K",
	"5588",
	"(59)58",
	"55(89)",
	"5589",
	"(5T)58",
	"55(8T)",
	"558T",
	"(5J)58",
	"55(8J)",
	"558J",
	"(5Q)58",
	"55(8Q)",
	"558Q",
	"(5K)58",
	"55(8K)",
	"558K",
	"(59)(59)",
	"(59)59",
	"(59T)5",
	"(59)(5T)",
	"(59)5T",
	"(59J)5",
	"(59)(5J)",
	"(59)5J",
	"(59Q)5",
	"(59)(5Q)",
	"(59)5Q",
	"(59K)5",
	"(59)(5K)",
	"(59)5K",
	"5599",
	"(5T)59",
	"55(9T)",
	"559T",
	"(5J)59",
	"55(9J)",
	"559J",
	"(5Q)59",
	"55(9Q)",
	"559Q",
	"(5K)59",
	"55(9K)",
	"559K",
	"(5T)(5T)",
	"(5T)5T",
	"(5TJ)5",
	"(5T)(5J)",
	"(5T)5J",
	"(5TQ)5",
	"(5T)(5Q)",
	"(5T)5Q",
	"(5TK)5",
	"(5T)(5K)",
	"(5T)5K",
	"55TT",
	"(5J)5T",
	"55(TJ)",
	"55TJ",
	"(5Q)5T",
	"55(TQ)",
	"55TQ",
	"(5K)5T",
	"55(TK)",
	"55TK",
	"(5J)(5J)",
	"(5J)5J",
	"(5JQ)5",
	"(5J)(5Q)",
	"(5J)5Q",
	"(5JK)5",
	"(5J)(5K)",
	"(5J)5K",
	"55JJ",
	"(5Q)5J",
	"55(JQ)",
	"55JQ",
	"(5K)5J",
	"55(JK)",
	"55JK",
	"(5Q)(5Q)",
	"(5Q)5Q",
	"(5QK)5",
	"(5Q)(5K)",
	"(5Q)5K",
	"55QQ",
	"(5K)5Q",
	"55(QK)",
	"55QK",
	"(5K)(5K)",
	"(5K)5K",
	"55KK",
	"(56)66",
	"(567)6",
	"(56)(67)",
	"(56)67",
	"(568)6",
	"(56)(68)",
	"(56)68",
	"(569)6",
	"(56)(69)",
	"(56)69",
	"(56T)6",
	"(56)(6T)",
	"(56)6T",
	"(56J)6",
	"(56)(6J)",
	"(56)6J",
	"(56Q)6",
	"(56)(6Q)",
	"(56)6Q",
	"(56K)6",
	"(56)(6K)",
	"(56)6K",
	"(567)7",
	"(5678)",
	"(567)8",
	"(5679)",
	"(567)9",
	"(567T)",
	"(567)T",
	"(567J)",
	"(567)J",
	"(567Q)",
	"(567)Q",
	"(567K)",
	"(567)K",
	"(56)77",
	"(568)7",
	"(56)(78)",
	"(56)78",
	"(569)7",
	"(56)(79)",
	"(56)79",
	"(56T)7",
	"(56)(7T)",
	"(56)7T",
	"(56J)7",
	"(56)(7J)",
	"(56)7J",
	"(56Q)7",
	"(56)(7Q)",
	"(56)7Q",
	"(56K)7",
	"(56)(7K)",
	"(56)7K",
	"(568)8",
	"(5689)",
	"(568)9",
	"(568T)",
	"(568)T",
	"(568J)",
	"(568)J",
	"(568Q)",
	"(568)Q",
	"(568K)",
	"(568)K",
	"(56)88",
	"(569)8",
	"(56)(89)",
	"(56)89",
	"(56T)8",
	"(56)(8T)",
	"(56)8T",
	"(56J)8",
	"(56)(8J)",
	"(56)8J",
	"(56Q)8",
	"(56)(8Q)",
	"(56)8Q",
	"(56K)8",
	"(56)(8K)",
	"(56)8K",
	"(569)9",
	"(569T)",
	"(569)T",
	"(569J)",
	"(569)J",
	"(569Q)",
	"(569)Q",
	"(569K)",
	"(569)K",
	"(56)99",
	"(56T)9",
	"(56)(9T)",
	"(56)9T",
	"(56J)9",
	"(56)(9J)",
	"(56)9J",
	"(56Q)9",
	"(56)(9Q)",
	"(56)9Q",
	"(56K)9",
	"(56)(9K)",
	"(56)9K",
	"(56T)T",
	"(56TJ)",
	"(56T)J",
	"(56TQ)",
	"(56T)Q",
	"(56TK)",
	"(56T)K",
	"(56)TT",
	"(56J)T",
	"(56)(TJ)",
	"(56)TJ",
	"(56Q)T",
	"(56)(TQ)",
	"(56)TQ",
	"(56K)T",
	"(56)(TK)",
	"(56)TK",
	"(56J)J",
	"(56JQ)",
	"(56J)Q",
	"(56JK)",
	"(56J)K",
	"(56)JJ",
	"(56Q)J",
	"(56)(JQ)",
	"(56)JQ",
	"(56K)J",
	"(56)(JK)",
	"(56)JK",
	"(56Q)Q",
	"(56QK)",
	"(56Q)K",
	"(56)QQ",
	"(56K)Q",
	"(56)(QK)",
	"(56)QK",
	"(56K)K",
	"(56)KK",
	"5666",
	"(57)66",
	"5(67)6",
	"5667",
	"(58)66",
	"5(68)6",
	"5668",
	"(59)66",
	"5(69)6",
	"5669",
	"(5T)66",
	"5(6T)6",
	"566T",
	"(5J)66",
	"5(6J)6",
	"566J",
	"(5Q)66",
	"5(6Q)6",
	"566Q",
	"(5K)66",
	"5(6K)6",
	"566K",
	"(57)(67)",
	"(57)67",
	"(578)6",
	"(57)(68)",
	"(57)68",
	"(579)6",
	"(57)(69)",
	"(57)69",
	"(57T)6",
	"(57)(6T)",
	"(57)6T",
	"(57J)6",
	"(57)(6J)",
	"(57)6J",
	"(57Q)6",
	"(57)(6Q)",
	"(57)6Q",
	"(57K)6",
	"(57)(6K)",
	"(57)6K",
	"5(67)7",
	"(58)(67)",
	"5(678)",
	"5(67)8",
	"(59)(67)",
	"5(679)",
	"5(67)9",
	"(5T)(67)",
	"5(67T)",
	"5(67)T",
	"(5J)(67)",
	"5(67J)",
	"5(67)J",
	"(5Q)(67)",
	"5(67Q)",
	"5(67)Q",
	"(5K)(67)",
	"5(67K)",
	"5(67)K",
	"5677",
	"(58)67",
	"5(68)7",
	"56(78)",
	"5678",
	"(59)67",
	"5(69)7",
	"56(79)",
	"5679",
	"(5T)67",
	"5(6T)7",
	"56(7T)",
	"567T",
	"(5J)67",
	"5(6J)7",
	"56(7J)",
	"567J",
	"(5Q)67",
	"5(6Q)7",
	"56(7Q)",
	"567Q",
	"(5K)67",
	"5(6K)7",
	"56(7K)",
	"567K",
	"(58)(68)",
	"(58)68",
	"(589)6",
	"(58)(69)",
	"(58)69",
	"(58T)6",
	"(58)(6T)",
	"(58)6T",
	"(58J)6",
	"(58)(6J)",
	"(58)6J",
	"(58Q)6",
	"(58)(6Q)",
	"(58)6Q",
	"(58K)6",
	"(58)(6K)",
	"(58)6K",
	"5(68)8",
	"(59)(68)",
	"5(689)",
	"5(68)9",
	"(5T)(68)",
	"5(68T)",
	"5(68)T",
	"(5J)(68)",
	"5(68J)",
	"5(68)J",
	"(5Q)(68)",
	"5(68Q)",
	"5(68)Q",
	"(5K)(68)",
	"5(68K)",
	"5(68)K",
	"5688",
	"(59)68",
	"5(69)8",
	"56(89)",
	"5689",
	"(5T)68",
	"5(6T)8",
	"56(8T)",
	"568T",
	"(5J)68",
	"5(6J)8",
	"56(8J)",
	"568J",
	"(5Q)68",
	"5(6Q)8",
	"56(8Q)",
	"568Q",
	"(5K)68",
	"5(6K)8",
	"56(8K)",
	"568K",
	"(59)(69)",
	"(59)69",
	"(59T)6",
	"(59)(6T)",
	"(59)6T",
	"(59J)6",
	"(59)(6J)",
	"(59)6J",
	"(59Q)6",
	"(59)(6Q)",
	"(59)6Q",
	"(59K)6",
	"(59)(6K)",
	"(59)6K",
	"5(69)9",
	"(5T)(69)",
	"5(69T)",
	"5(69)T",
	"(5J)(69)",
	"5(69J)",
	"5(69)J",
	"(5Q)(69)",
	"5(69Q)",
	"5(69)Q",
	"(5K)(69)",
	"5(69K)",
	"5(69)K",
	"5699",
	"(5T)69",
	"5(6T)9",
	"56(9T)",
	"569T",
	"(5J)69",
	"5(6J)9",
	"56(9J)",
	"569J",
	"(5Q)69",
	"5(6Q)9",
	"56(9Q)",
	"569Q",
	"(5K)69",
	"5(6K)9",
	"56(9K)",
	"569K",
	"(5T)(6T)",
	"(5T)6T",
	"(5TJ)6",
	"(5T)(6J)",
	"(5T)6J",
	"(5TQ)6",
	"(5T)(6Q)",
	"(5T)6Q",
	"(5TK)6",
	"(5T)(6K)",
	"(5T)6K",
	"5(6T)T",
	"(5J)(6T)",
	"5(6TJ)",
	"5(6T)J",
	"(5Q)(6T)",
	"5(6TQ)",
	"5(6T)Q",
	"(5K)(6T)",
	"5(6TK)",
	"5(6T)K",
	"56TT",
	"(5J)6T",
	"5(6J)T",
	"56(TJ)",
	"56TJ",
	"(5Q)6T",
	"5(6Q)T",
	"56(TQ)",
	"56TQ",
	"(5K)6T",
	"5(6K)T",
	"56(TK)",
	"56TK",
	"(5J)(6J)",
	"(5J)6J",
	"(5JQ)6",
	"(5J)(6Q)",
	"(5J)6Q",
	"(5JK)6",
	"(5J)(6K)",
	"(5J)6K",
	"5(6J)J",
	"(5Q)(6J)",
	"5(6JQ)",
	"5(6J)Q",
	"(5K)(6J)",
	"5(6JK)",
	"5(6J)K",
	"56JJ",
	"(5Q)6J",
	"5(6Q)J",
	"56(JQ)",
	"56JQ",
	"(5K)6J",
	"5(6K)J",
	"56(JK)",
	"56JK",
	"(5Q)(6Q)",
	"(5Q)6Q",
	"(5QK)6",
	"(5Q)(6K)",
	"(5Q)6K",
	"5(6Q)Q",
	"(5K)(6Q)",
	"5(6QK)",
	"5(6Q)K",
	"56QQ",
	"(5K)6Q",
	"5(6K)Q",
	"56(QK)",
	"56QK",
	"(5K)(6K)",
	"(5K)6K",
	"5(6K)K",
	"56KK",
	"(57)77",
	"(578)7",
	"(57)(78)",
	"(57)78",
	"(579)7",
	"(57)(79)",
	"(57)79",
	"(57T)7",
	"(57)(7T)",
	"(57)7T",
	"(57J)7",
	"(57)(7J)",
	"(57)7J",
	"(57Q)7",
	"(57)(7Q)",
	"(57)7Q",
	"(57K)7",
	"(57)(7K)",
	"(57)7K",
	"(578)8",
	"(5789)",
	"(578)9",
	"(578T)",
	"(578)T",
	"(578J)",
	"(578)J",
	"(578Q)",
	"(578)Q",
	"(578K)",
	"(578)K",
	"(57)88",
	"(579)8",
	"(57)(89)",
	"(57)89",
	"(57T)8",
	"(57)(8T)",
	"(57)8T",
	"(57J)8",
	"(57)(8J)",
	"(57)8J",
	"(57Q)8",
	"(57)(8Q)",
	"(57)8Q",
	"(57K)8",
	"(57)(8K)",
	"(57)8K",
	"(579)9",
	"(579T)",
	"(579)T",
	"(579J)",
	"(579)J",
	"(579Q)",
	"(579)Q",
	"(579K)",
	"(579)K",
	"(57)99",
	"(57T)9",
	"(57)(9T)",
	"(57)9T",
	"(57J)9",
	"(57)(9J)",
	"(57)9J",
	"(57Q)9",
	"(57)(9Q)",
	"(57)9Q",
	"(57K)9",
	"(57)(9K)",
	"(57)9K",
	"(57T)T",
	"(57TJ)",
	"(57T)J",
	"(57TQ)",
	"(57T)Q",
	"(57TK)",
	"(57T)K",
	"(57)TT",
	"(57J)T",
	"(57)(TJ)",
	"(57)TJ",
	"(57Q)T",
	"(57)(TQ)",
	"(57)TQ",
	"(57K)T",
	"(57)(TK)",
	"(57)TK",
	"(57J)J",
	"(57JQ)",
	"(57J)Q",
	"(57JK)",
	"(57J)K",
	"(57)JJ",
	"(57Q)J",
	"(57)(JQ)",
	"(57)JQ",
	"(57K)J",
	"(57)(JK)",
	"(57)JK",
	"(57Q)Q",
	"(57QK)",
	"(57Q)K",
	"(57)QQ",
	"(57K)Q",
	"(57)(QK)",
	"(57)QK",
	"(57K)K",
	"(57)KK",
	"5777",
	"(58)77",
	"5(78)7",
	"5778",
	"(59)77",
	"5(79)7",
	"5779",
	"(5T)77",
	"5(7T)7",
	"577T",
	"(5J)77",
	"5(7J)7",
	"577J",
	"(5Q)77",
	"5(7Q)7",
	"577Q",
	"(5K)77",
	"5(7K)7",
	"577K",
	"(58)(78)",
	"(58)78",
	"(589)7",
	"(58)(79)",
	"(58)79",
	"(58T)7",
	"(58)(7T)",
	"(58)7T",
	"(58J)7",
	"(58)(7J)",
	"(58)7J",
	"(58Q)7",
	"(58)(7Q)",
	"(58)7Q",
	"(58K)7",
	"(58)(7K)",
	"(58)7K",
	"5(78)8",
	"(59)(78)",
	"5(789)",
	"5(78)9",
	"(5T)(78)",
	"5(78T)",
	"5(78)T",
	"(5J)(78)",
	"5(78J)",
	"5(78)J",
	"(5Q)(78)",
	"5(78Q)",
	"5(78)Q",
	"(5K)(78)",
	"5(78K)",
	"5(78)K",
	"5788",
	"(59)78",
	"5(79)8",
	"57(89)",
	"5789",
	"(5T)78",
	"5(7T)8",
	"57(8T)",
	"578T",
	"(5J)78",
	"5(7J)8",
	"57(8J)",
	"578J",
	"(5Q)78",
	"5(7Q)8",
	"57(8Q)",
	"578Q",
	"(5K)78",
	"5(7K)8",
	"57(8K)",
	"578K",
	"(59)(79)",
	"(59)79",
	"(59T)7",
	"(59)(7T)",
	"(59)7T",
	"(59J)7",
	"(59)(7J)",
	"(59)7J",
	"(59Q)7",
	"(59)(7Q)",
	"(59)7Q",
	"(59K)7",
	"(59)(7K)",
	"(59)7K",
	"5(79)9",
	"(5T)(79)",
	"5(79T)",
	"5(79)T",
	"(5J)(79)",
	"5(79J)",
	"5(79)J",
	"(5Q)(79)",
	"5(79Q)",
	"5(79)Q",
	"(5K)(79)",
	"5(79K)",
	"5(79)K",
	"5799",
	"(5T)79",
	"5(7T)9",
	"57(9T)",
	"579T",
	"(5J)79",
	"5(7J)9",
	"57(9J)",
	"579J",
	"(5Q)79",
	"5(7Q)9",
	"57(9Q)",
	"579Q",
	"(5K)79",
	"5(7K)9",
	"57(9K)",
	"579K",
	"(5T)(7T)",
	"(5T)7T",
	"(5TJ)7",
	"(5T)(7J)",
	"(5T)7J",
	"(5TQ)7",
	"(5T)(7Q)",
	"(5T)7Q",
	"(5TK)7",
	"(5T)(7K)",
	"(5T)7K",
	"5(7T)T",
	"(5J)(7T)",
	"5(7TJ)",
	"5(7T)J",
	"(5Q)(7T)",
	"5(7TQ)",
	"5(7T)Q",
	"(5K)(7T)",
	"5(7TK)",
	"5(7T)K",
	"57TT",
	"(5J)7T",
	"5(7J)T",
	"57(TJ)",
	"57TJ",
	"(5Q)7T",
	"5(7Q)T",
	"57(TQ)",
	"57TQ",
	"(5K)7T",
	"5(7K)T",
	"57(TK)",
	"57TK",
	"(5J)(7J)",
	"(5J)7J",
	"(5JQ)7",
	"(5J)(7Q)",
	"(5J)7Q",
	"(5JK)7",
	"(5J)(7K)",
	"(5J)7K",
	"5(7J)J",
	"(5Q)(7J)",
	"5(7JQ)",
	"5(7J)Q",
	"(5K)(7J)",
	"5(7JK)",
	"5(7J)K",
	"57JJ",
	"(5Q)7J",
	"5(7Q)J",
	"57(JQ)",
	"57JQ",
	"(5K)7J",
	"5(7K)J",
	"57(JK)",
	"57JK",
	"(5Q)(7Q)",
	"(5Q)7Q",
	"(5QK)7",
	"(5Q)(7K)",
	"(5Q)7K",
	"5(7Q)Q",
	"(5K)(7Q)",
	"5(7QK)",
	"5(7Q)K",
	"57QQ",
	"(5K)7Q",
	"5(7K)Q",
	"57(QK)",
	"57QK",
	"(5K)(7K)",
	"(5K)7K",
	"5(7K)K",
	"57KK",
	"(58)88",
	"(589)8",
	"(58)(89)",
	"(58)89",
	"(58T)8",
	"(58)(8T)",
	"(58)8T",
	"(58J)8",
	"(58)(8J)",
	"(58)8J",
	"(58Q)8",
	"(58)(8Q)",
	"(58)8Q",
	"(58K)8",
	"(58)(8K)",
	"(58)8K",
	"(589)9",
	"(589T)",
	"(589)T",
	"(589J)",
	"(589)J",
	"(589Q)",
	"(589)Q",
	"(589K)",
	"(589)K",
	"(58)99",
	"(58T)9",
	"(58)(9T)",
	"(58)9T",
	"(58J)9",
	"(58)(9J)",
	"(58)9J",
	"(58Q)9",
	"(58)(9Q)",
	"(58)9Q",
	"(58K)9",
	"(58)(9K)",
	"(58)9K",
	"(58T)T",
	"(58TJ)",
	"(58T)J",
	"(58TQ)",
	"(58T)Q",
	"(58TK)",
	"(58T)K",
	"(58)TT",
	"(58J)T",
	"(58)(TJ)",
	"(58)TJ",
	"(58Q)T",
	"(58)(TQ)",
	"(58)TQ",
	"(58K)T",
	"(58)(TK)",
	"(58)TK",
	"(58J)J",
	"(58JQ)",
	"(58J)Q",
	"(58JK)",
	"(58J)K",
	"(58)JJ",
	"(58Q)J",
	"(58)(JQ)",
	"(58)JQ",
	"(58K)J",
	"(58)(JK)",
	"(58)JK",
	"(58Q)Q",
	"(58QK)",
	"(58Q)K",
	"(58)QQ",
	"(58K)Q",
	"(58)(QK)",
	"(58)QK",
	"(58K)K",
	"(58)KK",
	"5888",
	"(59)88",
	"5(89)8",
	"5889",
	"(5T)88",
	"5(8T)8",
	"588T",
	"(5J)88",
	"5(8J)8",
	"588J",
	"(5Q)88",
	"5(8Q)8",
	"588Q",
	"(5K)88",
	"5(8K)8",
	"588K",
	"(59)(89)",
	"(59)89",
	"(59T)8",
	"(59)(8T)",
	"(59)8T",
	"(59J)8",
	"(59)(8J)",
	"(59)8J",
	"(59Q)8",
	"(59)(8Q)",
	"(59)8Q",
	"(59K)8",
	"(59)(8K)",
	"(59)8K",
	"5(89)9",
	"(5T)(89)",
	"5(89T)",
	"5(89)T",
	"(5J)(89)",
	"5(89J)",
	"5(89)J",
	"(5Q)(89)",
	"5(89Q)",
	"5(89)Q",
	"(5K)(89)",
	"5(89K)",
	"5(89)K",
	"5899",
	"(5T)89",
	"5(8T)9",
	"58(9T)",
	"589T",
	"(5J)89",
	"5(8J)9",
	"58(9J)",
	"589J",
	"(5Q)89",
	"5(8Q)9",
	"58(9Q)",
	"589Q",
	"(5K)89",
	"5(8K)9",
	"58(9K)",
	"589K",
	"(5T)(8T)",
	"(5T)8T",
	"(5TJ)8",
	"(5T)(8J)",
	"(5T)8J",
	"(5TQ)8",
	"(5T)(8Q)",
	"(5T)8Q",
	"(5TK)8",
	"(5T)(8K)",
	"(5T)8K",
	"5(8T)T",
	"(5J)(8T)",
	"5(8TJ)",
	"5(8T)J",
	"(5Q)(8T)",
	"5(8TQ)",
	"5(8T)Q",
	"(5K)(8T)",
	"5(8TK)",
	"5(8T)K",
	"58TT",
	"(5J)8T",
	"5(8J)T",
	"58(TJ)",
	"58TJ",
	"(5Q)8T",
	"5(8Q)T",
	"58(TQ)",
	"58TQ",
	"(5K)8T",
	"5(8K)T",
	"58(TK)",
	"58TK",
	"(5J)(8J)",
	"(5J)8J",
	"(5JQ)8",
	"(5J)(8Q)",
	"(5J)8Q",
	"(5JK)8",
	"(5J)(8K)",
	"(5J)8K",
	"5(8J)J",
	"(5Q)(8J)",
	"5(8JQ)",
	"5(8J)Q",
	"(5K)(8J)",
	"5(8JK)",
	"5(8J)K",
	"58JJ",
	"(5Q)8J",
	"5(8Q)J",
	"58(JQ)",
	"58JQ",
	"(5K)8J",
	"5(8K)J",
	"58(JK)",
	"58JK",
	"(5Q)(8Q)",
	"(5Q)8Q",
	"(5QK)8",
	"(5Q)(8K)",
	"(5Q)8K",
	"5(8Q)Q",
	"(5K)(8Q)",
	"5(8QK)",
	"5(8Q)K",
	"58QQ",
	"(5K)8Q",
	"5(8K)Q",
	"58(QK)",
	"58QK",
	"(5K)(8K)",
	"(5K)8K",
	"5(8K)K",
	"58KK",
	"(59)99",
	"(59T)9",
	"(59)(9T)",
	"(59)9T",
	"(59J)9",
	"(59)(9J)",
	"(59)9J",
	"(59Q)9",
	"(59)(9Q)",
	"(59)9Q",
	"(59K)9",
	"(59)(9K)",
	"(59)9K",
	"(59T)T",
	"(59TJ)",
	"(59T)J",
	"(59TQ)",
	"(59T)Q",
	"(59TK)",
	"(59T)K",
	"(59)TT",
	"(59J)T",
	"(59)(TJ)",
	"(59)TJ",
	"(59Q)T",
	"(59)(TQ)",
	"(59)TQ",
	"(59K)T",
	"(59)(TK)",
	"(59)TK",
	"(59J)J",
	"(59JQ)",
	"(59J)Q",
	"(59JK)",
	"(59J)K",
	"(59)JJ",
	"(59Q)J",
	"(59)(JQ)",
	"(59)JQ",
	"(59K)J",
	"(59)(JK)",
	"(59)JK",
	"(59Q)Q",
	"(59QK)",
	"(59Q)K",
	"(59)QQ",
	"(59K)Q",
	"(59)(QK)",
	"(59)QK",
	"(59K)K",
	"(59)KK",
	"5999",
	"(5T)99",
	"5(9T)9",
	"599T",
	"(5J)99",
	"5(9J)9",
	"599J",
	"(5Q)99",
	"5(9Q)9",
	"599Q",
	"(5K)99",
	"5(9K)9",
	"599K",
	"(5T)(9T)",
	"(5T)9T",
	"(5TJ)9",
	"(5T)(9J)",
	"(5T)9J",
	"(5TQ)9",
	"(5T)(9Q)",
	"(5T)9Q",
	"(5TK)9",
	"(5T)(9K)",
	"(5T)9K",
	"5(9T)T",
	"(5J)(9T)",
	"5(9TJ)",
	"5(9T)J",
	"(5Q)(9T)",
	"5(9TQ)",
	"5(9T)Q",
	"(5K)(9T)",
	"5(9TK)",
	"5(9T)K",
	"59TT",
	"(5J)9T",
	"5(9J)T",
	"59(TJ)",
	"59TJ",
	"(5Q)9T",
	"5(9Q)T",
	"59(TQ)",
	"59TQ",
	"(5K)9T",
	"5(9K)T",
	"59(TK)",
	"59TK",
	"(5J)(9J)",
	"(5J)9J",
	"(5JQ)9",
	"(5J)(9Q)",
	"(5J)9Q",
	"(5JK)9",
	"(5J)(9K)",
	"(5J)9K",
	"5(9J)J",
	"(5Q)(9J)",
	"5(9JQ)",
	"5(9J)Q",
	"(5K)(9J)",
	"5(9JK)",
	"5(9J)K",
	"59JJ",
	"(5Q)9J",
	"5(9Q)J",
	"59(JQ)",
	"59JQ",
	"(5K)9J",
	"5(9K)J",
	"59(JK)",
	"59JK",
	"(5Q)(9Q)",
	"(5Q)9Q",
	"(5QK)9",
	"(5Q)(9K)",
	"(5Q)9K",
	"5(9Q)Q",
	"(5K)(9Q)",
	"5(9QK)",
	"5(9Q)K",
	"59QQ",
	"(5K)9Q",
	"5(9K)Q",
	"59(QK)",
	"59QK",
	"(5K)(9K)",
	"(5K)9K",
	"5(9K)K",
	"59KK",
	"(5T)TT",
	"(5TJ)T",
	"(5T)(TJ)",
	"(5T)TJ",
	"(5TQ)T",
	"(5T)(TQ)",
	"(5T)TQ",
	"(5TK)T",
	"(5T)(TK)",
	"(5T)TK",
	"(5TJ)J",
	"(5TJQ)",
	"(5TJ)Q",
	"(5TJK)",
	"(5TJ)K",
	"(5T)JJ",
	"(5TQ)J",
	"(5T)(JQ)",
	"(5T)JQ",
	"(5TK)J",
	"(5T)(JK)",
	"(5T)JK",
	"(5TQ)Q",
	"(5TQK)",
	"(5TQ)K",
	"(5T)QQ",
	"(5TK)Q",
	"(5T)(QK)",
	"(5T)QK",
	"(5TK)K",
	"(5T)KK",
	"5TTT",
	"(5J)TT",
	"5(TJ)T",
	"5TTJ",
	"(5Q)TT",
	"5(TQ)T",
	"5TTQ",
	"(5K)TT",
	"5(TK)T",
	"5TTK",
	"(5J)(TJ)",
	"(5J)TJ",
	"(5JQ)T",
	"(5J)(TQ)",
	"(5J)TQ",
	"(5JK)T",
	"(5J)(TK)",
	"(5J)TK",
	"5(TJ)J",
	"(5Q)(TJ)",
	"5(TJQ)",
	"5(TJ)Q",
	"(5K)(TJ)",
	"5(TJK)",
	"5(TJ)K",
	"5TJJ",
	"(5Q)TJ",
	"5(TQ)J",
	"5T(JQ)",
	"5TJQ",
	"(5K)TJ",
	"5(TK)J",
	"5T(JK)",
	"5TJK",
	"(5Q)(TQ)",
	"(5Q)TQ",
	"(5QK)T",
	"(5Q)(TK)",
	"(5Q)TK",
	"5(TQ)Q",
	"(5K)(TQ)",
	"5(TQK)",
	"5(TQ)K",
	"5TQQ",
	"(5K)TQ",
	"5(TK)Q",
	"5T(QK)",
	"5TQK",
	"(5K)(TK)",
	"(5K)TK",
	"5(TK)K",
	"5TKK",
	"(5J)JJ",
	"(5JQ)J",
	"(5J)(JQ)",
	"(5J)JQ",
	"(5JK)J",
	"(5J)(JK)",
	"(5J)JK",
	"(5JQ)Q",
	"(5JQK)",
	"(5JQ)K",
	"(5J)QQ",
	"(5JK)Q",
	"(5J)(QK)",
	"(5J)QK",
	"(5JK)K",
	"(5J)KK",
	"5JJJ",
	"(5Q)JJ",
	"5(JQ)J",
	"5JJQ",
	"(5K)JJ",
	"5(JK)J",
	"5JJK",
	"(5Q)(JQ)",
	"(5Q)JQ",
	"(5QK)J",
	"(5Q)(JK)",
	"(5Q)JK",
	"5(JQ)Q",
	"(5K)(JQ)",
	"5(JQK)",
	"5(JQ)K",
	"5JQQ",
	"(5K)JQ",
	"5(JK)Q",
	"5J(QK)",
	"5JQK",
	"(5K)(JK)",
	"(5K)JK",
	"5(JK)K",
	"5JKK",
	"(5Q)QQ",
	"(5QK)Q",
	"(5Q)(QK)",
	"(5Q)QK",
	"(5QK)K",
	"(5Q)KK",
	"5QQQ",
	"(5K)QQ",
	"5(QK)Q",
	"5QQK",
	"(5K)(QK)",
	"(5K)QK",
	"5(QK)K",
	"5QKK",
	"(5K)KK",
	"5KKK",
	"6666",
	"(67)66",
	"6667",
	"(68)66",
	"6668",
	"(69)66",
	"6669",
	"(6T)66",
	"666T",
	"(6J)66",
	"666J",
	"(6Q)66",
	"666Q",
	"(6K)66",
	"666K",
	"(67)(67)",
	"(67)67",
	"(678)6",
	"(67)(68)",
	"(67)68",
	"(679)6",
	"(67)(69)",
	"(67)69",
	"(67T)6",
	"(67)(6T)",
	"(67)6T",
	"(67J)6",
	"(67)(6J)",
	"(67)6J",
	"(67Q)6",
	"(67)(6Q)",
	"(67)6Q",
	"(67K)6",
	"(67)(6K)",
	"(67)6K",
	"6677",
	"(68)67",
	"66(78)",
	"6678",
	"(69)67",
	"66(79)",
	"6679",
	"(6T)67",
	"66(7T)",
	"667T",
	"(6J)67",
	"66(7J)",
	"667J",
	"(6Q)67",
	"66(7Q)",
	"667Q",
	"(6K)67",
	"66(7K)",
	"667K",
	"(68)(68)",
	"(68)68",
	"(689)6",
	"(68)(69)",
	"(68)69",
	"(68T)6",
	"(68)(6T)",
	"(68)6T",
	"(68J)6",
	"(68)(6J)",
	"(68)6J",
	"(68Q)6",
	"(68)(6Q)",
	"(68)6Q",
	"(68K)6",
	"(68)(6K)",
	"(68)6K",
	"6688",
	"(69)68",
	"66(89)",
	"6689",
	"(6T)68",
	"66(8T)",
	"668T",
	"(6J)68",
	"66(8J)",
	"668J",
	"(6Q)68",
	"66(8Q)",
	"668Q",
	"(6K)68",
	"66(8K)",
	"668K",
	"(69)(69)",
	"(69)69",
	"(69T)6",
	"(69)(6T)",
	"(69)6T",
	"(69J)6",
	"(69)(6J)",
	"(69)6J",
	"(69Q)6",
	"(69)(6Q)",
	"(69)6Q",
	"(69K)6",
	"(69)(6K)",
	"(69)6K",
	"6699",
	"(6T)69",
	"66(9T)",
	"669T",
	"(6J)69",
	"66(9J)",
	"669J",
	"(6Q)69",
	"66(9Q)",
	"669Q",
	"(6K)69",
	"66(9K)",
	"669K",
	"(6T)(6T)",
	"(6T)6T",
	"(6TJ)6",
	"(6T)(6J)",
	"(6T)6J",
	"(6TQ)6",
	"(6T)(6Q)",
	"(6T)6Q",
	"(6TK)6",
	"(6T)(6K)",
	"(6T)6K",
	"66TT",
	"(6J)6T",
	"66(TJ)",
	"66TJ",
	"(6Q)6T",
	"66(TQ)",
	"66TQ",
	"(6K)6T",
	"66(TK)",
	"66TK",
	"(6J)(6J)",
	"(6J)6J",
	"(6JQ)6",
	"(6J)(6Q)",
	"(6J)6Q",
	"(6JK)6",
	"(6J)(6K)",
	"(6J)6K",
	"66JJ",
	"(6Q)6J",
	"66(JQ)",
	"66JQ",
	"(6K)6J",
	"66(JK)",
	"66JK",
	"(6Q)(6Q)",
	"(6Q)6Q",
	"(6QK)6",
	"(6Q)(6K)",
	"(6Q)6K",
	"66QQ",
	"(6K)6Q",
	"66(QK)",
	"66QK",
	"(6K)(6K)",
	"(6K)6K",
	"66KK",
	"(67)77",
	"(678)7",
	"(67)(78)",
	"(67)78",
	"(679)7",
	"(67)(79)",
	"(67)79",
	"(67T)7",
	"(67)(7T)",
	"(67)7T",
	"(67J)7",
	"(67)(7J)",
	"(67)7J",
	"(67Q)7",
	"(67)(7Q)",
	"(67)7Q",
	"(67K)7",
	"(67)(7K)",
	"(67)7K",
	"(678)8",
	"(6789)",
	"(678)9",
	"(678T)",
	"(678)T",
	"(678J)",
	"(678)J",
	"(678Q)",
	"(678)Q",
	"(678K)",
	"(678)K",
	"(67)88",
	"(679)8",
	"(67)(89)",
	"(67)89",
	"(67T)8",
	"(67)(8T)",
	"(67)8T",
	"(67J)8",
	"(67)(8J)",
	"(67)8J",
	"(67Q)8",
	"(67)(8Q)",
	"(67)8Q",
	"(67K)8",
	"(67)(8K)",
	"(67)8K",
	"(679)9",
	"(679T)",
	"(679)T",
	"(679J)",
	"(679)J",
	"(679Q)",
	"(679)Q",
	"(679K)",
	"(679)K",
	"(67)99",
	"(67T)9",
	"(67)(9T)",
	"(67)9T",
	"(67J)9",
	"(67)(9J)",
	"(67)9J",
	"(67Q)9",
	"(67)(9Q)",
	"(67)9Q",
	"(67K)9",
	"(67)(9K)",
	"(67)9K",
	"(67T)T",
	"(67TJ)",
	"(67T)J",
	"(67TQ)",
	"(67T)Q",
	"(67TK)",
	"(67T)K",
	"(67)TT",
	"(67J)T",
	"(67)(TJ)",
	"(67)TJ",
	"(67Q)T",
	"(67)(TQ)",
	"(67)TQ",
	"(67K)T",
	"(67)(TK)",
	"(67)TK",
	"(67J)J",
	"(67JQ)",
	"(67J)Q",
	"(67JK)",
	"(67J)K",
	"(67)JJ",
	"(67Q)J",
	"(67)(JQ)",
	"(67)JQ",
	"(67K)J",
	"(67)(JK)",
	"(67)JK",
	"(67Q)Q",
	"(67QK)",
	"(67Q)K",
	"(67)QQ",
	"(67K)Q",
	"(67)(QK)",
	"(67)QK",
	"(67K)K",
	"(67)KK",
	"6777",
	"(68)77",
	"6(78)7",
	"6778",
	"(69)77",
	"6(79)7",
	"6779",
	"(6T)77",
	"6(7T)7",
	"677T",
	"(6J)77",
	"6(7J)7",
	"677J",
	"(6Q)77",
	"6(7Q)7",
	"677Q",
	"(6K)77",
	"6(7K)7",
	"677K",
	"(68)(78)",
	"(68)78",
	"(689)7",
	"(68)(79)",
	"(68)79",
	"(68T)7",
	"(68)(7T)",
	"(68)7T",
	"(68J)7",
	"(68)(7J)",
	"(68)7J",
	"(68Q)7",
	"(68)(7Q)",
	"(68)7Q",
	"(68K)7",
	"(68)(7K)",
	"(68)7K",
	"6(78)8",
	"(69)(78)",
	"6(789)",
	"6(78)9",
	"(6T)(78)",
	"6(78T)",
	"6(78)T",
	"(6J)(78)",
	"6(78J)",
	"6(78)J",
	"(6Q)(78)",
	"6(78Q)",
	"6(78)Q",
	"(6K)(78)",
	"6(78K)",
	"6(78)K",
	"6788",
	"(69)78",
	"6(79)8",
	"67(89)",
	"6789",
	"(6T)78",
	"6(7T)8",
	"67(8T)",
	"678T",
	"(6J)78",
	"6(7J)8",
	"67(8J)",
	"678J",
	"(6Q)78",
	"6(7Q)8",
	"67(8Q)",
	"678Q",
	"(6K)78",
	"6(7K)8",
	"67(8K)",
	"678K",
	"(69)(79)",
	"(69)79",
	"(69T)7",
	"(69)(7T)",
	"(69)7T",
	"(69J)7",
	"(69)(7J)",
	"(69)7J",
	"(69Q)7",
	"(69)(7Q)",
	"(69)7Q",
	"(69K)7",
	"(69)(7K)",
	"(69)7K",
	"6(79)9",
	"(6T)(79)",
	"6(79T)",
	"6(79)T",
	"(6J)(79)",
	"6(79J)",
	"6(79)J",
	"(6Q)(79)",
	"6(79Q)",
	"6(79)Q",
	"(6K)(79)",
	"6(79K)",
	"6(79)K",
	"6799",
	"(6T)79",
	"6(7T)9",
	"67(9T)",
	"679T",
	"(6J)79",
	"6(7J)9",
	"67(9J)",
	"679J",
	"(6Q)79",
	"6(7Q)9",
	"67(9Q)",
	"679Q",
	"(6K)79",
	"6(7K)9",
	"67(9K)",
	"679K",
	"(6T)(7T)",
	"(6T)7T",
	"(6TJ)7",
	"(6T)(7J)",
	"(6T)7J",
	"(6TQ)7",
	"(6T)(7Q)",
	"(6T)7Q",
	"(6TK)7",
	"(6T)(7K)",
	"(6T)7K",
	"6(7T)T",
	"(6J)(7T)",
	"6(7TJ)",
	"6(7T)J",
	"(6Q)(7T)",
	"6(7TQ)",
	"6(7T)Q",
	"(6K)(7T)",
	"6(7TK)",
	"6(7T)K",
	"67TT",
	"(6J)7T",
	"6(7J)T",
	"67(TJ)",
	"67TJ",
	"(6Q)7T",
	"6(7Q)T",
	"67(TQ)",
	"67TQ",
	"(6K)7T",
	"6(7K)T",
	"67(TK)",
	"67TK",
	"(6J)(7J)",
	"(6J)7J",
	"(6JQ)7",
	"(6J)(7Q)",
	"(6J)7Q",
	"(6JK)7",
	"(6J)(7K)",
	"(6J)7K",
	"6(7J)J",
	"(6Q)(7J)",
	"6(7JQ)",
	"6(7J)Q",
	"(6K)(7J)",
	"6(7JK)",
	"6(7J)K",
	"67JJ",
	"(6Q)7J",
	"6(7Q)J",
	"67(JQ)",
	"67JQ",
	"(6K)7J",
	"6(7K)J",
	"67(JK)",
	"67JK",
	"(6Q)(7Q)",
	"(6Q)7Q",
	"(6QK)7",
	"(6Q)(7K)",
	"(6Q)7K",
	"6(7Q)Q",
	"(6K)(7Q)",
	"6(7QK)",
	"6(7Q)K",
	"67QQ",
	"(6K)7Q",
	"6(7K)Q",
	"67(QK)",
	"67QK",
	"(6K)(7K)",
	"(6K)7K",
	"6(7K)K",
	"67KK",
	"(68)88",
	"(689)8",
	"(68)(89)",
	"(68)89",
	"(68T)8",
	"(68)(8T)",
	"(68)8T",
	"(68J)8",
	"(68)(8J)",
	"(68)8J",
	"(68Q)8",
	"(68)(8Q)",
	"(68)8Q",
	"(68K)8",
	"(68)(8K)",
	"(68)8K",
	"(689)9",
	"(689T)",
	"(689)T",
	"(689J)",
	"(689)J",
	"(689Q)",
	"(689)Q",
	"(689K)",
	"(689)K",
	"(68)99",
	"(68T)9",
	"(68)(9T)",
	"(68)9T",
	"(68J)9",
	"(68)(9J)",
	"(68)9J",
	"(68Q)9",
	"(68)(9Q)",
	"(68)9Q",
	"(68K)9",
	"(68)(9K)",
	"(68)9K",
	"(68T)T",
	"(68TJ)",
	"(68T)J",
	"(68TQ)",
	"(68T)Q",
	"(68TK)",
	"(68T)K",
	"(68)TT",
	"(68J)T",
	"(68)(TJ)",
	"(68)TJ",
	"(68Q)T",
	"(68)(TQ)",
	"(68)TQ",
	"(68K)T",
	"(68)(TK)",
	"(68)TK",
	"(68J)J",
	"(68JQ)",
	"(68J)Q",
	"(68JK)",
	"(68J)K",
	"(68)JJ",
	"(68Q)J",
	"(68)(JQ)",
	"(68)JQ",
	"(68K)J",
	"(68)(JK)",
	"(68)JK",
	"(68Q)Q",
	"(68QK)",
	"(68Q)K",
	"(68)QQ",
	"(68K)Q",
	"(68)(QK)",
	"(68)QK",
	"(68K)K",
	"(68)KK",
	"6888",
	"(69)88",
	"6(89)8",
	"6889",
	"(6T)88",
	"6(8T)8",
	"688T",
	"(6J)88",
	"6(8J)8",
	"688J",
	"(6Q)88",
	"6(8Q)8",
	"688Q",
	"(6K)88",
	"6(8K)8",
	"688K",
	"(69)(89)",
	"(69)89",
	"(69T)8",
	"(69)(8T)",
	"(69)8T",
	"(69J)8",
	"(69)(8J)",
	"(69)8J",
	"(69Q)8",
	"(69)(8Q)",
	"(69)8Q",
	"(69K)8",
	"(69)(8K)",
	"(69)8K",
	"6(89)9",
	"(6T)(89)",
	"6(89T)",
	"6(89)T",
	"(6J)(89)",
	"6(89J)",
	"6(89)J",
	"(6Q)(89)",
	"6(89Q)",
	"6(89)Q",
	"(6K)(89)",
	"6(89K)",
	"6(89)K",
	"6899",
	"(6T)89",
	"6(8T)9",
	"68(9T)",
	"689T",
	"(6J)89",
	"6(8J)9",
	"68(9J)",
	"689J",
	"(6Q)89",
	"6(8Q)9",
	"68(9Q)",
	"689Q",
	"(6K)89",
	"6(8K)9",
	"68(9K)",
	"689K",
	"(6T)(8T)",
	"(6T)8T",
	"(6TJ)8",
	"(6T)(8J)",
	"(6T)8J",
	"(6TQ)8",
	"(6T)(8Q)",
	"(6T)8Q",
	"(6TK)8",
	"(6T)(8K)",
	"(6T)8K",
	"6(8T)T",
	"(6J)(8T)",
	"6(8TJ)",
	"6(8T)J",
	"(6Q)(8T)",
	"6(8TQ)",
	"6(8T)Q",
	"(6K)(8T)",
	"6(8TK)",
	"6(8T)K",
	"68TT",
	"(6J)8T",
	"6(8J)T",
	"68(TJ)",
	"68TJ",
	"(6Q)8T",
	"6(8Q)T",
	"68(TQ)",
	"68TQ",
	"(6K)8T",
	"6(8K)T",
	"68(TK)",
	"68TK",
	"(6J)(8J)",
	"(6J)8J",
	"(6JQ)8",
	"(6J)(8Q)",
	"(6J)8Q",
	"(6JK)8",
	"(6J)(8K)",
	"(6J)8K",
	"6(8J)J",
	"(6Q)(8J)",
	"6(8JQ)",
	"6(8J)Q",
	"(6K)(8J)",
	"6(8JK)",
	"6(8J)K",
	"68JJ",
	"(6Q)8J",
	"6(8Q)J",
	"68(JQ)",
	"68JQ",
	"(6K)8J",
	"6(8K)J",
	"68(JK)",
	"68JK",
	"(6Q)(8Q)",
	"(6Q)8Q",
	"(6QK)8",
	"(6Q)(8K)",
	"(6Q)8K",
	"6(8Q)Q",
	"(6K)(8Q)",
	"6(8QK)",
	"6(8Q)K",
	"68QQ",
	"(6K)8Q",
	"6(8K)Q",
	"68(QK)",
	"68QK",
	"(6K)(8K)",
	"(6K)8K",
	"6(8K)K",
	"68KK",
	"(69)99",
	"(69T)9",
	"(69)(9T)",
	"(69)9T",
	"(69J)9",
	"(69)(9J)",
	"(69)9J",
	"(69Q)9",
	"(69)(9Q)",
	"(69)9Q",
	"(69K)9",
	"(69)(9K)",
	"(69)9K",
	"(69T)T",
	"(69TJ)",
	"(69T)J",
	"(69TQ)",
	"(69T)Q",
	"(69TK)",
	"(69T)K",
	"(69)TT",
	"(69J)T",
	"(69)(TJ)",
	"(69)TJ",
	"(69Q)T",
	"(69)(TQ)",
	"(69)TQ",
	"(69K)T",
	"(69)(TK)",
	"(69)TK",
	"(69J)J",
	"(69JQ)",
	"(69J)Q",
	"(69JK)",
	"(69J)K",
	"(69)JJ",
	"(69Q)J",
	"(69)(JQ)",
	"(69)JQ",
	"(69K)J",
	"(69)(JK)",
	"(69)JK",
	"(69Q)Q",
	"(69QK)",
	"(69Q)K",
	"(69)QQ",
	"(69K)Q",
	"(69)(QK)",
	"(69)QK",
	"(69K)K",
	"(69)KK",
	"6999",
	"(6T)99",
	"6(9T)9",
	"699T",
	"(6J)99",
	"6(9J)9",
	"699J",
	"(6Q)99",
	"6(9Q)9",
	"699Q",
	"(6K)99",
	"6(9K)9",
	"699K",
	"(6T)(9T)",
	"(6T)9T",
	"(6TJ)9",
	"(6T)(9J)",
	"(6T)9J",
	"(6TQ)9",
	"(6T)(9Q)",
	"(6T)9Q",
	"(6TK)9",
	"(6T)(9K)",
	"(6T)9K",
	"6(9T)T",
	"(6J)(9T)",
	"6(9TJ)",
	"6(9T)J",
	"(6Q)(9T)",
	"6(9TQ)",
	"6(9T)Q",
	"(6K)(9T)",
	"6(9TK)",
	"6(9T)K",
	"69TT",
	"(6J)9T",
	"6(9J)T",
	"69(TJ)",
	"69TJ",
	"(6Q)9T",
	"6(9Q)T",
	"69(TQ)",
	"69TQ",
	"(6K)9T",
	"6(9K)T",
	"69(TK)",
	"69TK",
	"(6J)(9J)",
	"(6J)9J",
	"(6JQ)9",
	"(6J)(9Q)",
	"(6J)9Q",
	"(6JK)9",
	"(6J)(9K)",
	"(6J)9K",
	"6(9J)J",
	"(6Q)(9J)",
	"6(9JQ)",
	"6(9J)Q",
	"(6K)(9J)",
	"6(9JK)",
	"6(9J)K",
	"69JJ",
	"(6Q)9J",
	"6(9Q)J",
	"69(JQ)",
	"69JQ",
	"(6K)9J",
	"6(9K)J",
	"69(JK)",
	"69JK",
	"(6Q)(9Q)",
	"(6Q)9Q",
	"(6QK)9",
	"(6Q)(9K)",
	"(6Q)9K",
	"6(9Q)Q",
	"(6K)(9Q)",
	"6(9QK)",
	"6(9Q)K",
	"69QQ",
	"(6K)9Q",
	"6(9K)Q",
	"69(QK)",
	"69QK",
	"(6K)(9K)",
	"(6K)9K",
	"6(9K)K",
	"69KK",
	"(6T)TT",
	"(6TJ)T",
	"(6T)(TJ)",
	"(6T)TJ",
	"(6TQ)T",
	"(6T)(TQ)",
	"(6T)TQ",
	"(6TK)T",
	"(6T)(TK)",
	"(6T)TK",
	"(6TJ)J",
	"(6TJQ)",
	"(6TJ)Q",
	"(6TJK)",
	"(6TJ)K",
	"(6T)JJ",
	"(6TQ)J",
	"(6T)(JQ)",
	"(6T)JQ",
	"(6TK)J",
	"(6T)(JK)",
	"(6T)JK",
	"(6TQ)Q",
	"(6TQK)",
	"(6TQ)K",
	"(6T)QQ",
	"(6TK)Q",
	"(6T)(QK)",
	"(6T)QK",
	"(6TK)K",
	"(6T)KK",
	"6TTT",
	"(6J)TT",
	"6(TJ)T",
	"6TTJ",
	"(6Q)TT",
	"6(TQ)T",
	"6TTQ",
	"(6K)TT",
	"6(TK)T",
	"6TTK",
	"(6J)(TJ)",
	"(6J)TJ",
	"(6JQ)T",
	"(6J)(TQ)",
	"(6J)TQ",
	"(6JK)T",
	"(6J)(TK)",
	"(6J)TK",
	"6(TJ)J",
	"(6Q)(TJ)",
	"6(TJQ)",
	"6(TJ)Q",
	"(6K)(TJ)",
	"6(TJK)",
	"6(TJ)K",
	"6TJJ",
	"(6Q)TJ",
	"6(TQ)J",
	"6T(JQ)",
	"6TJQ",
	"(6K)TJ",
	"6(TK)J",
	"6T(JK)",
	"6TJK",
	"(6Q)(TQ)",
	"(6Q)TQ",
	"(6QK)T",
	"(6Q)(TK)",
	"(6Q)TK",
	"6(TQ)Q",
	"(6K)(TQ)",
	"6(TQK)",
	"6(TQ)K",
	"6TQQ",
	"(6K)TQ",
	"6(TK)Q",
	"6T(QK)",
	"6TQK",
	"(6K)(TK)",
	"(6K)TK",
	"6(TK)K",
	"6TKK",
	"(6J)JJ",
	"(6JQ)J",
	"(6J)(JQ)",
	"(6J)JQ",
	"(6JK)J",
	"(6J)(JK)",
	"(6J)JK",
	"(6JQ)Q",
	"(6JQK)",
	"(6JQ)K",
	"(6J)QQ",
	"(6JK)Q",
	"(6J)(QK)",
	"(6J)QK",
	"(6JK)K",
	"(6J)KK",
	"6JJJ",
	"(6Q)JJ",
	"6(JQ)J",
	"6JJQ",
	"(6K)JJ",
	"6(JK)J",
	"6JJK",
	"(6Q)(JQ)",
	"(6Q)JQ",
	"(6QK)J",
	"(6Q)(JK)",
	"(6Q)JK",
	"6(JQ)Q",
	"(6K)(JQ)",
	"6(JQK)",
	"6(JQ)K",
	"6JQQ",
	"(6K)JQ",
	"6(JK)Q",
	"6J(QK)",
	"6JQK",
	"(6K)(JK)",
	"(6K)JK",
	"6(JK)K",
	"6JKK",
	"(6Q)QQ",
	"(6QK)Q",
	"(6Q)(QK)",
	"(6Q)QK",
	"(6QK)K",
	"(6Q)KK",
	"6QQQ",
	"(6K)QQ",
	"6(QK)Q",
	"6QQK",
	"(6K)(QK)",
	"(6K)QK",
	"6(QK)K",
	"6QKK",
	"(6K)KK",
	"6KKK",
	"7777",
	"(78)77",
	"7778",
	"(79)77",
	"7779",
	"(7T)77",
	"777T",
	"(7J)77",
	"777J",
	"(7Q)77",
	"777Q",
	"(7K)77",
	"777K",
	"(78)(78)",
	"(78)78",
	"(789)7",
	"(78)(79)",
	"(78)79",
	"(78T)7",
	"(78)(7T)",
	"(78)7T",
	"(78J)7",
	"(78)(7J)",
	"(78)7J",
	"(78Q)7",
	"(78)(7Q)",
	"(78)7Q",
	"(78K)7",
	"(78)(7K)",
	"(78)7K",
	"7788",
	"(79)78",
	"77(89)",
	"7789",
	"(7T)78",
	"77(8T)",
	"778T",
	"(7J)78",
	"77(8J)",
	"778J",
	"(7Q)78",
	"77(8Q)",
	"778Q",
	"(7K)78",
	"77(8K)",
	"778K",
	"(79)(79)",
	"(79)79",
	"(79T)7",
	"(79)(7T)",
	"(79)7T",
	"(79J)7",
	"(79)(7J)",
	"(79)7J",
	"(79Q)7",
	"(79)(7Q)",
	"(79)7Q",
	"(79K)7",
	"(79)(7K)",
	"(79)7K",
	"7799",
	"(7T)79",
	"77(9T)",
	"779T",
	"(7J)79",
	"77(9J)",
	"779J",
	"(7Q)79",
	"77(9Q)",
	"779Q",
	"(7K)79",
	"77(9K)",
	"779K",
	"(7T)(7T)",
	"(7T)7T",
	"(7TJ)7",
	"(7T)(7J)",
	"(7T)7J",
	"(7TQ)7",
	"(7T)(7Q)",
	"(7T)7Q",
	"(7TK)7",
	"(7T)(7K)",
	"(7T)7K",
	"77TT",
	"(7J)7T",
	"77(TJ)",
	"77TJ",
	"(7Q)7T",
	"77(TQ)",
	"77TQ",
	"(7K)7T",
	"77(TK)",
	"77TK",
	"(7J)(7J)",
	"(7J)7J",
	"(7JQ)7",
	"(7J)(7Q)",
	"(7J)7Q",
	"(7JK)7",
	"(7J)(7K)",
	"(7J)7K",
	"77JJ",
	"(7Q)7J",
	"77(JQ)",
	"77JQ",
	"(7K)7J",
	"77(JK)",
	"77JK",
	"(7Q)(7Q)",
	"(7Q)7Q",
	"(7QK)7",
	"(7Q)(7K)",
	"(7Q)7K",
	"77QQ",
	"(7K)7Q",
	"77(QK)",
	"77QK",
	"(7K)(7K)",
	"(7K)7K",
	"77KK",
	"(78)88",
	"(789)8",
	"(78)(89)",
	"(78)89",
	"(78T)8",
	"(78)(8T)",
	"(78)8T",
	"(78J)8",
	"(78)(8J)",
	"(78)8J",
	"(78Q)8",
	"(78)(8Q)",
	"(78)8Q",
	"(78K)8",
	"(78)(8K)",
	"(78)8K",
	"(789)9",
	"(789T)",
	"(789)T",
	"(789J)",
	"(789)J",
	"(789Q)",
	"(789)Q",
	"(789K)",
	"(789)K",
	"(78)99",
	"(78T)9",
	"(78)(9T)",
	"(78)9T",
	"(78J)9",
	"(78)(9J)",
	"(78)9J",
	"(78Q)9",
	"(78)(9Q)",
	"(78)9Q",
	"(78K)9",
	"(78)(9K)",
	"(78)9K",
	"(78T)T",
	"(78TJ)",
	"(78T)J",
	"(78TQ)",
	"(78T)Q",
	"(78TK)",
	"(78T)K",
	"(78)TT",
	"(78J)T",
	"(78)(TJ)",
	"(78)TJ",
	"(78Q)T",
	"(78)(TQ)",
	"(78)TQ",
	"(78K)T",
	"(78)(TK)",
	"(78)TK",
	"(78J)J",
	"(78JQ)",
	"(78J)Q",
	"(78JK)",
	"(78J)K",
	"(78)JJ",
	"(78Q)J",
	"(78)(JQ)",
	"(78)JQ",
	"(78K)J",
	"(78)(JK)",
	"(78)JK",
	"(78Q)Q",
	"(78QK)",
	"(78Q)K",
	"(78)QQ",
	"(78K)Q",
	"(78)(QK)",
	"(78)QK",
	"(78K)K",
	"(78)KK",
	"7888",
	"(79)88",
	"7(89)8",
	"7889",
	"(7T)88",
	"7(8T)8",
	"788T",
	"(7J)88",
	"7(8J)8",
	"788J",
	"(7Q)88",
	"7(8Q)8",
	"788Q",
	"(7K)88",
	"7(8K)8",
	"788K",
	"(79)(89)",
	"(79)89",
	"(79T)8",
	"(79)(8T)",
	"(79)8T",
	"(79J)8",
	"(79)(8J)",
	"(79)8J",
	"(79Q)8",
	"(79)(8Q)",
	"(79)8Q",
	"(79K)8",
	"(79)(8K)",
	"(79)8K",
	"7(89)9",
	"(7T)(89)",
	"7(89T)",
	"7(89)T",
	"(7J)(89)",
	"7(89J)",
	"7(89)J",
	"(7Q)(89)",
	"7(89Q)",
	"7(89)Q",
	"(7K)(89)",
	"7(89K)",
	"7(89)K",
	"7899",
	"(7T)89",
	"7(8T)9",
	"78(9T)",
	"789T",
	"(7J)89",
	"7(8J)9",
	"78(9J)",
	"789J",
	"(7Q)89",
	"7(8Q)9",
	"78(9Q)",
	"789Q",
	"(7K)89",
	"7(8K)9",
	"78(9K)",
	"789K",
	"(7T)(8T)",
	"(7T)8T",
	"(7TJ)8",
	"(7T)(8J)",
	"(7T)8J",
	"(7TQ)8",
	"(7T)(8Q)",
	"(7T)8Q",
	"(7TK)8",
	"(7T)(8K)",
	"(7T)8K",
	"7(8T)T",
	"(7J)(8T)",
	"7(8TJ)",
	"7(8T)J",
	"(7Q)(8T)",
	"7(8TQ)",
	"7(8T)Q",
	"(7K)(8T)",
	"7(8TK)",
	"7(8T)K",
	"78TT",
	"(7J)8T",
	"7(8J)T",
	"78(TJ)",
	"78TJ",
	"(7Q)8T",
	"7(8Q)T",
	"78(TQ)",
	"78TQ",
	"(7K)8T",
	"7(8K)T",
	"78(TK)",
	"78TK",
	"(7J)(8J)",
	"(7J)8J",
	"(7JQ)8",
	"(7J)(8Q)",
	"(7J)8Q",
	"(7JK)8",
	"(7J)(8K)",
	"(7J)8K",
	"7(8J)J",
	"(7Q)(8J)",
	"7(8JQ)",
	"7(8J)Q",
	"(7K)(8J)",
	"7(8JK)",
	"7(8J)K",
	"78JJ",
	"(7Q)8J",
	"7(8Q)J",
	"78(JQ)",
	"78JQ",
	"(7K)8J",
	"7(8K)J",
	"78(JK)",
	"78JK",
	"(7Q)(8Q)",
	"(7Q)8Q",
	"(7QK)8",
	"(7Q)(8K)",
	"(7Q)8K",
	"7(8Q)Q",
	"(7K)(8Q)",
	"7(8QK)",
	"7(8Q)K",
	"78QQ",
	"(7K)8Q",
	"7(8K)Q",
	"78(QK)",
	"78QK",
	"(7K)(8K)",
	"(7K)8K",
	"7(8K)K",
	"78KK",
	"(79)99",
	"(79T)9",
	"(79)(9T)",
	"(79)9T",
	"(79J)9",
	"(79)(9J)",
	"(79)9J",
	"(79Q)9",
	"(79)(9Q)",
	"(79)9Q",
	"(79K)9",
	"(79)(9K)",
	"(79)9K",
	"(79T)T",
	"(79TJ)",
	"(79T)J",
	"(79TQ)",
	"(79T)Q",
	"(79TK)",
	"(79T)K",
	"(79)TT",
	"(79J)T",
	"(79)(TJ)",
	"(79)TJ",
	"(79Q)T",
	"(79)(TQ)",
	"(79)TQ",
	"(79K)T",
	"(79)(TK)",
	"(79)TK",
	"(79J)J",
	"(79JQ)",
	"(79J)Q",
	"(79JK)",
	"(79J)K",
	"(79)JJ",
	"(79Q)J",
	"(79)(JQ)",
	"(79)JQ",
	"(79K)J",
	"(79)(JK)",
	"(79)JK",
	"(79Q)Q",
	"(79QK)",
	"(79Q)K",
	"(79)QQ",
	"(79K)Q",
	"(79)(QK)",
	"(79)QK",
	"(79K)K",
	"(79)KK",
	"7999",
	"(7T)99",
	"7(9T)9",
	"799T",
	"(7J)99",
	"7(9J)9",
	"799J",
	"(7Q)99",
	"7(9Q)9",
	"799Q",
	"(7K)99",
	"7(9K)9",
	"799K",
	"(7T)(9T)",
	"(7T)9T",
	"(7TJ)9",
	"(7T)(9J)",
	"(7T)9J",
	"(7TQ)9",
	"(7T)(9Q)",
	"(7T)9Q",
	"(7TK)9",
	"(7T)(9K)",
	"(7T)9K",
	"7(9T)T",
	"(7J)(9T)",
	"7(9TJ)",
	"7(9T)J",
	"(7Q)(9T)",
	"7(9TQ)",
	"7(9T)Q",
	"(7K)(9T)",
	"7(9TK)",
	"7(9T)K",
	"79TT",
	"(7J)9T",
	"7(9J)T",
	"79(TJ)",
	"79TJ",
	"(7Q)9T",
	"7(9Q)T",
	"79(TQ)",
	"79TQ",
	"(7K)9T",
	"7(9K)T",
	"79(TK)",
	"79TK",
	"(7J)(9J)",
	"(7J)9J",
	"(7JQ)9",
	"(7J)(9Q)",
	"(7J)9Q",
	"(7JK)9",
	"(7J)(9K)",
	"(7J)9K",
	"7(9J)J",
	"(7Q)(9J)",
	"7(9JQ)",
	"7(9J)Q",
	"(7K)(9J)",
	"7(9JK)",
	"7(9J)K",
	"79JJ",
	"(7Q)9J",
	"7(9Q)J",
	"79(JQ)",
	"79JQ",
	"(7K)9J",
	"7(9K)J",
	"79(JK)",
	"79JK",
	"(7Q)(9Q)",
	"(7Q)9Q",
	"(7QK)9",
	"(7Q)(9K)",
	"(7Q)9K",
	"7(9Q)Q",
	"(7K)(9Q)",
	"7(9QK)",
	"7(9Q)K",
	"79QQ",
	"(7K)9Q",
	"7(9K)Q",
	"79(QK)",
	"79QK",
	"(7K)(9K)",
	"(7K)9K",
	"7(9K)K",
	"79KK",
	"(7T)TT",
	"(7TJ)T",
	"(7T)(TJ)",
	"(7T)TJ",
	"(7TQ)T",
	"(7T)(TQ)",
	"(7T)TQ",
	"(7TK)T",
	"(7T)(TK)",
	"(7T)TK",
	"(7TJ)J",
	"(7TJQ)",
	"(7TJ)Q",
	"(7TJK)",
	"(7TJ)K",
	"(7T)JJ",
	"(7TQ)J",
	"(7T)(JQ)",
	"(7T)JQ",
	"(7TK)J",
	"(7T)(JK)",
	"(7T)JK",
	"(7TQ)Q",
	"(7TQK)",
	"(7TQ)K",
	"(7T)QQ",
	"(7TK)Q",
	"(7T)(QK)",
	"(7T)QK",
	"(7TK)K",
	"(7T)KK",
	"7TTT",
	"(7J)TT",
	"7(TJ)T",
	"7TTJ",
	"(7Q)TT",
	"7(TQ)T",
	"7TTQ",
	"(7K)TT",
	"7(TK)T",
	"7TTK",
	"(7J)(TJ)",
	"(7J)TJ",
	"(7JQ)T",
	"(7J)(TQ)",
	"(7J)TQ",
	"(7JK)T",
	"(7J)(TK)",
	"(7J)TK",
	"7(TJ)J",
	"(7Q)(TJ)",
	"7(TJQ)",
	"7(TJ)Q",
	"(7K)(TJ)",
	"7(TJK)",
	"7(TJ)K",
	"7TJJ",
	"(7Q)TJ",
	"7(TQ)J",
	"7T(JQ)",
	"7TJQ",
	"(7K)TJ",
	"7(TK)J",
	"7T(JK)",
	"7TJK",
	"(7Q)(TQ)",
	"(7Q)TQ",
	"(7QK)T",
	"(7Q)(TK)",
	"(7Q)TK",
	"7(TQ)Q",
	"(7K)(TQ)",
	"7(TQK)",
	"7(TQ)K",
	"7TQQ",
	"(7K)TQ",
	"7(TK)Q",
	"7T(QK)",
	"7TQK",
	"(7K)(TK)",
	"(7K)TK",
	"7(TK)K",
	"7TKK",
	"(7J)JJ",
	"(7JQ)J",
	"(7J)(JQ)",
	"(7J)JQ",
	"(7JK)J",
	"(7J)(JK)",
	"(7J)JK",
	"(7JQ)Q",
	"(7JQK)",
	"(7JQ)K",
	"(7J)QQ",
	"(7JK)Q",
	"(7J)(QK)",
	"(7J)QK",
	"(7JK)K",
	"(7J)KK",
	"7JJJ",
	"(7Q)JJ",
	"7(JQ)J",
	"7JJQ",
	"(7K)JJ",
	"7(JK)J",
	"7JJK",
	"(7Q)(JQ)",
	"(7Q)JQ",
	"(7QK)J",
	"(7Q)(JK)",
	"(7Q)JK",
	"7(JQ)Q",
	"(7K)(JQ)",
	"7(JQK)",
	"7(JQ)K",
	"7JQQ",
	"(7K)JQ",
	"7(JK)Q",
	"7J(QK)",
	"7JQK",
	"(7K)(JK)",
	"(7K)JK",
	"7(JK)K",
	"7JKK",
	"(7Q)QQ",
	"(7QK)Q",
	"(7Q)(QK)",
	"(7Q)QK",
	"(7QK)K",
	"(7Q)KK",
	"7QQQ",
	"(7K)QQ",
	"7(QK)Q",
	"7QQK",
	"(7K)(QK)",
	"(7K)QK",
	"7(QK)K",
	"7QKK",
	"(7K)KK",
	"7KKK",
	"8888",
	"(89)88",
	"8889",
	"(8T)88",
	"888T",
	"(8J)88",
	"888J",
	"(8Q)88",
	"888Q",
	"(8K)88",
	"888K",
	"(89)(89)",
	"(89)89",
	"(89T)8",
	"(89)(8T)",
	"(89)8T",
	"(89J)8",
	"(89)(8J)",
	"(89)8J",
	"(89Q)8",
	"(89)(8Q)",
	"(89)8Q",
	"(89K)8",
	"(89)(8K)",
	"(89)8K",
	"8899",
	"(8T)89",
	"88(9T)",
	"889T",
	"(8J)89",
	"88(9J)",
	"889J",
	"(8Q)89",
	"88(9Q)",
	"889Q",
	"(8K)89",
	"88(9K)",
	"889K",
	"(8T)(8T)",
	"(8T)8T",
	"(8TJ)8",
	"(8T)(8J)",
	"(8T)8J",
	"(8TQ)8",
	"(8T)(8Q)",
	"(8T)8Q",
	"(8TK)8",
	"(8T)(8K)",
	"(8T)8K",
	"88TT",
	"(8J)8T",
	"88(TJ)",
	"88TJ",
	"(8Q)8T",
	"88(TQ)",
	"88TQ",
	"(8K)8T",
	"88(TK)",
	"88TK",
	"(8J)(8J)",
	"(8J)8J",
	"(8JQ)8",
	"(8J)(8Q)",
	"(8J)8Q",
	"(8JK)8",
	"(8J)(8K)",
	"(8J)8K",
	"88JJ",
	"(8Q)8J",
	"88(JQ)",
	"88JQ",
	"(8K)8J",
	"88(JK)",
	"88JK",
	"(8Q)(8Q)",
	"(8Q)8Q",
	"(8QK)8",
	"(8Q)(8K)",
	"(8Q)8K",
	"88QQ",
	"(8K)8Q",
	"88(QK)",
	"88QK",
	"(8K)(8K)",
	"(8K)8K",
	"88KK",
	"(89)99",
	"(89T)9",
	"(89)(9T)",
	"(89)9T",
	"(89J)9",
	"(89)(9J)",
	"(89)9J",
	"(89Q)9",
	"(89)(9Q)",
	"(89)9Q",
	"(89K)9",
	"(89)(9K)",
	"(89)9K",
	"(89T)T",
	"(89TJ)",
	"(89T)J",
	"(89TQ)",
	"(89T)Q",
	"(89TK)",
	"(89T)K",
	"(89)TT",
	"(89J)T",
	"(89)(TJ)",
	"(89)TJ",
	"(89Q)T",
	"(89)(TQ)",
	"(89)TQ",
	"(89K)T",
	"(89)(TK)",
	"(89)TK",
	"(89J)J",
	"(89JQ)",
	"(89J)Q",
	"(89JK)",
	"(89J)K",
	"(89)JJ",
	"(89Q)J",
	"(89)(JQ)",
	"(89)JQ",
	"(89K)J",
	"(89)(JK)",
	"(89)JK",
	"(89Q)Q",
	"(89QK)",
	"(89Q)K",
	"(89)QQ",
	"(89K)Q",
	"(89)(QK)",
	"(89)QK",
	"(89K)K",
	"(89)KK",
	"8999",
	"(8T)99",
	"8(9T)9",
	"899T",
	"(8J)99",
	"8(9J)9",
	"899J",
	"(8Q)99",
	"8(9Q)9",
	"899Q",
	"(8K)99",
	"8(9K)9",
	"899K",
	"(8T)(9T)",
	"(8T)9T",
	"(8TJ)9",
	"(8T)(9J)",
	"(8T)9J",
	"(8TQ)9",
	"(8T)(9Q)",
	"(8T)9Q",
	"(8TK)9",
	"(8T)(9K)",
	"(8T)9K",
	"8(9T)T",
	"(8J)(9T)",
	"8(9TJ)",
	"8(9T)J",
	"(8Q)(9T)",
	"8(9TQ)",
	"8(9T)Q",
	"(8K)(9T)",
	"8(9TK)",
	"8(9T)K",
	"89TT",
	"(8J)9T",
	"8(9J)T",
	"89(TJ)",
	"89TJ",
	"(8Q)9T",
	"8(9Q)T",
	"89(TQ)",
	"89TQ",
	"(8K)9T",
	"8(9K)T",
	"89(TK)",
	"89TK",
	"(8J)(9J)",
	"(8J)9J",
	"(8JQ)9",
	"(8J)(9Q)",
	"(8J)9Q",
	"(8JK)9",
	"(8J)(9K)",
	"(8J)9K",
	"8(9J)J",
	"(8Q)(9J)",
	"8(9JQ)",
	"8(9J)Q",
	"(8K)(9J)",
	"8(9JK)",
	"8(9J)K",
	"89JJ",
	"(8Q)9J",
	"8(9Q)J",
	"89(JQ)",
	"89JQ",
	"(8K)9J",
	"8(9K)J",
	"89(JK)",
	"89JK",
	"(8Q)(9Q)",
	"(8Q)9Q",
	"(8QK)9",
	"(8Q)(9K)",
	"(8Q)9K",
	"8(9Q)Q",
	"(8K)(9Q)",
	"8(9QK)",
	"8(9Q)K",
	"89QQ",
	"(8K)9Q",
	"8(9K)Q",
	"89(QK)",
	"89QK",
	"(8K)(9K)",
	"(8K)9K",
	"8(9K)K",
	"89KK",
	"(8T)TT",
	"(8TJ)T",
	"(8T)(TJ)",
	"(8T)TJ",
	"(8TQ)T",
	"(8T)(TQ)",
	"(8T)TQ",
	"(8TK)T",
	"(8T)(TK)",
	"(8T)TK",
	"(8TJ)J",
	"(8TJQ)",
	"(8TJ)Q",
	"(8TJK)",
	"(8TJ)K",
	"(8T)JJ",
	"(8TQ)J",
	"(8T)(JQ)",
	"(8T)JQ",
	"(8TK)J",
	"(8T)(JK)",
	"(8T)JK",
	"(8TQ)Q",
	"(8TQK)",
	"(8TQ)K",
	"(8T)QQ",
	"(8TK)Q",
	"(8T)(QK)",
	"(8T)QK",
	"(8TK)K",
	"(8T)KK",
	"8TTT",
	"(8J)TT",
	"8(TJ)T",
	"8TTJ",
	"(8Q)TT",
	"8(TQ)T",
	"8TTQ",
	"(8K)TT",
	"8(TK)T",
	"8TTK",
	"(8J)(TJ)",
	"(8J)TJ",
	"(8JQ)T",
	"(8J)(TQ)",
	"(8J)TQ",
	"(8JK)T",
	"(8J)(TK)",
	"(8J)TK",
	"8(TJ)J",
	"(8Q)(TJ)",
	"8(TJQ)",
	"8(TJ)Q",
	"(8K)(TJ)",
	"8(TJK)",
	"8(TJ)K",
	"8TJJ",
	"(8Q)TJ",
	"8(TQ)J",
	"8T(JQ)",
	"8TJQ",
	"(8K)TJ",
	"8(TK)J",
	"8T(JK)",
	"8TJK",
	"(8Q)(TQ)",
	"(8Q)TQ",
	"(8QK)T",
	"(8Q)(TK)",
	"(8Q)TK",
	"8(TQ)Q",
	"(8K)(TQ)",
	"8(TQK)",
	"8(TQ)K",
	"8TQQ",
	"(8K)TQ",
	"8(TK)Q",
	"8T(QK)",
	"8TQK",
	"(8K)(TK)",
	"(8K)TK",
	"8(TK)K",
	"8TKK",
	"(8J)JJ",
	"(8JQ)J",
	"(8J)(JQ)",
	"(8J)JQ",
	"(8JK)J",
	"(8J)(JK)",
	"(8J)JK",
	"(8JQ)Q",
	"(8JQK)",
	"(8JQ)K",
	"(8J)QQ",
	"(8JK)Q",
	"(8J)(QK)",
	"(8J)QK",
	"(8JK)K",
	"(8J)KK",
	"8JJJ",
	"(8Q)JJ",
	"8(JQ)J",
	"8JJQ",
	"(8K)JJ",
	"8(JK)J",
	"8JJK",
	"(8Q)(JQ)",
	"(8Q)JQ",
	"(8QK)J",
	"(8Q)(JK)",
	"(8Q)JK",
	"8(JQ)Q",
	"(8K)(JQ)",
	"8(JQK)",
	"8(JQ)K",
	"8JQQ",
	"(8K)JQ",
	"8(JK)Q",
	"8J(QK)",
	"8JQK",
	"(8K)(JK)",
	"(8K)JK",
	"8(JK)K",
	"8JKK",
	"(8Q)QQ",
	"(8QK)Q",
	"(8Q)(QK)",
	"(8Q)QK",
	"(8QK)K",
	"(8Q)KK",
	"8QQQ",
	"(8K)QQ",
	"8(QK)Q",
	"8QQK",
	"(8K)(QK)",
	"(8K)QK",
	"8(QK)K",
	"8QKK",
	"(8K)KK",
	"8KKK",
	"9999",
	"(9T)99",
	"999T",
	"(9J)99",
	"999J",
	"(9Q)99",
	"999Q",
	"(9K)99",
	"999K",
	"(9T)(9T)",
	"(9T)9T",
	"(9TJ)9",
	"(9T)(9J)",
	"(9T)9J",
	"(9TQ)9",
	"(9T)(9Q)",
	"(9T)9Q",
	"(9TK)9",
	"(9T)(9K)",
	"(9T)9K",
	"99TT",
	"(9J)9T",
	"99(TJ)",
	"99TJ",
	"(9Q)9T",
	"99(TQ)",
	"99TQ",
	"(9K)9T",
	"99(TK)",
	"99TK",
	"(9J)(9J)",
	"(9J)9J",
	"(9JQ)9",
	"(9J)(9Q)",
	"(9J)9Q",
	"(9JK)9",
	"(9J)(9K)",
	"(9J)9K",
	"99JJ",
	"(9Q)9J",
	"99(JQ)",
	"99JQ",
	"(9K)9J",
	"99(JK)",
	"99JK",
	"(9Q)(9Q)",
	"(9Q)9Q",
	"(9QK)9",
	"(9Q)(9K)",
	"(9Q)9K",
	"99QQ",
	"(9K)9Q",
	"99(QK)",
	"99QK",
	"(9K)(9K)",
	"(9K)9K",
	"99KK",
	"(9T)TT",
	"(9TJ)T",
	"(9T)(TJ)",
	"(9T)TJ",
	"(9TQ)T",
	"(9T)(TQ)",
	"(9T)TQ",
	"(9TK)T",
	"(9T)(TK)",
	"(9T)TK",
	"(9TJ)J",
	"(9TJQ)",
	"(9TJ)Q",
	"(9TJK)",
	"(9TJ)K",
	"(9T)JJ",
	"(9TQ)J",
	"(9T)(JQ)",
	"(9T)JQ",
	"(9TK)J",
	"(9T)(JK)",
	"(9T)JK",
	"(9TQ)Q",
	"(9TQK)",
	"(9TQ)K",
	"(9T)QQ",
	"(9TK)Q",
	"(9T)(QK)",
	"(9T)QK",
	"(9TK)K",
	"(9T)KK",
	"9TTT",
	"(9J)TT",
	"9(TJ)T",
	"9TTJ",
	"(9Q)TT",
	"9(TQ)T",
	"9TTQ",
	"(9K)TT",
	"9(TK)T",
	"9TTK",
	"(9J)(TJ)",
	"(9J)TJ",
	"(9JQ)T",
	"(9J)(TQ)",
	"(9J)TQ",
	"(9JK)T",
	"(9J)(TK)",
	"(9J)TK",
	"9(TJ)J",
	"(9Q)(TJ)",
	"9(TJQ)",
	"9(TJ)Q",
	"(9K)(TJ)",
	"9(TJK)",
	"9(TJ)K",
	"9TJJ",
	"(9Q)TJ",
	"9(TQ)J",
	"9T(JQ)",
	"9TJQ",
	"(9K)TJ",
	"9(TK)J",
	"9T(JK)",
	"9TJK",
	"(9Q)(TQ)",
	"(9Q)TQ",
	"(9QK)T",
	"(9Q)(TK)",
	"(9Q)TK",
	"9(TQ)Q",
	"(9K)(TQ)",
	"9(TQK)",
	"9(TQ)K",
	"9TQQ",
	"(9K)TQ",
	"9(TK)Q",
	"9T(QK)",
	"9TQK",
	"(9K)(TK)",
	"(9K)TK",
	"9(TK)K",
	"9TKK",
	"(9J)JJ",
	"(9JQ)J",
	"(9J)(JQ)",
	"(9J)JQ",
	"(9JK)J",
	"(9J)(JK)",
	"(9J)JK",
	"(9JQ)Q",
	"(9JQK)",
	"(9JQ)K",
	"(9J)QQ",
	"(9JK)Q",
	"(9J)(QK)",
	"(9J)QK",
	"(9JK)K",
	"(9J)KK",
	"9JJJ",
	"(9Q)JJ",
	"9(JQ)J",
	"9JJQ",
	"(9K)JJ",
	"9(JK)J",
	"9JJK",
	"(9Q)(JQ)",
	"(9Q)JQ",
	"(9QK)J",
	"(9Q)(JK)",
	"(9Q)JK",
	"9(JQ)Q",
	"(9K)(JQ)",
	"9(JQK)",
	"9(JQ)K",
	"9JQQ",
	"(9K)JQ",
	"9(JK)Q",
	"9J(QK)",
	"9JQK",
	"(9K)(JK)",
	"(9K)JK",
	"9(JK)K",
	"9JKK",
	"(9Q)QQ",
	"(9QK)Q",
	"(9Q)(QK)",
	"(9Q)QK",
	"(9QK)K",
	"(9Q)KK",
	"9QQQ",
	"(9K)QQ",
	"9(QK)Q",
	"9QQK",
	"(9K)(QK)",
	"(9K)QK",
	"9(QK)K",
	"9QKK",
	"(9K)KK",
	"9KKK",
	"TTTT",
	"(TJ)TT",
	"TTTJ",
	"(TQ)TT",
	"TTTQ",
	"(TK)TT",
	"TTTK",
	"(TJ)(TJ)",
	"(TJ)TJ",
	"(TJQ)T",
	"(TJ)(TQ)",
	"(TJ)TQ",
	"(TJK)T",
	"(TJ)(TK)",
	"(TJ)TK",
	"TTJJ",
	"(TQ)TJ",
	"TT(JQ)",
	"TTJQ",
	"(TK)TJ",
	"TT(JK)",
	"TTJK",
	"(TQ)(TQ)",
	"(TQ)TQ",
	"(TQK)T",
	"(TQ)(TK)",
	"(TQ)TK",
	"TTQQ",
	"(TK)TQ",
	"TT(QK)",
	"TTQK",
	"(TK)(TK)",
	"(TK)TK",
	"TTKK",
	"(TJ)JJ",
	"(TJQ)J",
	"(TJ)(JQ)",
	"(TJ)JQ",
	"(TJK)J",
	"(TJ)(JK)",
	"(TJ)JK",
	"(TJQ)Q",
	"(TJQK)",
	"(TJQ)K",
	"(TJ)QQ",
	"(TJK)Q",
	"(TJ)(QK)",
	"(TJ)QK",
	"(TJK)K",
	"(TJ)KK",
	"TJJJ",
	"(TQ)JJ",
	"T(JQ)J",
	"TJJQ",
	"(TK)JJ",
	"T(JK)J",
	"TJJK",
	"(TQ)(JQ)",
	"(TQ)JQ",
	"(TQK)J",
	"(TQ)(JK)",
	"(TQ)JK",
	"T(JQ)Q",
	"(TK)(JQ)",
	"T(JQK)",
	"T(JQ)K",
	"TJQQ",
	"(TK)JQ",
	"T(JK)Q",
	"TJ(QK)",
	"TJQK",
	"(TK)(JK)",
	"(TK)JK",
	"T(JK)K",
	"TJKK",
	"(TQ)QQ",
	"(TQK)Q",
	"(TQ)(QK)",
	"(TQ)QK",
	"(TQK)K",
	"(TQ)KK",
	"TQQQ",
	"(TK)QQ",
	"T(QK)Q",
	"TQQK",
	"(TK)(QK)",
	"(TK)QK",
	"T(QK)K",
	"TQKK",
	"(TK)KK",
	"TKKK",
	"JJJJ",
	"(JQ)JJ",
	"JJJQ",
	"(JK)JJ",
	"JJJK",
	"(JQ)(JQ)",
	"(JQ)JQ",
	"(JQK)J",
	"(JQ)(JK)",
	"(JQ)JK",
	"JJQQ",
	"(JK)JQ",
	"JJ(QK)",
	"JJQK",
	"(JK)(JK)",
	"(JK)JK",
	"JJKK",
	"(JQ)QQ",
	"(JQK)Q",
	"(JQ)(QK)",
	"(JQ)QK",
	"(JQK)K",
	"(JQ)KK",
	"JQQQ",
	"(JK)QQ",
	"J(QK)Q",
	"JQQK",
	"(JK)(QK)",
	"(JK)QK",
	"J(QK)K",
	"JQKK",
	"(JK)KK",
	"JKKK",
	"QQQQ",
	"(QK)QQ",
	"QQQK",
	"(QK)(QK)",
	"(QK)QK",
	"QQKK",
	"(QK)KK",
	"QKKK",
	"KKKK",
];

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const currencies = [
	"CAD",
	"USD",
	"GBP",
	"AED",
	"AFN",
	"ALL",
	"AMD",
	"ANG",
	"AOA",
	"ARS",
	"AUD",
	"AWG",
	"AZN",
	"BAM",
	"BBD",
	"BDT",
	"BGN",
	"BHD",
	"BIF",
	"BMD",
	"BND",
	"BOB",
	"BRL",
	"BSD",
	"BTC",
	"BTN",
	"BWP",
	"BYN",
	"BZD",
	"CDF",
	"CHF",
	"CLF",
	"CLP",
	"CNH",
	"CNY",
	"COP",
	"CRC",
	"CUC",
	"CUP",
	"CVE",
	"CZK",
	"DJF",
	"DKK",
	"DOP",
	"DZD",
	"EGP",
	"ERN",
	"ETB",
	"EUR",
	"FJD",
	"FKP",
	"GEL",
	"GGP",
	"GHS",
	"GIP",
	"GMD",
	"GNF",
	"GTQ",
	"GYD",
	"HKD",
	"HNL",
	"HRK",
	"HTG",
	"HUF",
	"IDR",
	"ILS",
	"IMP",
	"INR",
	"IQD",
	"IRR",
	"ISK",
	"JEP",
	"JMD",
	"JOD",
	"JPY",
	"KES",
	"KGS",
	"KHR",
	"KMF",
	"KPW",
	"KRW",
	"KWD",
	"KYD",
	"KZT",
	"LAK",
	"LBP",
	"LKR",
	"LRD",
	"LSL",
	"LYD",
	"MAD",
	"MDL",
	"MGA",
	"MKD",
	"MMK",
	"MNT",
	"MOP",
	"MRU",
	"MUR",
	"MVR",
	"MWK",
	"MXN",
	"MYR",
	"MZN",
	"NAD",
	"NGN",
	"NIO",
	"NOK",
	"NPR",
	"NZD",
	"OMR",
	"PAB",
	"PEN",
	"PGK",
	"PHP",
	"PKR",
	"PLN",
	"PYG",
	"QAR",
	"RON",
	"RSD",
	"RUB",
	"RWF",
	"SAR",
	"SBD",
	"SCR",
	"SDG",
	"SEK",
	"SGD",
	"SHP",
	"SLL",
	"SOS",
	"SRD",
	"SSP",
	"STD",
	"STN",
	"SVC",
	"SYP",
	"SZL",
	"THB",
	"TJS",
	"TMT",
	"TND",
	"TOP",
	"TRY",
	"TTD",
	"TWD",
	"TZS",
	"UAH",
	"UGX",
	"UYU",
	"UZS",
	"VES",
	"VND",
	"VUV",
	"WST",
	"XAF",
	"XAG",
	"XAU",
	"XCD",
	"XDR",
	"XOF",
	"XPD",
	"XPF",
	"XPT",
	"YER",
	"ZAR",
	"ZMW",
	"ZWL",
];
