export default function validateShareholderBreakdown(shb, dealType) {
	let totalAction = 0;
	let totalBankroll = 0;

	Object.keys(shb).forEach((person) => {
		totalAction = totalAction + parseFloat(shb[person]?.backing) + parseFloat(shb[person]?.action);
		totalBankroll += parseFloat(shb[person]?.bankroll);
	});

	if (totalAction !== 100) {
		alert("Total action should sum to 100%");
		return;
	}
	if (totalBankroll !== 100 && dealType !== "backing") {
		alert("Total bankroll equty should sum to 100% for action or hybrid deals");
		return;
	}

	return true;
}
