import React, { Component } from "react";
import { Link } from "react-router-dom";

const tools = {
	pioMassExport: "pio-mass-export",
};

export default class Tools extends Component {
	render() {
		return (
			<div>
				<h2>Dojo Misc Tools</h2>
				<br />
				<div>
					<span className="tool-label">Pio Mass Export Tool</span>
					<Link to="/tools/pio-mass-export">
						<button className="tool-button">Launch</button>
					</Link>
				</div>
			</div>
		);
	}
}
