import { useEffect, useState } from 'react';
import ButtonArrowIcon from '../../../css/images/btn-arrow.svg';
import FreePlanIcon from '../../../css/images/free-plan.svg';
import BasicPlanIcon from '../../../css/images/basic.svg';
import ProPlanIcon from '../../../css/images/pro.svg';
import ChecklistIcon from '../../../css/images/check-list.svg';
import closelistIcon from '../../../css/images/close-list.svg';
import freeTrail from '../../../css/images/free-trail.svg';
import useSignupReducer from '../reducer/signupReducer';
import { Loader } from '../../common/Loader';
import ActivationModal from './activationCodeModal';
export const Plans = ({ currentTab, switchTab, setData, data, history }) => {
    const {
        getPlans,
        plans,
        isPlanLoading,
        createUser,
        successMessage,
        isSignupLoading,
    } = useSignupReducer((state) => state);

    const { planDetails, personalInfo } = data;
    const [currentPlan, setCurrentPlan] = useState('');
    const [activationModal, setActivationModal] = useState(false);
    useEffect(() => {
        getPlans();
    }, []);

    useEffect(() => {
        if (successMessage === 'User created successfully') {
            history.push('/login');
        }
    }, [successMessage]);
    console.log('data',data)
    useEffect(() => {
        console.log(
            'Object.keys(currentPlan).length',
            Object.keys(currentPlan).length
        );
        if (Object.keys(currentPlan).length !== 0) {
            setData({
                ...data,
                planDetails: {
                    id: currentPlan.id,
                    icon: currentPlan.icon,
                    name: currentPlan.name,
                    amount: currentPlan.amount,
                    description: currentPlan.description,
                    period: currentPlan.period,
                    priceId: currentPlan.priceId,
                    trialPeriod: currentPlan.trialPeriod,
                },
            });
        }
    }, [currentPlan]);

    useEffect(() => {
        if (currentPlan.amount !== undefined) {
            const icon = handlePlanIcon(currentPlan.amount);
            setCurrentPlan((prev) => ({ ...prev, icon }));
        }
    }, [currentPlan.amount]);

    const handlePlanIcon = (amount) => {
        if (amount === 0) {
            return FreePlanIcon;
        } else if (amount <= 500) {
            return BasicPlanIcon;
        } else if (amount > 500) {
            return ProPlanIcon;
        } else return null;
    };
    const handleSubmit = () => {
        let payload = {
            name: personalInfo.name,
            // lastName: personalInfo.lastName,
            email: personalInfo.email,
            // phone: data.phoneNumber,

            // countryCode: '+91',
            // stripeCustomerId: customerId,
            planId: planDetails?.id,
        };
        if (personalInfo.googleUserData) {
            payload.googleUID = personalInfo.googleUserData.uid;
        } else {
            payload.password = personalInfo.password;
            payload.confirmPassword = personalInfo.confirmPassword;
        }
        createUser(payload);
    };
    return (
        <div
            className={`form-steps plan step-2 ${
                currentTab !== 'choosePlan' ? 'd-none' : ''
            }`}
        >
            <div className="row message1">
                {isPlanLoading ? (
                    <Loader />
                ) : (
                    plans?.map((e, index) => (
                        <div className="col-lg-4" key={index}>
                            <div className="plan-card-checkbox">
                                <label
                                    htmlFor={`plan${index}`}
                                    className="custom-chk"
                                >
                                    <input
                                        type="radio"
                                        name="planCard"
                                        id={`plan${index}`}
                                        className="custom-chk-inp"
                                        onChange={() => setCurrentPlan(e)}
                                        checked={planDetails.id === e.id}
                                    />
                                    <span className="radio" />
                                    {e?.trialPeriod > 0 && (
                                        <div className="freetrail-text1">
                                            <button className="freetrail-text">
                                                <span>
                                                    <img
                                                        src={freeTrail}
                                                        alt=""
                                                    />
                                                </span>
                                                <span>
                                                    Try it free for{' '}
                                                    {e?.trialPeriod}{' '}
                                                    {e?.trialPeriod > 1
                                                        ? 'Days'
                                                        : 'Day'}
                                                </span>
                                            </button>
                                        </div>
                                    )}
                                    <div className="plan-card">
                                        <div className="plan-head">
                                            <div className="plan-titles">
                                                <div className="txt">
                                                    <h3 className="service">
                                                        <span className="icon">
                                                            <img
                                                                src={handlePlanIcon(
                                                                    e.amount
                                                                )}
                                                                alt=""
                                                            />
                                                        </span>
                                                        {e.name}
                                                    </h3>
                                                    <div className="short-desc">
                                                        {e.description}
                                                    </div>
                                                    <h3 className="rate">
                                                        <span className="amt">
                                                            ${e.amount}
                                                        </span>
                                                        <span className="billcycle">
                                                            /{e.period}
                                                        </span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plan-info">
                                            <ul className="plan-info-list">
                                                {e.features?.map((i, index) => (
                                                    <li
                                                        className="list-itm"
                                                        key={index}
                                                    >
                                                        <span className="ico">
                                                            <img
                                                                src={
                                                                    ChecklistIcon
                                                                }
                                                                alt=""
                                                            />
                                                        </span>
                                                        <div className="txt-inner">
                                                            <span className="txt title">
                                                                {i.title}:
                                                            </span>
                                                            <br />
                                                            <span className="txt">
                                                                {i.description}
                                                            </span>
                                                        </div>
                                                    </li>
                                                ))}
                                                {/* <li className="list-itm">
                          <span className="ico">
                            <img src={closelistIcon} alt="" />
                          </span>
                          <span className="txt">Community Access</span>
                        </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))
                )}
            </div>

            <div class="sep-wrp">
                <h3 class="sep-title">OR</h3>
            </div>
            <div class="sep-msg">
                <span>Do you Already Have an Activation Code?</span>
                <span>
                    <a onClick={() => setActivationModal(true)}>
                        Please click here
                    </a>
                </span>
            </div>

            <div className="signup-action-footer">
                <button
                    className="btn btn-primary actn-arrows prev"
                    onClick={() => switchTab('personalInfo')}
                >
                    <span className="ico">
                        <img src={ButtonArrowIcon} alt="previous" />
                    </span>
                    <span className="txt">Prev</span>
                </button>
                {Object.keys(currentPlan).length ||
                Object.keys(planDetails).length ? (
                    planDetails.amount > 0 ? (
                        <button
                            className="btn btn-primary actn-arrows next"
                            onClick={() => switchTab('payment')}
                        >
                            <span className="txt">Next</span>
                            <span className="ico">
                                <img src={ButtonArrowIcon} alt="next" />
                            </span>
                        </button>
                    ) : (
                        <button
                            className="btn btn-primary actn-arrows"
                            onClick={handleSubmit}
                        >
                            <span className="txt">
                                {isSignupLoading ? 'Loading...' : 'Sign Up'}
                            </span>
                        </button>
                    )
                ) : null}
            </div>
            <ActivationModal
                showModal={activationModal}
                setShowModal={setActivationModal}
                personalInfo={personalInfo}
            />
        </div>
    );
};
