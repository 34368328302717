import { ranks, suits } from "../../../constants";

export default function exportRangeToPio(range, setCopySuccess) {
	let pioRange = "";
	for (const s1 of suits) {
		for (const r1 of ranks) {
			// Get first card
			const card1 = r1 + s1;
			for (const s2 of suits) {
				for (const r2 of ranks) {
					// Get second card and check if its valid/not already in pioRange
					const card2 = r2 + s2;
					if (card1 === card2) continue;
					if (pioRange.includes(card2 + card1)) continue;
					let handKey = r1 + r2;
					if (ranks.indexOf(r1) > ranks.indexOf(r2)) handKey = r2 + r1;
					if (r1 !== r2) {
						if (s1 !== s2) handKey += "o";
						else handKey += "s";
					}
					pioRange = `${card1}${card2}: ${range.hasOwnProperty(handKey) && range[handKey] ? range[handKey] : "0"}, \n` + pioRange;
				}
			}
		}
	}
	pioRange = pioRange.substring(0, pioRange.length - 3);
	navigator.clipboard.writeText(pioRange);
	setCopySuccess(true);
}
