import { PLOTrainingHandsArray } from "../../../constants";
import { countOccurrences, hasDuplicates, hasTriples, removeDuplicates } from "../../../helpers";

export default function filterHands(query, filters) {
	let hands = PLOTrainingHandsArray;

	// filter pairs
	if (filters[0]) {
		hands = hands.filter((hand) => {
			const h = hand.replaceAll("(", "").replaceAll(")", "");
			if (filters[0] === "0p") return !hasDuplicates(h);
			else if (filters[0] === "trips") return hasTriples(h.split("")) && removeDuplicates(h).length !== 1;
			else if (filters[0] === "1p") return hasDuplicates(h) && removeDuplicates(h).length === 3;
			else if (filters[0] === "2p") return removeDuplicates(h).length === 2 && !hasTriples(h.split(""));
		});
	}

	// filter suits
	if (filters[1]) {
		hands = hands.filter((hand) => {
			if (filters[1] === "rainbow") return !hand.includes("(");
			else if (filters[1] === "mono") return hand.startsWith("(") && hand.endsWith(")") && countOccurrences(hand.split(""), "(") === 1;
			else if (filters[1] === "ds") return hand.startsWith("(") && hand.endsWith(")") && countOccurrences(hand.split(""), "(") === 2;
			else if (filters[1] === "2s") return countOccurrences(hand.split(""), "(") === 1 && hand.indexOf("(") === hand.indexOf(")") - 3;
			else if (filters[1] === "3s") return countOccurrences(hand.split(""), "(") === 1 && hand.indexOf("(") === hand.indexOf(")") - 4;
		});
	}

	// filter query
	const queryArray = query.split("");
	hands = hands.filter((hand) => {
		if (
			queryArray.every((card) => {
				const h = hand.split("");
				if (h.includes(card) && countOccurrences(h, card) >= countOccurrences(queryArray, card)) return true;
			})
		)
			return true;
	});

	return hands;
}
