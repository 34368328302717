import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import drdSplash from '../css/images/drd-splash.png';
import drtSplash from '../css/images/drt-splash.png';
import dataSplash from '../css/images/data-splash2.png';

//import common components
// import Header from './common/Header';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: { signInSuccessWithAuthResult: () => false },
};

function handleScroll() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    const navbar = document.querySelector('.splash-top-cnt');
    navbar.style.paddingTop = '0.4rem';
    navbar.style.paddingBottom = '0.4rem';
  } else {
    document.querySelector('.splash-top-cnt').style.padding = null;
  }
}

//TODO: fix rem on zoomout? do I set a minimum for that or just leave it for unzoomers?
//TODO: All the content should be in one div column, almost certainly no? Offset that whole column by a bit and then just have different colour backgrounds
export default function Splash() {
  const [showOverlay, showOverlayToggle] = useState(false);
  const [overlayText, overlayTextSet] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleOverlay = (text) => {
    showOverlayToggle(true);
    text === 'signup'
      ? overlayTextSet('Create an account:')
      : overlayTextSet('Log into your account:');
  };

  return (
    <div className="splash-page">
      {/* <Header /> */}
      <header className="splash-top-cnt">
        <span className="splash-top-text">Preflop Mastery</span>
        <div className="right-sec">
          <Link to="/login">
            <button className="splash-btn">Log In</button>
          </Link>
          <Link to="/signup">
            <button className="splash-btn signup">Sign Up</button>
          </Link>
        </div>
      </header>

      {/* <div
        className={`splash-overlay ${!showOverlay ? 'display-none' : ''}`}
        onClick={() => showOverlayToggle(false)}>
        <div
          id="splash-ui"
          className="absolute-center"
          onClick={(e) => e.stopPropagation()}>
          <h4>{overlayText}</h4>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      </div> */}
      <div className="splash-section splash-red">
        <h1 className="splash-header">Master the Preflop. Master your game.</h1>
        <h3 className="splash-subheader">
          Preflop Mastery is the ultimate tool to perfect your GTO execution of
          Texas Hold'em and PLO's most fundamental street.
        </h3>
        {/* <button
          className="splash-btn signup big"
          onClick={() => toggleOverlay('signup')}
        >
          Try It Now for Free
        </button> */}
        <Link className="splash-btn signup" to="/signup">
          Try It Now for Free
        </Link>
      </div>

      <div className="splash-col">
        <div className="splash-content-cnt">
          <img className="splash-drd" src={drdSplash} />
          <div className="splash-text-cnt">
            <h3>Range Display</h3>
            <p className="splash-body">
              Ranges are the heart of high level poker play - use Range Display
              to access ranges for any preflop spot across dozens of
              highly-accurate GTO trees.
            </p>
            <p className="splash-body">
              Optimal strategy changes based on different open sizes, rake,
              antes, and player counts. Master every scenario and you can never
              be caught off guard.
            </p>
          </div>
        </div>
        <div className="splash-content-cnt mobile-flip">
          <div className="splash-text-cnt">
            <h3>Range Trainer</h3>
            <p className="splash-body">
              Drill yourself with practice reps and receive instant feedback.
            </p>
            <p className="splash-body">
              Train every spot or target your focus on a positional matchup.
              Hone precision with RNG-roll mixing to score high while deeply
              internalizing ranges.
            </p>
          </div>
          <img className="splash-drt" src={drtSplash} />
        </div>
        <div className="splash-content-cnt ">
          <img className="splash-drd" src={dataSplash} />
          <div className="splash-text-cnt">
            <h3>Training Data</h3>
            <p className="splash-body">
              Review data from your training reps with tailored statistics.
              Highlight positions, recent reps, and areas to improve.
            </p>
            <p className="splash-body">
              Plug every last leak to achieve preflop mastery.
            </p>
          </div>
        </div>
        <div className="splash-cta">
          <Link className="splash-btn signup" to="/signup">
            Sign Up Now
          </Link>
        </div>
      </div>
      <div className="splash-section splash-dark"></div>
      <div className="splash-section splash-red"></div>
    </div>
  );
}

// import React, { useState } from "react";
// import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
// import firebase from "firebase";
// import drdSplash from "../css/images/drd-splash.png";
// import drtSplash from "../css/images/drt-splash.png";

// // Configure FirebaseUI.
// const uiConfig = {
// 	// Popup signin flow rather than redirect flow.
// 	signInFlow: "popup",
// 	signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.GoogleAuthProvider.PROVIDER_ID],
// 	callbacks: { signInSuccessWithAuthResult: () => false },
// };

// window.onscroll = function () {
// 	scrollFunction();
// };
// function scrollFunction() {
// 	if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
// 		const navbar = document.querySelector(".splash-top-cnt");
// 		navbar.style.paddingTop = "0.4rem";
// 		navbar.style.paddingBottom = "0.4rem";
// 	} else {
// 		document.querySelector(".splash-top-cnt").style.padding = null;
// 	}
// }

// //TODO: fix rem on zoomout? do I set a minimum for that or just leave it for unzoomers?
// //TODO: All the content should be in one div column, almost certainly no? Offset that whole column by a bit and then just have different colour backgrounds
// export default function Splash() {
// 	const [showOverlay, showOverlayToggle] = useState(false);
// 	const [overlayText, overlayTextSet] = useState("");

// 	const toggleOverlay = (text) => {
// 		showOverlayToggle(true);
// 		text === "signup" ? overlayTextSet("Create an account:") : overlayTextSet("Log into your account:");
// 	};

// 	return (
// 		<div className="splash-page">
// 			<header className="splash-top-cnt">
// 				<span className="splash-top-text">Preflop Mastery</span>
// 				<div>
// 					<button className="splash-btn" onClick={() => toggleOverlay("login")}>
// 						Log In
// 					</button>
// 					<button className="splash-btn signup" onClick={() => toggleOverlay("signup")}>
// 						Sign Up
// 					</button>
// 				</div>
// 			</header>
// 			<div className={`splash-overlay ${!showOverlay ? "display-none" : ""}`} onClick={() => showOverlayToggle(false)}>
// 				<div id="splash-ui" className="absolute-center" onClick={(e) => e.stopPropagation()}>
// 					<h4>{overlayText}</h4>
// 					<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
// 				</div>
// 			</div>
// 			<div className="splash-section splash-red">
// 				<h1 className="splash-header">Master the Preflop. Master your game.</h1>
// 				<h3 className="splash-subheader">
// 					Preflop Mastery is the ultimate tool to perfect your GTO execution of Texas Hold'em and PLO's most fundamental street.
// 				</h3>
// 			</div>
// 			<div className="splash-section splash-dark">
// 				<div className="splash-content-cnt splash-1">
// 					<img className="splash-drd" src={drdSplash} />
// 					<div className="splash-text-cnt">
// 						<h3>Range Display</h3>
// 						<p className="splash-body">
// 							Ranges are the heart of high level poker play - use Range Display to instantly access ranges for any preflop spot across
// 							dozens of highly-accurate GTO trees.
// 						</p>
// 						<p className="splash-body">
// 							Optimal strategy changes based on different open sizes, rake, antes, and player counts. Master every scenario and you can
// 							never be caught off guard.
// 						</p>
// 					</div>
// 				</div>
// 				<div className="splash-content-cnt splash-2 mobile-flip">
// 					<div className="splash-text-cnt">
// 						<h3>Range Trainer</h3>
// 						<p className="splash-body">
// 							Ranges are the heart of high level poker play - use Range Display to instantly access ranges for any preflop spot across
// 							dozens of highly-accurate GTO trees.
// 						</p>
// 						<p className="splash-body">
// 							Optimal strategy changes based on different open sizes, rake, antes, and player counts. Master every scenario and you can
// 							never be caught off guard.
// 						</p>
// 					</div>
// 					<img className="splash-drt" src={drtSplash} />
// 				</div>
// 			</div>
// 			<div className="splash-section splash-red">
// 				<div className="splash-content-cnt  splash-3">
// 					<img className="splash-drd" src={drdSplash} />
// 					<div className="splash-text-cnt">
// 						<h3>Training Data</h3>
// 						<p className="splash-body">
// 							Ranges are the heart of high level poker play - use Range Display to instantly access ranges for any preflop spot across
// 							dozens of highly-accurate GTO trees.
// 						</p>
// 						<p className="splash-body">
// 							Optimal strategy changes based on different open sizes, rake, antes, and player counts. Master every scenario and you can
// 							never be caught off guard.
// 						</p>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }
