// React
import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../../UserContext";
// RTComponents
import Loading from "../../Loading";
import TableView from "./TableView";
import TrainingTableButtons from "./TrainingTableButtons";
// RTFunctions
import checkIfSpotIsMixed from "../functions/checkIfSpotIsMixed";
import getActionFrequencies from "../functions/getActionFrequencies";
import getActionSummaryFrames from "../functions/getActionSummaryFrames";
import getCorrectAction from "../functions/getCorrectAction";
import isCheckAnAvailableAction from "../functions/isCheckAnAvailableAction";
import getRaiseSize from "../../Shared/functions/getRaiseSize";
import getActionCumulativeFrequency from "../functions/getActionCumulativeFrequency";
import sortActions from "../functions/sortActions";
// Helpers
import { capitalize, cloneDeep, round } from "lodash";
import { actionNames, allTrainingScenarios } from "../../../constants";
import { getAccuracy, lastElement, sleep } from "../../../helpers";

export default function TrainingTable({
	props,
	finishRep,
	reviewHand,
	resumeTraining,
	markRep,
	showRanges,
	handlePlayerSpotMouseEnter,
	handlePlayerSpotMouseLeave,
	toggleRangeDisplay,
}) {
	const context = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [actionSummaries, setActionSummaries] = useState([]);
	const [currentActionSummary, setCurrentActionSummary] = useState([]);
	const [rngRoll, setRngRoll] = useState(null);
	const [message, setMessage] = useState("");
	const [messageStatus, setMessageStatus] = useState("");
	const [tableRepHistory, setTableRepHistory] = useState([]);
	const [reviewIndex, setReviewIndex] = useState(null);
	// for animation frame consistency
	const [playCounter, setPlayCounter] = useState(0);
	const playCounterRef = useRef(playCounter);
	const rangesRef = useRef(props.ranges);

	useEffect(() => {
		window.addEventListener("keydown", handleHotkeys);
		return () => window.removeEventListener("keydown", handleHotkeys);
	});

	// hotkey is janking up in a similar way - need to figure out why these don't actually access updated &#@!&#ing state
	const handleHotkeys = (e) => {
		if (loading || e.altKey || e.ctrlKey || e.shiftKey) return;
		const availableActions = sortActions(Object.keys(rangesRef.current));
		if (e.key === "f" || e.key === "1") checkAnswer("0");
		else if ((e.key === "c" || e.key === "2") && availableActions.includes("1")) checkAnswer("1");
		else if (e.key === "r" || e.key === "3") {
			if (availableActions.hasOwnProperty("3")) checkAnswer("3");
			else {
				const action = availableActions.reduce((acc, val) => {
					return Math.max(acc, parseInt(val));
				}, 0);
				if (![1].includes(action)) checkAnswer(action);
			}
		} else if (e.key === " " || e.key === "4") rollRng();
		// Manual Deal hotkey for testing
		// else if (e.key === "0"):
		// this.deal();
	};

	// On component load, get all the 'frames' of action, to flip through at 500ms - or jump to last for fast deal
	useEffect(() => {
		setLoading(true);
		setRngRoll(null);
		setPlayCounter(playCounter + 1);
		playCounterRef.current = playCounter + 1;
		rangesRef.current = props.ranges;
		if (props.message) {
			setMessage(props.message);
			if (reviewIndex === null) setMessageStatus(props.messageStatus);
			else setMessageStatus("");
		}
		const actionSummaryFrames = getActionSummaryFrames(props.treeInfo, props.spot);
		if (context.settings.rtFastDeal) {
			setCurrentActionSummary(lastElement(actionSummaryFrames));
			setLoading(false);
		} else {
			setCurrentActionSummary(actionSummaryFrames[0]);
			playActionFrames(actionSummaryFrames, playCounter + 1);
			setLoading(false);
		}
		setActionSummaries(actionSummaryFrames);
	}, [props.repID]);

	async function playActionFrames(frames, index) {
		for (let i = 1; i < frames.length; i++) {
			await sleep(500);
			if (index === playCounterRef.current) setCurrentActionSummary(frames[i]);
		}
	}

	const rollRng = () => {
		if (reviewIndex !== null) return; // Ignores mix hotkey if in review of previous hands
		if (context.settings.rtOptions.mix === "high-freq") return; // Ignores rngRoll hotkey if not training mixing
		if (rngRoll) return; // Only roll once per hand
		let rng = round((Math.floor(Math.random() * 100) + 1) / 100, 3);
		setRngRoll(round(rng, 3));
	};

	//TODO: if more than one tree add in the tree Name
	const checkAnswer = (action) => {
		if (loading || reviewIndex !== null) return;
		let score;
		let messageStatus = "incorrect";
		let statusDetail = "";
		let frequencySummary = "";
		let pos = lastElement(props.spot.split(","));
		const actionFrequencies = getActionFrequencies(props.ranges, props.hand[0], context.settings.rtOptions.mix);
		const actionNamesCopy = cloneDeep(actionNames);
		if (isCheckAnAvailableAction()) {
			if (action === "0") return; // Fails to submit a fold answer if the option existed to check
			actionNamesCopy["1"] = "Check";
			delete actionFrequencies["0"];
		}
		//FIXME: when rtRngLowToHigh is ON, reverse the order that the answers are checked in
		// see your thoughts about this here: https://amar.academy/topic/3582/introducing-dojo-preflop-mastery-preflop-range-trainer/68?_=1642092691453
		const correctAction = getCorrectAction(actionFrequencies, rngRoll);
		const spotIsMixed = checkIfSpotIsMixed(actionFrequencies);
		action = action.toString();
		let selectedActionName = Object.keys(actionNamesCopy).includes(action) ? actionNamesCopy[action] : `Raise`;
		let correctActionName = Object.keys(actionNamesCopy).includes(correctAction)
			? actionNamesCopy[correctAction]
			: `Raise ${getRaiseSize(correctAction, props.treeInfo.chipSize)}`;
		Object.keys(actionFrequencies).forEach((act) => {
			let ev;
			if (props.treeInfo.evEnabled) {
				ev =
					act === "0" ? round(currentActionSummary[pos].amountCommitted * -1000) : props.evs[act][props.hand[0]] * props.treeInfo.chipSize;
			}
			const actionName = Object.keys(actionNamesCopy).includes(act)
				? actionNamesCopy[act]
				: `Raise ${getRaiseSize(act, props.treeInfo.chipSize)}`;
			frequencySummary += `${actionName}  -  ${Math.round(actionFrequencies[act] * 1000) / 10}%${
				props.treeInfo.evEnabled ? `  (EV: ${round(ev)})` : ""
			}\n`;
		});
		if (context.settings.rtOptions.mix === "high-freq") {
			if (action === correctAction) {
				messageStatus = "correct";
				score = "1/1";
			} else score = "0/1";
		} else {
			// Mix spot, Mix button clicked
			if (spotIsMixed && rngRoll) {
				if (action === correctAction) {
					messageStatus = "correct";
					score = "3/3";
				} else {
					messageStatus = "inaccurate";
					if (actionFrequencies[action] === 0) score = `1/3`;
					// For every pip away from the correct rng-based answer, knock 10% off the result
					else {
						// Check if the action was an over-aggressive estimate or under estimate and calculate the distance from the RNG roll based on that
						let s;
						if (action === "3" || parseInt(action) > parseInt(correctAction)) {
							s = Math.floor(Math.abs(getActionCumulativeFrequency(actionFrequencies, action) - rngRoll) / 0.1) + 1;
						} else {
							s =
								Math.floor(Math.abs(getActionCumulativeFrequency(actionFrequencies, action) + actionFrequencies[action] - rngRoll) / 0.1) +
								1;
						}
						score = `${round((1 - s / 10) * 3, 1)}/3`;
					}
					statusDetail = `${correctActionName} was correct given the RNG.\n`;
				}
			}
			// Mix spot, Mix button was NOT clicked
			else if (spotIsMixed && !rngRoll) {
				messageStatus = "inaccurate";
				score = `${round(actionFrequencies[action] * 2, 1)}/2`;
				statusDetail = `Spot is mixed. `;
			}
			// Not a Mix spot, Mix button was clicked
			else if (!spotIsMixed && rngRoll) {
				if (action === correctAction) {
					messageStatus = "inaccurate";
					score = "2/3";
					statusDetail = `Correct answer but spot isn't mixed.\n`;
				} else {
					messageStatus = "incorrect";
					score = "0/3";
					statusDetail = `${capitalize(correctActionName)} (no mix) was the correct choice.\n`;
				}
			}
			// Not a Mix spot, Mix button was NOT clicked
			else {
				if (action === correctAction) {
					messageStatus = "correct";
					if (correctAction === "0") score = "1/1";
					else score = "2/2";
				} else {
					messageStatus = "incorrect";
					score = "0/2";
				}
			}
		}
		statusDetail += `Score +${score}`;
		const rngText = rngRoll && context.settings.rtRngLowToHigh ? `${100 - round(rngRoll * 100, 0)}` : `${round(rngRoll * 100, 0)}`;
		let message = `${selectedActionName} is ${capitalize(messageStatus)}! ${statusDetail} \nSituation: ${
			allTrainingScenarios[props.scenario]
		} as ${pos.toUpperCase()} - Hand: ${props.hand[0]} ${rngRoll ? `- RNG: ${rngText}` : ""} \n${frequencySummary}`;

		setMessage(message);
		setMessageStatus(messageStatus);
		setTableRepHistory([...tableRepHistory, props.rep]);
		finishRep(props, action, correctAction, score, rngRoll, message, messageStatus);
	};

	const viewPreviousHand = () => {
		const newReviewIndex = reviewIndex ? reviewIndex - 1 : tableRepHistory.length - 1;
		setReviewIndex(newReviewIndex);
		playCounterRef.current = newReviewIndex;
		reviewHand(props.tableIndex, tableRepHistory[newReviewIndex], message, messageStatus);
	};
	const viewNextHand = () => {
		if (reviewIndex === tableRepHistory.length - 1) handleResumeTraining();
		else {
			setReviewIndex(reviewIndex + 1);
			reviewHand(props.tableIndex, tableRepHistory[reviewIndex + 1], message, messageStatus);
		}
	};
	const handleResumeTraining = () => {
		setReviewIndex(null);
		playCounterRef.current = null;
		resumeTraining(props.tableIndex);
	};
	const handleMarkRep = () => {
		const index = reviewIndex === null ? tableRepHistory.length - 1 : reviewIndex;
		markRep(props.tableIndex, tableRepHistory[index]);
	};

	const handleShowRanges = () => showRanges(props.tableIndex, props.treeID, props.treeInfo, props.spot, props.hand);

	let tableViewDisplay, tableButtons;
	tableViewDisplay = loading ? (
		<Loading />
	) : (
		<TableView
			treeID={props.treeID}
			treeInfo={props.treeInfo}
			spot={props.spot}
			actionSummary={currentActionSummary}
			hand={props.hand}
			prevFreqDisplaySetting={context.settings.rtShowPrevFreq}
			prevFreq={props.prevFreq}
			handleMouseEnter={handlePlayerSpotMouseEnter}
			handleMouseLeave={handlePlayerSpotMouseLeave}
			toggleRangeDisplay={toggleRangeDisplay}
		/>
	);
	tableButtons = loading ? (
		<></>
	) : (
		<TrainingTableButtons
			treeInfo={props.treeInfo}
			ranges={props.ranges}
			rngRoll={rngRoll && context.settings.rtRngLowToHigh ? 1 - rngRoll : rngRoll}
			rollRng={rollRng}
			spot={props.spot}
			scenario={props.scenario}
			actionSummary={lastElement(actionSummaries)} // to determine price to call + replacing playerSpotArray
			mixOptions={context.settings.rtOptions.mix}
			checkAnswer={checkAnswer}
			//from table view's portion
			message={message}
			messageStatus={messageStatus}
			// review
			reviewIndex={reviewIndex}
			hidePreviousButton={props.rep === 1 || reviewIndex === 0}
			viewPreviousHand={viewPreviousHand}
			viewNextHand={viewNextHand}
			handleResumeTraining={handleResumeTraining}
			handleShowRanges={handleShowRanges}
			handleMarkRep={handleMarkRep}
		/>
	);

	return (
		<div id={`table-${props.tableIndex}`} className="training-table-container">
			{tableViewDisplay}
			{tableButtons}
		</div>
	);
}
