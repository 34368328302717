import { round } from "lodash";
import { PLOTrainingHandsArray } from "../../../constants";
import { countOccurrences, hasDuplicates, hasTriples, removeDuplicates } from "../../../helpers";

export default function getPLOCombos(range) {
	const totalCombos = PLOTrainingHandsArray.reduce((total, hand) => {
		const splitHand = [];
		let group = "";
		let open = false;
		for (let i = 0; i < hand.length; i++) {
			if (hand[i] === "(") open = true;
			else if (hand[i] === ")") {
				open = false;
				splitHand.push(group);
				group = "";
			} else if (open) group += hand[i];
			else splitHand.push(hand[i]);
		}
		// iterate over each hand in suitedness 'chunks', there are always at least 4 combos
		let combos = 1;
		let possibleSuitOptions = 4;
		splitHand.forEach(() => {
			combos = combos * possibleSuitOptions;
			possibleSuitOptions -= 1;
		});

		// edge cases
		// rainbow hands w/ a triple only have 4 possible combos
		if (hasTriples(hand.split("")) & (splitHand.length === 4)) combos = 4;
		else if (hasDuplicates(splitHand)) {
			// specifically 2 pair rainbow hands (6 combos)
			if (removeDuplicates(splitHand).length === 2 && !hand.includes("(")) combos = combos / 4;
			// any other 1 pair hand has half the combos if it has a duplicate chunk (if one part of the pair is coupled no need to halve combos)
			else combos = combos / 2;
		}

		if (!range[hand].f) return total;
		combos = combos * range[hand].f;
		return total + combos;
	}, 0);
	return round(totalCombos, 1);
}
