import React from "react";

const suitColors = {
	s: "#313236",
	d: "#2e6fea",
	h: "#b02822",
	c: "#4da208",
};

export default class Card extends React.Component {
	render() {
		let rank = this.props.cardInfo.split("")[0].toUpperCase();
		let suit = this.props.cardInfo.split("")[1];
		let suitColor = suitColors[suit];
		let cardX = this.props.fullRing ? 300 : 530;
		let cardY = this.props.fullRing ? 380 : 410;
		return (
			<svg>
				<g>
					<rect width="70" height="95" x={`${cardX + this.props.cardNumber * 40}`} y={cardY} rx="1" fill={suitColor} stroke="white" strokeWidth="2" />
					<text x={`${cardX + 15 + this.props.cardNumber * 40}`} y={cardY + 30} textAnchor="middle" fontSize="1.8em" fill="white">
						{rank}
					</text>
				</g>
			</svg>
		);
	}
}
