import { allHandsMatrix } from "../../../constants";
import { removeDuplicates } from "../../../helpers";

export default function getHandsInRadius(hand, tightHandSelect = false) {
	const handsInRadius = [];
	let yIndex;
	let xIndex;

	// Find the matrix index/'coordinates' of the hand in the allHandsMatrix
	for (let i = 0; i < allHandsMatrix.length; i++) {
		for (let j = 0; j < allHandsMatrix.length; j++) {
			if (hand === allHandsMatrix[i][j]) {
				yIndex = i;
				xIndex = j;
			}
		}
	}

	//TODO: TEST TIGHT HAND SELECTION

	// // Get all hands in a 2 square radius
	// for (let i = 0; i <= 2; i++) {
	// 	for (let j = 0; j <= 2; j++) {
	// 		allHandsMatrix[yIndex + i]?.[xIndex + j] ? handsInRadius.push(allHandsMatrix[yIndex + i][xIndex + j]) : "";
	// 		allHandsMatrix[yIndex - i]?.[xIndex + j] ? handsInRadius.push(allHandsMatrix[yIndex - i][xIndex + j]) : "";
	// 		allHandsMatrix[yIndex + i]?.[xIndex - j] ? handsInRadius.push(allHandsMatrix[yIndex + i][xIndex - j]) : "";
	// 		allHandsMatrix[yIndex - i]?.[xIndex - j] ? handsInRadius.push(allHandsMatrix[yIndex - i][xIndex - j]) : "";
	// 	}
	// }
	if (tightHandSelect) {
		allHandsMatrix[yIndex]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex][xIndex - 1]) : "";

		allHandsMatrix[yIndex + 1]?.[xIndex] ? handsInRadius.push(allHandsMatrix[yIndex + 1][xIndex]) : "";
		allHandsMatrix[yIndex - 1]?.[xIndex] ? handsInRadius.push(allHandsMatrix[yIndex - 1][xIndex]) : "";

		allHandsMatrix[yIndex]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex][xIndex - 1]) : "";
		return removeDuplicates(handsInRadius);
	}
	// Get all hands in a shape like this
	//       o
	//    o  o  o
	// o  o  o  o  o
	//    o  o  o
	//       o
	else {
		allHandsMatrix[yIndex]?.[xIndex - 2] ? handsInRadius.push(allHandsMatrix[yIndex][xIndex - 2]) : "";

		allHandsMatrix[yIndex + 1]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex + 1][xIndex - 1]) : "";
		allHandsMatrix[yIndex]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex][xIndex - 1]) : "";
		allHandsMatrix[yIndex - 1]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex - 1][xIndex - 1]) : "";

		allHandsMatrix[yIndex + 2]?.[xIndex] ? handsInRadius.push(allHandsMatrix[yIndex + 2][xIndex]) : "";
		allHandsMatrix[yIndex + 1]?.[xIndex] ? handsInRadius.push(allHandsMatrix[yIndex + 1][xIndex]) : "";
		allHandsMatrix[yIndex - 1]?.[xIndex] ? handsInRadius.push(allHandsMatrix[yIndex - 1][xIndex]) : "";
		allHandsMatrix[yIndex - 2]?.[xIndex] ? handsInRadius.push(allHandsMatrix[yIndex - 2][xIndex]) : "";

		allHandsMatrix[yIndex + 1]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex + 1][xIndex - 1]) : "";
		allHandsMatrix[yIndex]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex][xIndex - 1]) : "";
		allHandsMatrix[yIndex - 1]?.[xIndex - 1] ? handsInRadius.push(allHandsMatrix[yIndex - 1][xIndex - 1]) : "";

		allHandsMatrix[yIndex]?.[xIndex - 2] ? handsInRadius.push(allHandsMatrix[yIndex][xIndex - 2]) : "";

		return removeDuplicates(handsInRadius);
	}
}
