import getSizingInfo from "../../Shared/functions/getSizingInfo";
import getStarterActionSummary from "../../Shared/functions/getStarterActionSummary";

export default function getPreviousActionSummary(action, treeInfo) {
	const actionArray = action.split(",");
	actionArray.pop();
	const previousAction = actionArray.join(",");
	if (!previousAction) return getStarterActionSummary(treeInfo);
	else return getSizingInfo(previousAction, treeInfo).actionSummary;
}
