// React Stuff
import React from 'react';
import base from '../../../base';
import { UserContext } from '../../UserContext';
// Components
import TrainingSetup from './TrainingSetup';
// Shared Functions
import getSeatNamesFromNumberOfSeats from '../../Shared/functions/getSeatNamesFromNumberOfSeats';
import fetchTreeInfo from '../../Shared/functions/fetchTreeInfo';
// Helpers & Constants
import {
  getPositionsFromActionArray,
  isObjEmpty,
  removePositionText,
  getPositionFromAction,
  removeDuplicates,
} from '../../../helpers';
import { allTrainingScenarios } from '../../../constants';
import areOptionsValid from '../functions/areOptionsValid';
import { cloneDeep } from 'lodash';
import filterAndSortScenarios from '../functions/filterAndSortScenarios';
import TrainingController from './TrainingController';
import Loading from '../../Loading';
import fetchAvailableRanges from '../../Shared/functions/fetchAvailableRanges';
import subscriptionReducer from '../../Subscription/reducer/subscriptionReducer';

class RangeTrainer extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      trainingIsRunning: false,
      treesInfo: {},
      allTrainingSpots: [],
      availableScenarios: [],
      loading: true,
      allSelectedScenarioSpots: {},
      // Group these into options
      options: {
        heroPositions: [],
        oppPositions: [],
        scenarios: [],
        mix: '10p',
      },
    };
  }

  componentDidMount() {
    if(this.state.loading && this.context.currentTrees.length>0){
      this.handleTreeSelect();
    }
  }

  componentDidUpdate(){
    if(this.state.loading && this.context.currentTrees.length>0){
      this.handleTreeSelect();
    }
  }

  handleTreeSelect = async () => {
    if (!this.context.currentTrees.length) {
      debugger;
      this.setState({ loading: false });
      return;
    }
    const treeInfoPromises = [];
    const availableRangesPromises = [];
    const allTrainingSpots = [];
    const availableScenariosByTree = [];
    let allAvailableScenarios = [];
    // Get promises for all treesInfo to promise all it as an array
    this.context.currentTrees.forEach((tree) => {
      treeInfoPromises.push(fetchTreeInfo(tree.id));
      availableRangesPromises.push(fetchAvailableRanges(tree.id));
    });
    const treesInfoArray = await Promise.all(treeInfoPromises);
    const availableRangesArray = await Promise.all(availableRangesPromises);
    const treesInfoObject = {};
    // get highest amount of seats from the tree
    const seatNames = getSeatNamesFromNumberOfSeats(
      Math.max(...treesInfoArray.map((tree) => tree.seats))
    );
    // iterate over trees
    for (let i = 0; i < treesInfoArray.length; i++) {
      const treeID = this.context.currentTrees[i].id;
      treesInfoObject[treeID] = treesInfoArray[i];
      treesInfoObject[treeID].availableRanges = availableRangesArray[i];
      // get all the training spots as an object and add them to an array which will go in state
      const trainingSpots = this.getTrainingSpotsFromAvailableRanges(
        treesInfoObject[treeID].availableRanges
      );
      allTrainingSpots[treeID] = trainingSpots;
      // get the seats of the tree and use them to get tree's available scenarios
      const seats = getSeatNamesFromNumberOfSeats(treesInfoArray[i].seats);
      // get a big blob of all available scenarios for each tree
      const treeScenarios = Object.keys(
        this.groupSpotsIntoScenarios(trainingSpots, seats, seats)
      );
      availableScenariosByTree.push(treeScenarios);
      allAvailableScenarios.push(...treeScenarios);
    }

    // remove duplicates from the aforementioned blob and make sure they're in the right order
    allAvailableScenarios = filterAndSortScenarios(
      removeDuplicates(allAvailableScenarios)
    );
    // validate options
    const optionsAreValid = areOptionsValid(
      this.context.settings?.rtOptions,
      seatNames,
      allAvailableScenarios
    );
    const options = optionsAreValid
      ? this.context.settings.rtOptions
      : {
          heroPositions: seatNames,
          oppPositions: seatNames,
          scenarios: ['rfi', 'frfi', 'f3bet', 'f4bet'],
          mix: '10p',
        };
    this.setState({
      treesInfo: treesInfoObject,
      seatNames,
      allTrainingSpots,
      availableScenariosByTree,
      allAvailableScenarios,
      options,
      loading: false,
      advancedScenariosOpen:
        options.scenarios.length === allAvailableScenarios.length
          ? true
          : false,
    });
  };

  handleOptionClick = (e, option) => {
    // Get the option clicked + the previous selection state
    const clicked = e.target.name;
    let selectedOptions = cloneDeep(this.state.options[option]);
    // If all is clicked, check whether to fill in or remove all checkboxes based on corresponding option's potential full array
    // If not, check if mix is the selection, then whether to add or remove the value
    if (clicked === 'all') {
      const allOptions =
        option === 'scenarios'
          ? this.state.allAvailableScenarios
          : this.state.seatNames;
      if (selectedOptions.length === allOptions.length) selectedOptions = [];
      else {
        selectedOptions = cloneDeep(allOptions);
        if (option === 'scenarios')
          this.setState({ advancedScenariosOpen: true });
      }
    } else if (option === 'mix') selectedOptions = clicked;
    else if (selectedOptions.includes(clicked))
      selectedOptions.splice(selectedOptions.indexOf(clicked), 1);
    else selectedOptions.push(clicked);
    // Set the new options state
    const options = this.state.options;
    options[option] = selectedOptions;
    this.setState({ options });

    this.context.handleSettingsUpdate('rtOptions', options);
  };

  handleAdvancedScenariosOpen = (val) =>
    this.setState({ advancedScenariosOpen: val });

  getTrainingSpotsFromAvailableRanges = (availableRanges) => {
    const trainingSpots = availableRanges.reduce((acc, actionString) => {
      let actionArray = actionString.split(',');
      const last = actionArray.pop();
      const position = getPositionFromAction(last);
      const action = last.replace(position, '');
      const spot =
        actionArray.length > 0 ? actionArray.join() + ',' + position : position;

      if (!acc[spot]) acc[spot] = [action];
      else acc[spot].push(action);
      return acc;
    }, {});
    return trainingSpots;
  };

  groupSpotsIntoScenarios = (
    trainingSpots,
    positions,
    oppPositions,
    selectedScenarios = Object.keys(allTrainingScenarios)
  ) => {
    const allSpotsBucketedIntoScenarios = Object.keys(trainingSpots).reduce(
      (acc, actionString) => {
        const actionArray = actionString.split(',');
        const pos = actionArray.pop();
        const opponents = getPositionsFromActionArray(actionArray).filter(
          (player) => player !== pos
        );
        let scenario = '';
        // Filter out positions not being studied
        if (!positions.includes(pos)) return acc;
        // Then RFI positions since they'll be blank arrays
        if (actionArray.length === 0) scenario = 'rfi';
        // Limping is filtered out next based on the first element in the action array
        else if (removePositionText(actionArray[0]) === '1') {
          if (actionArray.length === 1) scenario = 'flmp';
          else if (actionArray.some((ele) => removePositionText(ele) === '3'))
            scenario = 'lmpj';
          else if (
            actionArray.length === 2 &&
            getPositionFromAction(actionArray[0]) === pos
          )
            scenario = 'fral';
          else if (actionArray.length === 3) scenario = 'flrr';
          else if (actionArray.length === 4) scenario = 'lrrr';
          else scenario = 'olmp';
        } else if (actionArray.length === 1) scenario = 'frfi';
        else if (actionArray.length === 2) {
          if (actionArray.some((act) => act.includes(pos))) scenario = 'f3bet';
          else if (
            actionArray.some((act) => act.length === 3 && act.includes('1'))
          )
            scenario = 'sqz';
          else scenario = 'f3betc';
        } else if (actionArray.length === 3) {
          if (actionArray.some((act) => act.length === 3 && act.includes('1')))
            scenario = 'fsqz';
          else if (getPositionsFromActionArray(actionArray).length === 2)
            scenario = 'f4bet';
          else scenario = 'f4betmw';
        } else if (
          actionArray.length === 4 &&
          getPositionsFromActionArray(actionArray).length === 2
        )
          scenario = 'f5bet';
        else if (
          actionArray.length === 5 &&
          getPositionsFromActionArray(actionArray).length === 2
        )
          scenario = 'f6bet';
        else if (
          actionArray.length === 6 &&
          getPositionsFromActionArray(actionArray).length === 2
        )
          scenario = 'f7bet';
        else if (
          actionArray.some((act) => act.length === 3 && act.includes('3'))
        )
          scenario = 'fjmw';
        else scenario = 'other';
        // Filter out if none of the opponents are selected
        if (
          !opponents.some((p) => oppPositions.includes(p)) &&
          scenario !== 'rfi'
        )
          return acc;

        if (!acc[scenario]) acc[scenario] = [actionString];
        else acc[scenario].push(actionString);
        return acc;
      },
      {}
    );
    return filterAndSortScenarios(
      allSpotsBucketedIntoScenarios,
      selectedScenarios
    );
  };

  clearAllSelections = (e) => {
    e.preventDefault();
    this.setState({
      options: {
        heroPositions: [],
        oppPositions: [],
        scenarios: [],
        mix: '10p',
      },
    });
  };

  launchTraining = (e) => {
    e.preventDefault();
    if (isObjEmpty(this.state.treesInfo)) {
      alert('Select at least one tree');
      return;
    }
    // Errors if no positions / scenarios selected
    if (!this.state.options.heroPositions.length) {
      alert('Select at least one position');
      return;
    }
    if (!this.state.options.oppPositions.length) {
      alert('Select at least one opponent position');
      return;
    }
    if (!this.state.options.scenarios.length) {
      alert('Select at least one scenario');
      return;
    }
    // Get all available scenario spots into an object where the key is the tree ID
    const allSelectedScenarioSpots = {};
    const treesWithNoSpots = [];
    this.context.currentTrees.forEach((tree) => {
      const scenarioSpots = this.groupSpotsIntoScenarios(
        this.state.allTrainingSpots[tree.id],
        this.state.options.heroPositions,
        this.state.options.oppPositions,
        this.state.options.scenarios
      );
      if (isObjEmpty(scenarioSpots)) treesWithNoSpots.push(tree.treeName);
      else allSelectedScenarioSpots[tree.id] = scenarioSpots;
    });
    if (treesWithNoSpots.length) {
      alert(
        `One or more trees has no spots with this configuration of options \nInvalid trees: ${treesWithNoSpots.join(
          ', '
        )}`
      );
      return;
    } else {
      this.setState({ allSelectedScenarioSpots, trainingIsRunning: true });
    }
  };

  render() {
    const { profileData, isProfileLoading } = this.props;
    if (isProfileLoading) return <Loading />;
    if (!profileData?.subscription_status)
      return (
        <div className="drd-txt">
          <span>
            You don't seem to have a subscription. Kindly contact the admin
          </span>
        </div>
      );
    // window.rt = this;
    let display;
    if (!this.state.loading && this.state.trainingIsRunning === false) {
      display = [
        <h1 key="header" className="page-heading">
          Range Trainer
        </h1>,
        <TrainingSetup
          key="content"
          loading={this.state.loading}
          treesInfo={this.state.treesInfo}
          handleTreeSelect={this.handleTreeSelect}
          allAvailableScenarios={this.state.allAvailableScenarios}
          seatNames={this.state.seatNames}
          selectedHeroPositions={this.state.options.heroPositions}
          selectedOppPositions={this.state.options.oppPositions}
          selectedScenarios={this.state.options.scenarios}
          mixOption={this.state.options.mix}
          handleOptionClick={this.handleOptionClick}
          clearAllSelections={this.clearAllSelections}
          launchTraining={this.launchTraining}
          advancedScenariosOpen={this.state.advancedScenariosOpen}
          handleAdvancedScenariosOpen={this.handleAdvancedScenariosOpen}
        />,
      ];
    } else if (!this.state.loading) {
      display = (
        <TrainingController
          numberOfTables={1}
          gameType={
            this.state.treesInfo[Object.keys(this.state.treesInfo)[0]].gameType
          } // this is hideous
          allTrainingSpots={this.state.allTrainingSpots}
          allSelectedScenarioSpots={this.state.allSelectedScenarioSpots}
          mixOption={this.state.options.mix}
          treesInfo={this.state.treesInfo}
        />
      );
    } else {
      display = <Loading />;
    }
    return <div>{display}</div>;
  }
}

function withUserProfile(WrappedComponent) {
  return function WrappedComponentWithUserProfile(props) {
    const profileData = subscriptionReducer((state) => state.profileData);
    const isProfileLoading = subscriptionReducer(
      (state) => state.isProfileLoading
    );
    return (
      <WrappedComponent
        profileData={profileData}
        isProfileLoading={isProfileLoading}
        {...props}
      />
    );
  };
}

export default withUserProfile(RangeTrainer);
