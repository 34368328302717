import React, { useState } from "react";

export default function AddNoteOverlay({ displayOverlay = false, addNote, closeNoteOverlay }) {
	const [note, setNote] = useState("");

	const handleButtonClick = (e) => {
		e.preventDefault();
		if (note !== "") addNote(note);
		setNote("");
	};

	const submitOnEnter = (e) => {
		if (e.which === 13 && !e.shiftKey) {
			addNote(note);
			setNote("");
		}
	};

	return (
		<div className={`screenshot-overlay ${displayOverlay ? "" : "display-none"}`} onClick={() => closeNoteOverlay()}>
			<div className={`add-site-cnt`} onClick={(e) => e.stopPropagation()}>
				<h4>Add Note</h4>
				<form className="note-add-form" onSubmit={(e) => handleButtonClick(e)}>
					<textarea
						id="note-input"
						className="note-add-textarea"
						value={note}
						onChange={(e) => setNote(e.target.value)}
						onKeyPress={(e) => submitOnEnter(e)}
					/>
					<button type="submit">Add</button>
				</form>
			</div>
		</div>
	);
}
