import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import HelpSharpIcon from "@material-ui/icons/Help";

export default function SettingsTooltip({ content }) {
	return (
		<Tooltip
			title={
				<span style={{ whiteSpace: "pre-line", padding: "0px" }} className="settings-tooltip">
					{content}
				</span>
			}
			placement="right">
			<HelpSharpIcon className="settings-tooltip-icon" fontSize="small" />
		</Tooltip>
	);
}
