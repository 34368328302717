import getSeatNamesFromNumberOfSeats from "../../Shared/functions/getSeatNamesFromNumberOfSeats";

export default function getActionKeyFromFilename(filename, numberOfSeats) {
	let seats = getSeatNamesFromNumberOfSeats(numberOfSeats);
	let seatIndex = -1;
	const actions = filename.split(".");
	let actionKey = "";

	for (var i = 0; i < actions.length; i++) {
		// 1. Increment seatIndex, representing the current player, loop if at the end of array
		seatIndex === seats.length - 1 ? (seatIndex = 0) : (seatIndex += 1);
		// 2. If the player folded, remove them from seats
		if (actions[i] === "0") {
			seats.splice(seatIndex, 1);
			seatIndex -= 1;
			continue;
		}
		// 3. Otherwise add them to the key
		else {
			actionKey += `,${seats[seatIndex]}${actions[i]}`;
		}
	}
	return actionKey.slice(1);
}
