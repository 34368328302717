import { Route, Redirect } from 'react-router-dom';
import { Suspense } from 'react';
import useLoginReducer from './Login/reducer/LoginReducer';
export const PublicRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useLoginReducer((state) => state);
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect to={'/home'} />
        ) : (
          <Suspense fallback={<></>}>
            <Component {...props} />
          </Suspense>
        )
      }
    />
  );
};
