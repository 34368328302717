// New method -> fetching range from google storage
// google cloud storage console https://console.cloud.google.com/storage/browser?project=aadojo-8bbac
export default async function fetchRange(tree, action, includeEv = false) {
	const response = await fetch(
		`https://storage.googleapis.com/aadojo-8bbac.appspot.com/rangeData/${tree}/${action.replaceAll(",", "%2C").replaceAll("+", "%2B")}.json`
	);
	// const response = await fetch(`http://naserv.dynu.net/DPM/rangeData/${tree}/${action.replaceAll(",", "%2C").replaceAll("+", "%2B")}.json`);
	// http://naserv.dynu.net/DPM/rangeData/${tree}${action}.json
	// "https://storage.googleapis.com/aadojo-8bbac.appspot.com/rangeData/${tree}/${action.replace(",", "%2C")}.json"

	if (!response.ok) {
		return res.text().then((text) => {
			throw new Error(text);
		});
	}
	const data = await response.json();

	let range = {};
	if (data?.ev_enabled && !includeEv) {
		for (let i in data) if (data[i].hasOwnProperty("f")) range[i] = data[i].f;
	} else range = data;

	return range;
}
