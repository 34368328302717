import onTouchScreen from "./onTouchScreen";
import MobileDetect from "mobile-detect";

// from https://stackoverflow.com/a/58171659

const onMobile = () => {
	let hasTouchScreen = onTouchScreen();

	const md = new MobileDetect(window.navigator.userAgent);
	const isMobileDetected = md.mobile() || md.phone() || md.tablet();

	return (hasTouchScreen && md.isPhoneSized(600)) || isMobileDetected;
};

export default onMobile;
