import React from "react";

export default function RangeCell({ classes, hand, freq, ev, large }) {
	return (
		<div className={classes} title={ev ? `EV: ${ev}` : ""} style={{ background: `rgba(120, 200, 255, ${freq})` }}>
			{`${hand} \n`}
			<span className={`freq ${large ? "freq-large" : ""}`}>{freq}</span>
		</div>
	);
}
