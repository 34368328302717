import { create } from 'zustand';
import resetService from './ResetService';
import useAlertReducer from '../../Login/reducer/AlertReducer';

const ResetPasswordReducer = create((set) => ({
  isResetLoading: false,
  successMessage: '',
  errorMessage: '',
  resetPwd: async (obj) => {
    try {
      set({ isResetLoading: true });
      const { data } = await resetService.resetPassword(obj);
      const plans = data.plans;
      set({
        plans,
        isResetLoading: false,
        successMessage: data && data.message,
      });
      const { success } = useAlertReducer.getState();
      success(data && data.message);
    } catch (err) {
      console.log('err', err?.response?.data?.message);
      const { error } = useAlertReducer.getState();
      error(err?.response?.data?.message ?? err.message);
      set({
        errorMessage: err?.response?.data?.message ?? err?.message,
        isResetLoading: false,
      });
    }
  },
}));

export default ResetPasswordReducer;
