import React from "react";
// Mui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

// Data table sub-components
import PositionRow from "./PositionRow";
import RFIPositionRow from "./RFIPositionRow";

export default function AccordionTable({ repScores, repIDsByPosition, scenarioRepData, handleSpotSelect, selectedSpot }) {
	const useStyles = makeStyles({
		tableContainer: {
			border: "none",
			maxWidth: 900,
			marginTop: "20px",
			backgroundColor: "maroon",
		},
		accuracy: {
			fontWeight: "bold",
			fontSize: 15,
			textShadow: "1px 1px #222",
		},
		icon: {
			color: "white",
		},
	});
	const classes = useStyles();
	const StyledTableCell = withStyles(() => ({
		head: {
			padding: "10px",
			backgroundColor: "black",
			color: "white",
		},
		body: {
			color: "white",
			border: "none",
			"& >button": {
				padding: -1,
			},
			"& >button:hover": {
				filter: "brightness(80%)",
			},
		},
	}))(TableCell);

	let RowComponent, tableHeaders;
	const allRepIDs = [];
	if (Array.isArray(repIDsByPosition[Object.keys(repIDsByPosition)[0]])) {
		// Gather all rep IDs for the Totals row at the bottom of the table
		Object.keys(repIDsByPosition).forEach((pos) => allRepIDs.push(...repIDsByPosition[pos]));
		// Set the row position to RFI
		RowComponent = RFIPositionRow;
		tableHeaders = (
			<TableRow>
				<StyledTableCell>Position</StyledTableCell>
				<StyledTableCell align="right">Reps</StyledTableCell>
				<StyledTableCell align="right">Accuracy %</StyledTableCell>
				<Tooltip
					title={<span style={{ fontSize: "1.4em" }}>Each inaccurate rep increases or decreases this factor by 1, depending on if you took the more/less aggressive action</span>}
					placement="top-start">
					<StyledTableCell align="right">Aggression</StyledTableCell>
				</Tooltip>
				<Tooltip
					title={<span style={{ fontSize: "1.4em" }}>Your aggregate accuracy over the last 50 reps - or 1000 reps for the "TOTAL" row. Each rep contributes up to 2% / 0.1%</span>}
					placement="top-start">
					<StyledTableCell align="right">Mastery</StyledTableCell>
				</Tooltip>
			</TableRow>
		);
	} else {
		Object.keys(repIDsByPosition).forEach((pos) => {
			Object.keys(repIDsByPosition[pos]).forEach((opp) => allRepIDs.push(...repIDsByPosition[pos][opp]));
		});
		RowComponent = PositionRow;
		tableHeaders = (
			<TableRow>
				<StyledTableCell>Your Position</StyledTableCell>
				<StyledTableCell align="right">Reps</StyledTableCell>
				<StyledTableCell align="right">Accuracy %</StyledTableCell>
				<StyledTableCell />
			</TableRow>
		);
	}

	//Mapping to an array with all the table rows, then pushing a row of all the totals
	const tableRows = Object.keys(repIDsByPosition).map((pos) => (
		<RowComponent
			key={pos}
			classes={classes}
			pos={pos}
			repScores={repScores}
			positionRepIDs={repIDsByPosition[pos]}
			scenarioRepData={scenarioRepData}
			handleSpotSelect={handleSpotSelect}
			selectedSpot={selectedSpot}
		/>
	));

	tableRows.push(
		<RowComponent
			key={"total"}
			classes={classes}
			pos={"TOTAL"}
			repScores={repScores}
			positionRepIDs={allRepIDs}
			scenarioRepData={scenarioRepData}
			handleSpotSelect={handleSpotSelect}
			selectedSpot={selectedSpot}
		/>
	);

	return (
		<TableContainer className={classes.tableContainer} component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>{tableHeaders}</TableHead>
				<TableBody>{tableRows}</TableBody>
			</Table>
		</TableContainer>
	);
}
