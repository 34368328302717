import { round } from "lodash";
import { getAccuracy } from "../../../helpers";

export default function getMasteryFactor(data, repScores, numberOfReps = 50) {
	let masteryFactor = 0;

	for (let i = 0; i < numberOfReps; i++) {
		if (data[i]) masteryFactor += getAccuracy(repScores[data[i]]) / numberOfReps;
		else break;
	}

	return round(masteryFactor, 1);
}
