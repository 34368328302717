import React from "react";
// Mui
import { withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// Data functions & components
import SpotDrilldown from "./SpotDrilldown";
// Shared functions and helpers
import addScore from "../../../Shared/functions/addScore";
import { getAccuracy, getColorFromPercentage } from "../../../../helpers";
import Tooltip from "@material-ui/core/Tooltip";

export default function PositionRow({ classes, pos, positionRepIDs, repScores, scenarioRepData, handleSpotSelect, selectedSpot }) {
	const [open, setOpen] = React.useState(false);
	const StyledTableCell = withStyles(() => ({
		head: {
			padding: "10px",
			backgroundColor: "black",
			color: "white",
		},
		body: {
			color: "white",
			border: "none",
			"& >button": {
				padding: -1,
			},
			"& >button:hover": {
				filter: "brightness(80%)",
			},
		},
	}))(TableCell);
	const ExpandableRow = withStyles(() => ({
		root: {
			backgroundColor: "#bdacac",
		},
	}))(TableRow);
	const StyledTableRow = withStyles(() => ({
		root: {
			backgroundColor: "rgba(0,0,0,0.4)",

			"&:nth-child(4n+1)": {
				backgroundColor: "rgba(0,0,0,0.5)",
			},
			"&:nth-child(4n+2)": {
				backgroundColor: "rgba(0,0,0,0.5)",
			},
			"&:nth-child(even)": {
				backgroundColor: "darkgrey",
			},
			"&:hover": {
				backgroundColor: "rgba(0,0,0,0.7)",
			},
		},
	}))(TableRow);

	let reps;
	let score = "0/0";
	if (pos !== "TOTAL") {
		reps = Object.keys(positionRepIDs).reduce((acc, cur) => {
			return acc + positionRepIDs[cur].length;
		}, 0);
		Object.keys(positionRepIDs).forEach((pos) => {
			Object.keys(positionRepIDs[pos]).forEach((opp) => {
				score = addScore(score, repScores[positionRepIDs[pos][opp]]);
			});
		});
	} else {
		reps = positionRepIDs.length;
		score = positionRepIDs.reduce((acc, id) => addScore(acc, repScores[id]), "0/0");
	}

	const clickRow = () => {
		if (pos === "TOTAL") return;
		setOpen(!open);
		if (selectedSpot && selectedSpot.startsWith(pos)) handleSpotSelect("", "");
	};

	let lastColumn;
	if (pos !== "TOTAL") {
		lastColumn = (
			<StyledTableCell align="right">
				<IconButton className={classes.icon} size="small">
					{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</StyledTableCell>
		);
	} else {
		lastColumn = <StyledTableCell align="right"></StyledTableCell>;
	}

	return (
		<React.Fragment>
			<StyledTableRow className={`${pos === "TOTAL" ? "totals-row" : ""}`} onClick={clickRow}>
				<StyledTableCell component="th" scope="row">
					{pos.toUpperCase()}
				</StyledTableCell>
				<StyledTableCell align="right">{reps}</StyledTableCell>
				<StyledTableCell align="right" className="data-stat" style={{ color: getColorFromPercentage(getAccuracy(score) / 100) }}>
					{getAccuracy(score)}
				</StyledTableCell>
				{lastColumn}
			</StyledTableRow>
			<ExpandableRow>
				<StyledTableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: "5%" }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<StyledTableCell>Against</StyledTableCell>
										<StyledTableCell>Reps</StyledTableCell>
										<StyledTableCell align="right">Accuracy</StyledTableCell>
										<Tooltip
											title={
												<span style={{ fontSize: "1.4em" }}>
													Each inaccurate rep increases or decreases this factor by 1, depending on if you took the more/less aggressive action
												</span>
											}
											placement="top-start">
											<StyledTableCell align="right">Aggression</StyledTableCell>
										</Tooltip>
										<Tooltip title={<span style={{ fontSize: "1.4em" }}>Your aggregate accuracy over the last 50 reps. Each rep contributes up to 2%</span>} placement="top-start">
											<StyledTableCell align="right">Mastery</StyledTableCell>
										</Tooltip>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(positionRepIDs).map((opps) => (
										<SpotDrilldown
											key={opps}
											pos={pos}
											opps={opps}
											data={positionRepIDs[opps]}
											repScores={repScores}
											scenarioRepData={scenarioRepData}
											handleSpotSelect={handleSpotSelect}
											selectedSpot={selectedSpot}
										/>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</StyledTableCell>
			</ExpandableRow>
		</React.Fragment>
	);
}
