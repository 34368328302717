import React from "react";

export default function PLOScrollBar({ numberOfHands, displayIndex, evSortArray }) {
	const size = evSortArray?.length;

	const height = Math.floor((numberOfHands * 2 * 560) / size);
	const marginTop = Math.floor(displayIndex / (size / 560));

	return (
		<div className={`plo-scroll-bar ${size < numberOfHands * 2 ? "display-none" : ""}`}>
			<div className="plo-scroll-handle" style={{ height: `${height}px`, marginTop: `${marginTop}px` }} />
		</div>
	);
}

