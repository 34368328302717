import { round } from "lodash";

export default function weightedRandomScenario(scenarioSpots) {
	let scenario;
	let total = 0;
	let pdf = {};
	let cdf = {};


	// generate prob density function from scenario spots
	Object.keys(scenarioSpots).forEach((scenario) => {
		// calculate average action length for scenarios with variable spots, like multiway
		const lengths = [];
		for (let i = 0; i < scenarioSpots[scenario].length; i++) {
			const spot = scenarioSpots[scenario][i];
			const length = spot.split(",").length;
			lengths.push(length);
		}
		const averageActionLength = lengths.reduce((acc, a) => acc + a, 0) / lengths.length;
		// 0.75 as the current scaling factor, each 'action' is 75% less likely to appear
		const prob = round(Math.pow(0.7, averageActionLength) * 10000, 0);
		pdf[scenario] = prob;
	});

	// generate cumulative dist function from prob dist f
	for (scenario in pdf) {
		const freq = pdf[scenario];
		cdf[scenario] = total + freq;
		total += freq;
	}

	// roll a number lower than the sum of all hand frequencies
	const roll = Math.floor(Math.random() * total);
	for (scenario in cdf) if (roll <= cdf[scenario]) return scenario;

	console.log("something went wrong with weightedRandomScenario. let dev know");
	return "something went wrong with weightedRandomScenario. let dev know";
}

// When the setting is turned on:

// 1. Equal frequency of scenarios
//				--> train all selected scenarios evenly, which is the current setup
// 2. More common scenarios more often
// 				--> train niche scenarios less often, but still have a chance for them to appear
