import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

export default function MarkedHandSnackBar({ markSuccess, setMarkSuccess }) {
	const handleClose = (e, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setMarkSuccess(false);
	};

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			open={markSuccess}
			autoHideDuration={2200}
			onClose={handleClose}
			message="Hand Marked"
            ContentProps={{ className: "hand-marked-snackbar" }}
		/>
	);
}
