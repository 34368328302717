import { getPositionFromAction } from "../../../helpers";

const betColorCSSClasses = ["call", "squeeze", "open", "_3-bet", "_4-bet", "_5-bet", "all-in"];

export default function getBetColors(actionString, seatNames) {
	const betColors = {};
	seatNames.forEach((seat) => (betColors[seat] = ""));
	if (!actionString) return betColors;

	let raiseLevel = 2;
	let isASqueeze = false;
	actionString.split(",").forEach((action) => {
		const pos = getPositionFromAction(action);
		const actionKey = action.replace(pos, "");

		if (actionKey === "3") betColors[pos] = "all-in";
		else if (actionKey === "1") {
			betColors[pos] = "call";
			isASqueeze = true;
		} else if (isASqueeze) {
			betColors[pos] = "squeeze";
			raiseLevel += 1;
			isASqueeze = false;
		} else {
			betColors[pos] = betColorCSSClasses[raiseLevel];
			raiseLevel += 1;
		}
	});
	return betColors;
}
