import base from "../base";
import emailjs from "emailjs-com";

const sendVerificationRequest = async (uid, name) => {
	// e.preventDefault(); //This is important, email won't send without it

	const user_id = await base.fetch(`emailjs/user_id`, { context: this });

	const data = {
		name: name,
		user_id: uid,
	};

	emailjs.send("service_gmail", "template_verification", data, user_id).then(
		(result) => {},
		(error) => {
			console.log(error.text);
		}
	);
};

export default sendVerificationRequest;
